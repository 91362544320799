import { computed, ref, useSlots, watch } from 'vue';
import { treeGridOption as defaultTreeGridOption } from '@/common/utils/define';
import {
  ColumnWithHideInfo,
  CustomColumn,
  CustomColumnsReadonly,
  GridSearchBarOption,
  TreeGridOption,
  TreeGridSortEventParameter,
} from '@/common/utils/types';
import {
  useCustomCellRender,
  useCustomColumns,
  useDeselect,
  useGridSetting,
  useGridTooltip,
  useSearchBar,
} from '@/common/components/molecules/grid/uses';
import { useClassName } from '@/common/components/molecules/grid/baseGrid.setup';
import { useInternational } from '@/common/locale';
import { ExportExcelOption } from '@/common/utils/exportExcelUtils';
import { COLUMN_EVENT_TYPE } from '@/common/components/molecules/grid/baseGrid.define';

export interface Props {
  columns: CustomColumn[] | CustomColumnsReadonly;
  rows: any[];
  columnEnvKey?: string;
  height?: number;
  selectedRows?: any[];
  checkedRows?: any[];
  searchWord?: string;
  option?: TreeGridOption;
  searchBarOption?: GridSearchBarOption & { useExternalSearchBar?: boolean };
  exportExcelOption?: ExportExcelOption;
  hasDynamicColumns?: boolean;
  refObj?: any;
}

export interface Emit {
  (e: 'click-cell', value: { field: string; value: any; row: any }): void;
  (e: 'click-cell-chip-more', value: any): void;
  (e: 'on-search', value: string): void;
  (e: 'page-change', value: any): void;
  (
    e: 'export-to-excel-from-server',
    value: { columns: CustomColumn[] | CustomColumnsReadonly },
  ): void;
  // v-model
  (e: 'update:selected-rows', value: any): void;
  (e: 'update:checked-rows', value: any): void;
  (e: 'update:search-word', value: string): void;
  (e: 'update:columns', value: CustomColumn[] | CustomColumnsReadonly): void;
  // ev-tree-grid-event
  (e: 'check-row', isCheck: boolean, index: number, data: any): void;
  (e: 'check-all', isCheck: boolean, checkedDataList: any): void;
  (e: 'click-row', value: any): void;
  (e: 'dblclick-row', value: any): void;
  (e: 'sortColumn', value: TreeGridSortEventParameter): void;
  (e: 'change-column-status', gridInfos: { columns: ColumnWithHideInfo[] }): void;
  (e: 'change-column-info', gridInfos: { type: string; columns: ColumnWithHideInfo[] }): void;
  (e: 'update:ref-obj', value: any): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();
  const slots = useSlots();
  const { searchWord, searchBarOption, filterSearchResultMV, filterTreeGridByFilterSearch } =
    useSearchBar(props, emit);

  const { gridSettingOption, setColumns: setColumnsForExcelDownload } = useGridSetting(props, emit);

  const treeGridOption = computed<TreeGridOption>(() => {
    const options = {
      ...defaultTreeGridOption,
      ...props.option,
      useGridSetting: gridSettingOption.value,
      columnMenuText: {
        ascending: t('WORD.ASCENDING'),
        descending: t('WORD.DESCENDING'),
        hide: t('WORD.HIDE'),
      },
      emptyText: t('MESSAGE.NO_DATA'),
    };

    if (searchBarOption.value.use && !searchBarOption.value.useClientFilter) {
      // Client Filter (Tree Grid 외부에서 필터링) 할 경우, Tree Grid 에 search word 넘기지 않음
      options.searchValue = searchWord.value;
    }

    return options;
  });
  const {
    computedColumns: treeGridColumns,
    customColumnFields,
    saveColumnInfo,
  } = useCustomColumns<Props['columns']>(props, treeGridOption.value, setColumnsForExcelDownload);

  const { optionClassName } = useClassName(props);

  const { slotNamesOfCustomCell } = useCustomCellRender();

  const { tooltipItems, customTooltipRef, clickCount, hideTooltip } = useGridTooltip(props, emit);

  const gridRef = ref();

  const refObj = computed<any>({
    get: () => props.refObj ?? {},
    set: (val) => emit('update:ref-obj', val),
  });

  const { selectedRows } = useDeselect(props, emit);

  const checkedRows = computed({
    get: () => props.checkedRows,
    set: (v) => {
      emit('update:checked-rows', v);
    },
  });

  const updateColumns = async (columns) => {
    await saveColumnInfo(columns);
    emit('update:columns', columns);
  };

  const emitEvent = {
    [COLUMN_EVENT_TYPE.DISPLAY]: (e) => {
      emit('change-column-status', e);
    },
    [COLUMN_EVENT_TYPE.SORT]: (e) => {
      emit('sortColumn', e);
    },
  };
  const onChangeColumnInfo = async (type, eventValue) => {
    const { columns } = eventValue ?? {};
    await updateColumns(columns);

    emitEvent[type]?.(eventValue);
    emit('change-column-info', { type, columns });
  };

  const filteredTreeData = computed(() => {
    const isTokenFilter = props?.searchBarOption?.use && props?.searchBarOption?.filterSearch?.use;
    const isClientSearchFilter =
      props?.searchBarOption?.use && props?.searchBarOption?.useClientFilter;

    if (isClientSearchFilter) {
      return props.rows;
    }

    if (isTokenFilter) {
      return filterTreeGridByFilterSearch([...props.rows], filterSearchResultMV.value);
    }

    return props.rows;
  });

  watch(
    () => props.rows,
    () => {
      if (!props.option?.maintainScrollOnUpdateRows) {
        const tableBody = gridRef?.value?.querySelector('.table-body');
        tableBody?.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      }
    },
  );

  const clickCell = (field, value, row) => {
    emit('click-cell', { field, value, row });
  };

  return {
    t,
    slots,
    gridRef,
    refObj,
    selectedRows,
    checkedRows,
    treeGridColumns,
    customColumnFields,
    treeGridOption,
    slotNamesOfCustomCell,
    clickCell,
    searchWord,
    searchBarOption,
    optionClassName,
    tooltipItems,
    customTooltipRef,
    clickCount,
    hideTooltip,
    onChangeColumnInfo,
    filterSearchResultMV,
    filterTreeGridByFilterSearch,
    filteredTreeData,
  };
};
