import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { BannerType } from '@/types/common.types';
import type { IconType } from '@/types/icon.types';
import { webStorageController } from '../utils/webStorage.util';

interface TopBannerContent {
  type: BannerType;
  title: string;
  contents?: string[];
  icon?: IconType | null;
}

export const useTopBannerStore = defineStore('topBanner', () => {
  const currentBannerKey = ref<string>('');
  const bannerContent = ref<TopBannerContent | null>(null);

  const resetBannerKey = () => {
    currentBannerKey.value = '';
  };

  const resetBannerContent = () => {
    bannerContent.value = null;
  };

  const onOpenBanner = (
    key: string,
    { type = 'default', title, contents = [], icon = null }: TopBannerContent,
  ) => {
    // key: env key 참고
    currentBannerKey.value = key;
    const isClosedOnce = webStorageController.getItem({
      key,
      type: 'local',
    });
    if (isClosedOnce) {
      return;
    }
    bannerContent.value = { type, title, contents, icon };
  };

  const onCloseBanner = () => {
    resetBannerKey();
    resetBannerContent();
  };

  const onClickCloseButton = () => {
    if (!currentBannerKey.value) {
      return;
    }

    webStorageController.setItem({
      type: 'local',
      key: currentBannerKey.value,
      value: true,
    });
    onCloseBanner();
  };

  return {
    bannerContent,

    resetBannerKey,
    onOpenBanner,
    onCloseBanner,
    onClickCloseButton,
  };
});
