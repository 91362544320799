import {
  DisplayStyleType,
  FilterWidgetChartType,
  LENGTH_RULE_KEY,
  TargetType,
  TextAlignType,
  WidgetChartType,
  WidgetColorOption,
  WidgetDataType,
  WidgetModeType,
  WidgetSeriesType,
  WidgetTitleStyle,
  WidgetType,
} from '@/dashboard/utils/types';
import { BY_TARGET_SERIES_TYPE } from '@/common/define/widget.define';
import { SelectboxItem } from '@/common/utils/types';
import { APPLICATION_DETAIL, DETAIL_COMPONENTS } from '@/common/define/slideDetail.define';
import { defineAsyncComponent } from 'vue';
import { i18n } from '@/common/locale';
import {
  getDbTabPanelInfo,
  getHostTabPanelInfo,
  getNetworkDeviceTabPanelInfo,
  getNetworkObjectTabPanelInfo,
  getWasTabPanelInfo,
} from '@/common/uses/useSlideDetail';
import { QUERY_TYPE } from '@/common/utils';

export const DEFAULT_DASHBOARD_GROUP_ID = {
  ALL: 'all',
  FAVORITE: 'favorite',
  SHARED: 'shared',
  FEATURED_DASHBOARD: 'featuredDashboard',
  FEATURED_REPORT: 'featuredReport',
  CUSTOM_REPORT: 'customReport',
};

export const DEFAULT_DASHBOARD_GROUP = [
  {
    dashboardGroupId: DEFAULT_DASHBOARD_GROUP_ID.ALL,
    dashboardGroupName: 'All',
  },
  {
    dashboardGroupId: DEFAULT_DASHBOARD_GROUP_ID.FAVORITE,
    dashboardGroupName: 'Favorite',
  },
  {
    dashboardGroupId: DEFAULT_DASHBOARD_GROUP_ID.SHARED,
    dashboardGroupName: 'Shared',
  },
  {
    dashboardGroupId: DEFAULT_DASHBOARD_GROUP_ID.FEATURED_DASHBOARD,
    dashboardGroupName: 'Featured Dashboard',
  },
  {
    dashboardGroupId: DEFAULT_DASHBOARD_GROUP_ID.FEATURED_REPORT,
    dashboardGroupName: 'Featured Report',
  },
  {
    dashboardGroupId: DEFAULT_DASHBOARD_GROUP_ID.CUSTOM_REPORT,
    dashboardGroupName: 'Report',
  },
];

export const DEFAULT_WIDGET_SIZE = {
  TIME_SERIES: {
    LINE: { width: 4, height: 6 },
    AREA: { width: 4, height: 6 },
    BAR: { width: 4, height: 6 },
    STACKED_AREA: { width: 4, height: 6 },
    STACKED_BAR: { width: 4, height: 6 },
  },
  SCATTER: {
    '': { width: 4, height: 6 },
  },
  ONE_DAY_COMPARE: {
    LINE: { width: 4, height: 6 },
    BAR: { width: 4, height: 6 },
  },
  EQUALIZER: {
    '': { width: 4, height: 6 },
  },
  SCOREBOARD: {
    STYLE_1: { width: 4, height: 6 },
    STYLE_2: { width: 6, height: 4 },
  },
  PIE: {
    PIE: { width: 4, height: 6 },
  },
  STACKED_PROPORTION_BAR: {
    '': { width: 4, height: 6 },
  },
  GAUGE: {
    GAUGE_CIRCLE: { width: 4, height: 6 },
    GAUGE_HALF_CIRCLE: { width: 4, height: 6 },
  },
  ACTION_VIEW: {
    STYLE_1: { width: 4, height: 6, minHeight: 5 },
    STYLE_2: { width: 4, height: 6 },
  },
  TABLE: {
    TABLE: { width: 4, height: 6 },
    TOP_3_CARD: { width: 4, height: 6 },
  },
  TOP_LIST: {
    SUMMARY_BAR: { width: 4, height: 6 },
  },
  TREE_GRID: {
    TREE_GRID: { width: 4, height: 6 },
  },
  CLUSTER_OVERVIEW: {
    '': { width: 4, height: 6 },
  },
  TOPOLOGY: {
    TOPOLOGY: { width: 4, height: 6 },
  },
  EXCLUSIVE: {
    STYLE_1: { width: 4, height: 6 },
    STYLE_2: { width: 4, height: 6 },
    STYLE_3: { width: 4, height: 6 },
  },
  STATUS: {
    HEXA: { width: 4, height: 6 },
  },
  STATUS_SUMMARY: {
    CARD: { width: 4, height: 6 },
    PIE: { width: 4, height: 6 },
  },
  SERVICE_CARD: {
    STYLE_1: { width: 4, height: 6 },
    STYLE_2: { width: 4, height: 6 },
  },
  GROUP: {
    '': {
      width: 16,
      height: 2,
    },
  },
  TAB_GROUP: {
    '': {
      width: 16,
      height: 2,
    },
  },
  MEMO: {
    '': {
      width: 4,
      height: 6,
    },
  },
  TEXT: {
    '': {
      width: 4,
      height: 1,
    },
  },
  IMAGE: {
    '': {
      width: 4,
      height: 6,
    },
  },
  HORIZONTAL_FILTER: {
    HORIZONTAL: {
      width: 14,
      height: 3,
    },
    VERTICAL: {
      width: 4,
      height: 10,
    },
  },
  VERTICAL_FILTER: {
    HORIZONTAL: {
      width: 14,
      height: 3,
    },
    VERTICAL: {
      width: 4,
      height: 10,
    },
  },
  SERVER: {
    SERVER: { width: 4, height: 6 },
  },
} as const;

export const WIDGET_TIME_PERIOD = {
  p5m: {
    display: 'Past 5 Minutes',
    interval: 'I5s',
  },
  p10m: {
    display: 'Past 10 Minutes',
    interval: 'I5s',
  },
  p30m: {
    display: 'Past 30 Minutes',
    interval: 'I1m',
  },
  p1h: {
    display: 'Past 1 Hours',
    interval: 'I1m',
  },
  p3h: {
    display: 'Past 3 Hours',
    interval: 'I10m',
  },
  p6h: {
    display: 'Past 6 Hours',
    interval: 'I10m',
  },
  p12h: {
    display: 'Past 12 Hours',
    interval: 'I10m',
  },
  p1d: {
    display: 'Past 1 Day',
    interval: 'I10m',
  },
};

export const TIME_PERIOD_LIST: SelectboxItem[] = [
  {
    name: '$Global Time',
    value: 'globalTime',
  },
  ...Object.keys(WIDGET_TIME_PERIOD).map((key) => ({
    name: WIDGET_TIME_PERIOD[key].display,
    value: key,
  })),
  {
    name: 'WORD.SELECT_FROM_CALENDAR',
    value: 'calendar',
  },
];

export const SCATTER_TIME_PERIOD_LIST = ['p5m', 'p10m'] as const;

export const WIDGET_MODE_TYPE: Record<string, WidgetModeType> = {
  VIEW: 'view',
  EDIT: 'edit',
  PREVIEW: 'preview',
};

export const DEFAULT_WIDGET_TITLE_STYLE: WidgetTitleStyle = {
  fontSize: '15px',
  color: 'var(--widget-frame-default-font-color)',
  textAlign: 'left',
} as const;

export const DEFAULT_WIDGET_COLOR_OPTION: WidgetColorOption = {
  background: 'var(--widget-frame-default-bg-color)',
  border: 'var(--widget-frame-default-border-color)',
} as const;

export const WIDGET_TYPE_KEY: Record<WidgetType, WidgetType> = {
  GRAPHS: 'GRAPHS',
  LIST: 'LIST',
  ARCHITECTURE: 'ARCHITECTURE',
  SERVICE: 'SERVICE',
  ETC: 'ETC',
  MEMO: 'MEMO',
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  GROUP: 'GROUP',
  FILTER: 'FILTER',
};

export const AllWidgetTypes = Object.values(WIDGET_TYPE_KEY);

export const WIDGET_CHART_TYPE_KEY: Record<WidgetChartType, WidgetChartType> = {
  TIME_SERIES: 'TIME_SERIES',
  SCATTER: 'SCATTER',
  ONE_DAY_COMPARE: 'ONE_DAY_COMPARE',
  EQUALIZER: 'EQUALIZER',
  SCOREBOARD: 'SCOREBOARD',
  PIE: 'PIE',
  STACKED_PROPORTION_BAR: 'STACKED_PROPORTION_BAR',
  GAUGE: 'GAUGE',
  ACTION_VIEW: 'ACTION_VIEW',
  TABLE: 'TABLE',
  TOP_LIST: 'TOP_LIST',
  TREE_GRID: 'TREE_GRID',
  // CLUSTER_OVERVIEW: 'CLUSTER_OVERVIEW',
  TOPOLOGY: 'TOPOLOGY',
  EXCLUSIVE: 'EXCLUSIVE',
  STATUS: 'STATUS',
  STATUS_SUMMARY: 'STATUS_SUMMARY',
  SERVICE_CARD: 'SERVICE_CARD',
  MEMO: 'MEMO',
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  GROUP: 'GROUP',
  TAB_GROUP: 'TAB_GROUP',
  HORIZONTAL_FILTER: 'HORIZONTAL_FILTER',
  VERTICAL_FILTER: 'VERTICAL_FILTER',
  SERVER: 'SERVER',
  LEGEND: 'LEGEND',
};

export const validStatByChartType: Partial<Record<WidgetChartType, string[]>> = {
  [WIDGET_CHART_TYPE_KEY.ACTION_VIEW]: ['app_activity_monitor'],
};

export const DISPLAY_STYLE_KEY: Record<DisplayStyleType, DisplayStyleType> = {
  LINE: 'LINE',
  AREA: 'AREA',
  BAR: 'BAR',
  STACKED_AREA: 'STACKED_AREA',
  STACKED_BAR: 'STACKED_BAR',
  STYLE_1: 'STYLE_1',
  STYLE_2: 'STYLE_2',
  STYLE_3: 'STYLE_3',
  HEXA: 'HEXA',
  CARD: 'CARD',
  TABLE: 'TABLE',
  TOP_3_CARD: 'TOP_3_CARD',
  SUMMARY_BAR: 'SUMMARY_BAR',
  PIE: 'PIE',
  TREE_GRID: 'TREE_GRID',
  TOPOLOGY: 'TOPOLOGY',
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
  SERVER: 'SERVER',
  GAUGE_HALF_CIRCLE: 'GAUGE_HALF_CIRCLE',
  GAUGE_CIRCLE: 'GAUGE_CIRCLE',
  '': '',
};

export const getWidgetTypeList = (): { name: string; value: WidgetType }[] => [
  { name: i18n.global.t('WORD.CREATE_GROUP_WIDGET'), value: WIDGET_TYPE_KEY.GROUP },
  { name: i18n.global.t('WORD.GRAPHS'), value: WIDGET_TYPE_KEY.GRAPHS },
  { name: i18n.global.t('WORD.LIST'), value: WIDGET_TYPE_KEY.LIST },
  { name: i18n.global.t('WORD.ARCHITECTURE'), value: WIDGET_TYPE_KEY.ARCHITECTURE },
  { name: i18n.global.t('WORD.BUSINESS'), value: WIDGET_TYPE_KEY.SERVICE },
  { name: i18n.global.t('WORD.ETC'), value: WIDGET_TYPE_KEY.ETC },
  { name: i18n.global.t('WORD.FILTER'), value: WIDGET_TYPE_KEY.FILTER },
];

export const getWidgetChartTypeList = (): Record<
  WidgetType,
  { name: string; value: WidgetChartType }[]
> => {
  return {
    GRAPHS: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.TIME_SERIES'),
        value: WIDGET_CHART_TYPE_KEY.TIME_SERIES,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.SCATTER'),
        value: WIDGET_CHART_TYPE_KEY.SCATTER,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.ONE_DAY_COMPARE'),
        value: WIDGET_CHART_TYPE_KEY.ONE_DAY_COMPARE,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.EQUALIZER'),
        value: WIDGET_CHART_TYPE_KEY.EQUALIZER,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.SCOREBOARD'),
        value: WIDGET_CHART_TYPE_KEY.SCOREBOARD,
      },
      { name: i18n.global.t('WORD.UI.DASHBOARD.PIE'), value: WIDGET_CHART_TYPE_KEY.PIE },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STACKED_PROPORTION_BAR'),
        value: WIDGET_CHART_TYPE_KEY.STACKED_PROPORTION_BAR,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.GAUGE'),
        value: WIDGET_CHART_TYPE_KEY.GAUGE,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.ACTION_VIEW'),
        value: WIDGET_CHART_TYPE_KEY.ACTION_VIEW,
      },
    ],
    LIST: [
      // { name: i18n.global.t('WORD.UI.DASHBOARD.TABLE'), value: WIDGET_CHART_TYPE_KEY.TABLE },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.TREE_GRID'),
        value: WIDGET_CHART_TYPE_KEY.TREE_GRID,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.TOP_LIST'),
        value: WIDGET_CHART_TYPE_KEY.TOP_LIST,
      },
    ],
    ARCHITECTURE: [
      // { name: 'WORD.UI.DASHBOARD.CLUSTER_OVERVIEW', value: 'CLUSTER_OVERVIEW' },
      // { name: 'WORD.UI.DASHBOARD.TOPOLOGY', value: 'TOPOLOGY' },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.EXCLUSIVE'),
        value: WIDGET_CHART_TYPE_KEY.EXCLUSIVE,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STATUS'),
        value: WIDGET_CHART_TYPE_KEY.STATUS,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STATUS_SUMMARY'),
        value: WIDGET_CHART_TYPE_KEY.STATUS_SUMMARY,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.TOPOLOGY'),
        value: WIDGET_CHART_TYPE_KEY.TOPOLOGY,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.SERVER'),
        value: WIDGET_CHART_TYPE_KEY.SERVER,
      },
    ],
    SERVICE: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.CARD'),
        value: WIDGET_CHART_TYPE_KEY.SERVICE_CARD,
      },
    ],
    ETC: [
      { name: i18n.global.t('WORD.UI.DASHBOARD.MEMO'), value: WIDGET_CHART_TYPE_KEY.MEMO },
      { name: i18n.global.t('WORD.UI.DASHBOARD.TEXT'), value: WIDGET_CHART_TYPE_KEY.TEXT },
      { name: i18n.global.t('WORD.UI.DASHBOARD.IMAGE'), value: WIDGET_CHART_TYPE_KEY.IMAGE },
    ],
    MEMO: [{ name: i18n.global.t('WORD.UI.DASHBOARD.MEMO'), value: WIDGET_CHART_TYPE_KEY.MEMO }],
    TEXT: [{ name: i18n.global.t('WORD.UI.DASHBOARD.TEXT'), value: WIDGET_CHART_TYPE_KEY.TEXT }],
    IMAGE: [{ name: i18n.global.t('WORD.UI.DASHBOARD.IMAGE'), value: WIDGET_CHART_TYPE_KEY.IMAGE }],
    GROUP: [
      { name: i18n.global.t('WORD.UI.DASHBOARD.GROUP'), value: WIDGET_CHART_TYPE_KEY.GROUP },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.TAB_GROUP'),
        value: WIDGET_CHART_TYPE_KEY.TAB_GROUP,
      },
    ],
    FILTER: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.HORIZONTAL_FILTER'),
        value: WIDGET_CHART_TYPE_KEY.HORIZONTAL_FILTER,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.VERTICAL_FILTER'),
        value: WIDGET_CHART_TYPE_KEY.VERTICAL_FILTER,
      },
    ],
  };
};

export const getChartTypeDescription = (): Record<
  Exclude<WidgetChartType, FilterWidgetChartType>,
  string
> => {
  return {
    TIME_SERIES: i18n.global.t('MESSAGE.UI.DASHBOARD.TIME_SERIES'),
    SCATTER: i18n.global.t('MESSAGE.UI.DASHBOARD.SCATTER'),
    ONE_DAY_COMPARE: i18n.global.t('MESSAGE.UI.DASHBOARD.ONE_DAY_COMPARE'),
    EQUALIZER: i18n.global.t('MESSAGE.UI.DASHBOARD.EQUALIZER'),
    SCOREBOARD: i18n.global.t('MESSAGE.UI.DASHBOARD.SCOREBOARD'),
    GAUGE: i18n.global.t('MESSAGE.UI.DASHBOARD.GAUGE'),
    PIE: i18n.global.t('MESSAGE.UI.DASHBOARD.PIE'),
    STACKED_PROPORTION_BAR: i18n.global.t('MESSAGE.UI.DASHBOARD.STACKED_PROPORTION_BAR'),
    ACTION_VIEW: i18n.global.t('MESSAGE.UI.DASHBOARD.ACTION_VIEW'),
    TABLE: i18n.global.t('MESSAGE.UI.DASHBOARD.TABLE'),
    TOP_LIST: i18n.global.t('MESSAGE.UI.DASHBOARD.TOP_LIST'),
    TREE_GRID: i18n.global.t('MESSAGE.UI.DASHBOARD.TREE_GRID'),
    // CLUSTER_OVERVIEW: i18n.global.t('DESC.UI.CLUSTER_OVERVIEW_CHART'),
    TOPOLOGY: '',
    EXCLUSIVE: '',
    STATUS: i18n.global.t('MESSAGE.UI.DASHBOARD.STATUS'),
    STATUS_SUMMARY: i18n.global.t('MESSAGE.UI.DASHBOARD.STATUS_SUMMARY'),
    SERVICE_CARD: i18n.global.t('MESSAGE.UI.DASHBOARD.SERVICE_CARD'),
    MEMO: '',
    TEXT: '',
    GROUP: '',
    TAB_GROUP: '',
    IMAGE: '',
    SERVER: i18n.global.t('MESSAGE.UI.DASHBOARD.SERVER'),
    LEGEND: '',
  };
};

export const getDisplayStyleDescription = (): Record<string, string> => {
  return {
    TIME_SERIES_LINE: i18n.global.t('MESSAGE.UI.DASHBOARD.TIME_SERIES_LINE'),
    TIME_SERIES_AREA: i18n.global.t('MESSAGE.UI.DASHBOARD.TIME_SERIES_AREA'),
    TIME_SERIES_BAR: i18n.global.t('MESSAGE.UI.DASHBOARD.TIME_SERIES_BAR'),
    TIME_SERIES_STACKED_AREA: i18n.global.t('MESSAGE.UI.DASHBOARD.TIME_SERIES_STACKED_AREA'),
    TIME_SERIES_STACKED_BAR: i18n.global.t('MESSAGE.UI.DASHBOARD.TIME_SERIES_STACKED_BAR'),
    ONE_DAY_COMPARE_LINE: i18n.global.t('MESSAGE.UI.DASHBOARD.ONE_DAY_COMPARE_LINE'),
    ONE_DAY_COMPARE_BAR: i18n.global.t('MESSAGE.UI.DASHBOARD.ONE_DAY_COMPARE_BAR'),
    SCOREBOARD_STYLE_1: i18n.global.t('MESSAGE.UI.DASHBOARD.SCOREBOARD_STYLE_1'),
    SCOREBOARD_STYLE_2: i18n.global.t('MESSAGE.UI.DASHBOARD.SCOREBOARD_STYLE_2'),
    ACTION_VIEW_STYLE_1: i18n.global.t('MESSAGE.UI.DASHBOARD.ACTION_VIEW_STYLE_1'),
    ACTION_VIEW_STYLE_2: i18n.global.t('MESSAGE.UI.DASHBOARD.ACTION_VIEW_STYLE_2'),
    EXCLUSIVE_STYLE_1: i18n.global.t('MESSAGE.UI.DASHBOARD.EXCLUSIVE_STYLE_1'),
    EXCLUSIVE_STYLE_2: i18n.global.t('MESSAGE.UI.DASHBOARD.EXCLUSIVE_STYLE_2'),
    EXCLUSIVE_STYLE_3: i18n.global.t('MESSAGE.UI.DASHBOARD.EXCLUSIVE_STYLE_3'),
    TOPOLOGY_TOPOLOGY: i18n.global.t('MESSAGE.UI.DASHBOARD.TOPOLOGY'),
    STATUS_HEXA: i18n.global.t('MESSAGE.UI.DASHBOARD.STATUS_HEXA'),
    STATUS_SUMMARY_CARD: i18n.global.t('MESSAGE.UI.DASHBOARD.STATUS_SUMMARY_CARD'),
    TABLE_TOP_3_CARD: i18n.global.t('MESSAGE.UI.DASHBOARD.TABLE_TOP_3_CARD'),
    TOP_LIST_SUMMARY_BAR: i18n.global.t('MESSAGE.UI.DASHBOARD.TOP_LIST_SUMMARY_BAR'),
    SERVICE_CARD_STYLE_1: i18n.global.t('MESSAGE.UI.DASHBOARD.BUSINESS_CARD_STYLE_1'),
    SERVICE_CARD_STYLE_2: i18n.global.t('MESSAGE.UI.DASHBOARD.BUSINESS_CARD_STYLE_2'),
    GAUGE_GAUGE_HALF_CIRCLE: i18n.global.t('MESSAGE.UI.DASHBOARD.GAUGE_HALF_CIRCLE'),
    GAUGE_GAUGE_CIRCLE: i18n.global.t('MESSAGE.UI.DASHBOARD.GAUGE_CIRCLE'),
  };
};

export const getDisplayStyleList = (): Record<
  WidgetChartType,
  { name: string; value: DisplayStyleType }[]
> => {
  return {
    TIME_SERIES: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.LINE'),
        value: DISPLAY_STYLE_KEY.LINE,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.AREA'),
        value: DISPLAY_STYLE_KEY.AREA,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.BAR'),
        value: DISPLAY_STYLE_KEY.BAR,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STACKED_AREA'),
        value: DISPLAY_STYLE_KEY.STACKED_AREA,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STACKED_BAR'),
        value: DISPLAY_STYLE_KEY.STACKED_BAR,
      },
    ],
    SCATTER: [],
    ONE_DAY_COMPARE: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.LINE'),
        value: DISPLAY_STYLE_KEY.LINE,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.BAR'),
        value: DISPLAY_STYLE_KEY.BAR,
      },
    ],
    EQUALIZER: [],
    SCOREBOARD: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STYLE_1'),
        value: DISPLAY_STYLE_KEY.STYLE_1,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STYLE_2'),
        value: DISPLAY_STYLE_KEY.STYLE_2,
      },
    ],
    PIE: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.PIE'),
        value: DISPLAY_STYLE_KEY.PIE,
      },
    ],
    STACKED_PROPORTION_BAR: [],
    GAUGE: [
      // TODO: GAUGE_HALF_CIRCLE 추가
      // {
      //   name: i18n.global.t('WORD.UI.DASHBOARD.GAUGE_HALF_CIRCLE'),
      //   value: DISPLAY_STYLE_KEY.GAUGE_HALF_CIRCLE,
      // },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.GAUGE_CIRCLE'),
        value: DISPLAY_STYLE_KEY.GAUGE_CIRCLE,
      },
    ],
    ACTION_VIEW: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STYLE_1'),
        value: DISPLAY_STYLE_KEY.STYLE_1,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STYLE_2'),
        value: DISPLAY_STYLE_KEY.STYLE_2,
      },
    ],
    TABLE: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.TABLE'),
        value: DISPLAY_STYLE_KEY.TABLE,
      },
    ],
    TREE_GRID: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.TREE_GRID'),
        value: DISPLAY_STYLE_KEY.TREE_GRID,
      },
    ],
    TOP_LIST: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.SUMMARY_BAR'),
        value: DISPLAY_STYLE_KEY.SUMMARY_BAR,
      },
    ],
    // CLUSTER_OVERVIEW: [],
    TOPOLOGY: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.TOPOLOGY'),
        value: DISPLAY_STYLE_KEY.TOPOLOGY,
      },
      // { name: 'WORD.UI.DASHBOARD.STYLE_2', value: DISPLAY_STYLE_KEY.STYLE_2 },
      // { name: 'WORD.UI.DASHBOARD.STYLE_3', value: DISPLAY_STYLE_KEY.STYLE_3 },
    ],
    EXCLUSIVE: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STYLE_1'),
        value: DISPLAY_STYLE_KEY.STYLE_1,
      },
      // { name: 'WORD.UI.DASHBOARD.STYLE_2', value: DISPLAY_STYLE_KEY.STYLE_2 },
      // { name: 'WORD.UI.DASHBOARD.STYLE_3', value: DISPLAY_STYLE_KEY.STYLE_3 },
    ],
    STATUS: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.HEXA'),
        value: DISPLAY_STYLE_KEY.HEXA,
      },
    ],
    STATUS_SUMMARY: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.CARD'),
        value: DISPLAY_STYLE_KEY.CARD,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.PIE'),
        value: DISPLAY_STYLE_KEY.PIE,
      },
    ],
    SERVICE_CARD: [
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STYLE_1'),
        value: DISPLAY_STYLE_KEY.STYLE_1,
      },
      {
        name: i18n.global.t('WORD.UI.DASHBOARD.STYLE_2'),
        value: DISPLAY_STYLE_KEY.STYLE_2,
      },
    ],
    MEMO: [],
    TEXT: [],
    IMAGE: [],
    GROUP: [],
    TAB_GROUP: [],
    HORIZONTAL_FILTER: [
      { name: '가로형', value: DISPLAY_STYLE_KEY.HORIZONTAL },
      { name: '세로형', value: DISPLAY_STYLE_KEY.VERTICAL },
    ],
    VERTICAL_FILTER: [
      { name: '세로형', value: DISPLAY_STYLE_KEY.VERTICAL },
      { name: '가로형', value: DISPLAY_STYLE_KEY.HORIZONTAL },
    ],
    SERVER: [{ name: i18n.global.t('WORD.UI.DASHBOARD.SERVER'), value: DISPLAY_STYLE_KEY.SERVER }],
    LEGEND: [],
  };
};

export const WIDGET_FONT_SIZE_LIST = [
  {
    name: '12',
    value: '12px',
  },
  {
    name: '15',
    value: '15px',
  },
  {
    name: '20',
    value: '20px',
  },
  {
    name: '25',
    value: '25px',
  },
];

export const WIDGET_TEXT_ALIGN_LIST: {
  name: string;
  value: TextAlignType;
  iconClass: string;
}[] = [
  {
    name: 'Left align',
    value: 'left',
    iconClass: 'icon-outline-align-left',
  },
  {
    name: 'Center align',
    value: 'center',
    iconClass: 'icon-outline-align-center',
  },
  {
    name: 'Right align',
    value: 'right',
    iconClass: 'icon-outline-align-right',
  },
];

// 영문자 기준으로 작성
export const MAX_LENGTH_FOR_DASHBOARD: Record<LENGTH_RULE_KEY, number> = {
  NAME: 150,
  DESCRIPTION: 300,
  WIDGET_NAME: 60,
};

export const ALL_KEY = 'ALL';
export const CUSTOM_KEY = 'CUSTOM';
export const ASTER_KEY = '*';

export const MAX_CHART_DATA_COUNT = 26;
export const MAX_FORMULA_DATA_COUNT = 10;
export const MAX_THRESHOLDS_DATA_COUNT = 26;

export const BY_TARGET_CHART_TYPES: Partial<WidgetChartType>[] = [
  'SCATTER',
  'EQUALIZER',
  'STACKED_PROPORTION_BAR',
  'ACTION_VIEW',
  'EXCLUSIVE',
  'STATUS',
  'STATUS_SUMMARY',
  'TABLE',
  'SERVICE_CARD',
  'TOP_LIST',
  'PIE',
  'TREE_GRID',
];

export const BY_STAT_CHART_TYPES = [
  { chartType: 'ONE_DAY_COMPARE', displayStyle: DISPLAY_STYLE_KEY.LINE },
  { chartType: 'ONE_DAY_COMPARE', displayStyle: DISPLAY_STYLE_KEY.BAR },
  { chartType: 'SCOREBOARD', displayStyle: DISPLAY_STYLE_KEY.STYLE_2 },
] as const;

export const DATA_TYPE_LIST: { name: string; value: WidgetDataType }[] = [
  {
    name: 'Last',
    value: 'current',
  },
  {
    name: 'Max',
    value: 'max',
  },
  {
    name: 'Sum',
    value: 'sum',
  },
  {
    name: 'Avg',
    value: 'avg',
  },
  {
    name: 'Delta',
    value: 'delta',
  },
  {
    name: 'Delta(per Sec)',
    value: 'deltaPerSec',
  },
  {
    name: 'Sum(per Sec)',
    value: 'sumPerSec',
  },
];

export const SERIES_TYPES_FOR_STAT: { name: string; value: WidgetSeriesType }[] = [
  {
    name: 'Avg',
    value: 'avg',
  },
  {
    name: 'Max',
    value: 'max',
  },
  {
    name: 'Sum',
    value: 'sum',
  },
];

export const SERIES_TYPES_FOR_BY_TARGET: { name: string; value: WidgetSeriesType }[] = [
  {
    name: 'By',
    value: BY_TARGET_SERIES_TYPE,
  },
];

export const TARGET_TYPES: { name: string; value: TargetType }[] = [
  {
    name: 'Node',
    value: 'node',
  },
  {
    name: 'Pod',
    value: 'pod',
  },
  {
    name: 'Container',
    value: 'container',
  },
  {
    name: 'Application',
    value: 'application',
  },
  {
    name: 'Database',
    value: 'database',
  },
  {
    name: 'Host',
    value: 'host',
  },
  {
    name: 'Network Device',
    value: 'network_device',
  },
  {
    name: 'AWS DocumentDB',
    value: 'aws_documentdb',
  },
  {
    name: 'AWS DynamoDB',
    value: 'aws_dynamodb',
  },
  {
    name: 'AWS ElastiCache Cluster',
    value: 'aws_elasticache_cluster',
  },
  {
    name: 'AWS ElastiCache Shard',
    value: 'aws_elasticache_shard',
  },
  {
    name: 'AWS ElastiCache Node',
    value: 'aws_elasticache_node',
  },
  {
    name: 'AWS ElastiCache Serverless',
    value: 'aws_elasticache_serverless',
  },
  {
    name: 'AWS Redshift',
    value: 'aws_redshift',
  },
  {
    name: 'Kafka Broker',
    value: 'kafka_broker',
  },
  {
    name: 'Kafka Cluster Consumer Group',
    value: 'kafka_cluster_consumer_group',
  },
];

export const DATABASE_DETAIL_PARAMS = {
  sqlInfo: {
    oracle: [
      {
        field: 'planHashValue',
        dataType: 'string',
      },
      {
        field: 'dbType',
        dataType: 'string',
      },
      {
        field: 'instanceId',
        dataType: 'string',
      },
      {
        field: 'sqlId',
        dataType: 'string',
      },
      {
        field: 'schema',
        dataType: 'string',
      },
      {
        field: 'module',
        dataType: 'string',
      },
      {
        field: 'fromTime',
        dataType: 'string',
      },
      {
        field: 'toTime',
        dataType: 'string',
      },
    ],
    mysql: [
      {
        field: 'dbType',
        dataType: 'string',
      },
      {
        field: 'instanceId',
        dataType: 'string',
      },
      {
        field: 'schema',
        dataType: 'string',
      },
      {
        field: 'digest',
        dataType: 'string',
      },
      {
        field: 'sqlId', // NOTE: sql full text, sample text 연계용
        dataType: 'string',
      },
      {
        field: 'fromTime',
        dataType: 'string',
      },
      {
        field: 'toTime',
        dataType: 'string',
      },
      {
        field: 'sqlTextHashId',
        dataType: 'string',
      },
    ],
    sqlserver: [
      {
        field: 'dbType',
        dataType: 'string',
      },
      {
        field: 'fromTime',
        dataType: 'string',
      },
      {
        field: 'toTime',
        dataType: 'string',
      },
      {
        field: 'instanceId',
        dataType: 'string',
      },
      {
        field: 'sqlId',
        dataType: 'string',
      },
      {
        field: 'sqlTextHashId',
        dataType: 'string',
      },
      {
        field: 'sessionId',
        dataType: 'string',
      },
      {
        field: 'lastExecutionStartTime',
        dataType: 'string',
      },
      {
        field: 'lastExecutionEndTime',
        dataType: 'string',
      },
      {
        field: 'databaseName',
        dataType: 'string',
      },
      {
        field: 'objectName',
        dataType: 'string',
      },
      {
        field: 'sqlHash',
        dataType: 'string',
      },
      {
        field: 'sqlHandle',
        dataType: 'string',
      },
      {
        field: 'statementStartOffset',
        dataType: 'string',
      },
      {
        field: 'statementEndOffset',
        dataType: 'string',
      },
      {
        field: 'planHash',
        dataType: 'string',
      },
      {
        field: 'planHandle',
        dataType: 'string',
      },
    ],
    postgresql: [
      {
        field: 'dbType',
        dataType: 'string',
      },
      {
        field: 'instanceId',
        dataType: 'string',
      },
      {
        field: 'sqlId',
        dataType: 'string',
      },
      {
        field: 'queryId',
        dataType: 'string',
      },
      {
        field: 'dbId',
        dataType: 'number',
      },
      {
        field: 'dbUserId',
        dataType: 'number',
      },
      {
        field: 'fromTime',
        dataType: 'string',
      },
      {
        field: 'toTime',
        dataType: 'string',
      },
    ],
    [QUERY_TYPE.SLOW_QUERY]: [
      { field: 'sqlId', dataType: 'string' },
      { field: 'schema', dataType: 'string' },
      { field: 'threadId', dataType: 'number' },
      { field: 'time', dataType: 'string' },
      { field: 'fromTime', dataType: 'string' },
      { field: 'toTime', dataType: 'string' },
      { field: 'instanceId', dataType: 'string' },
    ],
  },
  sessionInfo: {
    oracle: [
      {
        field: 'sid',
        dataType: 'number',
      },
      {
        field: 'instanceId',
        dataType: 'string',
      },
      {
        field: 'serialNumber',
        dataType: 'number',
      },
      {
        field: 'fromTime',
        dataType: 'string',
      },
      {
        field: 'toTime',
        dataType: 'string',
      },
    ],
    mysql: [
      {
        field: 'threadId',
        dataType: 'number',
      },
      {
        field: 'instanceId',
        dataType: 'string',
      },
      {
        field: 'digest',
        dataType: 'string',
      },
      {
        field: 'fromTime',
        dataType: 'string',
      },
      {
        field: 'toTime',
        dataType: 'string',
      },
    ],
    sqlserver: [
      {
        field: 'sessionId',
        dataType: 'string',
      },
      {
        field: 'instanceId',
        dataType: 'string',
      },
      {
        field: 'fromTime',
        dataType: 'string',
      },
      {
        field: 'toTime',
        dataType: 'string',
      },
    ],
  },
};

export const SUMMARY_DETAIL_INFO = {
  etoeTrace: {
    ...DETAIL_COMPONENTS[APPLICATION_DETAIL.TRANSACTION],
    params: [
      {
        field: 'wasId',
        dataType: 'string',
      },
      {
        field: 'tid',
        dataType: 'string',
      },
      {
        field: 'collectTime',
        dataType: 'string',
      },
    ],
  },
  sqlInfo: {
    oracle: {
      component: defineAsyncComponent(
        () => import('@/database/components/sqlDetail/InstanceSqlDetailWithParams.vue'),
      ),
      params: DATABASE_DETAIL_PARAMS.sqlInfo.oracle,
    },
    mysql: {
      component: defineAsyncComponent(
        () => import('@/database/components/sqlDetail/InstanceSqlDetailWithParams.vue'),
      ),
      params: DATABASE_DETAIL_PARAMS.sqlInfo.mysql,
    },
    sqlserver: {
      component: defineAsyncComponent(
        () => import('@/database/components/sqlDetail/InstanceSqlDetailWithParams.vue'),
      ),
      params: DATABASE_DETAIL_PARAMS.sqlInfo.sqlserver,
    },
    postgresql: {
      component: defineAsyncComponent(
        () => import('@/database/components/sqlDetail/InstanceSqlDetailWithParams.vue'),
      ),
      params: DATABASE_DETAIL_PARAMS.sqlInfo.postgresql,
    },
    [QUERY_TYPE.SLOW_QUERY]: {
      component: defineAsyncComponent(
        () => import('@/database/components/slowQuerySqlDetail/SlowQuerySqlDetail.vue'),
      ),
      params: DATABASE_DETAIL_PARAMS.sqlInfo.slowQuery,
    },
  },
  application: {
    component: defineAsyncComponent(
      () => import('@/application/components/wasDetail/WasDetail.vue'),
    ),
    getParam: getWasTabPanelInfo,
  },
  database: {
    component: defineAsyncComponent(
      () => import('@/database/components/instanceDetail/InstanceDetail.vue'),
    ),
    getParam: getDbTabPanelInfo,
  },
  host: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/hostView/hostDetail/HostDetail.vue'),
    ),
    getParam: getHostTabPanelInfo,
  },
  networkDevice: {
    component: defineAsyncComponent(
      () =>
        import(
          '@/infrastructure/views/networkDeviceView/networkDeviceDetail/NetworkDeviceDetail.vue'
        ),
    ),
    getParam: getNetworkDeviceTabPanelInfo,
  },
  networkObject: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/npm/networkObject/NetworkObjectDetail.vue'),
    ),
    getParam: getNetworkObjectTabPanelInfo,
  },
} as const;

export const FIXED_DATA_ID = {
  STATUS: 'target_status_list',
  STATUS_SUMMARY: 'target_status_summary',
  TOPOLOGY: 'target_topology',
  SERVER: 'target_server',
};

export const TIME_RANGE_COUNT_BY_CHART_TYPE = {
  TABLE: 2,
  TREE_GRID: 2,
};

/* eslint-disable no-redeclare */
export const TimeArgumentList = ['fromTime', 'toTime'] as const;
export type TimeArgumentList = (typeof TimeArgumentList)[number];

export const WidgetCommonArgument: Record<WidgetChartType, Readonly<string[]> | undefined> = {
  TIME_SERIES: TimeArgumentList,
  SCATTER: undefined,
  ONE_DAY_COMPARE: undefined,
  EQUALIZER: undefined,
  SCOREBOARD: undefined,
  STACKED_PROPORTION_BAR: undefined,
  GAUGE: undefined,
  ACTION_VIEW: undefined,
  TREE_GRID: undefined,
  TABLE: undefined,
  TOP_LIST: undefined,
  EXCLUSIVE: undefined,
  STATUS: undefined,
  STATUS_SUMMARY: undefined,
  SERVICE_CARD: undefined,
  MEMO: undefined,
  TEXT: undefined,
  IMAGE: undefined,
  GROUP: undefined,
  TAB_GROUP: undefined,
  PIE: undefined,
  TOPOLOGY: undefined,
  HORIZONTAL_FILTER: undefined,
  VERTICAL_FILTER: undefined,
  SERVER: undefined,
  LEGEND: undefined,
} as const;

export const TIME_PERIOD_LIST_FOR_TOOLTIP = () => [
  {
    name: i18n.global.t('WORD.GLOBAL_TIME'),
    value: i18n.global.t('MESSAGE.UI.DASHBOARD_WIDGET_TIME_PERIOD_INFO.GLOBAL_TIME'),
  },
  {
    name: `${i18n.global.t('WORD.TIME_PERIOD_ITEMS.PAST_5_MINUTES')}, ${i18n.global.t(
      'WORD.TIME_PERIOD_ITEMS.PAST_10_MINUTES',
    )}`,
    value: i18n.global.t('MESSAGE.UI.DASHBOARD_WIDGET_TIME_PERIOD_INFO.P5M_P10M'),
  },
  {
    name: `${i18n.global.t('WORD.TIME_PERIOD_ITEMS.PAST_30_MINUTES')}, ${i18n.global.t(
      'WORD.TIME_PERIOD_ITEMS.PAST_1_HOURS',
    )}`,
    value: i18n.global.t('MESSAGE.UI.DASHBOARD_WIDGET_TIME_PERIOD_INFO.P30M_P1H'),
  },
  {
    name: `${i18n.global.t('WORD.TIME_PERIOD_ITEMS.PAST_3_HOURS')}, ${i18n.global.t(
      'WORD.TIME_PERIOD_ITEMS.PAST_6_HOURS',
    )}, ${i18n.global.t('WORD.TIME_PERIOD_ITEMS.PAST_12_HOURS')}, ${i18n.global.t(
      'WORD.TIME_PERIOD_ITEMS.PAST_1_DAY',
    )}`,
    value: i18n.global.t('MESSAGE.UI.DASHBOARD_WIDGET_TIME_PERIOD_INFO.P3H_P6H_P12H_P1D'),
  },
  {
    name: i18n.global.t('WORD.TIME_PERIOD_ITEMS.PAST_7_DAY'),
    value: i18n.global.t('MESSAGE.UI.DASHBOARD_WIDGET_TIME_PERIOD_INFO.P7D'),
  },
  {
    name: i18n.global.t('WORD.TIME_PERIOD_ITEMS.OVER_7_DAY'),
    value: i18n.global.t('MESSAGE.UI.DASHBOARD_WIDGET_TIME_PERIOD_INFO.OVER_P7D'),
  },
  {
    name: i18n.global.t('WORD.TIME_PERIOD_ITEMS.SELECT_TIME_RANGE'),
    value: i18n.global.t('MESSAGE.UI.DASHBOARD_WIDGET_TIME_PERIOD_INFO.SELECT_TIME_RANGE'),
  },
];
