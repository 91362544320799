/**
 * 10분 데이터
 */
import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { getMetricData } from '@/common/api/data';
import { transformMetricChartValues } from '@/database/utils/metricUtils';
import { checkValidMetric, mapMetricNameToDataId, MetricFailError } from '@/database/utils/utils';
import { DB_TYPE, MetricRequest } from '@/common/utils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { useStatInfoStore } from '@/common/stores/stat-info';
import { addXmUserEnvControllerAxios } from '@/openapi/metav8Over/api/xm-user-env-controller-api';

interface TrendSummaryStat {
  name: string;
  value: string;
}

interface TrendSummaryStatInfo {
  top: TrendSummaryStat;
  bottom: TrendSummaryStat;
}

interface TrendSummaryValue {
  top: any[];
  bottom: any[];
}

export interface TrendSummaryState {
  trendSummary: TrendSummaryValue;
  trendSummaryStat: TrendSummaryStatInfo;
  errorStatusText: string;
}

const TREND_SUMMARY_METRIC_NAMES_KEY = 'MYSQL_TREND_SUMMARY_METRIC_NAMES';

const mapTrendStat = (statInfo: TrendSummaryStatInfo) => {
  return {
    top: {
      ...statInfo.top,
      value: mapMetricNameToDataId({ dbType: DB_TYPE.MYSQL, metricName: statInfo.top.value }),
    },
    bottom: {
      ...statInfo.bottom,
      value: mapMetricNameToDataId({ dbType: DB_TYPE.MYSQL, metricName: statInfo.bottom.value }),
    },
  };
};

export const trendSummary: Module<TrendSummaryState, RootState> = {
  namespaced: true,
  state: {
    trendSummary: {
      top: [],
      bottom: [],
    },
    trendSummaryStat: {
      top: {
        name: '',
        value: '',
      },
      bottom: {
        name: '',
        value: '',
      },
    },
    errorStatusText: '',
  },
  mutations: {
    setTrendSummary: (state: TrendSummaryState, data: TrendSummaryValue) => {
      state.trendSummary = data;
    },
    setTrendSummaryStat: (state: TrendSummaryState, stat) => {
      state.trendSummaryStat = stat;
    },
    setErrorStatusText: (state: TrendSummaryState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    initTrendSummaryStat: ({ commit, rootGetters }) => {
      const defaultStatInfo = {
        top: {
          name: 'TPS',
          value: 'db_mysql_tps',
        },
        bottom: {
          name: 'Table Locks Waited',
          value: 'db_mysql_table_locks_waited',
        },
      };

      const statInfoInUserEnv = rootGetters['userEnv/getUserEnvMap'].get(
        TREND_SUMMARY_METRIC_NAMES_KEY,
      );

      if (statInfoInUserEnv) {
        commit('setTrendSummaryStat', mapTrendStat(JSON.parse(statInfoInUserEnv)));
        return;
      }

      commit('setTrendSummaryStat', defaultStatInfo);
    },
    fetchTrendSummary: async ({ commit, getters }, instanceId) => {
      const statInfoStore = useStatInfoStore();
      const frameName = 'TrendSummary';

      try {
        const selectedMetric: TrendSummaryStatInfo = getters.getTrendSummaryStat;

        const metricRequests = [
          selectedMetric.top.value,
          selectedMetric.bottom.value,
        ].map<MetricRequest>((name) => {
          const statInfo = statInfoStore.getStatInfo({ childCategory: 'mysql', statId: name });
          return {
            aggregationType: 'byTarget',
            period: 'p1h',
            interval: 'I10m',
            category: 'mysql',
            interpolateType: 'Null',
            dataId: name,
            summaryType: statInfo?.dataKind === 'current' ? 'avg' : 'sum',
            targetIds: [instanceId],
          };
        });

        const frameNamePrefix = FRAME_NAMES.MYSQL_SINGLE_VIEW.TREND_SUMMARY;
        const { data } = await getMetricData({
          metricV7Requests: metricRequests,
          frameName: `${frameNamePrefix} > ${selectedMetric.top.value}, ${selectedMetric.bottom.value}`,
          isTimeout: true,
        });

        const metricData: TrendSummaryValue = {
          top: [],
          bottom: [],
        };
        data?.data!.forEach((value) => {
          Object.keys(selectedMetric).forEach((key) => {
            if (
              value.dataDefinition?.dataId === selectedMetric[key].value ||
              value.dataDefinition?.dataId === `db_mysql_${selectedMetric[key].value}`
            ) {
              metricData[key] = transformMetricChartValues(value.metrics?.[0].values);
            }
          });
        });

        commit('setTrendSummary', metricData);

        checkValidMetric(data?.data ?? []);
        commit('setErrorStatusText', '');
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
    saveTrendSummaryStatInUserEnv: async ({ commit }, metricNames: TrendSummaryStatInfo) => {
      const trendSummaryStat = {
        top: metricNames[0],
        bottom: metricNames[1],
      };

      await addXmUserEnvControllerAxios({
        request: [
          {
            key: TREND_SUMMARY_METRIC_NAMES_KEY,
            value: JSON.stringify(trendSummaryStat),
          },
        ],
      });
      commit(
        'userEnv/setUserEnvMap',
        { key: TREND_SUMMARY_METRIC_NAMES_KEY, value: JSON.stringify(trendSummaryStat) },
        { root: true },
      );
    },
  },
  getters: {
    getTrendSummary: (state: TrendSummaryState) => state.trendSummary,
    getDefaultMetric: () => ({
      top: 'db_mysql_tps',
      bottom: 'db_mysql_table_locks_waited',
    }),
    getTrendSummaryStat: (state: TrendSummaryState) => state.trendSummaryStat,
    getErrorStatusText: (state: TrendSummaryState) => state.errorStatusText,
  },
};
