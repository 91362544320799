import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { StatValue } from '@/postgresql/store/singleView/dbStats';
import { getMetricData } from '@/common/api/data';
import { MetricRequest } from '@/common/utils';

export interface StatData {
  comparisonDate: StatValue[];
  today: StatValue[];
}

interface dbStatsBy10MinParams {
  instanceId: string;
  statNames: string[];
  interval: string;
  fromTime?: string;
  toTime?: string;
  period?: string;
  isToday: boolean;
}

interface State {
  stats: {
    [key: string]: StatData;
  };
}

export const defaultStatData = {
  comparisonDate: [],
  today: [],
};
export const dbStatsBy10Min: Module<State, RootState> = {
  namespaced: true,
  state: {
    stats: {},
  },
  mutations: {
    setStats: (state: State, { data, isToday }) => {
      const statObj = state.stats;
      const date = isToday ? 'today' : 'comparisonDate';
      data.forEach((item) => {
        if (!statObj[item.metricName]) {
          statObj[item.metricName] = { ...defaultStatData };
        }
        statObj[item.metricName][date] = item.values;
      });
      state.stats = { ...statObj };
    },
    initStatData(state: State) {
      state.stats = {};
    },
  },
  actions: {
    async fetchDBStatsBy10Min(
      { commit },
      { instanceId, fromTime, toTime, statNames, isToday }: dbStatsBy10MinParams,
    ) {
      const deduplicationNames: string[] = [...new Set(statNames)];
      const metricRequests = deduplicationNames.map<MetricRequest>((name) => ({
        aggregationType: 'byTarget',
        interval: 'I10m',
        category: 'postgresql',
        dataId: name,
        summaryType: 'delta',
        interpolateType: 'Null',
        fromTime,
        toTime,
        targetIds: [instanceId],
      }));
      const { data } = await getMetricData({
        metricV7Requests: metricRequests,
      });
      commit('setStats', { data: data?.data![0].metrics, isToday });
    },
  },
  getters: {
    getAllStatData: (state: State) => state.stats,
  },
};
