<script setup lang="ts">
import { RouterLink } from 'vue-router';
import { setup, type Props, type Emit } from './breadCrumbsButton.setup';

const props = defineProps<Props>();

const emit = defineEmits<Emit>();

const { buttonClass, onMouseEnter, onMouseClick, onMouseLeave } = setup(props, emit);
</script>

<template>
  <router-link
    v-if="props.to"
    :class="buttonClass"
    :to="props.to"
  >
    <span><slot /></span>
  </router-link>
  <button
    v-else
    type="button"
    :class="buttonClass"
    :data-bread-crumb-id="breadCrumbId"
    @mouseover="onMouseEnter"
    @click="onMouseClick"
    @mouseleave="onMouseLeave"
  >
    <span><slot /></span>
    <ev-icon icon="icon-arrow-down" />
  </button>
</template>

<style lang="scss" scoped>
.path-button {
  display: inline-flex;
  position: relative;
  align-items: center;
  max-width: 320px;
  padding: 2px 2px 2px 6px;
  cursor: pointer;
  border-radius: 2px;
  background-color: transparent;
  font-size: inherit;
  line-height: inherit;
  color: var(--color-gray-00-12);

  span {
    display: inline-block;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  i {
    pointer-events: none;
    background-color: var(--color-gray-00-12);
    transition: transform $animate-base;
  }

  &::after {
    pointer-events: none;
  }

  &:last-child {
    &::after {
      content: none;
    }
  }

  &--active,
  &:active {
    background-color: var(--app-header-path-active-bg-color);

    i {
      transform: rotate(-180deg);
    }
  }

  &:hover:not(&--active, &:active) {
    background-color: var(--app-header-path-hover-bg-color);
  }
}
</style>
