export const PA = {
  APPLICATION: {
    PA_TRANSACTION_TREND_DETAIL_DASHBOARD_SETTING_LIST:
      'PA_TRANSACTION_TREND_DETAIL_DASHBOARD_SETTING_LIST',

    GRIDCOLUMNS_pa_trendAnalysis_activeSession: 'GRIDCOLUMNS_pa_trendAnalysis_activeSession',
    GRIDCOLUMNS_pa_trendAnalysis_activeSessionSum: 'GRIDCOLUMNS_pa_trendAnalysis_activeSessionSum',
    GRIDCOLUMNS_pa_trendAnalysis_activeSessionProcess:
      'GRIDCOLUMNS_pa_trendAnalysis_activeSessionProcess',

    GRIDCOLUMNS_pa_transactionTrend_transactionSummary:
      'GRIDCOLUMNS_pa_transactionTrend_transactionSummary',
    GRIDCOLUMNS_pa_transactionTrend_transactionList:
      'GRIDCOLUMNS_pa_transactionTrend_transactionList',

    GRIDCOLUMNS_pa_exceptionSummary_exceptionList: 'GRIDCOLUMNS_pa_exceptionSummary_exceptionList',
    GRIDCOLUMNS_pa_exceptionSummary_totalExceptionCount:
      'GRIDCOLUMNS_pa_exceptionSummary_totalExceptionCount',
  },
  DATABASE: {
    // ORACLE
    GRIDCOLUMNS_pa_instanceSessionDetail_activeSessionHistory:
      'GRIDCOLUMNS_pa_instanceSessionDetail_activeSessionHistory',
    GRIDCOLUMNS_pa_instanceParameterDetail_parameterList:
      'GRIDCOLUMNS_pa_instanceParameterDetail_parameterList',

    GRIDCOLUMNS_pa_oracleSqlSummary_topSql: 'GRIDCOLUMNS_pa_oracleSqlSummary_topSql',

    GRIDCOLUMNS_pa_oracleTopNAnalysis_topSql: 'GRIDCOLUMNS_pa_oracleTopNAnalysis_topSql',
    GRIDCOLUMNS_pa_oracleTopNAnalysis_schema: 'GRIDCOLUMNS_pa_oracleTopNAnalysis_schema',
    GRIDCOLUMNS_pa_oracleTopNAnalysis_module: 'GRIDCOLUMNS_pa_oracleTopNAnalysis_module',

    GRIDCOLUMNS_pa_oraclePlanAnalysis_planAnalysisList:
      'GRIDCOLUMNS_pa_oraclePlanAnalysis_planAnalysisList',
    GRIDCOLUMNS_pa_oraclePlanAnalysis_sqlList: 'GRIDCOLUMNS_pa_oraclePlanAnalysis_sqlList',
    GRIDCOLUMNS_pa_oraclePlanAnalysis_plan: 'GRIDCOLUMNS_pa_oraclePlanAnalysis_plan',

    GRIDCOLUMNS_pa_oracleSearchSql_sqlList: 'GRIDCOLUMNS_pa_oracleSearchSql_sqlList',
    GRIDCOLUMNS_pa_oracleSearchSql_history: 'GRIDCOLUMNS_pa_oracleSearchSql_history',
    GRIDCOLUMNS_pa_oracleSearchSql_plan: 'GRIDCOLUMNS_pa_oracleSearchSql_plan',

    GRIDCOLUMNS_pa_oracleWaitSummary_waitSqlList: 'GRIDCOLUMNS_pa_oracleWaitSummary_waitSqlList',

    GRIDCOLUMNS_pa_oracleSearchSession_searchSessionList:
      'GRIDCOLUMNS_pa_oracleSearchSession_searchSessionList',

    GRIDCOLUMNS_pa_oracleTrendAnalysis_session: 'GRIDCOLUMNS_pa_oracleTrendAnalysis_session',
    GRIDCOLUMNS_pa_oracleTrendAnalysis_waitChains: 'GRIDCOLUMNS_pa_oracleTrendAnalysis_waitChains',
    GRIDCOLUMNS_pa_oracleTrendAnalysis_sqlSummary: 'GRIDCOLUMNS_pa_oracleTrendAnalysis_sqlSummary',
    GRIDCOLUMNS_pa_oracleTrendAnalysis_eventLeft: 'GRIDCOLUMNS_pa_oracleTrendAnalysis_eventLeft',
    GRIDCOLUMNS_pa_oracleTrendAnalysis_eventRight: 'GRIDCOLUMNS_pa_oracleTrendAnalysis_eventRight',
    GRIDCOLUMNS_pa_oracleTrendAnalysis_longSessionQuery:
      'GRIDCOLUMNS_pa_oracleTrendAnalysis_longSessionQuery',

    FILTERSEARCH_pa_oracleTrendAnalysis_session: 'FILTERSEARCH_pa_oracleTrendAnalysis_session',
    FILTERSEARCH_pa_oracleTrendAnalysis_waitChains:
      'FILTERSEARCH_pa_oracleTrendAnalysis_waitChains',

    GRIDCOLUMNS_pa_oracleParameterHistory_parameterList:
      'GRIDCOLUMNS_pa_oracleParameterHistory_parameterList',

    GRIDCOLUMNS_pa_oracleAlertHistory_alertList: 'GRIDCOLUMNS_pa_oracleAlertHistory_alertList',

    GRIDCOLUMNS_pa_oracleTablespace_asmGroup: 'GRIDCOLUMNS_pa_oracleTablespace_asmGroup',
    GRIDCOLUMNS_pa_oracleTablespace_top100Tablespace:
      'GRIDCOLUMNS_pa_oracleTablespace_top100Tablespace',
    GRIDCOLUMNS_pa_oracleTablespace_top100Segment: 'GRIDCOLUMNS_pa_oracleTablespace_top100Segment',

    GRIDCOLUMNS_pa_oracleUndoTemp_undoSessionList: 'GRIDCOLUMNS_pa_oracleUndoTemp_undoSessionList',
    GRIDCOLUMNS_pa_oracleUndoTemp_tempSessionList: 'GRIDCOLUMNS_pa_oracleUndoTemp_tempSessionList',

    GRIDCOLUMNS_pa_oracleSqlDetail_historyTab: 'GRIDCOLUMNS_pa_oracleSqlDetail_historyTab',
    GRIDCOLUMNS_pa_oracleSqlDetail_planTab: 'GRIDCOLUMNS_pa_oracleSqlDetail_planTab',

    // POSTGRES
    GRIDCOLUMNS_pa_postgresSqlTopNAnalysis_topSql: 'GRIDCOLUMNS_pa_postgresSqlTopNAnalysis_topSql',
    GRIDCOLUMNS_pa_postgresSqlTopNAnalysis_topUser:
      'GRIDCOLUMNS_pa_postgresSqlTopNAnalysis_topUser',

    GRIDCOLUMNS_pa_postgresSqlSearchSql_searchSqlList:
      'GRIDCOLUMNS_pa_postgresSqlSearchSql_searchSqlList',
    GRIDCOLUMNS_pa_postgresSqlSearchSql_searchSqlHistory:
      'GRIDCOLUMNS_pa_postgresSqlSearchSql_searchSqlHistory',

    GRIDCOLUMNS_pa_postgresSqlSearchSession_searchSessionList:
      'GRIDCOLUMNS_pa_postgresSqlSearchSession_searchSessionList',

    GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_backends:
      'GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_backends',
    GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_lockTree:
      'GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_lockTree',
    GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_topSql:
      'GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_topSql',
    GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_scanRatio:
      'GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_scanRatio',
    GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_topEvent:
      'GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_topEvent',
    GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_longActiveBackends:
      'GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_longActiveBackends',
    GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_applicationRatio:
      'GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_applicationRatio',
    GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_userRatio:
      'GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_userRatio',
    GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_alert: 'GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_alert',
    GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_Vacuum:
      'GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_Vacuum',
    GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_topObject:
      'GRIDCOLUMNS_pa_postgresSqlTrendAnalysis_topObject',

    FILTERSEARCH_pa_postgresSqlTrendAnalysis_backends:
      'FILTERSEARCH_pa_postgresSqlTrendAnalysis_backends',
    FILTERSEARCH_pa_postgresSqlTrendAnalysis_lockTree:
      'FILTERSEARCH_pa_postgresSqlTrendAnalysis_lockTree',
    FILTERSEARCH_pa_postgresSqlTrendAnalysis_Vacuum:
      'FILTERSEARCH_pa_postgresSqlTrendAnalysis_Vacuum',

    GRIDCOLUMNS_pa_postgresSqlParameterHistory_parameterList:
      'GRIDCOLUMNS_pa_postgresSqlParameterHistory_parameterList',

    GRIDCOLUMNS_pa_postgresSqlObjectSize_databaseSizeInfo:
      'GRIDCOLUMNS_pa_postgresSqlObjectSize_databaseSizeInfo',
    GRIDCOLUMNS_pa_postgresSqlObjectSize_topObject:
      'GRIDCOLUMNS_pa_postgresSqlObjectSize_topObject',
    GRIDCOLUMNS_pa_postgresSqlObjectSizeDetail_objectHistory:
      'GRIDCOLUMNS_pa_postgresSqlObjectSizeDetail_objectHistory',

    GRIDCOLUMNS_pa_postgresSqlSqlDetailSlide_planView:
      'GRIDCOLUMNS_pa_postgresSqlSqlDetailSlide_planView',
    GRIDCOLUMNS_pa_postgresSqlSessionDetailWindow_planView:
      'GRIDCOLUMNS_pa_postgresSqlSessionDetailWindow_planView',

    // MYSQL
    GRIDCOLUMNS_pa_mySqlTopNAnalysis_topSql: 'GRIDCOLUMNS_pa_mySqlTopNAnalysis_topSql',
    GRIDCOLUMNS_pa_mySqlTopNAnalysis_topSchema: 'GRIDCOLUMNS_pa_mySqlTopNAnalysis_topSchema',

    GRIDCOLUMNS_pa_mySqlSearchSql_searchSqlList: 'GRIDCOLUMNS_pa_mySqlSearchSql_searchSqlList',
    GRIDCOLUMNS_pa_mysqlSearchSql_searchSqlHistory:
      'GRIDCOLUMNS_pa_mysqlSearchSql_searchSqlHistory',
    GRIDCOLUMNS_pa_mysqlSearchSql_searchSqlPlan: 'GRIDCOLUMNS_pa_mysqlSearchSql_searchSqlPlan',

    GRIDCOLUMNS_pa_mySqlSearchSession_searchSessionList:
      'GRIDCOLUMNS_pa_mySqlSearchSession_searchSessionList',

    GRIDCOLUMNS_pa_mySqlTrendAnalysis_threads: 'GRIDCOLUMNS_pa_mySqlTrendAnalysis_threads',
    GRIDCOLUMNS_pa_mySqlTrendAnalysis_lockTree: 'GRIDCOLUMNS_pa_mySqlTrendAnalysis_lockTree',
    GRIDCOLUMNS_pa_mySqlTrendAnalysis_metaLockTree:
      'GRIDCOLUMNS_pa_mySqlTrendAnalysis_metaLockTree',
    GRIDCOLUMNS_pa_mySqlTrendAnalysis_topSql: 'GRIDCOLUMNS_pa_mySqlTrendAnalysis_topSql',
    GRIDCOLUMNS_pa_mySqlTrendAnalysis_topEvent: 'GRIDCOLUMNS_pa_mySqlTrendAnalysis_topEvent',
    GRIDCOLUMNS_pa_mySqlTrendAnalysis_longActiveThread:
      'GRIDCOLUMNS_pa_mySqlTrendAnalysis_longActiveThread',
    GRIDCOLUMNS_pa_mySqlTrendAnalysis_userConnectionInfo:
      'GRIDCOLUMNS_pa_mySqlTrendAnalysis_userConnectionInfo',
    GRIDCOLUMNS_pa_mySqlTrendAnalysis_alert: 'GRIDCOLUMNS_pa_mySqlTrendAnalysis_alert',
    GRIDCOLUMNS_pa_mySqlTrendAnalysis_slowQuery: 'GRIDCOLUMNS_pa_mySqlTrendAnalysis_slowQuery',

    FILTERSEARCH_pa_mySqlTrendAnalysis_threads: 'FILTERSEARCH_pa_mySqlTrendAnalysis_threads',
    FILTERSEARCH_pa_mySqlTrendAnalysis_lockTree: 'FILTERSEARCH_pa_mySqlTrendAnalysis_lockTree',
    FILTERSEARCH_pa_mySqlTrendAnalysis_metaLockTree:
      'FILTERSEARCH_pa_mySqlTrendAnalysis_metaLockTree',

    GRIDCOLUMNS_pa_mySqlParameterHistory_parameterList:
      'GRIDCOLUMNS_pa_mySqlParameterHistory_parameterList',

    GRIDCOLUMNS_pa_mySqlObjectSize_databaseSizeInfo:
      'GRIDCOLUMNS_pa_mySqlObjectSize_databaseSizeInfo',
    GRIDCOLUMNS_pa_mySqlObjectSize_topObject: 'GRIDCOLUMNS_pa_mySqlObjectSize_topObject',
    GRIDCOLUMNS_pa_mySqlObjectSizeDetail_objectHistory:
      'GRIDCOLUMNS_pa_mySqlObjectSizeDetail_objectHistory',

    GRIDCOLUMNS_pa_mySqlSqlDetailSlide_planView: 'GRIDCOLUMNS_pa_mySqlSqlDetailSlide_planView',

    // SQL SERVER
    GRIDCOLUMNS_pa_sqlServerTopNAnalysis_topSql: 'GRIDCOLUMNS_pa_sqlServerTopNAnalysis_topSql',
    GRIDCOLUMNS_pa_sqlServerTopNAnalysis_topProcedure:
      'GRIDCOLUMNS_pa_sqlServerTopNAnalysis_topProcedure',

    GRIDCOLUMNS_pa_sqlServerPlanAnalysis_planAnalysisList:
      'GRIDCOLUMNS_pa_sqlServerPlanAnalysis_planAnalysisList',
    GRIDCOLUMNS_pa_sqlServerPlanAnalysis_sqlList: 'GRIDCOLUMNS_pa_sqlServerPlanAnalysis_sqlList',
    GRIDCOLUMNS_pa_sqlServerPlanAnalysis_plan: 'GRIDCOLUMNS_pa_sqlServerPlanAnalysis_plan',

    GRIDCOLUMNS_pa_sqlServerSearchSql_searchSqlList:
      'GRIDCOLUMNS_pa_sqlServerSearchSql_searchSqlList',
    GRIDCOLUMNS_pa_sqlServerSearchSql_searchSqlHistory:
      'GRIDCOLUMNS_pa_sqlServerSearchSql_searchSqlHistory',
    GRIDCOLUMNS_pa_sqlServerSearchSql_plan: 'GRIDCOLUMNS_pa_sqlServerSearchSql_plan',

    GRIDCOLUMNS_pa_sqlServerSearchSession_searchSessionList:
      'GRIDCOLUMNS_pa_sqlServerSearchSession_searchSessionList',

    GRIDCOLUMNS_pa_sqlServerTrendAnalysis_session: 'GRIDCOLUMNS_pa_sqlServerTrendAnalysis_session',
    GRIDCOLUMNS_pa_sqlServerTrendAnalysis_blockedTop:
      'GRIDCOLUMNS_pa_sqlServerTrendAnalysis_blockedTop',
    GRIDCOLUMNS_pa_sqlServerTrendAnalysis_blockedBottom:
      'GRIDCOLUMNS_pa_sqlServerTrendAnalysis_blockedBottom',
    GRIDCOLUMNS_pa_sqlServerTrendAnalysis_sqlStat: 'GRIDCOLUMNS_pa_sqlServerTrendAnalysis_sqlStat',
    GRIDCOLUMNS_pa_sqlServerTrendAnalysis_wait: 'GRIDCOLUMNS_pa_sqlServerTrendAnalysis_wait',

    FILTERSEARCH_pa_sqlServerTrendAnalysis_session:
      'FILTERSEARCH_pa_sqlServerTrendAnalysis_session',
    FILTERSEARCH_pa_sqlServerTrendAnalysis_blockedTop:
      'FILTERSEARCH_pa_sqlServerTrendAnalysis_blockedTop',
    FILTERSEARCH_pa_sqlServerTrendAnalysis_blockedBottom:
      'FILTERSEARCH_pa_sqlServerTrendAnalysis_blockedBottom',

    GRIDCOLUMNS_pa_sqlServerParameterHistory_parameterList:
      'GRIDCOLUMNS_pa_sqlServerParameterHistory_parameterList',

    GRIDCOLUMNS_pa_sqlServerAlertHistory_alertList:
      'GRIDCOLUMNS_pa_sqlServerAlertHistory_alertList',

    GRIDCOLUMNS_pa_sqlServerDbInfo_dbSizeInfo: 'GRIDCOLUMNS_pa_sqlServerDbInfo_dbSizeInfo',
    GRIDCOLUMNS_pa_sqlServerDbInfo_dbInfo: 'GRIDCOLUMNS_pa_sqlServerDbInfo_dbInfo',
    GRIDCOLUMNS_pa_sqlServerDbInfo_dbFileInfo: 'GRIDCOLUMNS_pa_sqlServerDbInfo_dbFileInfo',
    GRIDCOLUMNS_pa_sqlServerDbInfo_topTable: 'GRIDCOLUMNS_pa_sqlServerDbInfo_topTable',

    GRIDCOLUMNS_pa_sqlServerSqlDetailSlide_plan: 'GRIDCOLUMNS_pa_sqlServerSqlDetailSlide_plan',
    GRIDCOLUMNS_pa_sqlServerSqlDetailSlide_parameterList:
      'GRIDCOLUMNS_pa_sqlServerSqlDetailSlide_parameterList',
    GRIDCOLUMNS_pa_sqlServerSessionDetailSlide_plan:
      'GRIDCOLUMNS_pa_sqlServerSessionDetailSlide_plan',
    GRIDCOLUMNS_pa_sqlServerSessionDetailSlide_parameterList:
      'GRIDCOLUMNS_pa_sqlServerSessionDetailSlide_parameterList',
    GRIDCOLUMNS_pa_sqlServerSessionDetailSlide_activeSessionHistory:
      'GRIDCOLUMNS_pa_sqlServerSessionDetailSlide_activeSessionHistory',
  },
  TOOL: {
    GRIDCOLUMNS_pa_queryMangerHistory_queryRunHistory:
      'GRIDCOLUMNS_pa_queryMangerHistory_queryRunHistory',
    GRIDCOLUMNS_pa_queryManger_queryEditorResult: 'GRIDCOLUMNS_pa_queryManger_queryEditorResult',
  },
};
