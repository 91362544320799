<script setup lang="ts">
import BaseHeader from '@/common/components/templates/BaseHeader.vue';
import TimePeriodIndicator from '@/common/components/molecules/timePeriodIndicator/TimePeriodIndicator.vue';
import {
  Emit,
  Props,
  setup,
} from '@/common/components/molecules/navigationHeader/navigationHeader.setup';
import { DEFAULT_PERIOD_ITEMS } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';
import BreadCrumbs from '@/common/components/molecules/breadCrumbs/BreadCrumbs.vue';

const props = withDefaults(defineProps<Props>(), {
  usePath: false,
  useTimePeriod: false,
  timePeriodItems: () => DEFAULT_PERIOD_ITEMS,
});
const emit = defineEmits<Emit>();

const { timeIndicatorRef, currentRoute, selectedPeriodInfo, onUpdatePeriodInfo } = setup(
  props,
  emit,
);

defineExpose({
  togglePause: () => {
    timeIndicatorRef.value?.togglePause();
  },
});
</script>

<template>
  <base-header class="navigation-header">
    <template #title>
      <div
        v-if="props.usePath"
        class="navigation-header__path"
      >
        <bread-crumbs />
      </div>
      <template v-else>
        <slot name="title" />
        <span class="navigation-header__name view-name">
          {{ currentRoute.text }}
        </span>
      </template>
    </template>
    <template #default>
      <slot />
      <div
        v-if="props.useTimePeriod"
        class="navigation-header__right"
      >
        <time-period-indicator
          ref="timeIndicatorRef"
          :period-info="selectedPeriodInfo"
          :period-items="props.timePeriodItems"
          :options="props.timePeriodOptions"
          class="navigation-header__time-period"
          @update-period-info="onUpdatePeriodInfo"
        />
        <slot name="action" />
      </div>
    </template>
  </base-header>
</template>

<style lang="scss">
.navigation-header {
  .app-additional-info {
    position: relative;
  }
  &__path {
    display: flex;
    align-items: center;
  }
  &__right {
    display: flex;
    position: absolute;
    top: 50%;
    right: 12px;
    gap: 13px;
    transform: translateY(-50%);
  }
}
</style>
