<script lang="ts" setup>
import BaseGridRatioBarCell from '@/common/components/molecules/BaseGridRatioBarCell.vue';
import BaseGridChipsCell from '@/common/components/molecules/baseGridChipsCell/BaseGridChipsCell.vue';
import BaseCustomTooltip from '@/common/components/molecules/baseCustomTooltip/BaseCustomTooltip.vue';
import {
  type Emit,
  type Props,
  setup,
} from '@/common/components/molecules/grid/baseTreeGrid.setup';
import { treeGridOption as defaultTreeGridOption } from '@/common/utils/define';
import ChipCellTooltipContent from '@/common/components/molecules/grid/ChipCellTooltipContent.vue';
import StatusTag from '@/common/components/molecules/statusTag/StatusTag.vue';
import { convertMsToSec, formatNumWithCommasAndDecimal } from '@/common/utils/commonUtils';
import FilterSearch from '@/common/components/molecules/filterSearch/FilterSearch.vue';
import { COLUMN_EVENT_TYPE } from '@/common/components/molecules/grid/baseGrid.define';
import { capitalize } from 'lodash-es';
import StatusTooltipTag from '@/common/components/molecules/statusTooltipTag/StatusTooltipTag.vue';

const props = withDefaults(defineProps<Props>(), {
  height: undefined,
  selectedRows: () => [],
  checkedRows: () => [],
  searchWord: undefined,
  columns: () => [],
  rows: () => [],
  option: () => structuredClone(defaultTreeGridOption),
  searchBarOption: () => ({
    use: false,
    mode: 'full',
    placeholder: '',
    filterSearch: {
      use: false,
    },
  }),
  exportExcelOption: () => ({}),
  hasDynamicColumns: false,
});
const emit = defineEmits<Emit>();

const {
  slots,
  gridRef,
  refObj,
  selectedRows,
  checkedRows,
  treeGridColumns,
  customColumnFields,
  treeGridOption,
  slotNamesOfCustomCell,
  clickCell,
  searchWord,
  searchBarOption,
  optionClassName,
  tooltipItems,
  customTooltipRef,
  clickCount,
  hideTooltip,
  onChangeColumnInfo,
  filterSearchResultMV,
  filteredTreeData,
} = setup(props, emit);
</script>

<template>
  <div
    ref="gridRef"
    :class="['base-tree-grid', 'page', optionClassName]"
  >
    <ev-tree-grid
      ref="refObj"
      v-model:selected="selectedRows"
      v-model:checked="checkedRows"
      :height="props?.height"
      :columns="treeGridColumns"
      :option="treeGridOption"
      :rows="filteredTreeData"
      @check-row="
        (v, i, d) => {
          emit('check-row', v, i, d);
        }
      "
      @check-all="
        (v, li) => {
          emit('check-all', v, li);
        }
      "
      @click-row="
        (v) => {
          emit('click-row', v);
        }
      "
      @dblclick-row="
        (v) => {
          emit('dblclick-row', v);
        }
      "
      @page-change="
        (v) => {
          emit('page-change', v);
        }
      "
      @change-column-status="(v) => onChangeColumnInfo(COLUMN_EVENT_TYPE.DISPLAY, v)"
      @sort-column="(v) => onChangeColumnInfo(COLUMN_EVENT_TYPE.SORT, v)"
      @resize-column="(v) => onChangeColumnInfo(COLUMN_EVENT_TYPE.RESIZE, v)"
    >
      <!-- toolbar -->
      <template
        v-if="slots.toolbar || props?.searchBarOption?.use"
        #toolbar
      >
        <div class="grid-toolbar">
          <!-- toolbar - search -->
          <div
            v-if="props?.searchBarOption?.use && !props?.searchBarOption.useExternalSearchBar"
            :class="[
              'grid-toolbar__row',
              'search',
              searchBarOption.mode,
              { 'with-grid-setting': props?.option?.useGridSetting?.use },
            ]"
          >
            <template v-if="props.searchBarOption.filterSearch?.use">
              <filter-search
                v-model:selected-values="filterSearchResultMV"
                :filter-items="props.searchBarOption?.filterSearch?.filterItems"
                :storage-key="props.searchBarOption.filterSearch?.storageKey ?? 'base-tree-grid'"
                :placeholder="props.searchBarOption.filterSearch?.placeholder"
              />
            </template>

            <template v-else>
              <ev-text-field
                v-model.trim="searchWord"
                :placeholder="searchBarOption.placeholder"
                :max-length="50"
              >
                <template #icon-prefix>
                  <ev-icon icon="ev-icon-search2" />
                </template>
              </ev-text-field>
              <div
                v-if="props?.searchBarOption?.use && slots.searchSide"
                class="grid-toolbar__search-side"
              >
                <slot name="searchSide" />
              </div>
            </template>
          </div>

          <!-- toolbar - custom slot -->
          <div
            v-if="slots.toolbar || props?.option?.useGridSetting?.use"
            :class="[
              'grid-toolbar__row',
              'custom',
              `${props?.option?.useGridSetting?.use ? 'with-grid-setting' : ''}`,
            ]"
          >
            <slot name="toolbar" />
          </div>
        </div>
      </template>

      <!-- cell renders by rendererType -->
      <template
        v-for="(fieldOption, fieldName) in customColumnFields"
        :key="fieldName"
        #[fieldName]="{ item }"
      >
        <template v-if="fieldOption?.rendererType === 'ratio'">
          <base-grid-ratio-bar-cell
            v-if="item.data[item.fieldName] != null"
            class="base-grid-ratio"
            :ratio="item.data[item.fieldName]"
          />
        </template>
        <template v-else-if="fieldOption?.rendererType === 'chip-cell'">
          <base-grid-chips-cell
            :row-index="-1"
            :chips="item.data[item.fieldName]"
            @click-count="clickCount"
            @resize="hideTooltip"
          />
        </template>
        <template v-else-if="fieldOption?.rendererType === 'label'">
          <div
            v-if="item.data[item.fieldName]"
            class="label-cell-wrapper"
          >
            <status-tag :type="item.data[item.fieldName]">
              {{ item.data[item.fieldName] }}
            </status-tag>
          </div>
        </template>
        <template v-else-if="fieldOption?.rendererType === 'capitalize-label'">
          <div
            v-if="item.data[item.fieldName]"
            class="label-cell-wrapper"
          >
            <status-tag
              v-for="(status, index) in item.data[item.fieldName].split(', ').sort()"
              :key="index"
              :type="status"
            >
              {{ capitalize(status) }}
            </status-tag>
          </div>
        </template>
        <template v-else-if="fieldOption?.rendererType === 'multi-chip-cell'">
          <template
            v-if="Array.isArray(item.data[item.fieldName]) && item.data[item.fieldName].length > 1"
          >
            <base-grid-chips-cell
              :row-index="-1"
              :chips="item.data[item.fieldName]"
              @click-count="clickCount"
              @resize="hideTooltip"
            />
          </template>
          <template v-else>
            {{ item.data[item.fieldName] }}
          </template>
        </template>
        <template v-else-if="fieldOption?.rendererType === 'tooltip-label'">
          <div
            v-if="item.data[item.fieldName]"
            class="label-cell-wrapper"
          >
            <status-tooltip-tag
              :status-info="
                typeof item.data[item.fieldName] === 'string'
                  ? { status: item.data[item.fieldName], type: '' }
                  : item.data[item.fieldName]
              "
            />
          </div>
        </template>
        <template v-else-if="fieldOption?.rendererType === 'sec'">
          <div :title="String(formatNumWithCommasAndDecimal(item.data[item.fieldName]))">
            {{ convertMsToSec(item.data[item.fieldName]) }}
          </div>
        </template>
        <template v-else-if="fieldOption?.clickable">
          <span
            :title="item.data[item.fieldName]"
            class="base-grid__cell--link"
            @click="clickCell(fieldName, item.data[item.fieldName], item.data)"
          >
            {{ item.data[item.fieldName] }}
          </span>
        </template>
      </template>

      <!-- custom-renderer -->
      <template
        v-for="(slotName, index) in slotNamesOfCustomCell"
        :key="`custom-cell_${slotName}_${index}`"
        #[slotName]="{ item }"
      >
        <slot
          :name="slotName"
          :item="item"
        />
      </template>
    </ev-tree-grid>

    <!-- tooltip for chip-cell -->
    <base-custom-tooltip
      ref="customTooltipRef"
      custom-class="grid-tooltip"
      :use-deselect="true"
    >
      <slot name="tooltip-content">
        <chip-cell-tooltip-content :items="tooltipItems" />
      </slot>
    </base-custom-tooltip>
  </div>
</template>

<style lang="scss">
.base-tree-grid {
  --option-height-h1: var(--grid-toolbar-height);
  --option-height-h2: var(--grid-toolbar-height) * 2;

  .slot-wrapper {
    // tree-grid > base-grid-ratio-bar-cell 위치 문제
    width: 100%;
    height: 100%;
  }

  .td-content {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .base-grid__cell--link {
    align-items: center;
    height: 100%;
    cursor: pointer;
    color: var(--color-blue-05) !important;

    @include shortening(flex);

    &:hover {
      text-decoration: underline !important;
    }

    .cell-text {
      @include shortening(inline-block, auto);

      &--icon-right {
        max-width: calc(100% - 4px - 20px);
        margin-right: 4px;
      }
    }
  }

  .selected .base-grid__cell--link {
    color: $color-white !important;
  }

  .base-grid__cell--link:empty {
    cursor: default;
    pointer-events: none;
  }

  .label-cell-wrapper {
    text-align: center;
  }

  .grid-toolbar {
    display: flex;
    flex-direction: column;

    &__row {
      height: var(--grid-toolbar-height);
    }

    &__row.search.full {
      .ev-text-field {
        width: 100% !important;
      }

      .ev-input {
        height: var(--grid-toolbar-height) !important;
      }
    }

    &__row.search.right {
      margin-left: auto;
    }

    &__row.search.left {
      margin-right: auto;
    }

    &__row.custom.with-grid-setting {
      width: calc(100% - var(--grid-setting-width) - var(--grid-setting-margin));
    }
  }

  .toolbar-wrapper + div {
    height: calc(100% - var(--grid-toolbar-height)) !important;
  }

  &.has-search,
  &.has-toolbar {
    .ev-tree-grid.table {
      height: calc(100% - var(--grid-toolbar-height) - 10px) !important;
    }
  }

  &.has-search.has-toolbar {
    .ev-tree-grid.table {
      height: calc(100% - var(--grid-toolbar-height) * 2 - 20px) !important;
    }
  }

  &.has-option-h1 {
    .toolbar-wrapper + div {
      height: calc(100% - var(--option-height-h1)) !important;
    }
  }

  &.has-option-h2 {
    .toolbar-wrapper + div {
      height: calc(100% - var(--option-height-h2)) !important;
    }
  }

  // for renderer-type ratio
  &__ratio {
    justify-content: center;
  }

  .cell .tag-item {
    max-width: 100%;
    height: 16px;
    margin-right: 4px;
    line-height: 16px;

    /* stylelint-disable */
    > span {
      @include shortening();
    }

    /* stylelint-enable */
  }
}
</style>
