<script setup lang="ts">
import { provide } from 'vue';
import { useSlideDetailStore } from '@/common/stores/slide-detail';
import { StoreDefinition } from 'pinia';

const props = defineProps<{
  store?: StoreDefinition;
}>();

provide('slideDetailStore', props.store || useSlideDetailStore);
</script>

<template>
  <slot />
</template>
