<script setup lang="ts">
import BadgeItem from '@/common/components/molecules/badge/BadgeItem.vue';
import { setup } from '@/common/components/organisms/onSiteAlarm/onSiteAlarm.setup';
import { startCase } from 'lodash-es';

const { alarms } = setup();
</script>

<template>
  <div
    v-if="alarms.find((alarm) => alarm.isShow)"
    class="on-site-alarm-wrapper"
  >
    <div
      v-for="alarm in alarms"
      v-show="alarm.isShow && alarm.data.id != null"
      :key="alarm.data.id"
      :class="['on-site-alarm', `on-site-alarm--${alarm.theme}`, { show: alarm.isTriggered }]"
    >
      <div class="on-site-alarm__header">
        <ev-icon class="icon-fill-notifications" />
        {{ $t('WORD.NOTIFICATION') }}
        <badge-item
          v-show="alarm.data.count > 0"
          html-type="span"
          size="xsmall"
          >+{{ alarm.data.count }}</badge-item
        >
        <span
          :class="['on-site-alarm__close', `cursor--${alarm.cursor}`]"
          @click.stop="alarm.onClose"
        >
          <ev-icon class="icon-close" />
        </span>
      </div>
      <div
        :class="['on-site-alarm__content', `cursor--${alarm.cursor}`]"
        @click="alarm.onMoveToView"
      >
        <span
          v-if="alarm.data.level"
          class="on-site-alarm__level"
        >
          <ev-icon class="icon-fill-warning" />
          {{ startCase(alarm.data.level) }}
        </span>
        <h3 class="on-site-alarm__title">{{ alarm.data.title }}</h3>
        <p class="on-site-alarm__message">{{ alarm.data.content }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.on-site-alarm-wrapper {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: $on-site-alarm;
  padding: 10px;
  overflow: hidden;
  background-color: transparent;
}
.on-site-alarm {
  position: relative;
  width: 400px;
  opacity: 0;
  box-shadow: var(--on-site-alarm-popup-shadow);
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
  transform: translateY(0);

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
  &.hide {
    opacity: 0;
    transform: translateY(50px);
  }

  & + & {
    margin-top: 10px;
  }
  .cursor {
    &--pointer {
      cursor: pointer;
    }
    &--wait {
      cursor: wait;
    }
    &--not-allowed {
      cursor: not-allowed;
    }
  }
  .ev-icon {
    width: 16px;
    height: 16px;
  }
  &__header {
    display: flex;
    gap: 4px;
    align-items: center;
    height: 36px;
    padding-left: 12px;
    border-radius: 4px 4px 0 0;
    font-size: 13px;
    font-weight: 700;
    color: var(--on-site-alarm-popup-head-font-color);
  }
  .icon-fill-notifications {
    background-color: var(--on-site-alarm-popup-head-font-color);
  }
  .badge-item {
    background-color: var(--on-site-alarm-popup-head-font-color);
  }
  &__close {
    display: block;
    padding: 7px 12px;
    margin-left: auto;
    .icon-close {
      background-color: var(--on-site-alarm-popup-head-font-color);
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 186px;
    padding: 12px;
    border-radius: 0 0 4px 4px;
    background-color: var(--on-site-alarm-popup-content-bg-color);
    line-height: 17px;
    color: var(--on-site-alarm-popup-content-font-color);
  }
  &__level {
    display: flex;
    gap: 2px;
    align-items: center;
    width: fit-content;
    height: 20px;
    padding: 0 6px 0 4px;
    margin-bottom: 12px;
    border-radius: 3px;
  }
  &__title {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
  }
  &__message {
    /* stylelint-disable */
    display: -webkit-box;
    /* stylelint-enable */
    flex: 1;
    min-height: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
}
.on-site-alarm--warning {
  .on-site-alarm__header,
  .icon-fill-warning {
    background-color: var(--on-site-alarm-popup-warning-head-color);
  }
  .badge-item {
    color: var(--on-site-alarm-popup-warning-head-color);
  }
  .on-site-alarm__level {
    background-color: var(--on-site-alarm-popup-warning-level-bg-color);
    color: var(--on-site-alarm-popup-warning-level-font-color);
  }
}
.on-site-alarm--critical {
  .on-site-alarm__header,
  .icon-fill-warning {
    background-color: var(--on-site-alarm-popup-critical-head-color);
  }
  .badge-item {
    color: var(--on-site-alarm-popup-critical-head-color);
  }
  .on-site-alarm__level {
    background-color: var(--on-site-alarm-popup-critical-level-bg-color);
    color: var(--on-site-alarm-popup-critical-level-font-color);
  }
}
</style>
