import { computed } from 'vue';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import router from '@/common/router';
import { MenuInfo } from '../types';
import { BASE_MENU_VALUE } from '../baseMenu.define';

export interface Props {
  menuInfo: MenuInfo;
  selected?: boolean;
}

export const setup = (props: Props) => {
  const { selectSubPanelMenu } = useBaseMenuStore();
  const computedChildrenLength = computed(() => props.menuInfo.subMenuList?.length ?? 0);

  const isChildMenuAvailable = computed(() => {
    if (props.menuInfo.value === BASE_MENU_VALUE.DASHBOARD) return true;
    return !!props.menuInfo.subMenuList && props.menuInfo.subMenuList.length;
  });

  const onClickElement = async () => {
    selectSubPanelMenu(props.menuInfo.value);
    if (!props.menuInfo.path) {
      return;
    }
    router.push(props.menuInfo.path);
  };

  return { computedChildrenLength, isChildMenuAvailable, onClickElement };
};
