import dayjs from 'dayjs';
import { isNull, isObject, isUndefined } from 'lodash-es';
import { Alignment, Column, Fill, Font, Workbook } from 'exceljs';
import { PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { PA_TOGGLE_DATA_TYPE } from '@/common/utils/define';
import BigNumber from 'bignumber.js';
import type { CustomColumn } from './types';
import { convertMsToSec, fileDownload, roundToDigitNumber } from './commonUtils';

type RowDataFormatter<RowType = any> = Record<
  string,
  (originalValue: any, currentRow?: RowType, curIndex?: number) => string | number | null
>;
type RowDataFetchPromise = () => Promise<any>;

export interface ExportExcelOption<RowType = any> {
  title?: string;
  hiddenField?: string[]; // 엑셀 파일에서 숨김 처리할 field
  shownField?: string[]; // hidden 처리되어 있지만 엑셀 파일에서 출력할 field
  fieldStyle?: Record<
    string, // field name
    {
      fill?: Fill | ((value: string) => Fill);
      font?: Partial<Font> | ((value: string) => Partial<Font>);
      alignment?: Partial<Alignment> | ((value: string) => Partial<Alignment>);
      width?: number;
    }
  >;
  rowDataFormatter?: RowDataFormatter<RowType>;
  exportByServer?: boolean;
  rowDataFetchPromise?: RowDataFetchPromise;
  // Row Data를 grid 자체에서 가져오는 것이 아닌 별도 API 호출로 가져올 때 사용
  // SQL Text 잘림 등 특수한 상황에서 사용
  // NOTE: any 대신 Typing 할 수 있는 방법이 있을까????
}

const isColumnHidden = (gridColumn: CustomColumn, option: ExportExcelOption) => {
  return (
    (gridColumn.hide || option.hiddenField?.includes(gridColumn.field)) &&
    !option.shownField?.includes(gridColumn.field)
  );
};

const formatGridColumnsToExcelColumns = (
  gridColumns: Readonly<CustomColumn[]>,
  option: ExportExcelOption,
) => {
  return gridColumns.reduce<Partial<Column>[]>((acc, cur) => {
    const { width: optionWidth } = option.fieldStyle?.[cur.field] ?? {};
    if (isColumnHidden(cur, option)) return acc;
    acc.push({
      header: cur.caption,
      key: cur.field,
      width: isUndefined(optionWidth) ? (cur.width ?? 400) / 6 : optionWidth,
      style: {
        font: {
          size: 12,
        },
        alignment: {
          vertical: 'middle',
        },
      },
    });
    return acc;
  }, []);
};

export const flattenTreeGridData = (gridRows: any[]): any[] => {
  const recurFlattenTreeGridData = (gridRow: { children: any[] }) => {
    if (!gridRow.children || gridRow.children.length === 0) return [gridRow];

    return gridRow.children.reduce<any[]>(
      (acc: any[], cur: any) => {
        acc.push(...recurFlattenTreeGridData(cur));
        return acc;
      },
      [gridRow],
    );
  };

  const flattenGridRows = gridRows.reduce<any[]>((acc, cur) => {
    acc.push(...recurFlattenTreeGridData(cur));
    return acc;
  }, []);

  return flattenGridRows;
};

const formatRowDataIfObjectOrArray = (rowData: any) => {
  if (isNull(rowData) || isUndefined(rowData)) {
    return '';
  }
  if (rowData instanceof BigNumber) {
    return `${rowData}`;
  }
  if (rowData instanceof Array) {
    return rowData.join(', ');
    // NOTE: 이 줄에서 버그 발생할 경우, rowData 가 객체형 배열이기 때문에 join이 불가능한 것
    // NOTE: 각 BaseGrid exportExcelOption에서 적절한 rowDataFormatter 지정 필요
    // NOTE: 사용자태그 컬럼은 기본적으로 hidden 이기 때문에 해당 함수에 진입 X
  }
  if (isObject(rowData)) {
    return Object.keys(rowData).join(', ');
  }
  return `${rowData}`;
};

const formatRowData = (rowData: any, columnInfo: CustomColumn) => {
  if (!columnInfo.type) return formatRowDataIfObjectOrArray(rowData);
  const { type } = columnInfo;

  switch (type) {
    case 'float':
      if (!rowData) return `${(0).toFixed(columnInfo.decimal ?? 3)}`;
      return `${rowData.toFixed(columnInfo.decimal ?? 3)}`;
    case 'number':
      if (rowData === 0) return '0';
      if (isNull(rowData) || isUndefined(rowData)) return '';
      return `${rowData}`;
    case 'string':
    case 'boolean':
    case 'stringNumber':
    default:
      return formatRowDataIfObjectOrArray(rowData);
  }
};

const formatGridDataToExcelData = (
  gridColumns: Readonly<CustomColumn[]>,
  gridRows: any[],
  option: ExportExcelOption,
) => {
  if (gridRows.length === 0) {
    // Empty Array
    return [];
  }
  const { rowDataFormatter } = option;
  if (gridRows[0] instanceof Array) {
    // BaseGrid
    const columnsMap = new Map(gridColumns.map((col) => [col.index, col]));

    return gridRows.reduce<Record<string, string>[]>((acc, cur, curIndex) => {
      const row = {};
      cur.forEach((rowData: any, index: number) => {
        const column = columnsMap.get(index);
        if (!column || isColumnHidden(column, option)) {
          return;
        }
        if (rowDataFormatter?.[column.field]) {
          row[column.field] = rowDataFormatter[column.field](rowData, cur, curIndex);
        } else {
          row[column.field] = formatRowData(rowData, column);
        }
      });
      acc.push(row);
      return acc;
    }, []);
  }
  // BaseTreeGrid
  const flattenRows = flattenTreeGridData(gridRows);

  return flattenRows.reduce<Record<string, string | number>[]>(
    (acc: Record<string, string | number>[], cur: Object, curIndex: number) => {
      const row = {};
      gridColumns.forEach((column, index) => {
        const { field } = column;
        if (!field || isColumnHidden(column, option)) {
          return;
        }
        if (rowDataFormatter?.[field]) {
          row[field] = rowDataFormatter[field](cur[field], cur, curIndex);
        } else {
          row[field] = formatRowData(cur[field], gridColumns[index]);
        }
      });
      acc.push(row);
      return acc;
    },
    [],
  );
};

const createExcelFile = (
  gridColumns: Readonly<CustomColumn[]>,
  gridRows: any[],
  option: ExportExcelOption,
) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();

  const data = formatGridDataToExcelData(gridColumns, gridRows, option);
  worksheet.columns = formatGridColumnsToExcelColumns(gridColumns, option);
  worksheet.insertRows(2, data);
  return workbook;
};

const styleExcelFile = (workbook: Workbook, option: ExportExcelOption) => {
  const worksheet = workbook.getWorksheet(1);
  if (!worksheet) return;

  const { fieldStyle } = option;

  // All Cells
  worksheet.eachRow((row) => {
    row.height = 24;
    row.eachCell((cell) => {
      cell.font = {
        size: 12,
      };
    });
  });

  // Top Column
  worksheet.getRow(1).height = 30;
  worksheet.getRow(1).alignment = { horizontal: 'center', vertical: 'middle' };
  worksheet.getRow(1).eachCell((cell) => {
    cell.font = {
      size: 13,
      bold: true,
    };
  });

  if (!fieldStyle) {
    return;
  }

  // Optional Styling
  const fieldStyleKeys = fieldStyle ? Object.keys(fieldStyle) : [];
  fieldStyleKeys?.forEach((field) => {
    const { fill, font, alignment } = fieldStyle[field as keyof typeof fieldStyle];
    const column = worksheet.getColumnKey(field);
    if (!column) {
      return;
    }
    column.eachCell((cell) => {
      if (cell.row.toString() === '1') return;
      if (fill) {
        cell.fill =
          typeof fill === 'function'
            ? { ...cell.fill, ...fill(cell.text) }
            : { ...cell.fill, ...fill };
      }
      if (font) {
        cell.font =
          typeof font === 'function'
            ? { ...cell.font, ...font(cell.text) }
            : { ...cell.font, ...font };
      }
      if (alignment) {
        cell.alignment =
          typeof alignment === 'function'
            ? { ...cell.alignment, ...alignment(cell.text) }
            : { ...cell.alignment, ...alignment };
      }
    });
  });
};

export const exportToExcelFromClient = async (
  gridColumns: Readonly<CustomColumn[]>,
  gridRows: any[],
  option: ExportExcelOption = {},
) => {
  const workbook = createExcelFile(gridColumns, gridRows, option);
  styleExcelFile(workbook, option);
  await new Promise((resolve) => setTimeout(resolve, 500));
  const buffer = await workbook.xlsx.writeBuffer();
  const filename = `${option.title ?? 'export'}_${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`;
  fileDownload({ fileName: filename, fileData: buffer, fileType: 'excel' });
};

export const exportToExcelFromServer = async <RequestParams, Response>(
  exportToExcelApiFn: (params: RequestParams) => PromiseAxiosResponse<Response>,
  params: RequestParams,
  option: ExportExcelOption = {},
) => {
  const { data: excelFile } = await exportToExcelApiFn(params);
  const serverResponseData = excelFile as unknown as string;

  const filename = `${option.title ?? 'export'}_${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`;
  fileDownload({ fileName: filename, fileData: serverResponseData, fileType: 'excel' });
};

export const formatExcelSecFields = ({ fields, toggle }: { fields: string[]; toggle?: string }) =>
  fields.reduce((acc, field) => {
    acc[field] = (data: number | string) =>
      convertMsToSec(data, { digits: toggle === PA_TOGGLE_DATA_TYPE.SUM ? 1 : 3 });
    return acc;
  }, {});

export const formatRatioExcelFields = ({ fields }: { fields: string[] }) =>
  fields.reduce((acc, field) => {
    acc[field] = (data: number) => roundToDigitNumber(data, 1);
    return acc;
  }, {});

export const formatExcelNumberFields = ({ fields }: { fields: string[] }) =>
  fields.reduce((acc, field) => {
    acc[field] = (data: number) => roundToDigitNumber(data, 3);
    return acc;
  }, {});
