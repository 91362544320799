export const TRX_ITEMS = [
  {
    name: '1Kbps',
    value: '1 kb/sec',
  },
  {
    name: '1Mbps (default)',
    value: '1 mb/sec',
  },
  {
    name: '100Mbps',
    value: '100 mb/sec',
  },
  {
    name: '500Mbps',
    value: '500 mb/sec',
  },
  {
    name: '1Gbps',
    value: '1 gb/sec',
  },
] as const;
