export const ALERT = {
  ALERT_LIST_DETAIL_HISTORY_LIST_DASHBOARD_SETTING_LIST:
    'ALERT_LIST_DETAIL_HISTORY_LIST_DASHBOARD_SETTING_LIST',
  GRIDCOLUMNS_alert_alertList_alertList: 'GRIDCOLUMNS_alert_alertList_alertList',
  GRIDCOLUMNS_alert_alertDetail_current: 'GRIDCOLUMNS_alert_alertDetail_current',
  GRIDCOLUMNS_alert_alertDetail_historyList: 'GRIDCOLUMNS_alert_alertDetail_historyList',
  GRIDCOLUMNS_alert_notificationHistory_notificationHistoryList:
    'GRIDCOLUMNS_alert_notificationHistory_notificationHistoryList',
  GRIDCOLUMNS_alert_platformDetail_alertLogs: 'GRIDCOLUMNS_alert_platformDetail_alertLogs',

  FILTER_alert_alertList: 'FILTER_alert_alertList',
  FILTER_alert_notificationHistory: 'FILTER_alert_notificationHistory',
  FILTERSEARCH_alert_alertList_alertList: 'FILTERSEARCH_alert_alertList_alertList',
  FILTERSEARCH_alert_notificationHistory_notificationHistoryList:
    'FILTERSEARCH_alert_notificationHistory_notificationHistoryList',
  FILTERSEARCH_alert_platformDetail_ruleList: 'FILTERSEARCH_alert_platformDetail_ruleList',
  FILTERSEARCH_alert_alertDetail_current: 'FILTERSEARCH_alert_alertDetail_current',
  FILTERSEARCH_alert_alertDetail_historyList: 'FILTERSEARCH_alert_alertDetail_historyList',
};
