<script setup lang="ts">
import { Props, setup } from './DashBoardListPopup.setup';

const props = defineProps<Props>();

const { selectedList, onClickDashboardList } = setup(props);
</script>

<template>
  <ol class="dashboard-move-list">
    <li
      v-for="(item, idx) in selectedList"
      :key="idx"
      class="dashboard-move-list__item"
      :title="item.dashboardName"
      @click="() => onClickDashboardList(item)"
    >
      <ev-icon
        :icon="item.icon"
        size="small"
      />
      <span class="link-name">
        {{ item.dashboardName }}
      </span>
    </li>
  </ol>
</template>

<style scoped lang="scss">
.dashboard-move-list {
  width: 320px;
  max-height: 500px;
  padding: 4px 0;
  overflow-y: auto;
  border-radius: 2px;
  background: var(--color-gray-06-00);
  box-shadow: 0 10px 26px 0 rgba(0, 16, 34, 0.15);
  color: var(--color-gray-00-12);
  &__item {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px;

    .link-name {
      flex: 1;
      @include shortening();
    }

    &:hover {
      background-color: var(--color-gray-05-02);
    }
  }
}
</style>
