<script setup lang="ts">
import BaseModalWindow from '@/common/components/templates/BaseModalWindow.vue';
import { Props, Emit, setup } from './setupDashBoardPopup.setup';

const emit = defineEmits<Emit>();

const props = withDefaults(defineProps<Props>(), {
  envKey: '',
  isAlert: false,
  isShow: false,
});

const {
  isPopupShow,
  urlInfoList,
  setupDashBoardData,
  onClickSave,
  isValid,
  searchFilterList,
  searchValue,
  nameErrorMsg,
  validateName,
  validateUrl,
  validateUrlName,
  checkedList,
  isLoading,
  t,
  onClickAddBtn,
  onClickDeleteBtn,
  isMaster,

  windowHeight,
} = setup(props, emit);
</script>

<template>
  <base-modal-window
    v-model:visible="isPopupShow"
    size-type="CUSTOM"
    :size="{ width: '800px', height: '566px' }"
    :title="t('MESSAGE.GO_TO_DASHBOARD')"
    window-class="setup-dashboard-modal"
  >
    <div class="setup-dashboard-modal__btn-name">
      <p class="setup-dashboard-modal__title">{{ t('WORD.CHANGE_BUTTON_NAME') }}</p>
      <ev-text-field
        v-model.trim="setupDashBoardData.name"
        :max-length="10"
        :error-msg="nameErrorMsg.name"
        @input="validateName"
      />
    </div>
    <div class="setup-dashboard-modal__content">
      <p class="setup-dashboard-modal__title">{{ t('WORD.SELECT_DASHBOARD') }}</p>
      <div class="dashboard-list">
        <ev-text-field
          v-model="searchValue"
          :placeholder="t('WORD.SEARCH')"
          type="search"
          class="search-input base-expansion__search"
        />
        <ev-loading :model-value="isLoading" />
        <div class="dashboard-list__check-area">
          <div
            v-for="(list, idx) in searchFilterList"
            :key="idx"
            :class="['base-expansion__list']"
          >
            <ev-checkbox
              v-model="checkedList"
              v-model:value="list.dashboardId"
              :label="list.dashboardId"
              :title="list.dashboardName"
            >
              {{ list.dashboardName }}
            </ev-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="setup-dashboard-modal__url-area">
      <div
        v-if="urlInfoList.length > 0"
        :class="[urlInfoList.length > 0 ? `setup-dashboard-modal__url-field` : null]"
      >
        <template
          v-for="(info, index) in urlInfoList"
          :key="index"
        >
          <div class="setup-dashboard-modal__field">
            <ev-checkbox
              v-model="info.checked"
              class="setup-dashboard-modal__field__checkbox"
            />
            <ev-text-field
              v-model="info.urlName"
              :disabled="info.isGlobal && !isMaster"
              :placeholder="t('WORD.NAME')"
              :error-msg="info.nameErrorMsg"
              @input="() => validateUrlName(info.urlName, index)"
            />
            <ev-text-field
              v-model="info.url"
              :disabled="info.isGlobal && !isMaster"
              :placeholder="t('WORD.URL')"
              :error-msg="info.urlErrorMsg"
              @input="() => validateUrl(info.url, index)"
            />
            <ev-checkbox v-model="info.isNewWindow">
              {{ t('WORD.OPEN_IN_NEW_WINDOW') }}
            </ev-checkbox>
            <ev-button
              :disabled="info.isGlobal && !isMaster"
              type="icon"
              @click="() => onClickDeleteBtn(index)"
            >
              <ev-icon icon="icon-outline-delete" />
            </ev-button>
          </div>
        </template>
      </div>
      <ev-button
        shape="border"
        class="add-btn"
        @click="onClickAddBtn"
      >
        <ev-icon icon="ev-icon-plus" />
        {{ t('WORD.ADD_URL') }}
      </ev-button>
    </div>

    <template #footer>
      <div class="btn-field setup-dashboard-modal__btn">
        <ev-button
          type="primary"
          :disabled="!isValid"
          @click="onClickSave"
        >
          {{ t('WORD.OK') }}
        </ev-button>
      </div>
    </template>
  </base-modal-window>
</template>

<style lang="scss">
.setup-dashboard-modal {
  font-size: 13px;

  &.ev-window {
    top: 50% !important;
    height: v-bind('windowHeight') !important;
    transform: translateY(-50%);
  }

  .ev-window-content {
    padding: 8px 12px 0 !important;
  }
  .ev-window-footer {
    padding: 10px 12px;
  }
  .ev-text-field {
    width: 100% !important;
  }

  &__title {
    margin-bottom: 6px;
    font-size: 12px;
    color: var(--color-gray-02-10);
  }
  &__btn-name {
    margin-bottom: 14px;
  }
  &__content {
    margin-bottom: 8px;
    .dashboard-list {
      border: 1px solid var(--color-gray-05-05);
      border-radius: 2px;

      &__check-area {
        height: 200px;
        padding: 8px 0;
        overflow-y: auto;
        .ev-checkbox {
          width: 100%;
          padding: 6px 8px;
        }
        .base-expansion__list {
          display: flex;
          align-items: center;
          height: 28px;
          cursor: pointer;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .ev-text-field {
        padding: 8px;
      }
    }
  }
  &__url-area {
    .add-btn {
      width: 99px;
    }
  }
  &__url-field {
    max-height: 200px;
    padding: 8px;
    margin-bottom: 8px;
    overflow-y: auto;
    border: 1px solid var(--color-gray-05-05);
    border-radius: 2px;
  }
  &__field {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
    /* stylelint-disable */
    .ev-text-field {
      flex: 1;
    }
    /* stylelint-enable */
  }
}
</style>
