import { type WidgetChartType } from '@/dashboard/utils/types';
import { StatDataType, type StatInfo } from '@/common/components/organisms/statWindow';
import { validStatByChartType } from '@/dashboard/utils/define';
import { StatCategory } from './statCategoryList';
import { ChildCategory, ParentCategory } from './statContents.setup';

export const sortParentCategory = (category1: string, category2: string) => {
  if (category1 === 'custom') {
    return -1;
  }
  if (category2 === 'custom') {
    return 1;
  }
  return category1.localeCompare(category2);
};

export const sortCategory = (category1: string, category2: string) => {
  if (category1 === 'common') {
    return -1;
  }
  if (category2 === 'common') {
    return 1;
  }
  return category1.localeCompare(category2);
};

export const sortTag = (tag1: string, tag2: string) => {
  return tag1.localeCompare(tag2);
};

export const mapChildCategoryToExact = ({ parentCategory, childCategory }: StatCategory) => {
  if (parentCategory === childCategory) {
    return 'common';
  }
  return childCategory;
};

export const mapChildCategoryWithParent = ({ parentCategory, childCategory }: StatCategory) => {
  return childCategory === 'common' ? parentCategory : childCategory;
};

export const setFilterStatList = ({
  statInfoList,
  tags = [],
  filterByFavorite = false,
  widgetChartType,
}: {
  statInfoList: StatInfo[];
  filterByFavorite?: boolean;
  tags?: string[];
  widgetChartType?: WidgetChartType;
}) => {
  return statInfoList.filter(({ tagList: statTagList, favorite, id }) => {
    const idMatching = validStatByChartType[widgetChartType ?? '']?.includes(id) ?? true;
    const matchesTags = tags?.some((tag) => statTagList.includes(tag));

    if (!tags?.length) {
      if (filterByFavorite) return favorite && idMatching;
      if (widgetChartType && Object.keys(validStatByChartType).includes(widgetChartType))
        return idMatching;
      return true;
    }

    if (widgetChartType && Object.keys(validStatByChartType).includes(widgetChartType)) {
      return filterByFavorite ? favorite && idMatching && matchesTags : idMatching && matchesTags;
    }

    return filterByFavorite ? favorite && matchesTags : matchesTags;
  });
};

const filterStatBySearchWord = (statInfos: StatInfo[], searchWord: string) => {
  if (!searchWord.trim()) {
    return statInfos.slice();
  }
  return statInfos.filter(({ name }) => name.toLowerCase().includes(searchWord.toLowerCase()));
};

const filterStatByDataTypes = (statInfos: StatInfo[], dataTypes: StatDataType[]) => {
  return statInfos.filter(({ dataType: statDataTypes }) =>
    statDataTypes.some((statDataType) => dataTypes.includes(statDataType)),
  );
};

export const filterStat = (
  from: Map<ParentCategory, Map<ChildCategory, StatInfo[]>>,
  filterInfo: {
    searchWord: string;
    dataTypes: StatDataType[];
  },
) => {
  const filtered = new Map();
  from.forEach((statInfoByCategory, parentCategory) => {
    statInfoByCategory.forEach((statInfos, childCategory) => {
      let filteredStatInfos = statInfos;
      filteredStatInfos = filterStatBySearchWord(filteredStatInfos, filterInfo.searchWord);
      filteredStatInfos = filterStatByDataTypes(filteredStatInfos, filterInfo.dataTypes);
      if (!filtered.get(parentCategory)) {
        filtered.set(parentCategory, new Map());
      }
      filtered.get(parentCategory).set(childCategory, filteredStatInfos);
    });
  });

  return filtered;
};

export const isCategoryHidden = (parentCategory: string, hiddenRegExp: RegExp): boolean => {
  return hiddenRegExp.test(parentCategory);
};

export const filterCategoryList = (
  categoryList: Map<ChildCategory, StatInfo[]>,
  widgetChartType: WidgetChartType,
) => {
  let filteredCategoryList = new Map<ChildCategory, StatInfo[]>();

  if (Object.keys(validStatByChartType).includes(widgetChartType)) {
    categoryList.forEach((statKey, childCategory) => {
      const filteredStatInfoList = statKey.filter(
        (statInfo) => validStatByChartType[widgetChartType]?.includes(statInfo.id),
      );
      if (filteredStatInfoList.length > 0) {
        filteredCategoryList.set(childCategory, filteredStatInfoList);
      }
    });
  } else {
    filteredCategoryList = categoryList;
  }

  return filteredCategoryList;
};

const isChildCategoryVisible = (
  name: string,
  parentCategory: string,
  hiddenRegExp: {
    use: boolean;
    child: RegExp;
  },
): boolean => {
  if (hiddenRegExp.use && hiddenRegExp.child.test(name)) {
    if (!hiddenRegExp.child.test(parentCategory) && name === 'common') {
      return true;
    }
    return false;
  }
  return true;
};

export const mapAndSortChildCategories = (
  filteredCategoryList: Map<ChildCategory, StatInfo[]>,
  parentCategory: string,
  hiddenRegExp: {
    use: boolean;
    child: RegExp;
  },
) => {
  return Array.from(filteredCategoryList)
    .sort((a, b) => sortCategory(a[0], b[0]))
    .map(([childCategory, statInfo]) => {
      return {
        name: mapChildCategoryToExact({ parentCategory, childCategory }),
        value: childCategory,
        count: statInfo.length,
      };
    })
    .filter(({ name }) => isChildCategoryVisible(name, parentCategory, hiddenRegExp));
};
