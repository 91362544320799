import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import {
  getSessionCountByTypeMysqlV7ControllerAxios,
  getSessionCountByTypePostMysqlV7ControllerAxios,
} from '@/openapi/mysqlV7/api/mysql-v7-controller-api';
import { SessionTypeItemV7 } from '@/openapi/mysqlV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface DbSessionTypeState {
  dbSessionTypes: SessionTypeItemV7[];
  errorStatusText: string;
}

const FRAME_NAME = 'dbSession';

export const dbSessionType: Module<DbSessionTypeState, RootState> = {
  namespaced: true,
  state: {
    dbSessionTypes: [],
    errorStatusText: '',
  },
  mutations: {
    setErrorStatusText: (state: DbSessionTypeState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
    setDbSessionTypes: (state: DbSessionTypeState, data: SessionTypeItemV7[]) => {
      state.dbSessionTypes = data;
    },
  },
  actions: {
    async fetchDbSessionTypes({ commit }, instanceIds: string[] = []) {
      try {
        if (!instanceIds.length) {
          return;
        }
        const { data } = await getSessionCountByTypePostMysqlV7ControllerAxios({
          queryParam: {
            instanceIds: instanceIds as unknown as Set<string>,
          },
          frameName: FRAME_NAMES.MYSQL_MULTI_VIEW.DB_SESSION_TYPE,
        });

        commit('setDbSessionTypes', data.data);
        commit('setErrorStatusText', '');
        commit('mysqlMultiViewEnv/deleteFramesByFailedApi', FRAME_NAME, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'mysqlMultiViewEnv/setFramesByFailedApi',
          { frameName: FRAME_NAME, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getErrorStatusText: (state: DbSessionTypeState) => state.errorStatusText,
    getDbSessionTypes: (state: DbSessionTypeState) => state.dbSessionTypes,
  },
};
