export const DASHBOARD = {
  GRIDCOLUMNS_dashboard_scatterSummaryDetail: 'GRIDCOLUMNS_dashboard_scatterSummaryDetail',
  GRIDCOLUMNS_dashboard_scatterSummaryDetailWithStatus:
    'GRIDCOLUMNS_dashboard_scatterSummaryDetailWithStatus',
  GRIDCOLUMNS_dashboard_topologySummaryDetail: 'GRIDCOLUMNS_dashboard_topologySummaryDetail',
  GRIDCOLUMNS_dashboard_tableWidget_global: 'GRIDCOLUMNS_dashboard_tableWidget_global',
  GRIDCOLUMNS_dashboard_tableWidget_user: 'GRIDCOLUMNS_dashboard_tableWidget_user',
  GRIDCOLUMNS_dashboard_treeGridWidget_global: 'GRIDCOLUMNS_dashboard_treeGridWidget_global',
  GRIDCOLUMNS_dashboard_treeGridWidget_user: 'GRIDCOLUMNS_dashboard_treeGridWidget_user',
  GRIDCOLUMNS_dashboard_dashboardList: 'GRIDCOLUMNS_dashboard_dashboardList',
  GRIDCOLUMNS_dashboard_shareWindow_userList: 'GRIDCOLUMNS_dashboard_shareWindow_userList',
  SEVERITY_dashboard_hexagonWidget_global: 'SEVERITY_dashboard_hexagonWidget_global',
  SEVERITY_dashboard_hexagonWidget_user: 'SEVERITY_dashboard_hexagonWidget_user',
  POSITIONS_dashboard_topologyWidget_global: 'POSITIONS_dashboard_topologyWidget_global',
  POSITIONS_dashboard_topologyWidget_user: 'POSITIONS_dashboard_topologyWidget_user',
  AUTOALIGN_dashboard_topologyWidget_global: 'AUTOALIGN_dashboard_topologyWidget_global',
  AUTOALIGN_dashboard_topologyWidget_user: 'AUTOALIGN_dashboard_topologyWidget_user',
  FILTERVALUES_dashboard_filterWidget_user: 'FILTERVALUES_dashboard_filterWidget_user',
  FILTERSEARCH_dashboard_dashboardList: 'FILTERSEARCH_dashboard_dashboardList',
  FILTERSEARCH_dashboard_tableWidget: 'FILTERSEARCH_dashboard_tableWidget',
  FILTERSEARCH_dashboard_treeGridWidget: 'FILTERSEARCH_dashboard_treeGridWidget',
  FILTERSEARCH_dashboard_shareWindow_userList: 'FILTERSEARCH_dashboard_shareWindow_userList',
  SELECTEDGROUP_dashboard_dashboardList: 'SELECTEDGROUP_dashboard_dashboardList',
  LEGEND_dashboard_global: 'LEGEND_dashboard_global',
  LEGEND_dashboard_user: 'LEGEND_dashboard_user',
} as const;
