<script setup lang="ts">
import { setup } from './tutorialGuide.setup';

const { t, tutorialInfo, showTooltip, goToTutorial, hideTooltip, dismissTooltip } = setup();
</script>

<template>
  <Teleport to="#app">
    <div
      v-if="showTooltip"
      class="tutorial-guide"
    >
      <div class="tutorial-guide__dim-layer">
        <ev-button
          type="icon"
          class="tutorial-guide__help-btn"
          @click="goToTutorial"
        >
          <ev-icon
            size="medium"
            icon="ev-icon-help"
          />
        </ev-button>

        <div class="tutorial-guide__tooltip">
          <div class="tutorial-guide__tooltip-content">
            <div class="tutorial-guide__tooltip-content__top-label">
              {{ t('WORD.GNB.TUTORIAL') }}
            </div>
            <div class="tutorial-guide__tooltip-content__title">
              {{ tutorialInfo.title }}
            </div>
            <div class="tutorial-guide__tooltip-content__description">
              {{ tutorialInfo.description }}
            </div>
            <button
              class="tutorial-guide__tooltip-content__dismiss-btn"
              @click="dismissTooltip"
            >
              {{ t('TUTORIAL.TOOLTIP.DONT_SHOW_AGAIN') }}
            </button>
            <ev-button
              class="tutorial-guide__tooltip-content__close-btn"
              type="icon"
              size="small"
              @click="hideTooltip"
            >
              <ev-icon
                size="small"
                icon="ev-icon-close3"
              />
            </ev-button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
.tutorial-guide {
  --help-btn-bottom-pos: 67px;

  $help-btn-gap: 6px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100%;
  height: 100%;

  @media (max-width: 1600px) {
    --help-btn-bottom-pos: 57px;
  }

  &__dim-layer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__help-btn {
    position: absolute;
    bottom: var(--help-btn-bottom-pos);
    left: $help-btn-gap;
    z-index: 1;
    width: calc(var(--gnb-menu-width) - #{$help-btn-gap * 2});
    height: calc(var(--gnb-menu-width) - #{$help-btn-gap * 2}) !important;
    border-radius: 2px;
    background-color: var(--tutorial-guide-help-btn-bg-color);

    * {
      color: var(--tutorial-guide-help-btn-icon-color);
    }
  }

  @keyframes tooltip-wiggle {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px);
    }
    75% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }

  &__tooltip {
    position: absolute;
    bottom: calc(var(--help-btn-bottom-pos) - 6px);
    left: calc(var(--gnb-menu-width) + 10px);
    width: 400px;
    height: 136px;
    border-radius: 4px;
    background-color: var(--tutorial-guide-tooltip-bg-color);
    box-shadow: 0 3px 10px 0 rgba(0, 21, 45, 0.3);
    animation: tooltip-wiggle 1.7s ease-in-out infinite;
    &::after {
      position: absolute;
      bottom: 20px;
      left: -10px;
      width: 0;
      border-width: 5px;
      border-style: solid;
      border-color: transparent var(--tutorial-guide-tooltip-bg-color) transparent transparent;
      content: '';
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 12px 16px;

      &__top-label {
        color: var(--tutorial-guide-tooltip-top-label-color);
        @include typography('sub-title-3');
      }

      &__title {
        color: var(--tutorial-guide-tooltip-title-font-color);
        @include typography('h-2');
      }

      &__description {
        color: var(--tutorial-guide-tooltip-description-font-color);
        white-space: pre-wrap;
        @include typography('body-3');
      }

      &__dismiss-btn {
        margin-left: auto;
        cursor: pointer;
        background-color: transparent;
        color: var(--tutorial-guide-tooltip-link-color);
        @include typography('button-small');

        &:hover {
          text-decoration: underline;
        }
      }

      &__close-btn {
        position: absolute;
        top: 10px;
        right: 10px;

        :deep(&.ev-button:hover) {
          background-color: transparent !important;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.tutorial-guide__help-btn.ev-button.type-icon:hover {
  background-color: var(--tutorial-guide-help-btn-hover-bg-color) !important;
}
</style>
