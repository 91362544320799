import { MyInfo } from '../api/resTypes/userInfoRes';

export type RoleId = NonNullable<MyInfo['role']>;

export type ActionTypeMap = {
  dashboardList: 'settingFeaturedDashboard' | 'deleteFeaturedDashboard';
  dashboardEdit: 'createViewPack';
  wasDetail: 'createDump' | 'killThread';
  instanceDetail: 'sessionKill';
  sessionDetail: 'sessionKill';
  logging: 'logKeyword';
};

export type PageType = keyof ActionTypeMap;

export type Role = 'Master' | 'Admin' | 'User';
export const RoleIdMap: Record<Role, RoleId> = {
  Master: 0,
  Admin: 1,
  User: 2,
};

export const Supervisors: Role[] = ['Master', 'Admin'];
export const Master: Role[] = ['Master'];
export const AllUsers: Role[] = ['Master', 'Admin', 'User'];

type AuthSpecType<T extends Record<string, string>> = {
  [K in keyof T]: Record<T[K], Role[]>;
};

export const AuthSpec: AuthSpecType<ActionTypeMap> = {
  dashboardList: {
    settingFeaturedDashboard: [],
    deleteFeaturedDashboard: [],
  },
  dashboardEdit: {
    createViewPack: [...AllUsers],
  },
  wasDetail: {
    createDump: [...Supervisors],
    killThread: [...Supervisors],
  },
  instanceDetail: {
    sessionKill: [...Supervisors],
  },
  sessionDetail: {
    sessionKill: [...Supervisors],
  },
  logging: {
    logKeyword: [...Supervisors],
  },
};
