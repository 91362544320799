import { computed, getCurrentInstance, ref, reactive, watch } from 'vue';
import { defineStore } from 'pinia';
import {
  getAPIErrorStatusText,
  showErrorMsg,
  utcZeroTimeToStandardTime,
} from '@/common/utils/commonUtils';
import { useI18n } from 'vue-i18n';
import {
  getAllV7ClusterKubernetesClusterControllerAxios,
  getV7NamespacesKubernetesClusterControllerAxios,
} from '@/openapi/metaV6/api/kubernetes-cluster-controller-api';
import type { KubernetesClusterItem, NamespaceItem } from '@/openapi/metaV6/model';
import type { ConfigGroupInfo } from '@/common/utils/types';
import dayjs from 'dayjs';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { useAbortApi } from '@/common/utils/apiUtils';
import {
  getClusterTopologyAlertCountAlertCommonControllerAxios,
  getK8sAlertCountAlertCommonControllerAxios,
} from '@/openapi/alert/api/alert-common-controller-api';
import { useRepeat } from '@/worker/composables/useRepeat';
import { useInternational } from '@/common/locale';

export interface AlertApiParamsForObjectTopology {
  clusterId: string;
  namespaceIds: string[];
  resourceKinds: string[];
}

export interface AlertApiParamsForClusterTopology {
  clusterIds: string[];
}

interface CurrentAlertCount {
  criticalCount: number;
  warningCount: number;
}

const useApi = () => {
  const ctx = getCurrentInstance()!.appContext?.config.globalProperties;
  const { t } = useI18n();

  const getKuberData = async (
    params,
  ): Promise<{
    data: KubernetesClusterItem[] | [];
    requestTime: string;
  }> => {
    let result: NamespaceItem[] = [];
    let requestTime: string;

    try {
      const { data } = await getV7NamespacesKubernetesClusterControllerAxios({
        ...params,
        frameName: FRAME_NAMES.CONFIG_KUBERNETES.NAMESPACE_LIST,
      });
      result = data.data ?? [];
      requestTime = utcZeroTimeToStandardTime(data.requestTime);
    } catch (e: any) {
      requestTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
      if (e.status && e.status !== 200) {
        showErrorMsg(ctx, t('NOTI.PRODUCT.TEMP_SERVER_ERROR'));
      }
    }
    return {
      data: result,
      requestTime,
    };
  };

  const getKuberClusterData = async (frameName?: string): Promise<KubernetesClusterItem[]> => {
    let data: KubernetesClusterItem[] = [];
    try {
      const { data: res } = await getAllV7ClusterKubernetesClusterControllerAxios({
        frameName: frameName ?? FRAME_NAMES.CONFIG_KUBERNETES.CLUSTER_LIST,
      });
      data = res.data ?? [];
    } catch (e: any) {
      data = [];
      if (e.status && e.status !== 200) {
        showErrorMsg(ctx, t('NOTI.PRODUCT.TEMP_SERVER_ERROR'));
      }
    }
    return data;
  };

  return {
    getKuberData,
    getKuberClusterData,
  };
};

const useGroup = () => {
  const { t } = useInternational();

  const kuberClusterList = ref<ConfigGroupInfo[]>([]);
  const selectedKuberClusterId = ref<string>('');
  const selectedKuberClusterName = computed<string>(() => {
    const selectedCluster = kuberClusterList.value.find(
      (item) => item.id === selectedKuberClusterId.value,
    );
    return selectedCluster ? selectedCluster.name || '' : '';
  });
  const kuberClusterIdForUpdateTarget = ref<ConfigGroupInfo>();

  const setKuberClusterIdForUpdateTarget = (groupId: string): void => {
    kuberClusterIdForUpdateTarget.value = kuberClusterList.value.find(
      (group) => group.id === groupId,
    );
  };

  const initKuberClusterIdForUpdateTarget = (): void => {
    kuberClusterIdForUpdateTarget.value = undefined;
  };

  const processGroupList = (data: KubernetesClusterItem[]): ConfigGroupInfo[] =>
    data.map((group) => ({
      id: group.clusterId!,
      name: group.name!,
      status: group.status,
      statusCountList: [
        {
          icon: 'icon-node',
          text: group.node!,
        },
        {
          icon: 'icon-pod',
          text: group.pod!,
        },
        {
          icon: 'icon-service',
          text: group.service!,
        },
      ],
      disableDelete: {
        disable: group.status?.toLowerCase() === 'active',
        tooltipMessage:
          group.status?.toLowerCase() === 'active'
            ? t('MESSAGE.DELETE_CLUSTER_GROUP_ERROR')
            : undefined,
      },
    }));

  const setkuberClusterList = (data: KubernetesClusterItem[]): void => {
    kuberClusterList.value = processGroupList(data);
  };

  return {
    kuberClusterList,
    selectedKuberClusterId,
    selectedKuberClusterName,
    kuberClusterIdForUpdateTarget,
    setKuberClusterIdForUpdateTarget,
    setkuberClusterList,
    initKuberClusterIdForUpdateTarget,
  };
};

const useList = () => {
  const kuberListOfSelectedGroup = ref<KubernetesClusterItem[]>([]);

  return {
    kuberListOfSelectedGroup,
  };
};

const useTopology = () => {
  const focusingClusterId = ref('');

  const setFocusingClusterId = (id: string): void => {
    focusingClusterId.value = id;
  };

  return {
    focusingClusterId,
    setFocusingClusterId,
  };
};

export const useConfigKubernetesStore = defineStore('configKubernetes', () => {
  const {
    kuberClusterList,
    selectedKuberClusterId,
    selectedKuberClusterName,
    kuberClusterIdForUpdateTarget,
    setKuberClusterIdForUpdateTarget,
    setkuberClusterList,
    initKuberClusterIdForUpdateTarget,
  } = useGroup();

  const { kuberListOfSelectedGroup } = useList();

  const { focusingClusterId, setFocusingClusterId } = useTopology();

  const { getKuberData, getKuberClusterData } = useApi();

  const refreshTime = ref('');
  const isInstallAgentWindowOpen = ref(false);

  const changeInstallAgentWindowVisibility = (isOpen: boolean): void => {
    isInstallAgentWindowOpen.value = isOpen;
  };

  const fetchKuberData = async (): Promise<void> => {
    const params: Record<string, string | number | string[]> = {
      clusterId: selectedKuberClusterId.value,
    };
    const { data, requestTime } = await getKuberData(params);
    kuberListOfSelectedGroup.value = data;
    refreshTime.value = requestTime;
  };

  const fetchKuberClusterData = async (): Promise<void> => {
    const groupList = await getKuberClusterData();
    setkuberClusterList(groupList);

    if (selectedKuberClusterId.value === '') {
      selectedKuberClusterId.value = kuberClusterList.value?.[0]?.id ?? '';
    }

    await fetchKuberData();
  };

  const selectKuberCluster = async (groupId: string): Promise<void> => {
    selectedKuberClusterId.value = groupId;
    await fetchKuberData();
  };

  const fetchKuberClusterList = async (frameName?: string): Promise<void> => {
    const groupList = await getKuberClusterData(frameName);
    setkuberClusterList(groupList);
  };

  return {
    kuberClusterList,
    selectedKuberClusterId,
    selectedKuberClusterName,
    kuberListOfSelectedGroup,
    kuberClusterIdForUpdateTarget,
    refreshTime,
    isInstallAgentWindowOpen,
    focusingClusterId,
    selectKuberCluster,
    setKuberClusterIdForUpdateTarget,
    initKuberClusterIdForUpdateTarget,
    fetchKuberClusterData,
    fetchKuberClusterList,
    changeInstallAgentWindowVisibility,
    setFocusingClusterId,
  };
});

const useAlertCount = () => {
  const currentAlertCount = ref<CurrentAlertCount>({
    criticalCount: 0,
    warningCount: 0,
  });
  const getTotalCount = () => {
    const { criticalCount, warningCount } = currentAlertCount.value;
    return (criticalCount ?? 0) + (warningCount ?? 0);
  };
  const totalBadgeCount = computed(() => {
    const total = getTotalCount();
    return total > 99 ? '99+' : `${total}`;
  });
  const initCount = () => {
    currentAlertCount.value = {
      criticalCount: 0,
      warningCount: 0,
    };
  };

  return {
    totalBadgeCount,
    currentAlertCount,
    initCount,
  };
};

export const useObjectTopologyAlertStore = defineStore('objectTopologyAlert', () => {
  const { getSignal } = useAbortApi();
  const validAlertType = ['namespace', 'node', 'pod', 'service'];
  const errorStatusText = ref('');
  const isShowAlertDetail = ref(false);
  const apiParams = ref<AlertApiParamsForObjectTopology>({
    clusterId: '',
    namespaceIds: [],
    resourceKinds: [],
  });
  const fetchFlag = ref<number>();

  const { totalBadgeCount, currentAlertCount, initCount } = useAlertCount();

  const setApiParams = (params: Partial<AlertApiParamsForObjectTopology>): void => {
    const resourceKinds =
      params.resourceKinds?.filter((kind) => validAlertType.includes(kind)) ?? [];
    apiParams.value = reactive({
      ...apiParams.value,
      ...params,
      ...(params.resourceKinds?.length && { resourceKinds }),
    });
  };

  const fetchAlertCount = async () => {
    if (!apiParams.value.clusterId || !apiParams.value.resourceKinds?.length) {
      initCount();
      return;
    }
    try {
      const { data } = await getK8sAlertCountAlertCommonControllerAxios({
        ...apiParams.value,
        signal: getSignal(),
        frameName: FRAME_NAMES.K8S_OBJECT_TOPOLOGY_VIEW.ALERT_COUNT,
        isPolling: true,
        isTimeout: false,
      });
      if (data?.data) {
        currentAlertCount.value = data.data as CurrentAlertCount;
        errorStatusText.value = '';
      }
    } catch (e) {
      console.log(e);
      errorStatusText.value = getAPIErrorStatusText(e);
    }
  };

  const fetchFn = () => {
    fetchFlag.value = +new Date();
    fetchAlertCount();
  };

  const { resetFetch, clearFetch } = useRepeat(fetchFn, 5_000);

  const openAlertSlide = () => {
    isShowAlertDetail.value = true;
    clearFetch();
  };

  watch(
    () => apiParams.value.resourceKinds,
    () => {
      if (!isShowAlertDetail.value) {
        resetFetch();
      } else {
        clearFetch();
      }
    },
    { deep: true, immediate: true },
  );

  return {
    apiParams,
    fetchFlag,
    totalBadgeCount,
    isShowAlertDetail,
    setApiParams,
    resetFetch,
    clearFetch,
    openAlertSlide,
  };
});

export const useClusterTopologyAlertStore = defineStore('clusterTopologyAlert', () => {
  const { getSignal } = useAbortApi();
  const isShowAlertDetail = ref(false);
  const fetchFlag = ref<number>();
  const errorStatusText = ref('');

  const apiParams = ref<AlertApiParamsForClusterTopology>({
    clusterIds: [],
  });

  const { totalBadgeCount, currentAlertCount, initCount } = useAlertCount();

  const setApiParams = (params: Partial<AlertApiParamsForClusterTopology>): void => {
    apiParams.value = reactive({
      ...apiParams.value,
      ...params,
    });
  };

  const fetchAlertCount = async () => {
    if (!apiParams.value.clusterIds?.length) {
      initCount();
      return;
    }
    try {
      const { data } = await getClusterTopologyAlertCountAlertCommonControllerAxios({
        ...apiParams.value,
        signal: getSignal(),
        frameName: FRAME_NAMES.K8S_CLUSTER_TOPOLOGY_VIEW.ALERT_COUNT,
        isPolling: true,
        isTimeout: false,
      });
      if (data?.data) {
        currentAlertCount.value = data.data as CurrentAlertCount;
        errorStatusText.value = '';
      }
    } catch (e) {
      console.log(e);
      errorStatusText.value = getAPIErrorStatusText(e);
    }
  };

  const fetchFn = () => {
    fetchFlag.value = +new Date();
    fetchAlertCount();
  };

  const { resetFetch, clearFetch } = useRepeat(fetchFn, 5_000);

  const openAlertSlide = () => {
    isShowAlertDetail.value = true;
    clearFetch();
  };

  watch(
    () => apiParams.value.clusterIds,
    () => {
      if (!isShowAlertDetail.value) {
        resetFetch();
      } else {
        clearFetch();
      }
    },
    { deep: true, immediate: true },
  );

  return {
    apiParams,
    fetchFlag,
    totalBadgeCount,
    isShowAlertDetail,
    setApiParams,
    resetFetch,
    clearFetch,
    openAlertSlide,
  };
});
