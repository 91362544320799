export const encrypt = {
  encode: (val: string) => encodeURIComponent(btoa(val)),
  decode: (val: string, isJson: boolean = false) => {
    if (isJson) {
      try {
        return JSON.parse(atob(decodeURIComponent(val)));
      } catch (e) {
        return '';
      }
    } else {
      return atob(decodeURIComponent(val));
    }
  },
};

export const webStorageController = {
  setItem: ({ type, key, value }: { type: 'session' | 'local'; key: string; value: any }): void =>
    type === 'session' ? sessionStorage.setItem(key, value) : localStorage.setItem(key, value),
  getItem: ({ type, key }: { type: 'session' | 'local'; key: string }): string | null =>
    (type === 'session' ? sessionStorage.getItem(key) : localStorage.getItem(key)) ?? null,
  removeItem: ({ type, key }: { type: 'session' | 'local'; key: string }): void =>
    type === 'session' ? sessionStorage.removeItem(key) : localStorage.removeItem(key),
  clear: (type: 'session' | 'local'): void =>
    type === 'session' ? sessionStorage.clear() : localStorage.clear(),
};
