import { Module } from 'vuex';
import { RootState } from '@/common/store';
import {
  getAPIErrorStatusText,
  getInterpolateTypeByInterval,
  isOverMysql812Version,
} from '@/common/utils/commonUtils';
import { camelCase } from 'lodash-es';
import {
  getOpenTableByIntervalMysqlV7ControllerAxios,
  getParameterMysqlV7ControllerAxios,
  getTablespaceMysqlV7ControllerAxios,
  globalTempsMysqlV7ControllerAxios,
  topTableMysqlV7ControllerAxios,
  topTempSessionListMysqlV7ControllerAxios,
} from '@/openapi/mysqlV7/api/mysql-v7-controller-api';
import {
  OpenTableMetricItemV7,
  TableItem,
  TableSpaceItemV7,
  TempItem,
  TempSessionV7,
} from '@/openapi/mysqlV7/model';
import {
  DataMetricResponse,
  MetricResponse,
  MetricV7RequestInterval,
  MetricV7RequestPeriod,
} from '@/openapi/data/model';
import { getMetricData } from '@/common/api/data';
import { transformMetricChartValues } from '@/database/utils/metricUtils';
import { checkValidMetric, MetricFailError } from '@/database/utils/utils';
import { MetricRequest } from '@/common/utils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface DbMetricParams {
  instanceIds: string[];
  metricNames: string[];
  interval:
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I20m'
    | 'I2h'
    | 'I30m'
    | 'I3m'
    | 'I5h'
    | 'I5s'
    | 'none';
  fromTime?: string;
  toTime?: string;
  summaryType?: Array<'avg' | 'delta' | 'max' | 'sigma'>;
  period?: 'p1h' | 'p3m';
}

interface TimePeriod {
  fromTime?: string;
  toTime?: string;
}
interface AdminRefParams extends TimePeriod {
  frameName?: string;
  size?: number;
  instanceId?: string;
  dbName?: string;
  interval?: string;
  frameType?: string;
}

type MetricData = [string, number][];
interface ParameterData {
  collectTime: string;
  instanceId: string;
  name: string;
  after: string;
  before: string;
}
interface AdminRefState {
  tempUsage: {
    globalTempTotalSize: MetricData;
    globalTempSize: MetricData;
    sessionTempSize: MetricData;
    globalTempList: TempItem[];
    top10SessionTempList: TempSessionV7[];
  };
  tempTimePeriod: TimePeriod;
  addInfo: {
    openTables: MetricData;
    openTablesCache: MetricData;
    parameter: ParameterData[];
    topTablespaceSize: TableSpaceItemV7[];
    top10TablespaceList: TableItem[];
  };
  addInfoTimePeriod: TimePeriod;
}

interface Payload<T> {
  data: T;
  timePeriod: TimePeriod;
  content: string;
  closePopIdx: number;
  frameName: string;
}

const INIT = {
  TEMP_USAGE: () => ({
    globalTempTotalSize: [],
    globalTempSize: [],
    sessionTempSize: [],
    globalTempList: [],
    top10SessionTempList: [],
  }),
  TEMP_TIME_PERIOD: () => ({
    fromTime: '',
    toTime: '',
  }),
  ADD_INFO: () => ({
    openTables: [],
    openTablesCache: [],
    parameter: [],
    topTablespaceSize: [],
    top10TablespaceList: [],
  }),
  ADD_INFO_TIME_PERIOD: () => ({
    fromTime: '',
    toTime: '',
  }),
};

const callAdminRefAPI = async (
  commit: any,
  commitRoot: string,
  frameName = '',
  params: (DbMetricParams & Pick<AdminRefParams, 'frameName' | 'frameType'>) | AdminRefParams,
): Promise<void> => {
  try {
    let resData;
    if (Object.prototype.hasOwnProperty.call(params, 'metricNames')) {
      const typedParams = params as DbMetricParams;

      const interpolateType = getInterpolateTypeByInterval(typedParams.interval);
      const metricRequests = typedParams.metricNames.map<MetricRequest>((name) => ({
        aggregationType: 'byTarget',
        fromTime: typedParams.fromTime,
        toTime: typedParams.toTime,
        interval: typedParams.interval as MetricV7RequestInterval,
        period: typedParams.period as MetricV7RequestPeriod,
        category: 'mysql',
        dataId: name,
        interpolateType,
        targetIds: typedParams.instanceIds,
        summaryType: 'current',
      }));

      resData = await getMetricData({
        metricV7Requests: metricRequests,
        frameName,
        isTimeout: true,
      });

      checkValidMetric((resData.data as DataMetricResponse)?.data ?? []);
    } else {
      params.frameName = frameName;

      const typedParams = params as AdminRefParams;
      switch (params.frameType) {
        case 'Global Temp List':
          resData = await globalTempsMysqlV7ControllerAxios({
            instanceId: typedParams.instanceId!,
            frameName: typedParams.frameName!,
          });
          break;
        case 'Top Tablespace Size':
          resData = await getTablespaceMysqlV7ControllerAxios({
            instanceId: typedParams.instanceId!,
            frameName: typedParams.frameName!,
          });
          break;
        case 'Top 10 Tablespace List':
          resData = await topTableMysqlV7ControllerAxios({
            instanceId: typedParams.instanceId!,
            size: typedParams.size!,
            dbName: typedParams.dbName!,
            frameName: typedParams.frameName!,
          });
          break;
        case 'Top 10 Session Temp List':
          resData = await topTempSessionListMysqlV7ControllerAxios({
            instanceId: typedParams.instanceId!,
            frameName: typedParams.frameName!,
            size: 10,
          });
          break;
        case 'Open Tables':
          resData = await getOpenTableByIntervalMysqlV7ControllerAxios({
            instanceId: typedParams.instanceId,
            interval: 'I5s',
            frameName: typedParams.frameName!,
          });
          break;
        case 'Parameter':
          resData = await getParameterMysqlV7ControllerAxios({
            instanceId: typedParams.instanceId!,
            frameName: typedParams.frameName!,
          });
          break;
        default:
          break;
      }
    }

    const {
      data: { data, query: timePeriod },
    } = resData;

    commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });

    commit(commitRoot, {
      data,
      timePeriod,
      frameName: camelCase(params.frameType ?? frameName),
    });
  } catch (e: any) {
    const statusText =
      e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);

    commit('mysqlSingleViewEnv/setFramesByFailedApi', { frameName, statusText }, { root: true });
  }
};

export const mysqlAdminRef: Module<AdminRefState, RootState> = {
  namespaced: true,
  state: {
    tempUsage: INIT.TEMP_USAGE(),
    tempTimePeriod: INIT.TEMP_TIME_PERIOD(),
    addInfo: INIT.ADD_INFO(),
    addInfoTimePeriod: INIT.ADD_INFO_TIME_PERIOD(),
  } as AdminRefState,
  mutations: {
    setTempUsageChartData(
      state: AdminRefState,
      { data, timePeriod }: Payload<MetricResponse[]>,
    ): void {
      const metricNameToStatName = {
        db_mysql_session_temp_usage: 'sessionTempSize',
        db_mysql_global_temp_usage: 'globalTempSize',
        db_mysql_global_temp_total: 'globalTempTotalSize',
      };
      data.forEach((value) => {
        const statName = metricNameToStatName[value.dataDefinition?.dataId ?? ''];
        state.tempUsage[statName] = transformMetricChartValues(value.metrics?.[0].values);
      });
      state.tempTimePeriod.fromTime = timePeriod.fromTime;
      state.tempTimePeriod.toTime = timePeriod.toTime;
    },
    setTempUsageListData(
      state: AdminRefState,
      { data, frameName, closePopIdx }: Payload<TempSessionV7>,
    ): void {
      if (data) {
        state.tempUsage[frameName].push(data);
      } else {
        state.tempUsage[frameName].splice(closePopIdx, 1);
      }
    },
    setAddInfoData(
      state: AdminRefState,
      { data, frameName, timePeriod }: Payload<OpenTableMetricItemV7[]>,
    ): void {
      if (frameName === 'openTables') {
        const metricNameToStatName = {
          db_mysql_open_tables: frameName,
          table_open_cache: `${frameName}Cache`,
        };
        data[0].metrics?.forEach(({ metricName, values }) => {
          const statName = metricNameToStatName[metricName ?? ''];
          state.addInfo[statName] = transformMetricChartValues(values);
        });

        state.addInfoTimePeriod.fromTime = timePeriod.fromTime;
        state.addInfoTimePeriod.toTime = timePeriod.toTime;
      } else {
        state.addInfo[frameName] = data;
      }
    },
    setAddInfoListData(state: AdminRefState, { data, closePopIdx }: Payload<TableItem>): void {
      if (data) {
        state.addInfo.top10TablespaceList.push(data);
      } else {
        state.addInfo.top10TablespaceList.splice(closePopIdx, 1);
      }
    },
    initTempUsageData(state: AdminRefState): void {
      state.tempUsage = INIT.TEMP_USAGE();
      state.tempTimePeriod = INIT.TEMP_TIME_PERIOD();
      state.addInfo = INIT.ADD_INFO();
      state.addInfoTimePeriod = INIT.ADD_INFO_TIME_PERIOD();
    },
    // Alert Logs는 PG꺼 사용
  },
  actions: {
    fetchTempUsageSizeData: ({ commit, rootGetters }, instanceId: string) => {
      const mysqlVersion = rootGetters['mysqlSingleViewEnv/getSelectedDbVersion'];
      const isShowSessionTemp =
        mysqlVersion &&
        isOverMysql812Version(mysqlVersion) &&
        !mysqlVersion.toLowerCase().includes('mariadb');
      const metricNames = isShowSessionTemp
        ? [
            'db_mysql_global_temp_usage',
            'db_mysql_session_temp_usage',
            'db_mysql_global_temp_total',
          ]
        : ['db_mysql_global_temp_usage', 'db_mysql_global_temp_total'];

      const frameName = FRAME_NAMES.MYSQL_SINGLE_VIEW.ADMIN_REFERENCE_TEMP_USAGE_CHART;
      return callAdminRefAPI(commit, 'setTempUsageChartData', frameName, {
        metricNames,
        instanceIds: [instanceId],
        interval: 'I5s',
        period: 'p3m',
      });
    },
    fetchTempUsageListData: (
      // AdminRefWindow 에서 사용
      { commit },
      {
        frameName,
        frameType,
        instanceId,
      }: {
        frameName: string;
        frameType: string;
        instanceId: string;
      },
    ) => callAdminRefAPI(commit, 'setTempUsageListData', frameName, { instanceId, frameType }),
    fetchAddInfoOpenTablesData: ({ commit }, instanceId: string) =>
      callAdminRefAPI(
        commit,
        'setAddInfoData',
        FRAME_NAMES.MYSQL_SINGLE_VIEW.ADMIN_REFERENCE_OPEN_TABLES,
        {
          instanceId,
          interval: '5s',
          frameType: 'Open Tables',
        },
      ),
    fetchAddInfoTablespaceData: ({ commit }, instanceId: string) =>
      callAdminRefAPI(
        commit,
        'setAddInfoData',
        FRAME_NAMES.MYSQL_SINGLE_VIEW.ADMIN_REFERENCE_TOP_TABLESPACE_SIZE,
        { instanceId, frameType: 'Top Tablespace Size' },
      ),
    fetchAddInfoParameterData: ({ commit }, instanceId: string) =>
      callAdminRefAPI(
        commit,
        'setAddInfoData',
        FRAME_NAMES.MYSQL_SINGLE_VIEW.ADMIN_REFERENCE_PARAMETER,
        { instanceId, frameType: 'Parameter' },
      ),
    fetchAddInfoTopTablespaceListData: (
      // AdminRefWindow 에서 사용
      { commit },
      {
        frameName,
        frameType,
        instanceId,
        params,
      }: {
        frameName: string;
        frameType: string;
        instanceId: string;
        params: { dbName: string };
      },
    ) =>
      callAdminRefAPI(commit, 'setAddInfoListData', frameName, {
        instanceId,
        ...params,
        frameType,
      }),
  },
  getters: {
    getTempUsageData: (state: AdminRefState) => state.tempUsage,
    getTempTimePeriod: (state: AdminRefState) => state.tempTimePeriod,
    getAddInfoData: (state: AdminRefState) => state.addInfo,
    getAddInfoTimePeriod: (state: AdminRefState) => state.addInfoTimePeriod,
  },
};
