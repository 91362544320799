import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  DashboardData,
  WidgetChartData,
  WidgetChartType,
  WidgetTimePeriod,
} from '@/dashboard/utils/types';
import { WidgetTriggerPathType, WidgetUUID } from '@/common/utils/types';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { ScatterDetailRequest } from '@/openapi/data/model';
import { getWidgetMappingInfoDashboardWidgetV8ControllerAxios } from '@/openapi/metaV6/api/dashboard-widget-v8-controller-api';
import { WidgetV8MappingResponseItem } from '@/openapi/metaV6/model';
import { isString } from 'lodash-es';
import { getBoardsIntegratedDashboardV8ControllerAxios } from '@/openapi/dashboard/api/integrated-dashboard-v8-controller-api';
import type {
  SelectedLegends,
  MonitoringDashboardTarget,
} from '@/dashboard/stores/dashboard-legend';
import type { AllDashboardTargetsColumns } from '@/dashboard/stores/dashboard-targets';

export interface TriggerDetailInfo {
  triggerPath: WidgetTriggerPathType;
  widgetId: WidgetUUID;
  targetId: string;
  targetName?: string;
  addition?: Record<string, any>;
}

export interface SummaryDetailData {
  title: string;
  widgetId: WidgetUUID;
  chartType: WidgetChartType;
  chartData: WidgetChartData[];
  chartOption: any;
  colorTheme: string;
  timePeriod: WidgetTimePeriod;
  params: any;
  targetIds?: (string[] | undefined)[];
  argParam?: ScatterDetailRequest['argParam'][];
  selectedLegends?: SelectedLegends[] | null;
  targetsColumns?: AllDashboardTargetsColumns[];
  legendList?: Record<string, MonitoringDashboardTarget>;
  matchingThemeSeriesColor?: Record<string, Record<string, string>>;
  isIncludeData?: boolean;
}

export const useDashboardViewStore = defineStore('dashboardDashboardView', () => {
  const monitoringDashboard = ref<DashboardData>({
    dashboardId: -1,
    dashboardGroupId: -1,
    dashboardName: '',
    preset: false,
    shared: false,
    sharing: false,
    startScreen: false,
    createUser: false,
    featuredReport: false,
    customReport: false,
    dashboardOption: {
      showTimePeriod: false,
    },
    iconId: '',
    creator: {
      name: '',
      activeId: '',
      profileImage: '',
    },
  });

  const toggleShowTimePeriod = () => {
    if (isString(monitoringDashboard.value.dashboardOption)) {
      const parsedOption = JSON.parse(monitoringDashboard.value.dashboardOption);
      monitoringDashboard.value.dashboardOption = {
        ...parsedOption,
        showTimePeriod: !parsedOption.showTimePeriod,
      };
    } else {
      monitoringDashboard.value.dashboardOption = {
        ...monitoringDashboard.value.dashboardOption,
        showTimePeriod: !monitoringDashboard.value.dashboardOption?.showTimePeriod,
      };
    }
  };

  const widgetsForMonitoringDashboard = ref<WidgetV8MappingResponseItem>();

  const fetchCurrentDashboardInfo = async (dashboardId: number) => {
    try {
      const { data } = await getBoardsIntegratedDashboardV8ControllerAxios({
        dashboardId,
        includeImage: false,
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_INFO,
      });

      const dashboardInfo = data?.data;

      if (dashboardInfo?.length) {
        monitoringDashboard.value = {
          dashboardId: dashboardInfo[0].dashboardId!,
          dashboardGroupId: dashboardInfo[0].dashboardGroupId || -1,
          dashboardName: dashboardInfo[0].dashboardName || '',
          startScreen: dashboardInfo[0].startScreen || false,
          preset: dashboardInfo[0].preset || false,
          shared: dashboardInfo[0].shared || false,
          sharing: dashboardInfo[0].sharing || false,
          createUser: dashboardInfo[0].createUser || false,
          featuredReport: dashboardInfo[0].featuredReport || false,
          customReport: dashboardInfo[0].customReport || false,
          menuPaths: dashboardInfo[0].menuPaths,
          analysisMenuPaths: dashboardInfo[0].analysisMenuPaths,
          dashboardOption: dashboardInfo[0].dashboardOption
            ? JSON.parse(dashboardInfo[0].dashboardOption)
            : {
                showTimePeriod: true,
              },
          iconId: dashboardInfo[0].iconId || '',
          creator: {
            name: dashboardInfo[0].createUserName || '',
            activeId: dashboardInfo[0].createUserActiveId || '',
            profileImage: dashboardInfo[0].createUserProfileImage || '',
          },
        };
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchWidgetsForMonitoringDashboard = async (dashboardId: number) => {
    try {
      const { data } = await getWidgetMappingInfoDashboardWidgetV8ControllerAxios({
        dashboardId,
        startScreen: false,
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_MAPPING_INFO,
      });
      widgetsForMonitoringDashboard.value = data.data?.[0] ?? {};
    } catch (e) {
      console.log(e);
      widgetsForMonitoringDashboard.value = {};
    }
  };

  const fetchMonitoringDashboard = async (dashboardId: number) => {
    await fetchCurrentDashboardInfo(dashboardId);
    await fetchWidgetsForMonitoringDashboard(dashboardId);
  };

  const selectedDetailInfo = ref<TriggerDetailInfo | null>(null);

  const isShowSummaryDetailSlide = ref<boolean>(false);
  const summaryDetailData = ref<SummaryDetailData | null>(null);

  const initSelectedDetailInfo = () => {
    selectedDetailInfo.value = null;
  };

  const setSelectedDetailInfo = (detailInfo: TriggerDetailInfo) => {
    selectedDetailInfo.value = detailInfo;
    isShowSummaryDetailSlide.value = false;
    summaryDetailData.value = null;
  };

  const initSummaryDetailSlide = () => {
    isShowSummaryDetailSlide.value = false;
    summaryDetailData.value = null;
    selectedDetailInfo.value = null;
  };

  const setSummaryDetailData = (data: SummaryDetailData) => {
    summaryDetailData.value = data;
    selectedDetailInfo.value = null;
    isShowSummaryDetailSlide.value = true;
  };

  const dashboardIdByRouter = ref<string>('');
  const changeIdByRouter = (id) => {
    dashboardIdByRouter.value = id;
  };

  return {
    monitoringDashboard,
    widgetsForMonitoringDashboard,
    fetchMonitoringDashboard,

    isShowSummaryDetailSlide,
    summaryDetailData,
    setSummaryDetailData,
    initSummaryDetailSlide,

    selectedDetailInfo,
    initSelectedDetailInfo,
    setSelectedDetailInfo,

    dashboardIdByRouter,
    changeIdByRouter,

    toggleShowTimePeriod,
  };
});
