import { PieChartItem } from '@/common/components/molecules/pieChartGroup/pieChartGroup.setup';
import { formatTimeRangeToUtc } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { BY_TARGET_SERIES_TYPE, GLOBAL_VARIABLE_TAG_KEY } from '@/common/define/widget.define';
import {
  convertApiDataUnitToBinaryByte,
  convertTimeApiUnit,
  isApiDataUnit,
  isApiTimeUnit,
} from '@/common/ports/convertUnits';
import { useStatInfoStore } from '@/common/stores/stat-info';
import { useUserEnvStore } from '@/common/stores/user-env';
import { useDynamicColumns } from '@/common/uses/useDynamicColumns';
import {
  DashboardUserEnvKeys,
  useDashboardUserEnv,
} from '@/common/uses/userEnv/useDashboardUserEnv';
import { formatYAxisWithUnit, useEvChartTooltipFormatter } from '@/common/utils/chartUnit.utils';
import {
  getCustomPeriodInterval,
  getSafeColorListByTheme,
  useEvChartStyle,
} from '@/common/utils/chartUtils';
import { getAPIErrorStatusText, getUtcTimeFromTzTime } from '@/common/utils/commonUtils';
import { convertValueWithUnit } from '@/common/utils/convertUnits.utils';
import type {
  Axes,
  ChartData,
  ChartOption,
  ColumnWithHideInfo,
  CustomColumn,
  PlotBands,
  PlotLines,
  SeriesData,
  TimePeriodType,
  WidgetChartDataStatusInfo,
  WidgetUUID,
} from '@/common/utils/types';
import type {
  ChartRawData,
  WidgetChartDataWithIndex,
  WidgetEmit as Emit,
  WidgetProps as Props,
} from '@/dashboard/components/widgets/widgets.types';
import { Threshold } from '@/dashboard/components/widgetSettingsWindow/thresholdsSetting/thresholdsSettings.types';
import { useDataFieldInject, useWidgetClickInject } from '@/dashboard/context';
import { useDashboardTargetsColorSyncStore } from '@/dashboard/stores/dashboard-targets-color-sync';
import { useDashboardUserEnvStore } from '@/dashboard/stores/dashboard-user-env';
import { useDashboardViewStore } from '@/dashboard/stores/dashboard-view';
import { useFilterSearchStore } from '@/dashboard/stores/filter-search';
import { useGlobalFilterStore } from '@/dashboard/stores/global-filter';
import { useWidgetRelationStore } from '@/dashboard/stores/widget-relation';
import { DefaultChartOptions } from '@/dashboard/utils/chartOption.define';
import {
  getChartDataStatusById,
  getDefaultSeriesColor,
  isWidgetReferenceGlobalTimePeriod,
} from '@/dashboard/utils/dashboardUtils';
import {
  CUSTOM_KEY,
  DISPLAY_STYLE_KEY,
  FIXED_DATA_ID,
  TIME_RANGE_COUNT_BY_CHART_TYPE,
  WIDGET_CHART_TYPE_KEY,
  WIDGET_TIME_PERIOD,
} from '@/dashboard/utils/define';
import {
  CalendarTimeRange,
  DisplayStyleType,
  Interpolation,
  WidgetChartType,
  WidgetIntervalType,
  WidgetModeType,
  WidgetTarget,
  WidgetTimePeriod,
} from '@/dashboard/utils/types';
import {
  CurrentV7Request,
  MetricV7Request,
  Nodes,
  ObjectV7Request,
  ScatterV7Request,
  TableV7Request,
  TagsRequest,
  TagsRequestTagType,
  TopologyV7Request,
  TopologyV7RequestAggregationType,
} from '@/openapi/data/model';
import { CurrentRawData } from '@/worker/commands/dashboard/current';
import { MetricRawData } from '@/worker/commands/dashboard/metrics';
import { TableRawData } from '@/worker/commands/dashboard/table';
import { TopologyRawData } from '@/worker/commands/dashboard/topology';
import { DashboardCommand, useDashboardCommand } from '@/worker/composables/useDashboardCommand';
import dayjs from 'dayjs';
import { cloneDeep, isEmpty, isEqual, isNil, uniq } from 'lodash-es';
import { storeToRefs } from 'pinia';
import {
  computed,
  MaybeRefOrGetter,
  onMounted,
  reactive,
  Ref,
  ref,
  toRaw,
  toValue,
  unref,
  type UnwrapRef,
  watch,
} from 'vue';
import {
  useDashboardTargetsStore,
  type AllDashboardTargetsColumns,
} from '@/dashboard/stores/dashboard-targets';
import {
  type SelectedLegends,
  type TargetItem,
  useDashboardLegendStore,
} from '@/dashboard/stores/dashboard-legend';
import { DecimalPrecision } from '../widgetSettingsWindow/decimalsPrecisionSelect/decimalsPrecisionSelect.setup';
import { ReferenceInfo } from '../widgetSettingsWindow/referenceSettingOption/referenceSettingOption.setup';
import { useChartDataStatus } from './checkChartDataStatus/useChartDataStatus';
import {
  CurrentDecimalFormatter,
  TableDecimalFormatter,
  useTableDecimalPrecision,
} from './decimalPrecision';
import { GaugeWidgetProps } from './gaugeWidget/gaugeWidget.setup';
import { PieWidgetProps } from './piePieWidget/piePieWidget.setup';
import { assignReferenceParams, useWidgetArgParam } from './useCustomStat';
import { ChartDataFilter } from './widgets.defines';
import {
  appendChartDataIndex,
  convertChartType,
  convertRequestKeyType,
  getFieldList,
  isChartDataValid,
} from './widgets.utils';

export interface CurrentData {
  id: string;
  label: string;
  value: number;
  unit?: string;
  color: string;
  chartDataIndex?: number;
  displayName?: string;
  statName?: string;
  alias?: string;
  show?: boolean;
}

export type RequestKeyType =
  | 'metricRequests'
  | 'currentRequests'
  | 'tableRequests'
  | 'objectRequests'
  | 'scatterRequests'
  | 'topologyRequests';

export type RequestType =
  | MetricV7Request
  | CurrentV7Request
  | TableV7Request
  | ObjectV7Request
  | ScatterV7Request
  | TopologyV7Request;

export interface DefaultParams {
  period?: WidgetTimePeriod;
  interval?: WidgetIntervalType;
  fromTime?: string;
  toTime?: string;
  limit?: number;
}

export interface WidgetApiOptions {
  requestKey: RequestKeyType;
  method: DashboardCommand['method'];
  defaultParams?: DefaultParams;
  timeout?: number;
}

export interface RequestPeriod {
  fromTime?: string;
  toTime?: string;
  period?: TimePeriodType;
  interval?: WidgetIntervalType;
  additionalFromTime?: string;
  additionalToTime?: string;
  additionalInterval?: WidgetIntervalType;
}

export interface TargetsToLegend {
  targetId: string;
  targetName: string;
  globalFilterId?: number;
}

export const useWidgetDataParams = () => {
  const { globalTimePeriod, selectedGlobalVariables } = storeToRefs(useGlobalFilterStore());
  const { allDashboardTargetsColumns } = storeToRefs(useDashboardTargetsStore());
  const { selectedLegends } = storeToRefs(useDashboardLegendStore());

  const getRequestTags = (targets: WidgetTarget[]): TagsRequest[] => {
    const useGlobalVariableIds = selectedGlobalVariables.value
      .filter(({ use }) => use !== false)
      .map(({ globalFilterId }) => `${globalFilterId}`);
    const filteredTargets =
      selectedGlobalVariables.value.length > 0
        ? targets.filter(
            ({ tagType, tagValue }) =>
              tagType !== 'globalVariable' ||
              (tagType === 'globalVariable' && useGlobalVariableIds.includes(tagValue)),
          )
        : targets;
    const tagsMap = filteredTargets.reduce(
      (requestTagsMap: Map<string, TagsRequest>, { tagKey, tagType, tagValue }) => {
        const mapKey = `${tagKey}${tagType}`;
        if (requestTagsMap.get(mapKey)) {
          requestTagsMap.get(mapKey)?.tagValue?.push(tagValue);
        } else {
          requestTagsMap.set(mapKey, {
            tagKey: tagKey ?? '',
            tagType: tagType as TagsRequestTagType,
            tagValue: [tagValue],
          });
        }
        return requestTagsMap;
      },
      new Map(),
    );

    return Array.from(tagsMap.values());
  };

  const getRequestUserTags = (globalFilterIdList?: string[]): TagsRequest[] => {
    let useGlobalVariables = selectedGlobalVariables.value.filter(({ use }) => use !== false);
    useGlobalVariables = useGlobalVariables.filter(({ globalFilterId }) => {
      if (isNil(globalFilterIdList)) return true;
      if (globalFilterIdList.length === 0) return false;
      return globalFilterIdList?.includes(`${globalFilterId}`);
    });

    const userTagsMap = useGlobalVariables.reduce(
      (requestTagsMap: Map<string, TagsRequest>, { globalFilterId, selectedList }) => {
        if (selectedList?.length) {
          selectedList.forEach(({ groupTagId, tagId, tagType }) => {
            const mapKey = `${groupTagId}${tagType}`;
            if (requestTagsMap.get(mapKey)) {
              requestTagsMap.get(mapKey)?.tagValue?.push(tagId);
            } else {
              requestTagsMap.set(mapKey, {
                tagKey: groupTagId ?? '',
                tagType: tagType as TagsRequestTagType,
                tagValue: [tagId],
              });
            }
          });
        } else {
          requestTagsMap.set(`${globalFilterId}`, {
            tagKey: GLOBAL_VARIABLE_TAG_KEY,
            tagType: 'globalVariable',
            tagValue: [`${globalFilterId}`],
          });
        }

        return requestTagsMap;
      },
      new Map(),
    );

    return Array.from(userTagsMap.values());
  };

  const getFilteredTags = ({
    targets,
    legends,
    widgetUUID,
    chartDataIndex,
    targetsColumns,
  }: {
    targets: WidgetTarget[];
    legends?: SelectedLegends[] | null;
    widgetUUID?: WidgetUUID;
    chartDataIndex?: number;
    targetsColumns?: AllDashboardTargetsColumns[];
  }): { tags?: TagsRequest[]; userTags?: TagsRequest[]; targetIds?: string[] } => {
    const resolvedLegends = legends !== undefined ? legends : selectedLegends.value;
    const resolvedTargetsColumns = allDashboardTargetsColumns.value ?? targetsColumns;

    if (resolvedLegends === null) {
      return {
        tags: [],
        userTags: [],
      };
    }

    const tags = getRequestTags(targets);
    const globalFilterIdList = tags.reduce<string[]>((idList, { tagType, tagValue }) => {
      if (tagType === 'globalVariable') {
        tagValue?.forEach((tagValueItem) => {
          if (tagValueItem != null) idList.push(tagValueItem);
        });
      }
      return idList;
    }, []);
    const userTags = getRequestUserTags(globalFilterIdList);

    if (resolvedLegends?.length) {
      const foundWidgets = resolvedTargetsColumns?.filter(
        (widget) => widget.widgetUUID === widgetUUID,
      );

      const filteredWidgetTargets = foundWidgets
        ?.filter((widget, index) => {
          if (chartDataIndex === undefined || widget.widgetUUID !== widgetUUID) return true;

          return chartDataIndex === index;
        })
        .flatMap((widget) =>
          widget.targets.filter(
            ({ id }) => resolvedLegends?.some(({ targetId }) => targetId === id),
          ),
        );

      const globalTagExist = !isNil(
        selectedGlobalVariables.value.find(({ globalFilterId }) =>
          userTags.some((tag) => tag.tagValue?.includes(`${globalFilterId}`)),
        ),
      );

      if (userTags.length && !globalTagExist) {
        return {
          targetIds: resolvedLegends
            .filter(({ targetId }) => {
              return userTags.some(({ tagValue }) => tagValue?.includes(`${targetId}`));
            })
            .map(({ targetId }) => `${targetId}`),
        };
      }

      if (
        (filteredWidgetTargets?.length &&
          foundWidgets?.length &&
          !foundWidgets?.every((widget) => !widget.targets?.length)) ||
        (userTags.length && globalTagExist)
      ) {
        return {
          targetIds: filteredWidgetTargets?.map(({ id }) => `${id}`),
        };
      }

      return {
        tags: [],
        userTags: [],
      };
    }

    return {
      tags,
      userTags,
    };
  };

  const getRequestPeriodByCalendar = (
    calendarTimeRange: CalendarTimeRange[],
    chartType: WidgetChartType,
  ): RequestPeriod => {
    const timeRangeCount = TIME_RANGE_COUNT_BY_CHART_TYPE[chartType];
    if (timeRangeCount === 2) {
      const [baseTimeRange, comTimeRange] = calendarTimeRange;
      const { fromTimeUtc: baseFromTime, toTimeUtc: baseToTime } =
        formatTimeRangeToUtc(baseTimeRange);
      const { fromTimeUtc: comFromTime, toTimeUtc: comToTime } = formatTimeRangeToUtc(comTimeRange);
      const requestPeriod: RequestPeriod = {
        fromTime: baseFromTime,
        toTime: baseToTime,
        interval: getCustomPeriodInterval('', {
          fromTime: +getUtcTimeFromTzTime(baseFromTime),
          toTime: +getUtcTimeFromTzTime(baseToTime),
        }),
      };

      if (comFromTime !== 'Invalid Date') {
        requestPeriod.additionalFromTime = comFromTime;
        requestPeriod.additionalToTime = comToTime;
        requestPeriod.additionalInterval = getCustomPeriodInterval('', {
          fromTime: +getUtcTimeFromTzTime(comFromTime),
          toTime: +getUtcTimeFromTzTime(comToTime),
        });
      }

      return requestPeriod;
    }
    const { fromTimeUtc, toTimeUtc } = formatTimeRangeToUtc(calendarTimeRange[0]);
    return {
      fromTime: fromTimeUtc,
      toTime: toTimeUtc,
      interval: getCustomPeriodInterval('', {
        fromTime: +getUtcTimeFromTzTime(fromTimeUtc),
        toTime: +getUtcTimeFromTzTime(toTimeUtc),
      }),
    };
  };

  const getRequestPeriod = (
    timePeriod?: WidgetTimePeriod,
    chartType?: WidgetChartType,
    calendarTimeRange?: CalendarTimeRange[],
  ): RequestPeriod => {
    if (timePeriod === 'globalTime') {
      if (globalTimePeriod.value.isPaused) {
        const widgetTimePeriod = WIDGET_TIME_PERIOD[globalTimePeriod.value.timePeriod];
        return {
          fromTime: globalTimePeriod.value.fromTimeUtc,
          toTime: globalTimePeriod.value.toTimeUtc,
          interval:
            widgetTimePeriod?.interval ??
            getCustomPeriodInterval('', {
              fromTime: +getUtcTimeFromTzTime(globalTimePeriod.value.fromTimeUtc),
              toTime: +getUtcTimeFromTzTime(globalTimePeriod.value.toTimeUtc),
            }),
        };
      }
      return { period: globalTimePeriod.value.timePeriod as TimePeriodType };
    }

    if (timePeriod === 'calendar') {
      return getRequestPeriodByCalendar(calendarTimeRange!, chartType!);
    }

    return { period: (timePeriod as TimePeriodType) ?? 'p5m' };
  };

  return {
    getRequestTags,
    getRequestUserTags,
    getFilteredTags,
    getRequestPeriod,
  };
};

export const useChangeApiParams = <ChartType extends 'TIME_SERIES'>(
  props: Props<ChartType>,
  options: WidgetApiOptions,
) => {
  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();
  const { defaultParams = {} } = options;

  const { getArgParams } = useWidgetArgParam(
    computed(() => props.chartOption?.referenceInfo ?? {}),
  );
  const { setAllDashboardCustomStats } = useDashboardLegendStore();

  const getInterpolateType = (
    interpolation: Interpolation | undefined,
    displayType: DisplayStyleType,
  ) => {
    const displayBar = [DISPLAY_STYLE_KEY.BAR, DISPLAY_STYLE_KEY.STACKED_BAR];

    if (displayBar.includes(displayType)) {
      return 'Null';
    }

    return interpolation ?? 'Null';
  };

  const changeApiParamsWithInterpolate = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(MetricV7Request & { id: string })[]> => {
    return widgetChartData.map(
      ({ id, dataType, dataId, targets, seriesType, category, chartDataIndex }) => {
        const widgetArgParams = getArgParams(props.chartType, chartDataIndex, dataId, category);

        if (props.createType === 'reference' && props.mode !== 'preview') {
          setAllDashboardCustomStats({
            widgetId: props.widgetId,
            stats: widgetArgParams,
          });
        }

        return {
          id,
          interval: defaultParams.interval,
          fromTime: defaultParams.fromTime,
          toTime: defaultParams.toTime,
          limit: defaultParams.limit,
          dataId: dataId ?? '',
          category,
          aggregationType: seriesType,
          summaryType: dataType,
          ...getFilteredTags({ targets, widgetUUID: props.widgetId, chartDataIndex }),
          ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
          interpolateType: getInterpolateType(props.chartOption?.interpolation, props.displayStyle),
          argParam: widgetArgParams,
        };
      },
    );
  };

  return { changeApiParamsWithInterpolate };
};

export const useWidgetApi = <T extends RequestType, R extends ChartRawData>(
  props: Props,
  emit: Emit,
  options: {
    widgetApiOptions: WidgetApiOptions;
    changeApiParams?: (
      widgetChartData: WidgetChartDataWithIndex[],
    ) => Promise<Array<T & { id: string }>>;
  },
) => {
  const isReferenceWidget = computed(() => props.createType === 'reference');

  // widget api 호출시에 사용된 time 정보. detail 연계시에 사용됨
  const widgetTimePeriod = ref<RequestPeriod | null>(null);

  const { filterToSearch, searchWidgetUUID } = storeToRefs(useFilterSearchStore());
  const { clickedWidgetInfo, clickedWidgetId } = useWidgetClickInject();
  const { isDescendantWidget } = useWidgetRelationStore();

  const {
    setLegendClick,
    setMonitoringDashboardTargetList,
    getMonitoringDashboardTargetList,
    updateChangedLegend,
  } = useDashboardLegendStore();
  const { isDashboardLegendOpen, isLegendClicked, selectedLegends, isChangedLegend } =
    storeToRefs(useDashboardLegendStore());

  const { requestKey, method, defaultParams = {}, timeout = 5_000 } = options.widgetApiOptions;

  const apiParams = ref<Record<string, Array<T & { id: string }>>>({
    [requestKey]: [],
  });

  const { widgetId = '', titleOption } = props;
  const frameName = `${FRAME_NAMES.DASHBOARD.WIDGETS}/${
    titleOption?.titleText ?? ''
  } (${widgetId})`;

  const command: Omit<DashboardCommand, 'namespace'> = {
    method,
    params: {
      ...toRaw(unref(apiParams)),
      frameName,
    },
  };

  const isRepeat =
    ((props.mode === 'view' || props.mode === 'edit' || props.chartType === 'ACTION_VIEW') &&
      !isReferenceWidget.value) ||
    (command.method === 'scatter' &&
      ((selectedLegends.value && selectedLegends.value.length > 0) ||
        selectedLegends.value === null));

  const commandOptions = {
    repeatInfo: {
      isRepeat,
      timeout,
    },
  };

  const {
    isLoading,
    data: rawData,
    chartDataStatus,
    error,
    fetchData,
    resetFetchData,
    clearFetch,
    abortApiController,
  } = useDashboardCommand<R>(command, commandOptions);

  useChartDataStatus(
    convertRequestKeyType(options.widgetApiOptions.requestKey),
    convertChartType(props.chartType, props.displayStyle),
    computed(() => rawData.value),
    computed(() => chartDataStatus.value),
    computed(() => props.mode),
    emit,
  );

  const { setWidgetDataField, getWidgetDataField } = useDataFieldInject();

  const {
    globalTimePeriod,
    selectedGlobalVariables,
    globalVariables,
    updatedGlobalVariableId,
    isPausedBySavingDashboard,
  } = storeToRefs(useGlobalFilterStore());

  const { getMatchingThemeSeriesColor } = useDashboardTargetsColorSyncStore();

  const setLegendList = (newRawData: UnwrapRef<R>) => {
    const targetsArr: TargetsToLegend[][] | undefined =
      'targets' in newRawData ? newRawData.targets : [];
    let colorIndex = 0;

    const foundGlobalVarTargets = props.chartData?.map(({ targets }) => {
      const foundTargets = globalVariables.value.find(({ globalFilterId }) => {
        return targets.find(({ tagValue }) => {
          return +tagValue === globalFilterId;
        });
      });

      return foundTargets?.targets.map(({ tagId, tagName }) => {
        return {
          targetId: `${tagId}`,
          targetName: tagName,
          globalFilterId: foundTargets!.globalFilterId,
        };
      });
    });

    if (props.mode !== 'preview') {
      const curTargetList: TargetItem[] = [];
      targetsArr?.forEach((targets, targetsIndex) => {
        if (props.chartData?.[targetsIndex]?.value?.isShow === false) {
          return;
        }

        targets.forEach(({ targetId, targetName }) => {
          const originColor = getMatchingThemeSeriesColor({
            colorTheme: props.colorTheme || props.chartOption.chartTheme || '',
            targetId,
            colorIndex,
          });
          const color = getMonitoringDashboardTargetList(targetId)?.color || originColor;

          const alias = getMonitoringDashboardTargetList(targetId)?.alias ?? '';

          let globalFilterId: number | undefined;
          if (foundGlobalVarTargets?.[0]?.length) {
            const foundTarget = foundGlobalVarTargets?.[targetsIndex]?.find(
              (v) => v.targetId === targetId,
            );
            globalFilterId = foundTarget?.globalFilterId;
          }

          curTargetList.push({
            widgetId: props.widgetId,
            widgetName: props.titleOption?.titleText ?? '',
            category:
              props.chartData?.[targetsIndex]?.category ?? props.chartData?.[0]?.category ?? '',
            targetId,
            targetName,
            alias,
            color,
            originColor,
            globalFilterId,
          });

          colorIndex += 1;
        });
      });

      setMonitoringDashboardTargetList(curTargetList.length ? curTargetList : props.widgetId);
    }
  };

  watch(rawData, (newRawData) => {
    if (
      props.chartData?.[0]?.seriesType === BY_TARGET_SERIES_TYPE &&
      !isLegendClicked.value &&
      isDashboardLegendOpen.value
    ) {
      setLegendList(newRawData);
      setLegendClick(false);
    }

    const fieldList = getWidgetDataField(props.widgetId);
    const newFieldList = getFieldList(props.chartType, newRawData);
    if (fieldList == null || !isEqual(fieldList, newFieldList)) {
      setWidgetDataField(props.widgetId, getFieldList(props.chartType, newRawData));
    }
  });

  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();

  const { monitoringDashboard, dashboardIdByRouter } = storeToRefs(useDashboardViewStore());

  const { setAllDashboardCustomStats } = useDashboardLegendStore();

  const { getArgParams } = useWidgetArgParam(
    computed(() => props.chartOption?.referenceInfo ?? {}),
  );

  const defaultChangeApiParams = async (widgetChartData: WidgetChartDataWithIndex[]) => {
    return widgetChartData.map(
      ({ chartDataIndex, id, dataType, dataId, targets, seriesType, category }) => {
        const widgetArgParams = getArgParams(props.chartType, chartDataIndex, dataId, category);

        if (props.createType === 'reference' && props.mode !== 'preview') {
          setAllDashboardCustomStats({
            widgetId: props.widgetId,
            stats: widgetArgParams,
          });
        }

        return {
          id,
          ...defaultParams,
          dataId: dataId ?? '',
          category,
          aggregationType: seriesType,
          summaryType: dataType,
          ...getFilteredTags({ targets, widgetUUID: props.widgetId, chartDataIndex }),
          ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
          argParam: widgetArgParams,
        };
      },
    ) as any;
  };

  const getApiKey = (id?: number) => {
    if (props.mode === 'preview') {
      return 'preview';
    }
    if (id) {
      return `dashboard_${id}`;
    }
    const { dashboardId } = monitoringDashboard.value;
    return `dashboard_${dashboardId}`;
  };

  const updateWidgetTimePeriod = (requestParam: RequestType) => {
    widgetTimePeriod.value = {
      fromTime: requestParam.fromTime,
      toTime: requestParam.toTime,
      period: requestParam.period,
    };
  };

  const callApi = async (apiOptions?: { isResetRecall?: boolean; apiKey?: string | number }) => {
    if (props.chartData?.length && props.timePeriod) {
      const chartDataWithIndex = appendChartDataIndex(props.chartData ?? []);
      const filteredChartData = chartDataWithIndex.filter(
        ChartDataFilter[props.chartType](props.createType),
      );
      let requestParam: (T & { id: string })[] = [];
      if (options.changeApiParams) {
        requestParam = await options.changeApiParams(filteredChartData);
      } else {
        requestParam = await defaultChangeApiParams(filteredChartData);
      }
      apiParams.value[requestKey] = requestParam.map((param) =>
        assignReferenceParams(param, props.createType),
      );
      const firstRequestParam = apiParams.value[requestKey].at(0);
      if (firstRequestParam) {
        updateWidgetTimePeriod(firstRequestParam);
      } else {
        widgetTimePeriod.value = null;
      }

      if (!apiParams.value[requestKey].length || !props.isInViewport) {
        return;
      }

      const { isResetRecall = true, apiKey } = apiOptions ?? {};

      if (
        isRepeat &&
        !globalTimePeriod.value.isPaused &&
        props.timePeriod !== 'calendar' &&
        resetFetchData
      ) {
        resetFetchData({
          ...toRaw(unref(apiParams.value)),
          frameName,
          isResetRecall,
          apiKey: apiKey ?? getApiKey(),
        });
      } else if (fetchData) {
        await fetchData({
          ...toRaw(unref(apiParams.value)),
          frameName,
          isResetRecall,
          apiKey: apiKey ?? getApiKey(),
        });
      }
    }
  };

  const abortApiByKey = () => {
    const apiKey = getApiKey();
    abortApiController.byKey(apiKey);
  };
  const abortApiDueToDashboardIdChange = (dashboardId?: number) => {
    if (props.mode === 'preview') {
      return;
    }
    const apiKey = getApiKey(dashboardId);
    abortApiController.exceptKey(apiKey);
  };
  const abortAllApi = () => {
    abortApiController.all();
  };

  const callApiAfterAbort = async (apiOptions?: {
    isResetRecall?: boolean;
    apiKey?: string | number;
  }) => {
    abortApiByKey();
    await callApi(apiOptions);
  };

  const errorMsg = computed<string>(() =>
    isEmpty(error.value) ? '' : getAPIErrorStatusText(error.value),
  );

  watch(
    () => [props.chartData, isDashboardLegendOpen.value, selectedLegends.value],
    async () => {
      await callApiAfterAbort();
    },
    {
      deep: true,
    },
  );

  watch(isChangedLegend, async (changed) => {
    if (changed) {
      await callApiAfterAbort();
      updateChangedLegend(false);
    }
  });

  watch(
    selectedGlobalVariables,
    async () => {
      if (isReferenceWidget.value) {
        const hasGlobalVariableReference = Object.values(
          props.chartOption.referenceInfo as ReferenceInfo,
        ).some((variableList) => {
          return variableList.some(
            (variable) =>
              variable.value?.type === 'globalVariable' &&
              variable.value.variableId.includes(`${updatedGlobalVariableId.value}`),
          );
        });
        if (hasGlobalVariableReference) {
          await callApiAfterAbort();
        }
      } else {
        const useGlobalVariables = props.chartData?.some(({ targets }) =>
          targets.some((t) => t.tagType === 'globalVariable'),
        );
        if (useGlobalVariables) {
          await callApiAfterAbort();
        }
      }
    },
    { deep: true },
  );

  watch(
    () => props.timePeriod,
    async () => {
      if (isReferenceWidget.value) return;
      if (props.timePeriod === 'calendar') {
        return;
      }

      if (isRepeat && clearFetch) {
        await clearFetch();
      }
      await callApiAfterAbort();
    },
  );

  watch(
    () => props.calendarTimeRange,
    async (timeRange) => {
      if (isReferenceWidget.value) return;
      if (props.timePeriod === 'calendar' && timeRange) {
        if (isRepeat && clearFetch) {
          await clearFetch();
        }
        await callApiAfterAbort();
      }
    },
    {
      deep: true,
    },
  );

  watch(globalTimePeriod, async (cur, pre) => {
    if (isReferenceWidget.value) {
      if (props.mode !== 'preview' && isWidgetReferenceGlobalTimePeriod(props.chartOption)) {
        await callApiAfterAbort();
      }
      return;
    }
    // calendar로 user custom period인 경우 글로벌 변수가 변경되어도 api 호출하지 않음
    if (props.timePeriod === 'calendar') {
      return;
    }

    // 재생 상태에서 timePeriod가 변경되거나 정지 상태에서 글로벌 타임으로 설정되고 fromTime, toTime 이 변경된 경우
    if (
      (!cur.isPaused && cur.timePeriod !== pre.timePeriod) ||
      (props.timePeriod === 'globalTime' && pre.isPaused && cur.isPaused)
    ) {
      await callApiAfterAbort();
      return;
    }
    // 재생중에 정지하거나 정지중에 재생할 때
    if (isRepeat && clearFetch && cur.isPaused !== pre.isPaused) {
      await clearFetch();
      if (!cur.isPaused || (props.timePeriod === 'globalTime' && cur.toTime !== pre.toTime)) {
        await callApiAfterAbort();
      }
    }
  });

  watch(dashboardIdByRouter, (id) => {
    if (id === 'all') {
      abortAllApi();
    } else {
      abortApiDueToDashboardIdChange(+id);
    }
  });

  watch(
    () => props.createType,
    async () => {
      await callApiAfterAbort();
    },
  );

  watch(
    () => props.isInViewport,
    (isInViewport) => {
      if (isInViewport) {
        callApi();
      } else {
        clearFetch?.();
      }
    },
  );

  watch(
    () => isPausedBySavingDashboard.value,
    (newValue) => {
      if (newValue) {
        clearFetch?.({
          immediate: true,
        });
      }
    },
  );

  watch(
    filterToSearch,
    async () => {
      if (!isReferenceWidget.value) return;
      if (!searchWidgetUUID.value) {
        return;
      }
      if (isDescendantWidget(searchWidgetUUID.value, props.widgetId)) {
        await callApiAfterAbort();
      }
    },
    {
      deep: true,
    },
  );

  watch(
    clickedWidgetInfo,
    async (newClickedWidgetData) => {
      if (!isReferenceWidget.value) return;
      if (!newClickedWidgetData || !clickedWidgetId.value) return;
      if (isDescendantWidget(clickedWidgetId.value, props.widgetId)) {
        await callApiAfterAbort();
      }
    },
    {
      deep: true,
    },
  );

  watch(
    () => props.chartOption?.referenceInfo,
    async () => {
      if (!isReferenceWidget.value) return;
      await callApiAfterAbort();
    },
  );

  onMounted(async () => {
    await callApi({ isResetRecall: false });
  });

  return {
    isLoading,
    rawData,
    errorMsg,
    chartDataStatus,
    callApiAfterAbort,
    widgetTimePeriod,
    clearFetch,
  };
};

export const useInitWidget = <R>({
  target,
  dataKey,
  callback,
  errorMsg,
}: {
  target: Ref<R>;
  dataKey: string;
  callback: (t: any, r: boolean) => boolean;
  errorMsg: Ref<string>;
}): Ref<boolean> => {
  const result = ref<boolean>(false);
  watch(
    () => target.value?.[dataKey],
    (t) => {
      result.value = callback(t, result.value);
    },
  );
  if (errorMsg) {
    watch(
      () => errorMsg.value,
      () => {
        result.value = true;
      },
    );
  }
  return result;
};

export const useMetricChartWidget = (
  props: Props<'TIME_SERIES'>,
  rawData: Ref<MetricRawData>,
  chartDataStatusInfo: Ref<WidgetChartDataStatusInfo[]>,
) => {
  const { axisStyle, legendStyle, tooltipStyle, indicatorStyle, maxTipStyle, tipStyle } =
    useEvChartStyle();

  const unitList = computed(() => rawData.value?.unitList ?? []);
  const { tooltipValueFormatter } = useEvChartTooltipFormatter(
    unitList,
    () =>
      props.chartOption.decimalOptions?.use
        ? props.chartOption.decimalOptions.decimalsPrecision
        : undefined,
    () => props.chartOption.decimalOptions?.use,
  );

  const store = useGlobalFilterStore();
  const { globalTimePeriod } = storeToRefs(store);

  const { setMatchingThemeSeriesColor, getMatchingThemeSeriesColor } =
    useDashboardTargetsColorSyncStore();
  const { getMonitoringDashboardTargetList } = useDashboardLegendStore();

  const getTimeAxisInfoByInterval = (interval: WidgetIntervalType | undefined) => {
    switch (interval) {
      case 'I1h':
        return {
          timeFormat: 'DD HH:mm',
          interval: 'hour',
        };
      case 'I24h':
        return {
          timeFormat: 'DD HH:mm',
          interval: 'day',
        };
      case 'I10m':
      case 'I1m':
        return {
          timeFormat: 'HH:mm',
          interval: 'minute',
        };
      default:
        return {
          timeFormat: 'HH:mm:ss',
          interval: 'second',
        };
    }
  };

  const getTimeAxisInfo = (
    timePeriod: WidgetTimePeriod | undefined,
  ): { timeFormat: string; interval: string } => {
    switch (timePeriod) {
      case 'globalTime': {
        const { fromTimeUtc, toTimeUtc } = globalTimePeriod.value;
        const interval = getCustomPeriodInterval('', {
          fromTime: +getUtcTimeFromTzTime(fromTimeUtc),
          toTime: +getUtcTimeFromTzTime(toTimeUtc),
        });

        return getTimeAxisInfoByInterval(interval);
      }

      case 'calendar': {
        if (props.calendarTimeRange?.length) {
          const { fromTime, toTime } = props.calendarTimeRange[0];
          const interval = getCustomPeriodInterval('', {
            fromTime: +getUtcTimeFromTzTime(fromTime),
            toTime: +getUtcTimeFromTzTime(toTime),
          });

          return getTimeAxisInfoByInterval(interval);
        }

        return getTimeAxisInfoByInterval('I5s');
      }

      case 'p30m':
      case 'p1h':
        return getTimeAxisInfoByInterval('I1m');

      case 'p6h':
      case 'p12h':
      case 'p1d':
        return getTimeAxisInfoByInterval('I10m');

      default:
      case 'p5m':
      case 'p10m':
        return getTimeAxisInfoByInterval('I5s');
    }
  };

  const getChartOption = (type: 'bar' | 'line', option?: ChartOption): ChartOption => {
    return reactive({
      type,
      width: '100%',
      height: '100%',
      title: {
        show: false,
      },
      selectItem: {
        ...tipStyle,
        use: true,
        limit: 1,
        showTextTip: true,
        tipText: 'label',
        fixedPosTop: true,
        showIndicator: true,
        useDeselectOverflow: true,
        useSeriesOpacity: false,
        useLabelOpacity: false,
      },
      legend: {
        ...legendStyle,
        show: props.chartOption?.showLegend ?? false,
        position: 'bottom',
        padding: { top: 0, left: 0 }, // NOTE: 음수 값 사용 불가
        height: 32,
        virtualScroll: true,
      },
      padding: {
        top: 20,
        right: 0,
        left: 0,
        bottom: 4,
      },
      axesX: computed<Axes[]>(() => {
        const labels = rawData.value?.chartData.labels;
        const fromTime = labels[0];
        const toTime = labels[labels.length - 1];
        const { timeFormat, interval } =
          props.createType === 'base'
            ? getTimeAxisInfo(props.timePeriod)
            : getTimeAxisInfoByInterval(
                getCustomPeriodInterval('', {
                  fromTime: +getUtcTimeFromTzTime(fromTime),
                  toTime: +getUtcTimeFromTzTime(toTime),
                }),
              );

        const formatter = (value: number, data: { prev: number }): string => {
          if (interval !== 'day') {
            const curr = dayjs(value).format('yy-MM-DD');
            const prev = data?.prev ? dayjs(data.prev).format('yy-MM-DD') : '';
            if (curr !== prev) {
              const format = interval === 'hour' ? 'HH:mm' : timeFormat;
              return dayjs(value).format(`DD ${format}`);
            }
          }

          return dayjs(value).format(timeFormat);
        };

        return [
          {
            ...axisStyle,
            type: 'time',
            timeFormat,
            interval,
            showAxis: true,
            showGrid: false,
            formatter,
          },
        ];
      }),
      axesY: [
        {
          ...axisStyle,
          type: 'linear',
          showGrid: true,
          showAxis: false,
          startToZero: true,
          autoScaleRatio: 0.1,
        },
      ],
      indicator: {
        ...indicatorStyle,
      },
      tooltip: {
        ...tooltipStyle,
        use: true,
        formatter: tooltipValueFormatter,
      },
      maxTip: {
        use: !!props.chartOption?.useMarkers && props.chartOption.showMaxValue,
        tipStyle: {
          ...maxTipStyle,
        },
      },
      ...option,
    });
  };

  const getMetricChartSeries = (defaultSeriesData: SeriesData = {}): Record<string, SeriesData> => {
    const series: Record<string, SeriesData> = {};
    const data = rawData.value;

    let colorIndex = 0;

    props.chartData
      ?.filter((item) => isChartDataValid(props.createType)(item))
      .forEach(({ alias, value, seriesType, dataId, id }, index: number) => {
        const rawSeries = data?.chartData?.series;
        const statInfo = getChartDataStatusById(chartDataStatusInfo.value, id);
        if (statInfo?.status === 'success') {
          const isNoData = statInfo.info === 'NO_DATA';
          if (
            props.chartType !== WIDGET_CHART_TYPE_KEY.SCATTER &&
            seriesType === BY_TARGET_SERIES_TYPE &&
            !isNoData
          ) {
            const targets = data?.targets?.[index] ?? [];

            setMatchingThemeSeriesColor({
              curTheme: props.colorTheme || '',
              targets,
            });

            targets.forEach(({ targetName, targetId }, targetIndex) => {
              const targetSeriesId = `${dataId}_${index}_${targetIndex}`;
              const legendData = getMonitoringDashboardTargetList(targetId);

              const color =
                legendData?.color ||
                getMatchingThemeSeriesColor({
                  colorTheme: props.colorTheme || '',
                  targetId,
                  colorIndex,
                });

              series[targetSeriesId] = {
                ...defaultSeriesData,
                name: legendData?.alias || targetName,
                color,
                pointFill: color,
                show: value.isShow,
                showLegend: value.isShow,
              };

              colorIndex += 1;
            });
            return;
          }
          const colorList =
            props.colorTheme && props.colorTheme !== CUSTOM_KEY
              ? getSafeColorListByTheme(props.colorTheme)
              : [];
          const color = colorList?.length ? colorList[colorIndex % colorList.length] : value.color;
          const dataSeriesId = `${dataId}_${index}`;
          const dataSeriesName = rawSeries?.[dataSeriesId]?.name ?? '';
          series[dataSeriesId] = {
            ...defaultSeriesData,
            name: isNoData ? 'No Data' : alias || dataSeriesName,
            color,
            overflowColor: color,
            show: value.isShow,
            showLegend: value.isShow,
          };
          colorIndex += 1;
        }
      });

    return series;
  };

  return {
    getChartOption,
    getMetricChartSeries,
  };
};

export const useCurrentChartWidget = (
  props: Props<'STACKED_PROPORTION_BAR' | 'SCOREBOARD'>,
  emit: Emit,
) => {
  const widgetApiOptions: WidgetApiOptions = {
    requestKey: 'currentRequests',
    method: 'current',
  };

  const { isLoading, rawData, chartDataStatus, errorMsg, callApiAfterAbort } = useWidgetApi<
    CurrentV7Request,
    CurrentRawData
  >(props, emit, { widgetApiOptions });

  const { setMatchingThemeSeriesColor, getMatchingThemeSeriesColor } =
    useDashboardTargetsColorSyncStore();
  const { getMonitoringDashboardTargetList } = useDashboardLegendStore();

  const getCurrentDataList = (currentRawData?: CurrentRawData): CurrentData[] => {
    const statInfoStore = useStatInfoStore();

    const currentDataList: CurrentData[] = [];
    const errorOccurredChartDataIdList = chartDataStatus.value
      .filter((statusInfo) => statusInfo.status === 'fail')
      .map((errorInfo) => errorInfo.chartDataId);

    let colorIndex = 0;

    props.chartData
      ?.filter((item) => isChartDataValid(props.createType)(item))
      .forEach(({ seriesType, category, dataId, alias, value, id }, index: number) => {
        if (!value.isShow) {
          return;
        }

        if (!errorOccurredChartDataIdList.includes(id)) {
          const currentItems = currentRawData?.currentData?.[index];

          const statName = statInfoStore.getStatInfo({
            childCategory: category,
            statId: dataId,
          })?.name;

          if (seriesType === BY_TARGET_SERIES_TYPE) {
            const targets = currentRawData?.targets?.[index] ?? [];

            setMatchingThemeSeriesColor({
              curTheme: props.colorTheme || '',
              targets,
            });

            targets.forEach(({ targetId, targetName }, targetIndex: number) => {
              const { unit = '', value: itemValue = 0 } =
                currentItems?.find((item) => item.targetId === targetId) ?? {};

              const legendData = getMonitoringDashboardTargetList(targetId);

              const color =
                legendData?.color ||
                getMatchingThemeSeriesColor({
                  colorTheme: props.colorTheme || '',
                  targetId,
                  colorIndex,
                });

              currentDataList.push({
                id: `${index}_${targetIndex}`,
                chartDataIndex: index,
                label: legendData?.alias || targetName,
                value: itemValue,
                color,
                unit: !unit || unit?.toLowerCase() === 'count' ? '' : unit,
                statName: statName || '',
                alias: alias || '',
                show: value.isShow,
              });
              colorIndex += 1;
            });
            return;
          }
          let { color } = value;
          if (props.colorTheme !== CUSTOM_KEY && props.colorTheme) {
            color = getSafeColorListByTheme(props.colorTheme)[index];
          } else {
            color = color ?? getDefaultSeriesColor(index);
          }
          const unit = currentItems?.[0]?.unit;

          currentDataList.push({
            id: `${dataId}_${index}`,
            chartDataIndex: index,
            label: alias || statName || '',
            value: currentItems?.[0]?.value ?? 0,
            color,
            unit: !unit || unit?.toLowerCase() === 'count' ? '' : unit,
            statName: statName || '',
            alias: alias || '',
            show: value.isShow,
          });
        }
      });
    return currentDataList;
  };

  const getDecimalFormattedCurrentDataList = (formatter: CurrentDecimalFormatter) => {
    if (!rawData.value) {
      return [];
    }
    const decimal = props.chartOption?.decimalOptions?.use
      ? props.chartOption.decimalOptions.decimalsPrecision
      : cloneDeep(DefaultChartOptions[props.chartType].decimalOptions.decimalsPrecision);
    const decimalFormatted = {
      ...rawData.value,
      currentData: formatter(rawData.value.currentData, decimal),
    };
    return getCurrentDataList(decimalFormatted);
  };

  watch(
    () => props.chartData,
    async () => {
      if (props.mode === 'preview') {
        await callApiAfterAbort();
      }
    },
    {
      immediate: true,
    },
  );

  return {
    isLoading,
    rawData,
    errorMsg,
    getCurrentDataList,
    getDecimalFormattedCurrentDataList,
  };
};

export const useStatusSummaryWidget = (props: Props, emit: Emit) => {
  const options: WidgetApiOptions = {
    requestKey: 'tableRequests',
    method: 'table',
  };

  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();

  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TableV7Request & { id: string })[]> => {
    return widgetChartData.map(
      ({ id, dataType, targets, seriesType, category, value, chartDataIndex }) => ({
        id,
        dataId: FIXED_DATA_ID.STATUS_SUMMARY,
        category,
        aggregationType: seriesType,
        summaryType: dataType,
        ...getFilteredTags({ targets, widgetUUID: props.widgetId, chartDataIndex }),
        ...getRequestPeriod(props.timePeriod),
        targetCategory: value?.targetCategory ?? category,
      }),
    );
  };

  return {
    ...useWidgetApi<TableV7Request, TableRawData>(props, emit, {
      widgetApiOptions: options,
      changeApiParams,
    }),
    changeApiParams,
  };
};

export const useStatusHexaWidget = (props: Props, emit: Emit) => {
  const options: WidgetApiOptions = {
    requestKey: 'tableRequests',
    method: 'table',
  };

  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();

  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TableV7Request & { id: string })[]> => {
    return widgetChartData.map(
      ({ id, dataType, targets, seriesType, category, value, chartDataIndex }) => ({
        id,
        dataId: FIXED_DATA_ID.STATUS,
        category,
        aggregationType: seriesType,
        summaryType: dataType,
        ...getFilteredTags({ targets, widgetUUID: props.widgetId, chartDataIndex }),
        ...getRequestPeriod(props.timePeriod),
        targetCategory: value?.targetCategory ?? category,
        sortColumn: value?.sort,
        sortType: value?.orderBy,
        limit: value?.limit,
      }),
    );
  };

  return {
    ...useWidgetApi<TableV7Request, TableRawData>(props, emit, {
      widgetApiOptions: options,
      changeApiParams,
    }),
    changeApiParams,
  };
};

export const useGaugeChartWidget = (props: GaugeWidgetProps, emit: Emit) => {
  const options: WidgetApiOptions = {
    requestKey: 'currentRequests',
    method: 'current',
  };

  const { getFilteredTags, getRequestPeriod } = useWidgetDataParams();
  const { getArgParams } = useWidgetArgParam(computed(() => ({})));
  const { setAllDashboardCustomStats } = useDashboardLegendStore();

  const multiData = computed(() => props.chartOption.multiGauge?.chartData);
  const markerData = computed(() => props.chartOption.marker?.markerValueOption?.chartData);
  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TableV7Request & { id: string })[]> => {
    const req = [
      ...widgetChartData,
      props.chartOption.multiGauge?.use
        ? {
            ...multiData.value,
            seriesType: widgetChartData?.[0]?.seriesType,
            targets: widgetChartData?.[0]?.targets,
          }
        : {},
      props.chartOption.marker?.markerValueOption?.type === 'metricMarker'
        ? {
            ...markerData.value,
            targets: widgetChartData?.[0]?.targets,
          }
        : {},
    ].filter((data): data is WidgetChartDataWithIndex => !!data);

    const chartDataWithIndex = appendChartDataIndex(req ?? []);
    const filteredChartData = chartDataWithIndex.filter(
      ChartDataFilter[props.chartType](props.createType),
    );

    return filteredChartData.map(
      ({ id, dataType, dataId, targets, seriesType, category, chartDataIndex }) => {
        const widgetArgParams = getArgParams(props.chartType, 0, dataId, category);

        if (props.createType === 'reference' && props.mode !== 'preview') {
          setAllDashboardCustomStats({
            widgetId: props.widgetId,
            stats: widgetArgParams,
          });
        }

        return {
          id,
          dataId,
          category,
          aggregationType: seriesType,
          summaryType: dataType,
          ...getFilteredTags({ targets, widgetUUID: props.widgetId, chartDataIndex }),
          ...getRequestPeriod(props.timePeriod),
          argParam: widgetArgParams,
        };
      },
    );
  };

  const widgetApi = useWidgetApi<CurrentV7Request, CurrentRawData>(props, emit, {
    widgetApiOptions: options,
    changeApiParams,
  });

  watch(
    [
      () => multiData.value?.dataType,
      () => multiData.value?.dataId,
      () => markerData.value?.dataId,
      () => markerData.value?.dataType,
      () => markerData.value?.seriesType,
      () => props.chartOption.multiGauge?.use,
      () => props.chartOption.marker?.markerValueOption?.type,
      () => props.chartData,
    ],
    async () => {
      await widgetApi.callApiAfterAbort();
    },
    { immediate: true },
  );

  return {
    ...widgetApi,
    changeApiParams,
  };
};

export const usePieChartWidget = (props: PieWidgetProps, emit: Emit) => {
  const chartData = ref<PieChartItem[]>([]);

  const widgetApiOptions: WidgetApiOptions = {
    requestKey: 'tableRequests',
    method: 'table',
  };

  const { getFilteredTags, getRequestPeriod } = useWidgetDataParams();

  const { getArgParams } = useWidgetArgParam(
    computed(() => props.chartOption?.referenceInfo ?? {}),
  );

  const { setAllDashboardCustomStats } = useDashboardLegendStore();

  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TableV7Request & { id: string })[]> => {
    let tableRequests: (TableV7Request & { id: string })[] = [];

    if (widgetChartData.length) {
      tableRequests = widgetChartData.map(
        ({ id, dataType, dataId, targets, seriesType, category, chartDataIndex }) => {
          const widgetArgParams = getArgParams(props.chartType, 0, dataId, category);

          if (props.createType === 'reference' && props.mode !== 'preview') {
            setAllDashboardCustomStats({
              widgetId: props.widgetId,
              stats: widgetArgParams,
            });
          }

          return {
            id,
            dataId: dataId ?? '',
            category,
            aggregationType: seriesType,
            summaryType: dataType,
            ...getFilteredTags({ targets, widgetUUID: props.widgetId, chartDataIndex }),
            ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
            argParam: widgetArgParams,
          };
        },
      );

      const { sortByFieldName, orderBy, limitNum } = props.chartOption;

      tableRequests[0] = {
        ...tableRequests[0],
        sortType: orderBy || undefined,
        limit: limitNum,
        sortColumn: sortByFieldName || undefined,
      };
    }
    return tableRequests;
  };

  const { isLoading, rawData, errorMsg, callApiAfterAbort } = useWidgetApi<
    TableV7Request,
    TableRawData
  >(props, emit, { widgetApiOptions, changeApiParams });

  const convertToPieChartData = (
    { tableData }: TableRawData,
    displayName: string,
    displayValue: string,
  ): PieChartItem[] => {
    return tableData.map(({ columns, rows }) => {
      const nameIndex =
        columns.findIndex((column) => (column.fieldName || column.name) === displayName) ?? -1;
      const valueIndex =
        columns.findIndex((column) => (column.fieldName || column.name) === displayValue) ?? -1;
      const valueUnit = columns[valueIndex]?.unit ?? '';

      if (nameIndex > -1 && valueIndex > -1) {
        const sumRowData = rows.reduce<[string, number][]>((sumData, row) => {
          const index = sumData.findIndex((data) => `${data[0]}` === `${row[nameIndex]}`);
          if (index > -1) {
            sumData[index] = [`${row[nameIndex]}`, (sumData[index][1] ?? 0) + +row[valueIndex]];
          } else {
            sumData.push([`${row[nameIndex]}`, +row[valueIndex]]);
          }
          return sumData;
        }, []);
        return {
          data: sumRowData.map(([name, value]) => ({
            name,
            value,
            unit: valueUnit,
          })),
        };
      }

      return { data: [] };
    });
  };

  const updateChartData = (formatter: TableDecimalFormatter) => {
    const decimal = props.chartOption.decimalOptions?.use
      ? props.chartOption.decimalOptions?.decimalsPrecision
      : cloneDeep(DefaultChartOptions.PIE.decimalOptions.decimalsPrecision);
    const decimalFormattedData = {
      ...rawData.value,
      tableData: formatter(rawData.value.tableData, decimal),
    };
    chartData.value = convertToPieChartData(
      decimalFormattedData,
      props.chartOption?.displayName ?? '',
      props.chartOption?.displayValue ?? '',
    );
  };

  const { formatByDecimalPrecision } = useTableDecimalPrecision(
    computed(() => props.chartOption.decimalOptions),
    updateChartData,
  );

  const isInit = useInitWidget<TableRawData>({
    target: rawData,
    dataKey: 'tableData',
    callback: (_, value) => {
      updateChartData(formatByDecimalPrecision);

      return value || (!value && (props.mode === 'view' || props.mode === 'edit'));
    },
    errorMsg,
  });

  watch(
    rawData,
    (newRawData) => {
      if (props.mode === 'preview') {
        emit('update:chartOption', {
          ...props.chartOption,
          legendStatus: undefined,
          fieldNameList: newRawData?.tableData.at(0)?.columns.map((column) => {
            return {
              name: column.name,
              value: column.fieldName ?? column.name ?? '',
              dataType: column.dataType,
            };
          }),
        });
      }
    },
    {
      immediate: true,
    },
  );

  watch(
    () => props.chartOption,
    (newChartOption) => {
      if (rawData.value && newChartOption?.displayName && newChartOption.displayValue) {
        updateChartData(formatByDecimalPrecision);
      } else {
        chartData.value = [];
      }
    },
    {
      deep: true,
    },
  );

  watch(
    [
      () => props.chartOption?.sortByFieldName,
      () => props.chartOption?.limitNum,
      () => props.chartOption?.orderBy,
    ],
    (newVal, oldVal) => {
      if (!isEqual(newVal, oldVal)) {
        callApiAfterAbort();
      }
    },
  );

  return { chartData, isInit, isLoading, errorMsg };
};

export const getTimeSeriesChartMaxData = (data: ChartData['data'] = {}) => {
  const values = Object.values(data).flat();
  if (values.length < 1) return 0;
  let max = -Infinity;
  for (let i = 0; i < values.length; i++) {
    if (values[i] > max) {
      max = values[i];
    }
  }
  return max;
};

export const getTimeSeriesStackedChartMaxData = (data: ChartData['data'] = {}) => {
  const values: number[][] = Object.values(data);

  if (values.length < 1) {
    return 0;
  }

  return values
    .reduce((acc, cur) => {
      cur.forEach((value, index) => {
        if (value != null) {
          acc[index] = (acc[index] || 0) + value;
        }
      });
      return acc;
    }, [])
    .reduce((acc, cur) => Math.max(acc, cur), 0);
};

export const useChartYAxisUnit = (
  unitList: MaybeRefOrGetter<string[]>,
  yMaxValue: MaybeRefOrGetter<number>,
  decimal?: MaybeRefOrGetter<DecimalPrecision | undefined>,
) => {
  const isUnitUnique = computed(() => uniq(toValue(unitList)).length === 1);
  const uniqueUnit = computed(() => (isUnitUnique.value ? toValue(unitList)[0] : ''));

  const yMaxValueUnit = computed(
    () =>
      convertValueWithUnit(
        {
          value: toValue(yMaxValue),
          unit: uniqueUnit.value,
        },
        {
          originUnit: true,
        },
      ).unit,
  );

  const yAxisFormatter = (value: number) => {
    return formatYAxisWithUnit({
      value,
      unit: isUnitUnique.value ? uniqueUnit.value : 'count',
      showUnit: false,
      toUnit: yMaxValueUnit.value,
      decimal: toValue(decimal),
    });
  };

  const assignYAxisFormatter = (chartOption: ChartOption) => {
    return {
      ...chartOption,
      axesY: chartOption.axesY?.map((yAxisOption, index) => {
        const unit = toValue(unitList)[index];

        return {
          ...yAxisOption,
          formatter: unit ? yAxisFormatter : undefined,
        };
      }),
    };
  };

  const yAxisUnit = computed(() => {
    return convertValueWithUnit({
      value: toValue(yMaxValue),
      unit: uniqueUnit.value,
    }).unit;
  });

  return { uniqueUnit: yAxisUnit, assignYAxisFormatter, yAxisFormatter };
};

export const useTopologyWidget = (props: Props, emit: Emit) => {
  const options: WidgetApiOptions = {
    requestKey: 'topologyRequests',
    method: 'topology',
  };

  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();

  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TopologyV7Request & { id: string })[]> => {
    const nodes: Nodes[] =
      widgetChartData.map(({ targets, value, chartDataIndex }) => ({
        ...value,
        ...getFilteredTags({ targets, widgetUUID: props.widgetId, chartDataIndex }),
      })) ?? [];

    return [
      {
        id: widgetChartData.at(0)?.id ?? '',
        dataId: FIXED_DATA_ID.TOPOLOGY,
        aggregationType: 'byTarget' as TopologyV7RequestAggregationType,
        summaryType: 'current',
        nodes,
        ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
      },
    ];
  };

  return {
    ...useWidgetApi<TopologyV7Request, TopologyRawData>(props, emit, {
      widgetApiOptions: options,
      changeApiParams,
    }),
  };
};

export const useServerWidget = (props: Props, emit: Emit) => {
  const options: WidgetApiOptions = {
    requestKey: 'tableRequests',
    method: 'table',
  };

  const { getRequestPeriod, getFilteredTags } = useWidgetDataParams();

  const changeApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(TableV7Request & { id: string })[]> => {
    return widgetChartData.map(
      ({ id, dataType, targets, seriesType, category, value, chartDataIndex }) => ({
        id,
        dataId: FIXED_DATA_ID.STATUS,
        category,
        aggregationType: seriesType,
        summaryType: dataType,
        ...getFilteredTags({ targets, widgetUUID: props.widgetId, chartDataIndex }),
        ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
        targetCategory: value?.targetCategory ?? category,
        sortColumn: value?.sort,
        sortType: value?.orderBy,
        limit: value?.limit,
      }),
    );
  };

  return {
    ...useWidgetApi<TableV7Request, TableRawData>(props, emit, {
      widgetApiOptions: options,
      changeApiParams,
    }),
    changeApiParams,
  };
};

export const useWidgetDynamicColumns = (
  widgetId: MaybeRefOrGetter<string>,
  widgetMode: MaybeRefOrGetter<WidgetModeType>,
  widgetChartType: MaybeRefOrGetter<Extract<WidgetChartType, 'TABLE' | 'TREE_GRID'>>,
  envKeys: {
    global: DashboardUserEnvKeys;
    custom: DashboardUserEnvKeys;
  },
) => {
  const dashboardUserEnvStore = useDashboardUserEnvStore();
  const userEnvStore = useUserEnvStore();

  const { getWidgetUserEnvKey } = useDashboardUserEnv();

  const userEnvKeyGlobal = getWidgetUserEnvKey(envKeys.global, toValue(widgetId));
  const userEnvKeyCustom = getWidgetUserEnvKey(envKeys.custom, toValue(widgetId));

  const getEnvData = () => {
    const globalUserEnvData = userEnvStore.getEnvValue(userEnvKeyGlobal);

    const editingUserEnvData = dashboardUserEnvStore.getEditingUserEnvData(
      userEnvKeyGlobal,
      toValue(widgetChartType),
    );
    const previewUserEnvData = dashboardUserEnvStore.getPreviewUserEnvData(
      userEnvKeyGlobal,
      toValue(widgetChartType),
    );

    switch (toValue(widgetMode)) {
      case 'edit':
        return editingUserEnvData ?? globalUserEnvData;
      case 'preview':
        return previewUserEnvData ?? editingUserEnvData ?? globalUserEnvData;
      default:
        return userEnvStore.getEnvValue(userEnvKeyCustom);
    }
  };

  const {
    applyChangeColumnInfo: applyChangeGlobalColumnInfo,
    makeColumnsAndRows: makeGlobalColumnsAndRows,
  } = useDynamicColumns();
  const {
    applyChangeColumnInfo: applyChangeCustomColumnInfo,
    makeColumnsAndRows: makeCustomColumnsAndRows,
  } = useDynamicColumns();

  const updateDynamicColumn = (
    dynamicColumns: CustomColumn[],
    rowData: Record<string, unknown>[],
    option?: {
      defaultColumn?: CustomColumn[];
    },
  ) => {
    const dynamicColumnData = [
      {
        columns: dynamicColumns.map(({ field }) => field),
        rowData,
      },
    ];

    const envCustomValue = userEnvStore.getEnvValue(userEnvKeyCustom);

    if (!!envCustomValue && toValue(widgetMode) === 'view') {
      return makeCustomColumnsAndRows(dynamicColumnData, dynamicColumns, {
        columnEnvKey: userEnvKeyCustom,
        defaultColumns: option?.defaultColumn,
      });
    }
    return makeGlobalColumnsAndRows(dynamicColumnData, dynamicColumns, {
      columnEnvKey: userEnvKeyGlobal,
      defaultColumns: option?.defaultColumn,
    });
  };

  const onChangeColumnInfo = async ({
    columns: changedColumns,
  }: {
    columns: ColumnWithHideInfo[];
  }) => {
    if (toValue(widgetMode) === 'edit') {
      dashboardUserEnvStore.setEditingUserEnvData(userEnvKeyGlobal, {
        chartType: toValue(widgetChartType),
        global: true,
        value: changedColumns,
      });
      dashboardUserEnvStore.setEditingUserEnvData(userEnvKeyCustom, {
        chartType: toValue(widgetChartType),
        global: false,
        value: changedColumns,
      });
    } else if (toValue(widgetMode) === 'preview') {
      dashboardUserEnvStore.setPreviewUserEnvData({
        key: userEnvKeyGlobal,
        value: { chartType: toValue(widgetChartType), value: changedColumns },
      });
    }
  };

  return {
    userEnvKeyGlobal,
    userEnvKeyCustom,
    getEnvData,
    applyChangeGlobalColumnInfo,
    applyChangeCustomColumnInfo,
    updateDynamicColumn,
    onChangeColumnInfo,
  };
};

export const useChartYAxisPlots = (
  unitList: MaybeRefOrGetter<string[]>,
  yMaxValue: MaybeRefOrGetter<number>,
  thresholds?: MaybeRefOrGetter<Threshold[] | undefined>,
) => {
  const isUnitUnique = computed(() => uniq(toValue(unitList)).length === 1);
  const uniqueUnit = computed(() => {
    if (!isUnitUnique.value) {
      return '';
    }

    const unit = toValue(unitList)[0];

    if (isApiDataUnit(unit)) {
      return convertApiDataUnitToBinaryByte(unit);
    }
    if (isApiTimeUnit(unit)) {
      return convertTimeApiUnit(unit);
    }

    return unit;
  });

  const yMax = computed(() =>
    convertValueWithUnit({
      value: toValue(yMaxValue),
      unit: uniqueUnit.value,
    }),
  );

  const yAxisPlotFormatter = (value: number) => {
    return uniqueUnit.value === yMax.value.unit
      ? value
      : convertValueWithUnit({ value, unit: yMax.value.unit }, { toUnit: uniqueUnit.value }).value;
  };

  const getYAxisPlots = () => {
    const segments = [6, 2];
    const plotLines: PlotLines[] = [];
    const plotBands: PlotBands[] = [];
    let range: null | number[] = null;

    if (thresholds) {
      let plotMaxValue: number = yMax.value.value;
      toValue(thresholds)?.forEach(({ type, value, color, strokeStyle }) => {
        if (type === 'line') {
          plotLines.push({
            color,
            value: yAxisPlotFormatter(+value.to),
            segments: strokeStyle === 'dashed' ? segments : [],
            lineWidth: 1,
            label: { show: false },
          });
        } else if (type === 'range') {
          plotBands.push({
            color,
            from: yAxisPlotFormatter(+value.from),
            to: yAxisPlotFormatter(+value.to),
            label: { show: false },
          });
        }

        plotMaxValue = Math.max(plotMaxValue, +value.to);
      });

      if (yMax.value.value < plotMaxValue) {
        range = [0, yAxisPlotFormatter(plotMaxValue)];
      }
    }

    return { range, plotLines, plotBands };
  };

  const assignYAxisPlots = (chartOption: ChartOption) => {
    return {
      ...chartOption,
      axesY: chartOption.axesY?.map((yAxisOption) => {
        return {
          ...yAxisOption,
          ...getYAxisPlots(),
        };
      }),
    };
  };

  return {
    assignYAxisPlots,
  };
};
