import { RouteRecordRaw } from 'vue-router';
import {
  requireAuth,
  requireInstances,
  setDayjsTimezone,
  validateDatabaseOverviewPath,
} from '@/common/router/navigationGuard';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import PaViewport from '@/common/components/templates/PaViewport.vue';
import { VIEW_MODE } from '@/common/define/common.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';

const PREFIX_SQL_SERVER = 'sqlserver_';

const sqlServerMenu: RouteRecordRaw[] = [
  {
    path: '/sqlserver/',
    name: `${PREFIX_SQL_SERVER}Real Time Monitoring`,
    component: RtmViewport,
    children: [
      {
        path: 'singleView',
        name: `${PREFIX_SQL_SERVER}Single View`,
        component: () =>
          import(
            /* webpackChunkName: "sql-server-single-view" */ '../views/singleView/SingleView.vue'
          ),
      },
    ],
    beforeEnter: [requireAuth, requireInstances, setDayjsTimezone, validateDatabaseOverviewPath],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD,
    },
  },
  {
    path: '/sqlserver/',
    name: `${PREFIX_SQL_SERVER}SQL Analysis`,
    component: PaViewport,
    props: (route) => ({
      useBaseMenu: !(route?.query?.isBrowserPopup === 'true'),
      useNavigationHeader: !(route?.query?.isBrowserPopup === 'true'),
    }),
    children: [
      {
        path: 'topNAnalysis',
        name: `${PREFIX_SQL_SERVER}Top N Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "sql-server-topN-Analysis" */ '../views/topNAnalysis/TopNAnalysis.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'planAnalysis',
        name: `${PREFIX_SQL_SERVER}Plan Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "sql-server-plan-analysis" */ '../views/planAnalysis/PlanAnalysis.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'searchSql',
        name: `${PREFIX_SQL_SERVER}Search SQL`,
        component: () =>
          import(
            /* webpackChunkName: "sql-server-search-sql" */ '../views/searchSql/SearchSqlView.vue'
          ),
        beforeEnter: [requireInstances],
      },
    ],
    beforeEnter: [requireAuth],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_SQLSERVER_SQL_ANALYSIS,
      },
    },
  },
  {
    path: '/sqlserver/',
    name: `${PREFIX_SQL_SERVER}Session Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'searchSession',
        name: `${PREFIX_SQL_SERVER}Search Session`,
        component: () =>
          import(
            /* webpackChunkName: "sqlserver-search-session" */ '../views/searchSession/SearchSession.vue'
          ),
      },
    ],
    beforeEnter: [requireAuth, requireInstances],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_SQLSERVER_SESSION_ANALYSIS,
      },
    },
  },
  {
    path: '/sqlserver/',
    name: `${PREFIX_SQL_SERVER}Performance Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'trendAnalysis',
        name: `${PREFIX_SQL_SERVER}Trend Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "sql-server-trend-analysis" */ '../views/trendAnalysis/TrendAnalysis.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'parameterHistory',
        name: `${PREFIX_SQL_SERVER}Parameter History`,
        component: () =>
          import(
            /* webpackChunkName: "sql-server-parameter-history" */ '../views/parameterHistory/ParameterHistory.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'alertHistory',
        name: `${PREFIX_SQL_SERVER}Alert History`,
        component: () =>
          import(
            /* webpackChunkName: "sql-server-alert-history" */ '../views/alertHistory/AlertHistory.vue'
          ),
        beforeEnter: [requireInstances],
      },
    ],
    beforeEnter: [requireAuth],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]:
          ROLE_PERMISSION_KEY.MONITORING.MONITORING_SQLSERVER_PERFORMANCE_ANALYSIS,
      },
    },
  },
  {
    path: '/sqlserver/',
    name: `${PREFIX_SQL_SERVER}DataSpace Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'dbInfo',
        name: `${PREFIX_SQL_SERVER}DB Info`,
        component: () =>
          import(/* webpackChunkName: "sql-server-db-info" */ '../views/dbInfo/DbInfo.vue'),
      },
    ],
    beforeEnter: [requireAuth, requireInstances],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]:
          ROLE_PERMISSION_KEY.MONITORING.MONITORING_SQLSERVER_DATASPACE_ANALYSIS,
      },
    },
  },
];

const subMenuMappingSqlServer = {
  instanceView: `${PREFIX_SQL_SERVER}Real Time Monitoring`,
  sqlAnalysis: `${PREFIX_SQL_SERVER}SQL Analysis`,
  sessionAnalysis: `${PREFIX_SQL_SERVER}Session Analysis`,
  performanceAnalysis: `${PREFIX_SQL_SERVER}Performance Analysis`,
  dataSpaceAnalysis: `${PREFIX_SQL_SERVER}DataSpace Analysis`,
};

const getSqlServerNaviMenu = (
  subMenu?:
    | 'instanceView'
    | 'sqlAnalysis'
    | 'sessionAnalysis'
    | 'performanceAnalysis'
    | 'dataSpaceAnalysis',
) => {
  const filteredSqlServerMenu = extractAccessibleRoutes(sqlServerMenu);

  if (subMenu && subMenuMappingSqlServer[subMenu]) {
    return filteredSqlServerMenu.filter((menu) => menu.name === subMenuMappingSqlServer[subMenu]);
  }

  return filteredSqlServerMenu.filter((menu) => menu.name !== subMenuMappingSqlServer.instanceView);
};

export { sqlServerMenu, getSqlServerNaviMenu };
