/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  CreatePathRequest,
  HostPathResponse,
  PathResponse,
  ResultResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const deletePathPathControllerAxios = ({
  pathId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  pathId: Array<number>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v7/meta/path';
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      pathId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getALlPathListPathControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<PathResponse> => {
  const localVarPath = '/api/v7/meta/path';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPathPathControllerAxios = ({
  pathId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  pathId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<PathResponse> => {
  const localVarPath = '/api/v7/meta/path/{pathId}'.replace(
    `{${'pathId'}}`,
    encodeURIComponent(String(pathId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPathListPathControllerAxios = ({
  targetTypes,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  targetTypes: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<PathResponse> => {
  const localVarPath = '/api/v7/meta/path/path-list';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      targetTypes,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getHostPathListPathControllerAxios = ({
  hostIds,
  loggingId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  hostIds: Array<string>;
  loggingId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<HostPathResponse> => {
  const localVarPath = '/api/v7/meta/path/path-list/{loggingId}'.replace(
    `{${'loggingId'}}`,
    encodeURIComponent(String(loggingId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      hostIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updatePathPathControllerAxios = ({
  pathId,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  pathId: number;
  request: CreatePathRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v7/meta/path/{pathId}'.replace(
    `{${'pathId'}}`,
    encodeURIComponent(String(pathId)),
  );
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addPathPathControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: CreatePathRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v7/meta/path';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  deletePathPathControllerAxios,
  getALlPathListPathControllerAxios,
  getPathPathControllerAxios,
  getPathListPathControllerAxios,
  getHostPathListPathControllerAxios,
  updatePathPathControllerAxios,
  addPathPathControllerAxios,
};
