import { isNumber } from 'lodash-es';

export const getStringByteLength = (s: string) => {
  const textEncoder = new TextEncoder();
  return textEncoder.encode(s).length;
};

export const isValidAddress = (address: string | number, ipVersion: 'IPv4' | 'IPv6' = 'IPv4') => {
  let isIpAddress = new RegExp(
    /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/,
  );
  const isDomainAddress = new RegExp(
    /^(?!-)(?!(?:.*--))(?:[a-zA-Z0-9가-힣](?:[a-zA-Z0-9가-힣-]{0,61}[a-zA-Z0-9가-힣])?\.)+(?:[a-zA-Z]{2,17}|xn--[a-zA-Z0-9]{2,17})$/,
  );

  if (ipVersion === 'IPv6') {
    isIpAddress = new RegExp(
      /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/,
    );
  }

  return isIpAddress.test(address.toString()) || isDomainAddress.test(address.toString());
};

export const isValidPort = (port: string | number) => {
  const num = +port;
  const isPort = new RegExp(/^(0|([1-9][0-9]{0,3})|([1-5][0-9]{4})|6[0-5][0-5][0-3][0-5])$/);

  return Number.isInteger(num) && num >= 0 && num <= 65535 && isPort.test(port.toString());
};

export const isValidName = (str: string) => {
  const isName = new RegExp(/^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣-_]+$/);

  return isName.test(str);
};

export const getOnlyNumber = (
  val: string,
  options: { min?: number; max?: number; useNegative?: boolean } = {},
) => {
  if (typeof val === 'string' && val) {
    const regExp = options.useNegative ? /[^0-9-]/g : /[^0-9]/g;
    const num = +val.replaceAll(regExp, '');
    const { min, max } = options;
    if (min && num < min) {
      return min;
    }
    if (max && num > max) {
      return max;
    }
    return num;
  }
  return '';
};

export const checkNumber = (value: unknown) => {
  return isNumber(value) && !Number.isNaN(value);
};
