import { ComponentInternalInstance, computed, getCurrentInstance, ref, unref, watch } from 'vue';
import { ChartData, ChartOption } from '@/common/utils/types';
import { CHART_PASSING_VALUE, ORACLE_DB_METRIC_CHART_COLORS } from '@/common/utils/define';
import { useStore } from '@/common/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { cloneDeep } from 'lodash-es';
import router from '@/common/router';
import { getAPIErrorStatusText, showErrorMsg } from '@/common/utils/commonUtils';
import { useI18n } from 'vue-i18n';
import { useOracleDbMetricStore } from '@/oracle/stores/multiView/db-metric';
import { storeToRefs } from 'pinia';
import { statChangeTreeGridOptions } from '@/common/define/grid.define';
import { getIntervalMs } from '@/worker/utils';
import { useEvChartStyle } from '@/common/utils/chartUtils';
import {
  StatChildren,
  StatData,
} from '@/common/components/organisms/statChartOption/statChartOption.types';
import { DISPLAY_DB_TYPE } from '@/database/utils/define';
import { useInternational } from '@/common/locale';
import type { ResChartData, StatChangeWindowEmit, StatChangeWindowProps } from './dbMetric.types';

dayjs.extend(utc);
dayjs.extend(timezone);

const ADDITIONAL_STATS_FOR_PA_OBJ = {
  WAIT_CLASS: {
    NAME: 'Wait Class',
    DISPLAY_NAME: 'Wait Class (sec)',
  },
  WAIT_CLASS_TIME_MODEL: {
    NAME: 'Time Model',
    DISPLAY_NAME: 'Time Model (sec)',
  },
};

const useMultiViewStatChart = () => {
  const store = useStore();
  const dbMetricPiniaStore = useOracleDbMetricStore();
  const { fetchMetricChartData } = dbMetricPiniaStore;
  const { chartDataList, errorMsgInfos, fetchCycleInfos, clearFetchInfos } =
    storeToRefs(dbMetricPiniaStore);

  const { props, emit, appContext }: ComponentInternalInstance = getCurrentInstance()!;
  const ctx = appContext.config.globalProperties;
  const { t } = useI18n();

  const selectedSeries = ref<{ seriesId: string[] }>({
    seriesId: [],
  });

  const { axisStyle, legendStyle, tooltipStyle, indicatorStyle } = useEvChartStyle();
  const chartOption: ChartOption = {
    type: 'line',
    padding: {
      top: 10,
      right: 0,
      left: 0,
      bottom: 4,
    },
    legend: {
      ...legendStyle,
      show: false,
      width: 140,
    },
    tooltip: {
      ...tooltipStyle,
      sortByValue: true,
    },
    axesX: [
      {
        ...axisStyle,
        type: 'time',
        showGrid: false,
        timeFormat: 'HH:mm:ss',
        interval: 'second',
      },
    ],
    axesY: [
      {
        ...axisStyle,
        type: 'linear',
        showGrid: true,
        showAxis: false,
        startToZero: true,
        autoScaleRatio: 0.1,
      },
    ],
    selectSeries: {
      use: true,
      limit: 1,
      useDeselectOverflow: true,
    },
    indicator: {
      ...indicatorStyle,
    },
  };
  const showStatChange = ref<boolean>(false);
  const optionList = ['more'];

  const openStatChange = () => {
    showStatChange.value = true;
  };
  const closeStatChange = () => {
    showStatChange.value = false;
  };

  const getMetricInterval = (metricName: string) => {
    const currMetricInfo = store.getters['dbMetric/getMetricNames'].find(
      (info) => info.metricName === metricName,
    );

    let intervalInfo:
      | {
          msTime: number;
          interval: string;
        }
      | undefined;

    if (currMetricInfo?.interval) {
      intervalInfo = getIntervalMs(currMetricInfo.interval);
    }

    return intervalInfo && [intervalInfo?.msTime, intervalInfo?.interval];
  };

  const changeMetricName = async (selectedMetricName) => {
    if (props.metricName === selectedMetricName.name || !selectedMetricName.name) {
      closeStatChange();
      return;
    }

    const previewMetricNames = store.getters['oracleMultiViewEnv/getDbMetricNames'];
    if (previewMetricNames.includes(selectedMetricName.name)) {
      showErrorMsg(ctx, t('NOTI.VALIDATION.ALREADY_EXISTENCE_STAT'));
      return;
    }

    // 새로운 Metric 데이터가 조회되면 기존 timeout을 초기화 한다.
    if (clearFetchInfos.value[props.frameId as number]) {
      clearFetchInfos.value[props.frameId as number]();
    }

    store.commit('oracleMultiViewEnv/setDbMetricName', {
      idx: props.frameId,
      metricName: selectedMetricName.name,
    });

    const metricNames = store.getters['oracleMultiViewEnv/getDbMetricNames'];
    const instanceIds = computed(() => store.getters['oracleMultiViewEnv/getInstanceIds']);
    fetchMetricChartData({
      instanceIds: instanceIds.value,
      metricName: selectedMetricName.name,
      frameId: Number(props.frameId),
      metricIntervalInfo: getMetricInterval(selectedMetricName.name),
    });
    await store.dispatch('oracleMultiViewEnv/saveMetricChartMetricNames', metricNames);
    emit('change-metric-name');
    closeStatChange();
  };

  const selectedId = ref('');
  const clickSeries = ({ selected }) => {
    if (selected == null) {
      return;
    }
    const instances = store.getters['oracleMultiViewEnv/getInstances'];
    let instanceId =
      instances?.find((item) => item.instanceId === selected.seriesId?.[0])?.instanceId ?? '';
    instanceId = selectedId.value === instanceId ? '' : instanceId;
    emit('update:selectedInstanceId', instanceId);
    selectedId.value = instanceId;
  };

  watch(
    () => props.selectedInstanceId as string,
    (newVal: string) => {
      selectedSeries.value = {
        seriesId: newVal ? [newVal] : [],
      };
      selectedId.value = newVal;
    },
  );

  return {
    selectedSeries,
    chartOption,
    showStatChange,
    optionList,
    openStatChange,
    changeMetricName,
    clickSeries,
    getMetricInterval,
    fetchMetricChartData,
    chartDataList,
    errorMsgInfos,
    fetchCycleInfos,
    clearFetchInfos,
  };
};

const useStatChangeWindow = (props: StatChangeWindowProps, emit: StatChangeWindowEmit) => {
  const { t } = useInternational();
  const store = useStore();
  const currentView = computed(() =>
    router.currentRoute.value.path.includes('multiView') ? 'rtm' : 'pa',
  );

  const DB_METRIC_CHART_COLORS = ORACLE_DB_METRIC_CHART_COLORS;

  const instanceIds = computed<string[]>(() =>
    currentView.value === 'rtm'
      ? store.getters['oracleMultiViewEnv/getInstanceIds']
      : [store.getters['oraclePaCondition/getSelectedInstanceId']],
  );
  const showStatChangeValue = computed<boolean>({
    get: () => props.showStatChange as boolean,
    set: (val) => emit('update:showStatChange', val),
  });
  const searchWord = ref('');
  const statListInfo = {
    columns: [
      {
        caption: 'Display Name',
        field: 'displayName',
        type: 'string',
      },
      {
        caption: 'Name',
        field: 'name',
        type: 'string',
        hide: true,
      },
      {
        caption: 'Format',
        field: 'format',
        type: 'string',
        searchable: false,
        hide: true,
      },
    ],
    option: {
      ...statChangeTreeGridOptions,
      searchValue: searchWord.value,
      columnMenuText: {
        hide: t('WORD.HIDE'),
      },
      emptyText: t('MESSAGE.NO_DATA'),
    },
  };
  const statListRows = ref<StatData[]>([
    {
      name: '',
      displayName: '',
      children: [],
    },
  ]);
  const selectedMetric = ref<any[]>([]);
  const prevSelectedMetricRow = ref();

  const { axisStyle, legendStyle, tooltipStyle, indicatorStyle } = useEvChartStyle();
  const chartOption: ChartOption = {
    type: 'line',
    padding: {
      top: 10,
      right: 0,
      left: 0,
      bottom: 4,
    },
    legend: {
      ...legendStyle,
      show: false,
      width: 140,
    },
    tooltip: {
      ...tooltipStyle,
      sortByValue: true,
    },
    axesX: [
      {
        ...axisStyle,
        type: 'time',
        timeFormat: 'HH:mm:ss',
        interval: 'second',
        showGrid: false,
      },
    ],
    axesY: [
      {
        ...axisStyle,
        type: 'linear',
        startToZero: true,
        autoScaleRatio: 0.1,
        showGrid: true,
        showAxis: false,
      },
    ],
    indicator: {
      ...indicatorStyle,
    },
  };

  const getPaChartData = () => {
    const tempChartData: ChartData = {
      series: {},
      labels: [],
      data: {},
    };

    const { chartData, chartDateTime }: ResChartData =
      store.getters['oracleMetric/getPreviewDbMetricInfo'];
    if (chartData && chartDateTime.length) {
      if (Array.isArray(chartData)) {
        tempChartData.series[prevSelectedMetricRow.value?.name ?? '0'] = {
          name: prevSelectedMetricRow.value?.displayName ?? 'metric',
          color: DB_METRIC_CHART_COLORS[0],
          point: false,
          passingValue: CHART_PASSING_VALUE,
        };
        tempChartData.data![prevSelectedMetricRow.value?.name ?? '0'] = chartData;
      } else {
        const dbMetricDisplayNameMap = store.getters['dbMetric/getMetricDisplayNames'];
        Object.keys(chartData).forEach((series, idx) => {
          tempChartData.series[series] = {
            name: dbMetricDisplayNameMap.get(series) ?? series,
            color: DB_METRIC_CHART_COLORS[idx],
            point: false,
            passingValue: CHART_PASSING_VALUE,
          };
        });
        tempChartData.data = chartData;
      }
      tempChartData.labels = chartDateTime;
    }

    return tempChartData;
  };
  const dbMetricPiniaStore = useOracleDbMetricStore();
  const { fetchPreviewChartData } = dbMetricPiniaStore;
  const { previewChartDataList, previewErrorMsgInfos } = storeToRefs(dbMetricPiniaStore);

  const previewErrorStatusText = computed<string>(() =>
    currentView.value === 'rtm'
      ? getAPIErrorStatusText(previewErrorMsgInfos.value[prevSelectedMetricRow.value.name])
      : '',
  );

  const chartData = computed(() =>
    currentView.value === 'rtm'
      ? unref(previewChartDataList.value[prevSelectedMetricRow.value.name])
      : getPaChartData(),
  );

  const closeStatChange = () => {
    showStatChangeValue.value = false;
  };
  const clickSave = () =>
    emit('click-save', {
      name: selectedMetric.value[0]?.name,
      displayName: selectedMetric.value[0]?.displayName,
    });

  const setStatListRows = () => {
    const data = store.getters['dbMetric/getMetricNames'];
    const DISPLAY_CATEGORY =
      currentView.value === 'pa'
        ? ['Additional Stats', DISPLAY_DB_TYPE.ORACLE]
        : [DISPLAY_DB_TYPE.ORACLE];

    const tempObj: Record<string, StatChildren[]> =
      currentView.value === 'pa'
        ? {
            'Additional Stats': Object.values(ADDITIONAL_STATS_FOR_PA_OBJ).map((v) => ({
              name: v.NAME,
              displayName: v.DISPLAY_NAME,
              format: '',
            })),
            [DISPLAY_DB_TYPE.ORACLE]: [],
          }
        : {
            [DISPLAY_DB_TYPE.ORACLE]: [],
          };

    data.forEach((item) => {
      const displayName = item.displayName || item.metricName;
      const unit = item.unit ? ` (${item.unit})` : '';

      tempObj[DISPLAY_DB_TYPE.ORACLE].push({
        name: item.dataId,
        displayName: displayName.replace('  ', ' ') + unit,
      });
    });

    const tempArr: StatData[] = [];
    DISPLAY_CATEGORY.forEach((key) => {
      tempArr.push({
        name: key,
        displayName: key,
        children: tempObj[key],
      });
    });
    statListRows.value = tempArr;
  };

  const clickRow = async ({ rowData }) => {
    if (rowData.name === prevSelectedMetricRow.value.name) {
      return;
    }
    if (!rowData.level) {
      selectedMetric.value = [prevSelectedMetricRow.value];
      return;
    }
    if (currentView.value === 'pa') {
      await store.dispatch('oracleMetric/fetchPreviewPaChartData', {
        metricName: rowData.name,
        instanceIds: instanceIds.value,
      });
    } else {
      fetchPreviewChartData({
        instanceIds: instanceIds.value,
        metricName: rowData.name,
      });
    }
    prevSelectedMetricRow.value = cloneDeep(rowData);
  };

  watch(showStatChangeValue, async (val) => {
    if (val) {
      if (currentView.value === 'pa') {
        await store.dispatch('dbMetric/fetchMetricNames', ['oracle']);
        await store.dispatch('oracleMetric/fetchPreviewPaChartData', {
          metricName: props.metricName,
          instanceIds: instanceIds.value,
        });
      } else {
        fetchPreviewChartData({
          instanceIds: instanceIds.value,
          metricName: props.metricName as string,
        });
      }
      setStatListRows();
      searchWord.value = '';

      const selectedInfo = statListRows.value
        .map((item) => item.children)
        .flat()
        .find((childRow) => childRow?.name === props.metricName);
      selectedMetric.value = selectedInfo ? [selectedInfo] : [];
      prevSelectedMetricRow.value = selectedInfo ?? {};
    } else {
      store.commit('oracleMetric/initPreviewPaChartData');
    }
  });

  watch(selectedMetric, (curr, prev) => {
    if (!curr[0] && prev[0]) {
      selectedMetric.value = [prev[0]];
    }
  });

  return {
    showStatChangeValue,
    searchWord,
    statListInfo,
    statListRows,
    selectedMetric,
    chartOption,
    chartData,
    clickRow,
    closeStatChange,
    clickSave,
    previewErrorStatusText,
    t,
  };
};

export { ADDITIONAL_STATS_FOR_PA_OBJ, useMultiViewStatChart, useStatChangeWindow };
