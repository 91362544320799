<script setup lang="ts">
export interface Props {
  htmlType?: 'button' | 'div' | 'span';
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  icon?: string;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
}

export interface Emit {
  (e: 'click', evt: MouseEvent): void;
  (e: 'mouseover', evt: MouseEvent): void;
  (e: 'mouseout', evt: MouseEvent): void;
  (e: 'icon-click'): void;
}

const props = withDefaults(defineProps<Props>(), {
  htmlType: 'div',
  size: 'medium',
  icon: '',
  iconPosition: 'right',
  disabled: false,
});

const emit = defineEmits<Emit>();
// TODO: click.self 설정 건드리지 않기 위해 button 은 별도로 추가
// TODO: .self 설정 삭제 가능하면 하나로 합치기
</script>

<template>
  <button
    v-if="props.htmlType === 'button'"
    class="tag-item"
    :class="[
      `tag-item--${props.size}`,
      props.iconPosition === 'left' ? 'icon-position-left' : 'icon-position-right',
      { disabled: props.disabled },
    ]"
    :disabled="props.disabled"
    @click="(e) => emit('click', e)"
    @mouseover.stop="(e) => emit('mouseover', e)"
    @mouseout.stop="(e) => emit('mouseout', e)"
  >
    <span><slot /></span>
    <ev-icon
      v-if="props.icon"
      :icon="props.icon"
      :size="props.size === 'xsmall' || props.size === 'small' ? 'xsmall' : 'small'"
    />
  </button>
  <component
    :is="props.htmlType"
    v-else
    class="tag-item"
    :class="[
      `tag-item--${size}`,
      props.iconPosition === 'left' ? 'icon-position-left' : 'icon-position-right',
    ]"
    @click.self="emit('click')"
    @mouseover.stop="(e) => emit('mouseover', e)"
    @mouseout.stop="(e) => emit('mouseout', e)"
  >
    <span>
      <slot />
    </span>
    <ev-icon
      v-if="icon"
      :icon="icon"
      :size="size === 'xsmall' || size === 'small' ? 'xsmall' : 'small'"
      @click="() => emit('icon-click')"
    />
  </component>
</template>

<style lang="scss" scoped>
.tag-item {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  border-radius: 2px;
  background: var(--tag-bg-color);
  color: var(--tag-font-color);

  &:not(div, span) {
    cursor: pointer;
  }

  &--xsmall {
    height: 16px;
    padding: 0 6px;
    font-size: 11px;
  }

  &--small {
    height: 20px;
    padding: 0 10px;
    font-size: 12px;
  }

  &--large {
    height: 28px;
    padding: 0 14px;
    font-size: 14px;
  }

  span {
    order: 0;
  }

  > i {
    margin: 0 -2px 0 4px;
    cursor: pointer;
    font-size: inherit;
  }

  &.icon-position-left {
    > i {
      margin: 0 4px 0 -2px;
      order: -1;
    }
  }
  &.border-button {
    padding: 0 10px 0 8px;
    cursor: pointer;
    border: 1px solid var(--color-gray-05-05);
    background-color: var(--color-gray-07-00);
    color: var(--color-gray-02-11);

    > * {
      pointer-events: none;
    }
  }
  &.disabled {
    cursor: not-allowed;
    border: 1px solid var(--color-gray-05-04);
    background-color: var(--color-gray-06-02);
    color: var(--color-gray-04-08);
    i {
      background-color: var(--color-gray-04-08);
    }
  }
  &.primary {
    border: 1px solid var(--color-blue-05-05);
    background-color: var(--color-blue-10-00);
    color: var(--color-blue-05-05);
    i {
      background-color: var(--color-blue-05-05);
    }
  }
}
</style>
