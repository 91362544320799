import { TableRawData } from '@/worker/commands/dashboard/table';
import { ComputedRef, watch } from 'vue';
import {
  DecimalOptions,
  DecimalPrecision,
} from '../../widgetSettingsWindow/decimalsPrecisionSelect/decimalsPrecisionSelect.setup';
import { formatToDecimalPrecision } from './utils';

export type TableDecimalFormatter = (
  data: NonNullable<TableRawData['tableData']>,
  decimalPrecision: DecimalPrecision,
) => NonNullable<TableRawData['tableData']>;

export const useTableDecimalPrecision = (
  decimalOptions: ComputedRef<DecimalOptions>,
  updateChartData: (formatter: TableDecimalFormatter) => void,
) => {
  const formatByDecimalPrecision: TableDecimalFormatter = (data, decimalPrecision) => {
    return data.map(({ columns, rows }) => {
      return {
        columns,
        rows: rows.map((row) => {
          return row.map((element, index) => {
            const dataType = columns.at(index)?.dataType;
            switch (dataType) {
              case 'Number':
                return formatToDecimalPrecision(element as number, decimalPrecision);
              default:
                return element;
            }
          });
        }),
      };
    });
  };

  watch(
    decimalOptions,
    () => {
      updateChartData(formatByDecimalPrecision);
    },
    {
      deep: true,
    },
  );

  return { formatByDecimalPrecision };
};
