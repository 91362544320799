<script setup lang="ts">
import BadgeItem from '@/common/components/molecules/badge/BadgeItem.vue';
import SimpleTextTooltip from '@/common/components/molecules/simpleTextTooltip/SimpleTextTooltip.vue';
import DashboardElement from './DashboardElement.vue';
import { BASE_MENU_VALUE } from '../baseMenu.define';
import { setup } from './rightPanelMenu.setup';
import RightPanelSubMenuElement from './RightPanelSubMenuElement.vue';
import ProductionModuleInfo from '../userSupportPopup/ProductionModuleInfo.vue';

const {
  t,

  panelMenuTitle,
  tooltipMouseEvent,
  subPanelInfo,

  onMouseEventInTarget,
  onClosePanel,
} = setup();
</script>

<template>
  <transition name="panel-slide-in">
    <nav
      v-if="subPanelInfo.openedSubPanelMenuInfo"
      class="panel-menu"
    >
      <div class="base-menu-header panel-menu-header">
        <div class="panel-menu-header__left">
          <h2>{{ panelMenuTitle }}</h2>
          <badge-item
            v-if="
              subPanelInfo.openedSubPanelMenuInfo.value === BASE_MENU_VALUE.DASHBOARD ||
              subPanelInfo.openedSubPanelMenuInfo.value === BASE_MENU_VALUE.ANALYSISBOARD
            "
            html-type="div"
            size="xsmall"
            >{{ subPanelInfo.openedSubPanelMenuInfo.subMenuList?.length ?? 0 }}
          </badge-item>
        </div>
        <button
          class="base-menu-header__slide-button"
          @click="onClosePanel"
          @mouseenter="onMouseEventInTarget"
          @mouseleave="onMouseEventInTarget"
        >
          <ev-icon icon="icon-left-panel" />
        </button>
        <simple-text-tooltip
          :mouse-event="tooltipMouseEvent"
          base-pos="bottom"
          :text="`${t('WORD.HIDE_MENU')}`"
        />
      </div>
      <ul class="panel-menu__list">
        <template v-if="subPanelInfo.openedSubPanelMenuInfo.value === BASE_MENU_VALUE.DASHBOARD">
          <dashboard-element
            v-for="(info, idx) in subPanelInfo.openedSubPanelMenuInfo.subMenuList"
            :key="`${info.text}__${idx}`"
            :selected="subPanelInfo.selectedSubPanelMenu === info.value"
            :dashboard-info="info"
          />
        </template>
        <template
          v-else-if="subPanelInfo.openedSubPanelMenuInfo.value === BASE_MENU_VALUE.ANALYSISBOARD"
        >
          <dashboard-element
            v-for="(info, idx) in subPanelInfo.openedSubPanelMenuInfo.subMenuList"
            :key="`${info.text}__${idx}`"
            :selected="subPanelInfo.selectedSubPanelMenu === info.value"
            :dashboard-info="info"
          />
        </template>
        <template v-else>
          <template
            v-for="(info, idx) in subPanelInfo.openedSubPanelMenuInfo.subMenuList"
            :key="`${info.text}__${idx}`"
          >
            <right-panel-sub-menu-element
              v-if="!info.children?.length"
              :menu-info="info"
              :selected="subPanelInfo.selectedSubPanelMenu === info.value"
            />

            <li
              v-else
              class="mid-menu"
            >
              <div :class="['mid-menu__title', { 'mid-menu__title--empty': !info.text }]">
                <span>{{ info.text }}</span>
              </div>
              <ul>
                <template
                  v-for="(childMenu, childIndex) in info.children"
                  :key="`${childMenu.value}_${childIndex}`"
                >
                  <right-panel-sub-menu-element
                    :menu-info="childMenu"
                    :selected="subPanelInfo.selectedSubPanelMenu === childMenu.value"
                  />
                </template>
              </ul>
            </li>
          </template>
          <production-module-info
            v-if="subPanelInfo.openedSubPanelMenuInfo.value === BASE_MENU_VALUE.HELP"
            class="menu-item"
          />
        </template>
      </ul>
    </nav>
  </transition>
</template>

<style lang="scss">
.panel-menu {
  display: flex;
  flex-direction: column;
  width: var(--gnb-menu-width-expanded);
  height: 100%;
  overflow: hidden;
  background-color: var(--gnb-sub-menu-bg-color);
  color: var(--gnb-text-color);

  &__list {
    max-height: calc(100% - var(--gnb-header-height));
    padding: 0 12px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px !important;
      background-color: var(--gnb-sub-menu-bg-color) !important;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--gnb-sub-menu-bg-color) !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--gnb-scroll-thumb-color) !important;
    }

    &:not(:hover)::-webkit-scrollbar-thumb {
      background-color: var(--gnb-sub-menu-bg-color) !important;
    }
  }
}
.panel-menu-header {
  justify-content: space-between;
  padding-left: 12px;

  &__left {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;

    h2 {
      height: 16px;
      font-size: 12px;
      line-height: 16px;
    }

    .badge-item {
      align-items: normal !important;
      height: 16px;
      background-color: var(--gnb-menu-badge-color);
      color: var(--gnb-text-color);

      &:hover {
        background-color: var(--gnb-menu-badge-active-color) !important;
      }
    }
  }

  i {
    background-color: var(--gnb-text-color) !important;
  }
}
.panel-slide-in-enter-active,
.panel-slide-in-leave-active {
  transition: width $animate-faster;
}
.panel-slide-in-enter-from,
.panel-slide-in-leave-to {
  width: 0;
}

@include max-screen() {
  .menu-item {
    font-size: 11px;
  }
}
</style>
