/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  ApiResponseBrowserErrorMetric,
  ApiResponseFilterItem,
  ApiResponseRumBrowserErrorCountItem,
  ApiResponseRumBrowserErrorDetailItem,
  ApiResponseRumBrowserErrorListItem,
  ApiResponseXmRumKeyword,
  ResultResponse,
  RumBrowserErrorListRequest,
  RumKeywordRequest,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const deleteRumFilterKeywordApplicationRumBrowserErrorControllerAxios = ({
  keywordIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  keywordIds: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/application/rum/exception/keyword';
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      keywordIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const rumBrowserErrorMetricApplicationRumBrowserErrorControllerAxios = ({
  applicationIds,
  fromTime,
  interval,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  applicationIds?: Array<string>;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseBrowserErrorMetric> => {
  const localVarPath = '/api/v8/application/rum/exception';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationIds,
      fromTime,
      interval,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const rumBrowserErrorCountApplicationRumBrowserErrorControllerAxios = ({
  applicationIds,
  fromTime,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  applicationIds?: Array<string>;
  fromTime?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseRumBrowserErrorCountItem> => {
  const localVarPath = '/api/v8/application/rum/exception/chart';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationIds,
      fromTime,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const rumBrowserErrorDetailApplicationRumBrowserErrorControllerAxios = ({
  applicationId,
  collectTime,
  error,
  pageAccessTime,
  sessionId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  applicationId: string;
  collectTime: string;
  error: string;
  pageAccessTime: string;
  sessionId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseRumBrowserErrorDetailItem> => {
  const localVarPath = '/api/v8/application/rum/exception/detail';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationId,
      collectTime,
      error,
      pageAccessTime,
      sessionId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getFiltersApplicationRumBrowserErrorControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseFilterItem> => {
  const localVarPath = '/api/v8/application/rum/exception/filter';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getRumFilterKeywordApplicationRumBrowserErrorControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseXmRumKeyword> => {
  const localVarPath = '/api/v8/application/rum/exception/keyword';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchRumFilterKeywordApplicationRumBrowserErrorControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: Array<RumKeywordRequest>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/application/rum/exception/keyword';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const rumBrowserErrorListApplicationRumBrowserErrorControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: RumBrowserErrorListRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseRumBrowserErrorListItem> => {
  const localVarPath = '/api/v8/application/rum/exception/list';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  deleteRumFilterKeywordApplicationRumBrowserErrorControllerAxios,
  rumBrowserErrorMetricApplicationRumBrowserErrorControllerAxios,
  rumBrowserErrorCountApplicationRumBrowserErrorControllerAxios,
  rumBrowserErrorDetailApplicationRumBrowserErrorControllerAxios,
  getFiltersApplicationRumBrowserErrorControllerAxios,
  getRumFilterKeywordApplicationRumBrowserErrorControllerAxios,
  patchRumFilterKeywordApplicationRumBrowserErrorControllerAxios,
  rumBrowserErrorListApplicationRumBrowserErrorControllerAxios,
};
