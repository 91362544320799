<script setup lang="ts">
import { setup } from './topBanner.setup';

const {
  bannerContent,

  onClickCloseButton,
} = setup();
</script>

<template>
  <aside
    v-if="bannerContent"
    :class="['top-banner', bannerContent.type]"
  >
    <div
      v-if="bannerContent.icon"
      class="top-banner__left"
    >
      <ev-icon :icon="bannerContent.icon" />
    </div>
    <div class="top-banner__mid">
      <h3>{{ bannerContent.title }}</h3>
      <ul>
        <li
          v-for="content in bannerContent.contents"
          :key="content"
        >
          {{ content }}
        </li>
      </ul>
    </div>
    <div class="top-banner__right">
      <ev-button
        type="icon"
        class="top-banner__close"
        @click="onClickCloseButton"
      >
        <ev-icon icon="icon-close" />
      </ev-button>
    </div>
  </aside>
</template>

<style lang="scss">
.top-banner {
  display: flex;
  position: relative;
  z-index: 350;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid;

  i {
    display: inline-block;
    width: 24px;
    height: 24px;
    font-size: 24px;
  }

  &__left,
  &__right {
    width: 24px;
    height: 24px;
  }

  &__mid {
    flex: 1;
    color: var(--color-gray-00-12);

    h3 {
      padding: 4px 0;
      margin-bottom: 8px;
      font-size: 13px;
      font-weight: 700;
      line-height: 14px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 2px;

      li {
        position: relative;
        padding-left: 20px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }

      li::before {
        position: absolute;
        top: 50%;
        left: 6px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--color-gray-00-12);
        transform: translateY(-50%);
        content: '';
      }
    }
  }

  &__close {
    padding: 0;
    cursor: pointer;
    border: none;
    background: none;

    &:hover:hover:hover {
      background-color: transparent !important;
    }
  }

  &.info {
    border-color: var(--color-blue-05-05);
    background-color: var(--color-blue-10-00);
    color: var(--color-blue-05-05);
  }

  &.default {
    border-color: var(--color-gray-00-12);
    background-color: var(--color-gray-05-03);
    i {
      color: var(--color-gray-00-12);
    }
  }

  &.success {
    border-color: var(--color-green-05-05);
    background-color: var(--color-green-10-00);
    i {
      color: var(--color-green-05-05);
    }
  }

  &.warning {
    border-color: var(--color-yellow-05-05);
    background-color: var(--color-yellow-10-00);
    i {
      color: var(--color-yellow-05-05);
    }
  }

  &.error {
    border-color: var(--color-red-05-05);
    background-color: var(--color-red-10-00);
    i {
      color: var(--color-red-05-05);
    }
  }
}
</style>
