/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  GroupListResponse,
  GroupWithUsersAndAlertsResponse,
  User2FaRequest,
  UserAccessMenuRequest,
  UserLoginRequest,
  UserLoginV6Response,
  UserV6Response,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getAllByTenantUsersUserV6ControllerAxios = ({
  includeAll,
  tenantId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  includeAll?: boolean;
  tenantId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<GroupListResponse> => {
  const localVarPath = '/api/v6/groups';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      includeAll,
      tenantId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getGroupByUsersUserV6ControllerAxios = ({
  groupId,
  tenantId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  groupId: number;
  tenantId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<GroupWithUsersAndAlertsResponse> => {
  const localVarPath = '/api/v6/groups/{groupId}'.replace(
    `{${'groupId'}}`,
    encodeURIComponent(String(groupId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      tenantId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateUserUserV6ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserV6Response> => {
  const localVarPath = '/api/v6/users';
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateUserLockUserV6ControllerAxios = ({
  locked,
  userId,
  patchUserId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  locked: boolean;
  userId: number;
  patchUserId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserV6Response> => {
  const localVarPath = '/api/v6/users/lock';
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      locked,
      patchUserId,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const accessMenuUserV6ControllerAxios = ({
  eventRequest,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  eventRequest: UserAccessMenuRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v6/access-menu';
  const httpMethod = 'post';

  const data: any = eventRequest;
  const config: any = {
    params: {
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const loginUserV6ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: UserLoginRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserLoginV6Response> => {
  const localVarPath = '/api/v6/login';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const randomRecreationUserV6ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v6/random-recreation';
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const twoFactorAuthenticationUserV6ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: User2FaRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserLoginV6Response> => {
  const localVarPath = '/api/v6/twoFactorAuthentication';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addUserUserV6ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserV6Response> => {
  const localVarPath = '/api/v6/users';
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getAllByTenantUsersUserV6ControllerAxios,
  getGroupByUsersUserV6ControllerAxios,
  updateUserUserV6ControllerAxios,
  updateUserLockUserV6ControllerAxios,
  accessMenuUserV6ControllerAxios,
  loginUserV6ControllerAxios,
  randomRecreationUserV6ControllerAxios,
  twoFactorAuthenticationUserV6ControllerAxios,
  addUserUserV6ControllerAxios,
};
