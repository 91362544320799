export const BUSINESS = {
  GRIDCOLUMNS_business_businessList_businessList_was:
    'GRIDCOLUMNS_business_businessList_businessList_was',
  GRIDCOLUMNS_business_businessList_businessList_database:
    'GRIDCOLUMNS_business_businessList_businessList_database',
  GRIDCOLUMNS_business_businessListDetail_businessTarget:
    'GRIDCOLUMNS_business_businessListDetail_businessTarget',
  GRIDCOLUMNS_business_businessListDetail_applicationTarget:
    'GRIDCOLUMNS_business_businessListDetail_applicationTarget',
  GRIDCOLUMNS_business_businessListDetail_databaseTarget:
    'GRIDCOLUMNS_business_businessListDetail_databaseTarget',
  GRIDCOLUMNS_business_businessListDetail_kubernetesTarget:
    'GRIDCOLUMNS_business_businessListDetail_kubernetesTarget',
  GRIDCOLUMNS_business_businessListDetail_infrastructureTarget:
    'GRIDCOLUMNS_business_businessListDetail_infrastructureTarget',
  BUSINESSMAP_nodesInfo: 'BUSINESSMAP_nodesInfo',

  FILTER_business_businessList: 'FILTER_business_businessList',
  FILTER_business_businessMap: 'FILTER_business_businessMap',
  POSITIONS_businessMap: 'POSITIONS_businessMap',
  POSITIONS_businessMap_global: 'POSITIONS_businessMap_global',
  FILTERSEARCH_business_businessList_was: 'FILTERSEARCH_business_businessList_was',
  FILTERSEARCH_business_businessList_database: 'FILTERSEARCH_business_businessList_database',
  FILTERSEARCH_business_businessMap: 'FILTERSEARCH_business_businessMap',
} as const;
