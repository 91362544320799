import { LocationQueryRaw, RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { DB_TYPE, DBType } from '@/common/utils';
import { store } from '@/common/store';
import { getRtmSelectedInfo } from '@/common/utils/commonUtils';
import { camelCase, upperFirst } from 'lodash-es';

type RTMType = 'multiView' | 'singleView';
type UseRTMDbType = Exclude<DBType, null | 'NONE' | 'java'>;

const DATABASE_LIST_VIEW_PATH = '/database/instance';

const getCurrentViewInfo = (path: string, query: LocationQueryRaw) => {
  const pathArr = path.split('/');
  const [, dbType, view] = pathArr;

  if (!dbType || !view) {
    return null;
  }

  return {
    dbType: dbType as UseRTMDbType,
    viewType: view as RTMType,
    id: query?.id as string | undefined,
  };
};

const hasDbType = {
  multiView: (dbType: UseRTMDbType) => {
    return DB_TYPE.POSTGRESQL === dbType || DB_TYPE.MYSQL === dbType || DB_TYPE.ORACLE === dbType;
  },
  singleView: (dbType: UseRTMDbType) => {
    return (
      DB_TYPE.POSTGRESQL === dbType || DB_TYPE.MYSQL === dbType || DB_TYPE.SQLSERVER === dbType
    );
  },
};

const isSingleView = (viewType?: RTMType) => viewType?.toLowerCase() === 'singleview';

const isMultiView = (viewType?: RTMType) => viewType?.toLowerCase() === 'multiview';

const isInvalidPath = {
  singleView: (path: string) =>
    path.toLowerCase().includes('singleview') && !path.includes('singleView'),
  multiView: (path: string) =>
    path.toLowerCase().includes('multiview') && !path.includes('multiView'),
};

const getValidatedPath = (path: string) => {
  if (isInvalidPath.singleView(path)) {
    return path.replace(/singleview/gi, 'singleView');
  }
  if (isInvalidPath.multiView(path)) {
    return path.replace(/multiview/gi, 'multiView');
  }
  return path;
};

export const validateDatabaseOverviewRoute = async (
  to: RouteLocationNormalized,
): Promise<RouteLocationRaw | undefined> => {
  const verifiedPath = getValidatedPath(to.path);
  const currentInfo = getCurrentViewInfo(verifiedPath, to.query);

  if (
    !to.path ||
    !currentInfo ||
    !currentInfo.dbType ||
    !hasDbType[currentInfo.viewType]?.(currentInfo.dbType)
  ) {
    return { path: DATABASE_LIST_VIEW_PATH };
  }

  const { viewType, dbType, id } = currentInfo;

  const storeName = `${dbType}${upperFirst(camelCase(viewType))}Env`;
  const is = {
    singleView: isSingleView(viewType),
    multiView: isMultiView(viewType),
  };

  const isInvalidId = is.singleView
    ? id && !store.getters['monitoringInstance/getInstanceById'](id)
    : id && is.multiView && !store.getters['monitoringFolder/getFolderById'](id);
  if (!id || isInvalidId) {
    await store.dispatch(`${storeName}/fetchLastSelectedId`);

    const targetId = is.singleView
      ? store.getters[`${storeName}/getSelectedInstanceId`]
      : store.getters[`${storeName}/getSelectedFolderId`];

    if (!targetId) {
      return { path: DATABASE_LIST_VIEW_PATH };
    }

    return { path: verifiedPath, query: { id: targetId } };
  }

  const instanceId: string | undefined = is.singleView ? id : undefined;
  const groupId = is.singleView
    ? store.getters['monitoringInstance/getInstanceById'](id)?.folders?.[0]?.folderId
    : id;
  const groups = store.getters['monitoringFolder/getFolders'];

  const { folderId: verifiedGroupId, instanceId: verifiedInstanceId } = getRtmSelectedInfo({
    idInfo: {
      folderId: groupId,
      instanceId,
    },
    folderList: groups,
    dbType,
    hasInstanceId: is.singleView,
  });

  if (is.multiView) {
    await store.dispatch(`${storeName}/saveLastSelectedId`, {
      folderId: verifiedGroupId,
    });
  } else if (is.singleView) {
    await store.dispatch(`${storeName}/saveLastSelectedId`, {
      folderId: verifiedGroupId,
      instanceId: verifiedInstanceId,
    });
  }

  if (isInvalidPath.singleView(to.path) || isInvalidPath.multiView(to.path)) {
    return {
      path: verifiedPath,
      query: to.query,
    };
  }

  return undefined;
};
