<template>
  <div
    :class="[
      'base-grid__ratio',
      { 'base-grid__ratio--active': isActive },
      { 'base-grid__ratio--inactive': !isActive },
    ]"
    @click="onClickRatio({ name, type })"
  >
    <div class="ratio-wrapper">
      <div class="ratio-bar">
        <span
          class="ratio-bar__thumb"
          :style="{
            width: `${computedWidth}%`,
          }"
        />
      </div>
      <span class="ratio-text ratio-text__name">
        {{ type ? `${type} / ${name}` : name }}
      </span>
      <span
        class="ratio-text"
        :style="{ right: name ? '30px' : 'auto' }"
      >
        <template v-if="props.displayText">
          {{ displayText }}
        </template>
        <template v-else>
          {{ hasUnit ? computedRatio + '%' : computedRatio }}
        </template>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { roundToDigit, roundToDigitNumber } from '@/common/utils/commonUtils';

interface Props {
  ratio: number | null;
  name?: string;
  hasUnit?: boolean;
  type?: string;
  isActive?: boolean;
  displayText?: string;
  digit?: number;
}

const props = withDefaults(defineProps<Props>(), {
  ratio: null,
  name: '',
  hasUnit: false,
  type: '',
  isActive: false,
  displayText: '',
  digit: 3,
});

interface Value {
  name: string;
  type: string;
}

interface Emit {
  (e: 'click-ratio', value: Value): void;
}
const emit = defineEmits<Emit>();

const computedRatio = computed(() =>
  props.ratio === null ? null : roundToDigit(props.ratio, props.digit),
);
const computedWidth = computed(() => {
  if (props.ratio === null) {
    return 0;
  }

  const width = roundToDigitNumber(props.ratio, props.digit);
  if (width > 100) {
    return 100;
  }

  return width;
});

const onClickRatio = ({ name, type }) => {
  emit('click-ratio', { name, type });
};
</script>
