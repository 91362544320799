<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount } from 'vue';
import { useStore } from '@/common/store';
import TopBanner from '@/common/components/molecules/topBanner/TopBanner.vue';
import NavigationHeader from '@/common/components/molecules/navigationHeader/NavigationHeader.vue';

const store = useStore();

const isLoading = computed<boolean>(() => store.getters.getIsLoading);

onBeforeMount(() => {
  document.body.classList.add('no-min-width');
});

onBeforeUnmount(() => {
  document.body.classList.remove('no-min-width');
});
</script>

<template>
  <base-menu />
  <div class="config-viewport">
    <navigation-header use-path />
    <top-banner />
    <div class="config-viewport__inner">
      <router-view />
    </div>
  </div>
  <ev-loading v-model="isLoading" />
</template>
