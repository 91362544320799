import { WidgetUUID } from '@/common/utils';
import { generateUUID } from '@/common/utils/commonUtils';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { GridLayoutItem } from '../utils/types';

export const useWidgetUUIDStore = defineStore('widgetUUIDStore', () => {
  // 현재 속해있는 대시보드의 uuid 리스트 (위젯의 uuid는 대시보드 내에서 중복되면 안됨)
  const widgetUUIDList = ref<WidgetUUID[]>([]);

  const setWidgetUUID = (gridLayoutItem: GridLayoutItem) => {
    widgetUUIDList.value.push(gridLayoutItem.i);
    gridLayoutItem.children?.forEach(setWidgetUUID);
  };

  const initWidgetUUIDList = (gridLayoutItem: GridLayoutItem[]) => {
    widgetUUIDList.value = [];
    gridLayoutItem.forEach(setWidgetUUID);
  };

  const generateWidgetUUID = () => {
    let newUUID = generateUUID();
    // 무한루프 방지를 위해 10번까지 시도
    let count = 0;
    while (count < 10 && widgetUUIDList.value.includes(newUUID)) {
      newUUID = generateUUID();
      count++;
    }
    widgetUUIDList.value.push(newUUID);
    return newUUID;
  };

  const isUUIDDuplicated = (widgetId: WidgetUUID) => {
    return widgetUUIDList.value.filter((id) => id === widgetId).length > 1;
  };

  const route = useRoute();
  watch(
    () => route.params.id,
    () => {
      widgetUUIDList.value = [];
    },
  );

  return { initWidgetUUIDList, generateWidgetUUID, isUUIDDuplicated };
});
