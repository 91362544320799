import { computed } from 'vue';
import { useRoute } from 'vue-router';

const useModel = () => {
  const route = useRoute();

  const currentDbType = computed(() => {
    const routeName = route?.name;
    return typeof routeName === 'string' ? routeName.split('_')[0] : '';
  });

  return {
    currentDbType,
  };
};

export { useModel };
