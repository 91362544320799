import { computed } from 'vue';
import { TimePeriodData } from '@/types/common.types';
import { DEFAULT_PERIOD_ITEMS } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';

export interface Props {
  modelValue: string;
  periods?: TimePeriodData[];
  disabled?: boolean;
}

export interface Emit {
  (e: 'update:modelValue', periodValue: string): void;
  (e: 'change-period', periodValue: string): void;
}

export const setup = (props: Props, emit: Emit) => {
  const usePropsPeriods = !!(props?.periods && props.periods.length);

  const periods = computed<TimePeriodData[]>(() => {
    return (usePropsPeriods ? props.periods : DEFAULT_PERIOD_ITEMS) as TimePeriodData[];
  });

  const selectedPeriod = computed({
    get: () => props.modelValue,
    set: (val: string) => {
      if (props.disabled) {
        return;
      }
      emit('update:modelValue', val);
      emit('change-period', selectedPeriod.value);
    },
  });

  const periodShortName = computed(
    () => periods.value.find(({ value }) => value === selectedPeriod.value)?.shortName,
  );

  return {
    periods,
    selectedPeriod,
    periodShortName,
  };
};
