import { defineStore, storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { getSafeColorListByTheme } from '@/common/utils/chartUtils';
import { CUSTOM_KEY } from '@/dashboard/utils/define';
import { CHART_COLORS_SPARROW } from '@/common/utils';
import type { TargetInfo } from '@/worker/commands/dashboard/metrics';
import { useDashboardViewStore } from './dashboard-view';

type Theme = string;
type TargetId = string;
export type MatchingThemeSeriesColor = Map<Theme, Map<TargetId, string>>;

const getThemeColorList = (curTheme: string) => {
  return curTheme === CUSTOM_KEY ? CHART_COLORS_SPARROW : getSafeColorListByTheme(curTheme);
};

const getCurThemeSeriesColor = (
  curTheme: string,
  targets: TargetInfo[] | string,
  matchingThemeSeriesColor: MatchingThemeSeriesColor | undefined,
) => {
  const curThemeList = getThemeColorList(curTheme);
  const curColorCount = matchingThemeSeriesColor?.get(curTheme)?.size || 0;

  if (Array.isArray(targets)) {
    const curThemeSeriesColor = new Map<TargetId, string>();
    targets.forEach(({ targetId }, index) => {
      if (!matchingThemeSeriesColor?.get(curTheme)?.has(targetId)) {
        const colorIndex = (curColorCount + index) % curThemeList.length;
        curThemeSeriesColor.set(targetId, curThemeList[colorIndex]);
      }
    });
    return curThemeSeriesColor;
  }

  if (matchingThemeSeriesColor?.get(curTheme)?.has(targets)) {
    return matchingThemeSeriesColor.get(curTheme);
  }

  const colorIndex = curColorCount % curThemeList.length;
  return new Map([[targets, curThemeList[colorIndex]]]);
};

export const useDashboardTargetsColorSyncStore = defineStore('dashboardTargetsColorSync', () => {
  const { widgetsForMonitoringDashboard } = storeToRefs(useDashboardViewStore());
  const matchingThemeSeriesColor = ref<MatchingThemeSeriesColor>(new Map());

  const setMatchingThemeSeriesColor = ({
    curTheme,
    targets,
  }: {
    curTheme: string;
    targets: TargetInfo[] | string;
  }) => {
    if (!curTheme) return;

    const curThemeColors = matchingThemeSeriesColor.value.get(curTheme) || new Map();
    const newColors = getCurThemeSeriesColor(curTheme, targets, matchingThemeSeriesColor.value);

    newColors?.forEach((color, targetId) => {
      if (!curThemeColors.has(targetId)) {
        curThemeColors.set(targetId, color);
      }
    });

    matchingThemeSeriesColor.value.set(curTheme, curThemeColors);
  };

  const getMatchingThemeSeriesColor = ({
    colorTheme,
    targetId,
    colorIndex,
  }: {
    colorTheme: string;
    targetId: string;
    colorIndex: number;
  }) => {
    const colorList = getThemeColorList(colorTheme);

    return (
      matchingThemeSeriesColor.value.get(colorTheme)?.get(targetId) ||
      colorList[colorIndex % colorList.length]
    );
  };

  const clearMatchingThemeSeriesColor = () => {
    matchingThemeSeriesColor.value.clear();
  };

  watch(() => widgetsForMonitoringDashboard.value?.dashboardId, clearMatchingThemeSeriesColor);

  return {
    matchingThemeSeriesColor,
    setMatchingThemeSeriesColor,
    getMatchingThemeSeriesColor,
  };
});
