<script setup lang="ts">
import SimpleTextTooltip from '@/common/components/molecules/simpleTextTooltip/SimpleTextTooltip.vue';
import {
  setup,
  Props,
} from '@/common/components/molecules/statusTooltipTag/statusTooltipTag.setup';
import VStack from '@/common/components/layouts/VStack.vue';
import { capitalize } from 'lodash-es';

const props = defineProps<Props>();
const { statusToClass, statusTooltipMouseEvent, onMouseStatus, statusTooltipInfo } = setup(props);
</script>

<template>
  <span
    class="status-tooltip-tag"
    :class="statusToClass"
    @mouseenter="(e) => onMouseStatus({ statusInfo: props.statusInfo, e })"
    @mouseleave="(e) => onMouseStatus({ e })"
  >
    <div class="status-tooltip-tag__name">
      {{ capitalize(props.statusInfo.status) }}
    </div>
  </span>
  <simple-text-tooltip
    :mouse-event="statusTooltipMouseEvent"
    :show-tail="false"
    base-pos="bottom"
    custom-class="status-tooltip"
  >
    <v-stack :spacing="4">
      <div class="status-tooltip__name">{{ statusTooltipInfo.name }}</div>
      <div class="status-tooltip__description">
        {{ statusTooltipInfo.description }}
      </div>
    </v-stack>
  </simple-text-tooltip>
</template>

<style lang="scss">
.status-tooltip-tag {
  display: inline-block;
  max-width: calc(100% - 20px);
  padding: 2px 10px 1px;
  cursor: default;
  border: 1px solid var(--status-tag-warning-border-color);
  border-radius: 2px;
  background-color: var(--status-tag-warning-bg-color);
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  color: var(--status-tag-warning-font-color);
  text-align: center;

  &__name {
    @include shortening();
  }
  & + & {
    margin-left: 4px;
  }

  &.green {
    border-color: var(--status-tag-succeeded-border-color);
    background-color: var(--status-tag-succeeded-bg-color);
    color: var(--status-tag-succeeded-font-color);
  }

  &.blue {
    border-color: var(--status-tag-active-border-color);
    background-color: var(--status-tag-active-bg-color);
    color: var(--status-tag-active-font-color);
  }

  &.red {
    border-color: var(--status-tag-error-border-color);
    background-color: var(--status-tag-error-bg-color);
    color: var(--status-tag-error-font-color);
  }

  &.gray {
    border-color: var(--color-gray-04-06);
    background-color: var(--color-gray-06-01);
    color: var(--color-gray-00-12);
  }
}
.status-tooltip {
  padding: 12px;

  &__name {
    font-size: 12px;
    font-weight: 700;
    color: var(--color-gray-00-12);
  }
  &__description {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: var(--color-gray-02-10);
  }
}
</style>
