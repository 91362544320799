import { LOGIN_CUSTOMER } from '@/common/define/common.define';
import { usePropertyStore } from '@/common/stores/property';
import { VIEW_TYPE } from '@/common/utils';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, onMounted, onUnmounted, ref, shallowRef } from 'vue';
import { useRoute } from 'vue-router';
import { useLoginRoute } from '@/login/components/loginForm/loginForm.setup';
import router from '@/common/router';

interface Props {
  isManager?: boolean;
}

const useSso = () => {
  const protocols = ['http://', 'https://'];
  const actionSso = (path: string | undefined) => {
    if (!path) {
      router.push('/401');
      return;
    }

    if (path && protocols.some((protocol) => path?.includes(protocol))) {
      location.replace(path);
    } else {
      location.replace(`http://${path}`);
    }
  };

  return {
    actionSso,
  };
};

const setup = (props: Props) => {
  const viewType = ref<string>(VIEW_TYPE.VIEW);
  const oldPassword = ref<string>('');
  const isShowGroupWindow = ref<boolean>(false);
  const isShowLoadingScreen = ref<boolean>(true);
  const { propertyData } = storeToRefs(usePropertyStore());
  const customOptions = computed(() => usePropertyStore().getOptions);
  const loginFormComponent = shallowRef();
  const { actionSso } = useSso();

  const changeViewType = (type: string) => {
    viewType.value = type;
  };

  const setOldPassword = (password: string) => {
    oldPassword.value = password;
  };

  onMounted(() => {
    document.body.style.minWidth = '420px';
    document.body.style.minHeight = '660px';
  });

  onUnmounted(() => {
    document.body.removeAttribute('style');
  });

  onBeforeMount(() => {
    if (props.isManager) {
      loginFormComponent.value = customOptions.value.loginComponent(LOGIN_CUSTOMER.EXEMONE);
      isShowLoadingScreen.value = false;
      return;
    }

    if (propertyData.value && propertyData.value.sso?.ssoMode) {
      // SSO 모드일 경우
      actionSso(propertyData.value.sso?.redirectionRootUrl);
    } else {
      isShowLoadingScreen.value = false;
    }
    loginFormComponent.value = customOptions.value.loginComponent();
  });

  return {
    viewType,
    oldPassword,
    isShowGroupWindow,
    isShowLoadingScreen,
    loginFormComponent,
    changeViewType,
    setOldPassword,
  };
};
const useRedirectToFrom = () => {
  const route = useRoute();
  const { goDashboard } = useLoginRoute();
  const redirectToFrom = async () => {
    const { redirectedFrom } = route;

    if (
      redirectedFrom?.path != null &&
      redirectedFrom?.path !== '/logout' &&
      redirectedFrom?.path !== '/login' &&
      redirectedFrom?.path !== '/'
    ) {
      await router.push(redirectedFrom.path);
    } else {
      await goDashboard();
    }
  };

  return { redirectToFrom };
};

export { useRedirectToFrom, setup };
export type { Props };
