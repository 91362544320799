import { computed, ref, watch } from 'vue';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { storeToRefs } from 'pinia';
import { useTimePeriodStore, type InitTimeOption } from '@/common/stores/main-view-time-period';
import type {
  TimePeriodInfo,
  TimePeriodOptions,
} from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import type { MenuInfo } from '@/common/components/organisms/baseMenu/types';
import type { TimePeriodData } from '@/types/common.types';
import TimePeriodIndicator from '@/common/components/molecules/timePeriodIndicator/TimePeriodIndicator.vue';

export interface Props {
  usePath?: boolean;
  useTimePeriod?: boolean;
  timePeriodOptions?: TimePeriodOptions;
  timePeriodItems?: Readonly<TimePeriodData[]>;
  initTimeOption?: InitTimeOption;
  customPath?: string;
}
export interface Emit {
  (e: 'update-time-period', value: TimePeriodInfo): void;
}

export const useGlobalTime = (props?: Props) => {
  const baseMenuStore = useBaseMenuStore();
  const { menuVisitRoute } = storeToRefs(baseMenuStore);

  const timePeriodStore = useTimePeriodStore();
  const { timePeriodInfo } = storeToRefs(timePeriodStore);
  const { updateTimePeriod, initTimePeriod } = timePeriodStore;

  const currentRoute = computed<MenuInfo>(() => menuVisitRoute.value.at(-1)!);
  const path = computed(() => props?.customPath || currentRoute.value.path);

  const selectedPeriodInfo = computed<TimePeriodInfo>(() => {
    return timePeriodInfo.value[path.value!] ?? {};
  });
  const updatePeriodInfo = (info: TimePeriodInfo) => {
    if (path.value && info) {
      updateTimePeriod(path.value, info);
    }
  };

  return {
    timePeriodInfo,
    menuVisitRoute,
    currentRoute,
    selectedPeriodInfo,
    updatePeriodInfo,
    initTimePeriod,
  };
};

const setup = (props: Props, emit: Emit) => {
  const {
    currentRoute,
    menuVisitRoute,
    timePeriodInfo,
    selectedPeriodInfo,
    updatePeriodInfo,
    initTimePeriod,
  } = useGlobalTime(props);
  const timeIndicatorRef = ref<InstanceType<typeof TimePeriodIndicator> | null>(null);

  const onUpdatePeriodInfo = (info: TimePeriodInfo) => {
    if (info) {
      updatePeriodInfo(info);
      emit('update-time-period', info);
    }
  };

  watch(
    menuVisitRoute,
    () => {
      const currentPath = currentRoute.value?.path;
      if (currentPath && !timePeriodInfo.value[currentPath] && props.useTimePeriod) {
        initTimePeriod(currentPath, props.initTimeOption);
      }
    },
    { immediate: true },
  );

  return {
    timeIndicatorRef,
    currentRoute,
    selectedPeriodInfo,
    onUpdatePeriodInfo,
  };
};

export { setup };
