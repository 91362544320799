import { isArray } from 'lodash-es';
import {
  ChartOptions,
  DefaultChartOptions,
  type WidgetChartOptionsMap,
} from './chartOption.define';
import { WidgetChartType } from './types';

export type WidgetChartOptions = WidgetChartOptionsMap[keyof WidgetChartOptionsMap];

const mergeNestedOptions = <K extends WidgetChartOptions>(
  defaultOption: K,
  userOption: Record<string, any> | undefined,
): Required<K> | typeof userOption => {
  if (!defaultOption) {
    return userOption;
  }
  return {
    ...userOption,
    ...Object.keys(defaultOption).reduce((acc, key) => {
      if (
        typeof defaultOption[key] === 'object' &&
        !isArray(defaultOption[key]) &&
        userOption?.[key]
      ) {
        acc[key] = mergeNestedOptions(defaultOption[key], userOption[key]);
      } else if (
        isArray(defaultOption[key]) &&
        typeof defaultOption[key][0] === 'object' &&
        userOption?.[key]
      ) {
        acc[key] = defaultOption[key].map((option, index) =>
          mergeNestedOptions(option, userOption[key][index]),
        );
      } else {
        acc[key] = userOption?.[key] ?? defaultOption[key];
      }
      return acc;
    }, {}),
  };
};

export const applyDefaultChartOptions = <K extends WidgetChartType>(
  chartType: K,
  chartOption: Record<string, any> | undefined,
): Required<ChartOptions<K>> | typeof chartOption => {
  const defaultChartOption: ChartOptions<K> = DefaultChartOptions[chartType];

  switch (chartType) {
    case 'TIME_SERIES':
    case 'SCATTER':
    case 'ONE_DAY_COMPARE':
    case 'EQUALIZER':
    case 'SCOREBOARD':
    case 'PIE':
    case 'STACKED_PROPORTION_BAR':
    case 'GAUGE':
    case 'TABLE':
    case 'TREE_GRID':
    case 'TOP_LIST':
    case 'STATUS':
    case 'STATUS_SUMMARY':
    case 'TOPOLOGY':
    case 'SERVER':
    case 'ACTION_VIEW':
    case 'EXCLUSIVE':
    case 'SERVICE_CARD':
    case 'MEMO':
    case 'TEXT':
    case 'IMAGE':
    case 'HORIZONTAL_FILTER':
    case 'VERTICAL_FILTER':
    case 'GROUP':
    case 'TAB_GROUP': {
      return mergeNestedOptions(defaultChartOption, chartOption);
    }
    default:
      throw new Error(`Not supported chart type: ${chartType}`);
  }
};
