<script setup lang="ts">
import { useInternational } from '@/common/locale';
import { Ref, ref, watch, watchEffect } from 'vue';
import { getTimeString, setCursorAtEnd, compareTime, parseTime } from './utils';

interface Props {
  modelValue: string; // HH:mm:ss
  min?: string; // HH:mm:ss
  max?: string; // HH:mm:ss
  errorMsg?: string;
  readonlyTimes?: {
    hour: boolean;
    minute: boolean;
    second: boolean;
  };
}

interface Emits {
  (event: 'update:modelValue', value: string): void;
}

const { t } = useInternational();
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const errorMsg = ref(props.errorMsg || '');
const validateRange = (value: string) => {
  if (props.min && compareTime(props.min, value) > 0) {
    errorMsg.value = t('ERROR.DATE_MIN', { date: props.min });
  } else if (props.max && compareTime(props.max, value) < 0) {
    errorMsg.value = t('ERROR.DATE_MAX', { date: props.max });
  } else {
    errorMsg.value = props.errorMsg || '';
  }
};
watch(
  () => props.errorMsg,
  (value) => {
    errorMsg.value = value || '';
  },
);

const timeFieldRef = ref<HTMLDivElement>();
const HH = ref('00');
const mm = ref('00');
const ss = ref('00');
watchEffect(() => {
  const [hour, minute, second] = parseTime(props.modelValue).map((n) =>
    n.toString().padStart(2, '0'),
  );
  HH.value = hour;
  mm.value = minute;
  ss.value = second;

  validateRange(`${hour}:${minute}:${second}`);
});

const editingValue = ref('');
watch(editingValue, () => {
  const isEditing = editingValue.value.length > 0;
  if (isEditing) return;

  const newTime = `${HH.value}:${mm.value}:${ss.value}`;
  emit('update:modelValue', newTime);

  validateRange(newTime);
});

const inputHandler = (refValue: Ref<string>, name: 'hour' | 'minute' | 'second') => {
  editingValue.value += refValue.value.slice(-1);

  const numbers = getTimeString(editingValue.value, name);

  if (numbers === 'NaN') {
    refValue.value = '00';
    editingValue.value = '';
    return;
  }
  refValue.value = numbers;

  if (editingValue.value.length === 2) {
    const inputs = timeFieldRef.value?.querySelectorAll('input');
    const index = Array.from(inputs!).findIndex((el) => el.name === name);
    const nextInput = inputs![index + 1] as HTMLInputElement;
    if (nextInput) {
      nextInput.focus();
    }
  }
  if (editingValue.value.length === 2) {
    editingValue.value = '';
  }
};

const onInputHour = (e: Event) => {
  inputHandler(HH, (e.target as HTMLInputElement).name as 'hour');
};
const onInputMinute = (e: Event) => {
  inputHandler(mm, (e.target as HTMLInputElement).name as 'minute');
};
const onInputSecond = (e: Event) => {
  inputHandler(ss, (e.target as HTMLInputElement).name as 'second');
};
const focusedInput = ref<'hour' | 'minute' | 'second'>();
const onFocus = async (e: FocusEvent) => {
  editingValue.value = '';
  setCursorAtEnd(e.target as HTMLInputElement);
  focusedInput.value = (e.target as HTMLInputElement).name as 'hour' | 'minute' | 'second';
};

const onBlur = () => {
  editingValue.value = '';
  focusedInput.value = undefined;
};
</script>

<template>
  <div class="time-field-wrap">
    <div
      ref="timeFieldRef"
      class="time-field"
      :class="{ error: errorMsg, focus: focusedInput }"
    >
      <ev-icon icon="ev-icon-time" />

      <label
        class="time-field__label"
        :class="{
          focus: focusedInput === 'hour',
          readonly: !!props.readonlyTimes?.hour,
        }"
      >
        <input
          v-model="HH"
          type="text"
          name="hour"
          :readonly="!!props.readonlyTimes?.hour"
          @input="onInputHour"
          @focus="onFocus"
          @blur="onBlur"
        />
        <span>{{ $t('WORD.DATE_HOUR') }}</span>
      </label>

      <label
        class="time-field__label"
        :class="{
          focus: focusedInput === 'minute',
          readonly: !!props.readonlyTimes?.minute,
        }"
      >
        <input
          v-model="mm"
          type="text"
          name="minute"
          :readonly="!!props.readonlyTimes?.minute"
          @input="onInputMinute"
          @focus="onFocus"
          @blur="onBlur"
        />
        <span>{{ $t('WORD.DATE_MINUTE') }}</span>
      </label>

      <label
        class="time-field__label"
        :class="{
          focus: focusedInput === 'second',
          readonly: !!props.readonlyTimes?.second,
        }"
      >
        <input
          v-model="ss"
          type="text"
          name="second"
          :readonly="!!props.readonlyTimes?.second"
          @input="onInputSecond"
          @focus="onFocus"
          @blur="onBlur"
        />
        <span>{{ $t('WORD.DATE_SECOND') }}</span>
      </label>
    </div>
    <span
      v-show="errorMsg"
      class="errorMsg"
    >
      <ev-icon
        icon="icon-error-circle"
        size="xsmall"
      />
      {{ errorMsg }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.time-field-wrap {
  display: flex;
  flex-direction: column;
  width: 132px;
}
.errorMsg {
  display: inline-flex;
  position: relative;
  left: 0;
  align-items: flex-start;
  color: var(--color-red-06);
  @include typography('caption-1');
  [class^='icon-'] {
    flex-shrink: 0;
    margin-top: 2px;
    margin-right: 4px;
    background-color: var(--color-red-06);
  }
}
.time-field {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  gap: 4px;
  align-items: center;
  height: 20px;
  padding: 4px 8px;
  overflow: hidden;
  border: 1px solid var(--color-gray-04-06);
  border-radius: 2px;

  &.focus {
    border-color: var(--color-blue-05-05);
  }

  &.error {
    border-color: var(--color-red-06);
  }

  &__label {
    text-wrap: nowrap;
  }

  &__label > input {
    width: 16px;
    padding: 0;
    outline: transparent;
    background: transparent;
    color: var(--color-gray-00-12);
    text-align: center;
    caret-color: transparent;
  }
  &__label.focus {
    background-color: var(--color-blue-09-01);
    color: var(--color-blue-04-06);
    input {
      color: var(--color-blue-04-06);
    }
  }
  &__label.readonly {
    background-color: var(--color-gray-06-01);
    color: var(--color-gray-04-08);
    input {
      color: var(--color-gray-04-08);
    }
  }
}
</style>
