import { TargetTagType } from '@/common/utils';
import {
  WidgetChildValueResponseV7,
  WidgetGroupValueResponseV7,
  WidgetValueTargetDetailV7,
  WidgetValueTargetV7,
} from '@/openapi/metaV6/model';
import { GridLayoutItem, WidgetChartData } from '../utils/types';

export const widgetTypeMap: {
  [key in Exclude<
    WidgetGroupValueResponseV7['widgetType'] | WidgetChildValueResponseV7['widgetType'],
    undefined
  >]: GridLayoutItem['widgetType'];
} = {
  GRAPHS: 'GRAPHS',
  ARCHITECTURE: 'ARCHITECTURE',
  LIST: 'LIST',
  MEMO: 'MEMO',
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  GROUP: 'GROUP',
  SERVICE: 'SERVICE',
  ETC: 'ETC',
  FILTER: 'FILTER',
};

export const chartTypeMap: {
  [key in Exclude<
    WidgetGroupValueResponseV7['chartType'] | WidgetChildValueResponseV7['chartType'],
    undefined
  >]: GridLayoutItem['chartType'];
} = {
  TIME_SERIES: 'TIME_SERIES',
  SCATTER: 'SCATTER',
  ONE_DAY_COMPARE: 'ONE_DAY_COMPARE',
  EQUALIZER: 'EQUALIZER',
  SCOREBOARD: 'SCOREBOARD',
  STACKED_PROPORTION_BAR: 'STACKED_PROPORTION_BAR',
  ACTION_VIEW: 'ACTION_VIEW',
  PIE: 'PIE',
  GAUGE: 'GAUGE',
  TOP_LIST: 'TOP_LIST',
  TREE_GRID: 'TREE_GRID',
  TABLE: 'TREE_GRID', // TABLE과 TREE_GRID의 매핑 처리
  TOPOLOGY: 'TOPOLOGY',
  EXCLUSIVE: 'EXCLUSIVE',
  STATUS: 'STATUS',
  STATUS_SUMMARY: 'STATUS_SUMMARY',
  SERVER: 'SERVER',
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  MEMO: 'MEMO',
  SERVICE_CARD: 'SERVICE_CARD',
  HORIZONTAL_FILTER: 'HORIZONTAL_FILTER',
  VERTICAL_FILTER: 'VERTICAL_FILTER',
  GROUP: 'GROUP',
  TAB_GROUP: 'TAB_GROUP',
};

export const displayStyleMap: {
  [key in Exclude<
    WidgetGroupValueResponseV7['displayStyle'] | WidgetChildValueResponseV7['displayStyle'],
    undefined
  >]: GridLayoutItem['displayStyle'];
} = {
  LINE: 'LINE',
  BAR: 'BAR',
  AREA: 'AREA',
  STACKED_AREA: 'STACKED_AREA',
  STACKED_BAR: 'STACKED_BAR',
  STYLE_1: 'STYLE_1',
  STYLE_2: 'STYLE_2',
  STYLE_3: 'STYLE_3',
  TOP_3_CARD: 'TOP_3_CARD',
  SUMMARY_BAR: 'SUMMARY_BAR',
  HEXA: 'HEXA',
  CARD: 'CARD',
  PIE: 'PIE',
  TREE_GRID: 'TREE_GRID',
  TABLE: 'TREE_GRID',
  TOPOLOGY: 'TOPOLOGY',
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
  SERVER: 'SERVER',
  GAUGE_HALF_CIRCLE: 'GAUGE_HALF_CIRCLE',
  GAUGE_CIRCLE: 'GAUGE_CIRCLE',
};

export const timePeriodMap: {
  [key in Exclude<
    WidgetGroupValueResponseV7['period'] | WidgetChildValueResponseV7['period'],
    undefined
  >]: GridLayoutItem['timePeriod'];
} = {
  globalTime: 'globalTime',
  calendar: 'calendar',
  p5m: 'p5m',
  p10m: 'p10m',
  p30m: 'p30m',
  p1h: 'p1h',
  p6h: 'p6h',
  p12h: 'p12h',
  p1d: 'p1d',
};

export const widgetSeriesTypeMap: {
  [key in Exclude<WidgetValueTargetV7['seriesType'], undefined>]: WidgetChartData['seriesType'];
} = {
  avg: 'avg',
  byTarget: 'byTarget',
  max: 'max',
  sum: 'sum',
};

export const widgetDataTypeMap: {
  [key in Exclude<WidgetValueTargetV7['dataType'], undefined>]: WidgetChartData['dataType'];
} = {
  current: 'current',
  delta: 'delta',
  sum: 'sum',
  avg: 'avg',
  max: 'max',
  deltaPerSec: 'deltaPerSec',
  sumPerSec: 'sumPerSec',
};

export const targetTagTypeMap: { [key in WidgetValueTargetDetailV7['tagType']]: TargetTagType } = {
  attribute: 'attribute',
  globalVariable: 'globalVariable',
  serviceGroup: 'serviceGroup',
  tag: 'tag',
};

export const createTypeMap: {
  [key in Exclude<
    WidgetGroupValueResponseV7['createType'] | WidgetChildValueResponseV7['createType'],
    undefined
  >]: GridLayoutItem['createType'];
} = {
  base: 'base',
  custom: 'reference', // custom은 reference로 변환
};

export const createTypeDtoMap: {
  [key in GridLayoutItem['createType']]:
    | WidgetGroupValueResponseV7['createType']
    | WidgetChildValueResponseV7['createType'];
} = {
  base: 'base',
  reference: 'custom', // reference는 custom으로 변환
};
