export const REPORT = {
  GRIDCOLUMNS_report_reportHistory_reportHistoryList:
    'GRIDCOLUMNS_report_reportHistory_reportHistoryList',
  GRIDCOLUMNS_report_reportExport_reportExportList:
    'GRIDCOLUMNS_report_reportExport_reportExportList',
  FILTERSEARCH_report_reportHistory_reportHistoryList:
    'FILTERSEARCH_report_reportHistory_reportHistoryList',
  FILTERSEARCH_report_reportExport_reportList: 'FILTERSEARCH_report_reportExport_reportList',
  FILTER_report_export: 'FILTER_report_export',
} as const;
