import { RouteRecordRaw } from 'vue-router';
import {
  requireAuth,
  requireInstances,
  setDayjsTimezone,
  validateDatabaseOverviewPath,
} from '@/common/router/navigationGuard';
import PaViewport from '@/common/components/templates/PaViewport.vue';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { VIEW_MODE } from '@/common/define/common.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';

const PREFIX_ORACLE = 'oracle_';

const oracleMenu: RouteRecordRaw[] = [
  {
    path: '/oracle/',
    name: `${PREFIX_ORACLE}Real Time Monitoring`,
    component: RtmViewport,
    children: [
      {
        path: 'multiView',
        name: `${PREFIX_ORACLE}Multi View`,
        component: () =>
          import(/* webpackChunkName: "oracle-multi-view" */ '../views/multiView/MultiView.vue'),
      },
    ],
    beforeEnter: [requireAuth, requireInstances, setDayjsTimezone, validateDatabaseOverviewPath],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD,
    },
  },
  {
    path: '/oracle/',
    name: `${PREFIX_ORACLE}SQL Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'sqlSummary',
        name: `${PREFIX_ORACLE}SQL Summary`,
        component: () =>
          import(
            /* webpackChunkName: "oracle-summary-view" */ '../views/sqlSummary/SqlSummary.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'topNAnalysis',
        name: `${PREFIX_ORACLE}Top N Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "oracle-topN-Analysis" */ '../views/topNAnalysis/TopNAnalysis.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'planAnalysis',
        name: `${PREFIX_ORACLE}Plan Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "oracle-plan-change" */ '../views/planAnalysis/PlanAnalysis.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'searchSql',
        name: `${PREFIX_ORACLE}Search SQL`,
        component: () =>
          import(/* webpackChunkName: "oracle-search-sql" */ '../views/searchSql/SearchSql.vue'),
        beforeEnter: [requireInstances],
      },
    ],
    beforeEnter: [requireAuth],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ORACLE_SQL_ANALYSIS,
      },
    },
  },
  {
    path: '/oracle/',
    name: `${PREFIX_ORACLE}Event Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'waitSummary',
        name: `${PREFIX_ORACLE}Wait Summary`,
        component: () =>
          import(
            /* webpackChunkName: "oracle-wait-summary" */ '../views/waitSummary/WaitSummary.vue'
          ),
      },
    ],
    beforeEnter: [requireAuth, requireInstances],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ORACLE_EVENT_ANALYSIS,
      },
    },
  },
  {
    path: '/oracle/',
    name: `${PREFIX_ORACLE}Session Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'searchSession',
        name: `${PREFIX_ORACLE}Search Session`,
        component: () =>
          import(
            /* webpackChunkName: "oracle-search-session" */ '../views/searchSession/SearchSession.vue'
          ),
      },
    ],
    beforeEnter: [requireAuth, requireInstances],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ORACLE_SESSION_ANALYSIS,
      },
    },
  },
  {
    path: '/oracle/',
    name: `${PREFIX_ORACLE}Performance Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'trendAnalysis',
        name: `${PREFIX_ORACLE}Trend Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "oracle-trend-analysis" */ '../views/trendAnalysis/TrendAnalysis.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'parameterHistory',
        name: `${PREFIX_ORACLE}Parameter History`,
        component: () =>
          import(
            /* webpackChunkName: "oracle-parameter-history" */ '../views/parameterHistory/ParameterHistory.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'alertHistory',
        name: `${PREFIX_ORACLE}Alert History`,
        component: () =>
          import(
            /* webpackChunkName: "oracle-alert-history" */ '../views/alertHistory/AlertHistory.vue'
          ),
        beforeEnter: [requireInstances],
      },
    ],
    beforeEnter: [requireAuth],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ORACLE_PERFORMANCE_ANALYSIS,
      },
    },
  },
  {
    path: '/oracle/',
    name: `${PREFIX_ORACLE}DataSpace Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'tablespace',
        name: `${PREFIX_ORACLE}Tablespace`,
        component: () =>
          import(/* webpackChunkName: "oracle-tablespace" */ '../views/tablespace/Tablespace.vue'),
        beforeEnter: [requireInstances],
      },
      {
        path: 'undoTemp',
        name: `${PREFIX_ORACLE}Undo & Temp`,
        component: () =>
          import(/* webpackChunkName: "oracle-undo-temp" */ '../views/undoTemp/UndoTemp.vue'),
        beforeEnter: [requireInstances],
      },
    ],
    beforeEnter: [requireAuth],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ORACLE_DATASPACE_ANALYSIS,
      },
    },
  },
];

const subMenuMapping = {
  instanceView: `${PREFIX_ORACLE}Real Time Monitoring`,
  sqlAnalysis: `${PREFIX_ORACLE}SQL Analysis`,
  eventAnalysis: `${PREFIX_ORACLE}Event Analysis`,
  sessionAnalysis: `${PREFIX_ORACLE}Session Analysis`,
  performanceAnalysis: `${PREFIX_ORACLE}Performance Analysis`,
  dataSpaceAnalysis: `${PREFIX_ORACLE}DataSpace Analysis`,
};

const getOracleNaviMenu = (
  subMenu?:
    | 'instanceView'
    | 'sqlAnalysis'
    | 'eventAnalysis'
    | 'sessionAnalysis'
    | 'performanceAnalysis'
    | 'dataSpaceAnalysis',
) => {
  const filteredOracleMenu = extractAccessibleRoutes(oracleMenu);

  if (subMenu && subMenuMapping[subMenu]) {
    return filteredOracleMenu.filter((menu) => menu.name === subMenuMapping[subMenu]);
  }

  return filteredOracleMenu.filter((menu) => menu.name !== subMenuMapping.instanceView);
};

export { oracleMenu, getOracleNaviMenu };
