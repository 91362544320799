import { useUserEnvStore } from '@/common/stores/user-env';
import { ColumnWithHideInfo } from '@/common/utils';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { TopologyWidgetPositionEnvData } from '@/dashboard/components/widgets/topologyWidget/topology/topology.type';
import type { LegendSaveInfo } from '@/dashboard/stores/dashboard-legend';
import { WidgetChartType } from '../utils/types';

export type TableOption = Omit<ColumnWithHideInfo, 'index'>;

export type WidgetEnvValue<ChartType extends WidgetChartType> = ChartType extends
  | 'TABLE'
  | 'TREE_GRID'
  ? TableOption[]
  : ChartType extends 'STATUS'
    ? { isSortedBySeverity: boolean }
    : ChartType extends 'TOPOLOGY'
      ? boolean | TopologyWidgetPositionEnvData
      : ChartType extends 'LEGEND'
        ? LegendSaveInfo
        : undefined;

// chartType에 따라 타입을 명확하게 강제하는 타입 설정
export type EnvConfig<ChartType extends WidgetChartType> = {
  chartType: ChartType;
  global: boolean;
  value: WidgetEnvValue<ChartType>;
};

type UserEnvKey = string;

type StringifiedUserEnvValue = string;

const stringifyEnvValue = <ChartType extends WidgetChartType>(
  envValue: WidgetEnvValue<ChartType>,
): StringifiedUserEnvValue => {
  if (envValue == null) {
    return '';
  }
  return JSON.stringify(envValue);
};

export const isMatchingChartType = <ChartType extends WidgetChartType>(
  value: EnvConfig<WidgetChartType>,
  chartType: ChartType,
): value is EnvConfig<ChartType> => {
  return value.chartType === chartType;
};

export const useDashboardUserEnvStore = defineStore('useDashboardUserEnvStore', () => {
  const userEnvStore = useUserEnvStore();

  const editingUserEnvData = ref<Map<UserEnvKey, EnvConfig<WidgetChartType>>>(new Map());

  // dashboard edit mode 에서 사용되는 user env data
  const useEditingUserEnv = () => {
    const clearEditingUserEnvData = () => {
      editingUserEnvData.value = new Map();
    };

    const getEditingUserEnvData = <ChartType extends WidgetChartType>(
      key: string,
      chartType: EnvConfig<ChartType>['chartType'],
    ): EnvConfig<ChartType>['value'] | undefined => {
      const envValue = editingUserEnvData.value.get(key);
      if (!envValue) {
        return undefined;
      }
      if (isMatchingChartType(envValue, chartType)) {
        return envValue.value;
      }
      return undefined;
    };

    const setEditingUserEnvData = <ChartType extends WidgetChartType>(
      key: string,
      newValue: EnvConfig<ChartType>,
    ) => {
      if (!editingUserEnvData.value) {
        editingUserEnvData.value = new Map();
      }
      editingUserEnvData.value.set(key, newValue);
    };

    const mutateEditingEnvValue = async () => {
      if (editingUserEnvData.value.size === 0) {
        return;
      }
      const { global: globalEnv, custom: customEnv } = Array.from(editingUserEnvData.value).reduce<{
        global: { key: string; value: string }[];
        custom: { key: string; value: string }[];
      }>(
        (categoried, [key, { global, value }]) => {
          if (global) {
            categoried.global.push({ key, value: stringifyEnvValue(value) });
          } else {
            categoried.custom.push({ key, value: stringifyEnvValue(value) });
          }
          return categoried;
        },
        {
          global: [],
          custom: [],
        },
      );
      await userEnvStore.setEnvValue(globalEnv, true);
      await userEnvStore.setEnvValue(customEnv, false);

      clearEditingUserEnvData();
    };

    return {
      editingUserEnvData,
      clearEditingUserEnvData,
      getEditingUserEnvData,
      setEditingUserEnvData,
      mutateEditingEnvValue,
    };
  };

  // dashboard preview mode 에서 사용되는 user env data
  const usePreviewUserEnv = () => {
    const previewUserEnvData = ref<{
      key: UserEnvKey;
      value: EnvConfig<WidgetChartType>;
    }>();

    const getPreviewUserEnvData = <ChartType extends WidgetChartType>(
      key: string,
      chartType: EnvConfig<ChartType>['chartType'],
    ): EnvConfig<ChartType>['value'] | undefined => {
      if (!previewUserEnvData.value || previewUserEnvData.value.key !== key) {
        return undefined;
      }
      const envValue = previewUserEnvData.value.value;
      if (isMatchingChartType(envValue, chartType)) {
        return envValue.value;
      }
      return undefined;
    };

    const setPreviewUserEnvData = (newUserEnvData: {
      key: string;
      value: Omit<EnvConfig<WidgetChartType>, 'global'>;
    }) => {
      previewUserEnvData.value = {
        key: newUserEnvData.key,
        value: {
          ...newUserEnvData.value,
          global: true,
        },
      };
    };

    const clearPreviewUserEnvData = () => {
      previewUserEnvData.value = undefined;
    };

    // widget setting 창에서 수정 후 save 버튼 눌렀을때 editingUserEnvData에 previewUserEnvData 동기화
    const syncEditingUserEnvData = async () => {
      if (previewUserEnvData.value === undefined) {
        return;
      }
      editingUserEnvData.value.set(previewUserEnvData.value.key, previewUserEnvData.value.value);
      previewUserEnvData.value = undefined;
    };

    return {
      getPreviewUserEnvData,
      setPreviewUserEnvData,
      clearPreviewUserEnvData,
      syncEditingUserEnvData,
    };
  };

  return {
    ...useEditingUserEnv(),
    ...usePreviewUserEnv(),
  };
});
