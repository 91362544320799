<script setup lang="ts">
import { type Props, setup } from './rightPanelSubMenuElement.setup';

const props = defineProps<Props>();

const { onClickElement } = setup(props);
</script>

<template>
  <li
    :class="['right-panel-element sub-menu-element', { selected }]"
    @click="onClickElement"
  >
    <ev-icon
      v-if="menuInfo.meta?.prefixIcon"
      :icon="menuInfo.meta.prefixIcon"
    />
    <div class="sub-menu-element__inner-text right-panel-element__inner-text">
      <span>
        {{ menuInfo.text }}
      </span>
      <ev-icon
        v-if="menuInfo.meta?.icon"
        :icon="menuInfo.meta.icon"
      />
    </div>
  </li>
</template>

<style lang="scss">
.right-panel-element {
  &__inner-text {
    justify-content: space-between;
    width: 100%;

    i {
      width: 16px;
      height: 16px;
      font-size: 16px;
      color: var(--gnb-text-color);

      &:not([class^='ev-']) {
        background-color: var(--gnb-text-color);
      }
    }
  }
}
</style>
