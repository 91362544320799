import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  addXmUserEnvControllerAxios,
  getByKeysXmUserEnvControllerAxios,
  removeXmUserEnvControllerAxios,
} from '@/openapi/metav8Over/api/xm-user-env-controller-api';
import { UserEnvValue } from '@/openapi/metav8Over/model';

const parseValue = (val?: string) => {
  if (!val) {
    return val;
  }

  try {
    return JSON.parse(val);
  } catch (e) {
    return val;
  }
};

export const useUserEnvStore = defineStore('userEnvStore', () => {
  const userEnvMap = ref<Map<string, string>>(new Map());

  const fetchAllEnvData = (data?: UserEnvValue[]) => {
    if (Array.isArray(data)) {
      userEnvMap.value = new Map(
        data.filter((item) => !!item.key && !!item.value).map((item) => [item.key!, item.value!]),
      );
    } else {
      userEnvMap.value.clear();
    }
  };

  const addNewEnvData = (data?: UserEnvValue[]) => {
    data?.forEach((item) => {
      if (item.key) {
        userEnvMap.value.set(item.key, item.value ?? '');
      }
    });
  };

  const fetchEnvDataByKeys = async (keys: string[], global = false) => {
    try {
      const { data } = await getByKeysXmUserEnvControllerAxios({ keys, global });
      if (data?.data?.length) {
        data.data.forEach((item) => {
          if (item.key) {
            userEnvMap.value.set(item.key, item.value ?? '');
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const clearUserEnvValue = async (key: string, all = false, global = false) => {
    await removeXmUserEnvControllerAxios({ key, all, global });
  };

  const setEnvValue = async <T extends Record<string, any> | string>(
    newEnv: {
      key: string;
      value: T;
    }[],
    global = false,
  ) => {
    const convertedValue = newEnv.map(({ key, value }) => ({
      key,
      value: typeof value === 'string' ? value : JSON.stringify(value),
    }));
    try {
      await addXmUserEnvControllerAxios({
        request: convertedValue,
        global,
      });
      convertedValue.forEach(({ key, value }) => {
        userEnvMap.value.set(key, value);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getEnvValue = <T>(key?: string): T | null => {
    return key ? parseValue(userEnvMap.value.get(key)) ?? null : null;
  };

  const getEnvValues = (keys: string[]): { [key in string]: any | null }[] => {
    return keys.map((key) => ({ [key]: parseValue(userEnvMap.value.get(key)) ?? null }));
  };

  const getEnvValueByPattern = (word: string): { [key in string]: any | null } => {
    const result: { [key in string]: any | null } = {};
    userEnvMap.value.forEach((value, key) => {
      if (key.includes(word)) {
        result[key] = parseValue(value) ?? null;
      }
    });
    return result;
  };

  const initUserEnvMap = () => {
    userEnvMap.value.clear();
  };

  return {
    fetchAllEnvData,
    addNewEnvData,
    fetchEnvDataByKeys,
    clearUserEnvValue,
    setEnvValue,
    getEnvValue,
    getEnvValues,
    getEnvValueByPattern,
    initUserEnvMap,
  };
});
