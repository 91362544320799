import { OrderBy } from './order-by';

export const AwsDataRequestIntervalEnum = {
  I100ms: 'I100ms',
  I10m: 'I10m',
  I10s: 'I10s',
  I15m: 'I15m',
  I15s: 'I15s',
  I1d: 'I1d',
  I1h: 'I1h',
  I1m: 'I1m',
  I1s: 'I1s',
  I20m: 'I20m',
  I24h: 'I24h',
  I2h: 'I2h',
  I30m: 'I30m',
  I30s: 'I30s',
  I3m: 'I3m',
  I3s: 'I3s',
  I5d: 'I5d',
  I5h: 'I5h',
  I5m: 'I5m',
  I5s: 'I5s',
  None: 'none',
} as const;

export type AwsDataRequestInterval =
  (typeof AwsDataRequestIntervalEnum)[keyof typeof AwsDataRequestIntervalEnum];
export const AwsDataRequestPeriodEnum = {
  P10m: 'p10m',
  P10s: 'p10s',
  P12h: 'p12h',
  P1d: 'p1d',
  P1h: 'p1h',
  P1m: 'p1m',
  P1s: 'p1s',
  P30m: 'p30m',
  P30s: 'p30s',
  P3h: 'p3h',
  P3m: 'p3m',
  P3s: 'p3s',
  P5d: 'p5d',
  P5m: 'p5m',
  P5s: 'p5s',
  P6h: 'p6h',
  P6m: 'p6m',
} as const;

export type AwsDataRequestPeriod =
  (typeof AwsDataRequestPeriodEnum)[keyof typeof AwsDataRequestPeriodEnum];
export const AwsDataRequestServiceEnum = {
  Aurora: 'AURORA',
  Documentdb: 'DOCUMENTDB',
  Dynamodb: 'DYNAMODB',
  Ec2: 'EC2',
  ElasticacheCluster: 'ELASTICACHE_CLUSTER',
  ElasticacheNode: 'ELASTICACHE_NODE',
  ElasticacheServerless: 'ELASTICACHE_SERVERLESS',
  ElasticacheShard: 'ELASTICACHE_SHARD',
  Lambda: 'LAMBDA',
  LightsailDatabase: 'LIGHTSAIL_DATABASE',
  LightsailInstance: 'LIGHTSAIL_INSTANCE',
  Rds: 'RDS',
  Redshift: 'REDSHIFT',
  S3: 'S3',
  Vpc: 'VPC',
} as const;

export type AwsDataRequestService =
  (typeof AwsDataRequestServiceEnum)[keyof typeof AwsDataRequestServiceEnum];
export const AwsDataRequestStatEnum = {
  Average: 'AVERAGE',
  Max: 'MAX',
  Min: 'MIN',
  SampleCount: 'SAMPLE_COUNT',
  Sum: 'SUM',
} as const;

export type AwsDataRequestStat =
  (typeof AwsDataRequestStatEnum)[keyof typeof AwsDataRequestStatEnum];
export const AwsDataRequestTimezoneEnum = {
  AfricaCairo: 'Africa_Cairo',
  AmericaArgentinaBuenosAires: 'America_Argentina_BuenosAires',
  AmericaCaracas: 'America_Caracas',
  AmericaChicago: 'America_Chicago',
  AmericaGlaceBay: 'America_GlaceBay',
  AmericaNewYork: 'America_New_York',
  AmericaNome: 'America_Nome',
  AmericaNoronha: 'America_Noronha',
  AmericaPhoenix: 'America_Phoenix',
  AmericaStJohns: 'America_StJohns',
  AmericaTijuana: 'America_Tijuana',
  AsiaAnadyr: 'Asia_Anadyr',
  AsiaBangkok: 'Asia_Bangkok',
  AsiaDhaka: 'Asia_Dhaka',
  AsiaKabul: 'Asia_Kabul',
  AsiaKathmandu: 'Asia_Kathmandu',
  AsiaKolkata: 'Asia_Kolkata',
  AsiaRangoon: 'Asia_Rangoon',
  AsiaSeoul: 'Asia_Seoul',
  AsiaShanghai: 'Asia_Shanghai',
  AsiaTashkent: 'Asia_Tashkent',
  AsiaYerevan: 'Asia_Yerevan',
  AtlanticAzores: 'Atlantic_Azores',
  AustraliaAdelaide: 'Australia_Adelaide',
  AustraliaBrisbane: 'Australia_Brisbane',
  AustraliaDarwin: 'Australia_Darwin',
  EuropeBerlin: 'Europe_Berlin',
  EuropeLondon: 'Europe_London',
  EuropeMoscow: 'Europe_Moscow',
  PacificApia: 'Pacific_Apia',
  PacificHonolulu: 'Pacific_Honolulu',
  PacificMidway: 'Pacific_Midway',
  PacificNoumea: 'Pacific_Noumea',
} as const;

export type AwsDataRequestTimezone =
  (typeof AwsDataRequestTimezoneEnum)[keyof typeof AwsDataRequestTimezoneEnum];
export const AwsDataRequestTotalTypeEnum = {
  Average: 'AVERAGE',
  Count: 'COUNT',
  Max: 'MAX',
  Sum: 'SUM',
} as const;

export type AwsDataRequestTotalType =
  (typeof AwsDataRequestTotalTypeEnum)[keyof typeof AwsDataRequestTotalTypeEnum];

export interface AwsDataRequest {
  dataName?: string;
  interval?: AwsDataRequestInterval;
  limit?: number;
  orderBy?: OrderBy;
  parameter?: Array<string>;
  period?: AwsDataRequestPeriod;
  service?: Array<AwsDataRequestService>;
  stat?: AwsDataRequestStat;
  timezone?: AwsDataRequestTimezone;
  totalType?: AwsDataRequestTotalType;
}
