<script setup lang="ts">
import { setup } from './productionModuleInfo.setup';

const {
  t,

  moduleInfo,
} = setup();
</script>

<template>
  <li class="module-info">
    <div class="module-info__title">
      <span>{{ t('WORD.GNB.HELP.MODULE_VERSION') }} : </span>
    </div>
    <div class="module-info__list">
      <div class="module-info__item-group">
        <span
          v-for="moduleName in moduleInfo"
          :key="moduleName"
        >
          {{ moduleName }}
        </span>
      </div>
    </div>
  </li>
</template>

<style scoped lang="scss">
.module-info {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 12px 6px 12px 8px;

  &__list {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
  }

  &__item-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
    user-select: text;
  }

  span {
    white-space: nowrap;
  }
}
</style>
