export const DATABASE = {
  GRIDCOLUMNS_database_instance_instanceList: 'GRIDCOLUMNS_database_instance_instanceList',
  GRIDCOLUMNS_database_instanceDetail_clientList: 'GRIDCOLUMNS_database_instanceDetail_clientList',
  GRIDCOLUMNS_database_instanceDetail_commandStat:
    'GRIDCOLUMNS_database_instanceDetail_commandStat',
  GRIDCOLUMNS_database_instanceDetail_waitClass: 'GRIDCOLUMNS_database_instanceDetail_waitClass',
  GRIDCOLUMNS_database_instanceDetail_waitEvent: 'GRIDCOLUMNS_database_instanceDetail_waitEvent',
  GRIDCOLUMNS_database_instanceDetail_waitSqlList:
    'GRIDCOLUMNS_database_instanceDetail_waitSqlList',
  GRIDCOLUMNS_database_instanceDetail_parameterList:
    'GRIDCOLUMNS_database_instanceDetail_parameterList',
  GRIDCOLUMNS_database_instanceDetail_log: 'GRIDCOLUMNS_database_instanceDetail_log',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_list:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_list',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_set:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_set',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_sortedSet:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_sortedSet',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_streamData:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_streamData',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_streamConsumerGroups:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_streamConsumerGroups',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_hash:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_hash',
  FILTERSEARCH_database_instanceDetail_keyBrowser_list:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_list',
  FILTERSEARCH_database_instanceDetail_keyBrowser_set:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_set',
  FILTERSEARCH_database_instanceDetail_keyBrowser_sortedSet:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_sortedSet',
  FILTERSEARCH_database_instanceDetail_keyBrowser_streamData:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_streamData',
  FILTERSEARCH_database_instanceDetail_keyBrowser_streamConsumerGroups:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_streamConsumerGroups',
  FILTERSEARCH_database_instanceDetail_keyBrowser_hash:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_hash',
  GRIDCOLUMNS_database_mysqlInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_mysqlInstanceDetail_activeSession',
  GRIDCOLUMNS_database_oracleInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_oracleInstanceDetail_activeSession',
  GRIDCOLUMNS_database_postgresqlInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_postgresqlInstanceDetail_activeSession',
  GRIDCOLUMNS_database_sqlserverInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_sqlserverInstanceDetail_activeSession',
  GRIDCOLUMNS_database_cubridInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_cubridInstanceDetail_activeSession',
  GRIDCOLUMNS_database_mongodbInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_mongodbInstanceDetail_activeSession',
  GRIDCOLUMNS_database_mysqlInstanceDetail_sqlList:
    'GRIDCOLUMNS_database_mysqlInstanceDetail_sqlList',
  GRIDCOLUMNS_database_oracleInstanceDetail_sqlList:
    'GRIDCOLUMNS_database_oracleInstanceDetail_sqlList',
  GRIDCOLUMNS_database_postgresqlInstanceDetail_sqlList:
    'GRIDCOLUMNS_database_postgresqlInstanceDetail_sqlList',
  GRIDCOLUMNS_database_sqlserverInstanceDetail_sqlList:
    'GRIDCOLUMNS_database_sqlserverInstanceDetail_sqlList',
  GRIDCOLUMNS_database_mysqlInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_mysqlInstanceDetail_lockInfo',
  GRIDCOLUMNS_database_oracleInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_oracleInstanceDetail_lockInfo',
  GRIDCOLUMNS_database_postgresqlInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_postgresqlInstanceDetail_lockInfo',
  GRIDCOLUMNS_database_sqlserverInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_sqlserverInstanceDetail_lockInfo',
  GRIDCOLUMNS_database_cubridInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_cubridInstanceDetail_lockInfo',
  GRIDCOLUMNS_database_instanceDetail_cloudEvent: 'GRIDCOLUMNS_database_instanceDetail_cloudEvent',
  GRIDCOLUMNS_database_clusterDetail_clusterMap: 'GRIDCOLUMNS_database_clusterDetail_clusterMap',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser: 'GRIDCOLUMNS_database_instanceDetail_keyBrowser',
  GRIDCOLUMNS_database_mysqlSessionDetail_activeSessionHistory:
    'GRIDCOLUMNS_database_mysqlSessionDetail_activeSessionHistory',
  GRIDCOLUMNS_database_mysqlSessionDetail_plan: 'GRIDCOLUMNS_database_mysqlSessionDetail_plan',
  GRIDCOLUMNS_database_oracleSessionDetail_activeSessionHistory:
    'GRIDCOLUMNS_database_oracleSessionDetail_activeSessionHistory',
  GRIDCOLUMNS_database_oracleSessionDetail_plan: 'GRIDCOLUMNS_database_oracleSessionDetail_plan',
  GRIDCOLUMNS_database_postgresqlSessionDetail_activeSessionHistory:
    'GRIDCOLUMNS_database_postgresqlSessionDetail_activeSessionHistory',
  GRIDCOLUMNS_database_postgresqlSessionDetail_plan:
    'GRIDCOLUMNS_database_postgresqlSessionDetail_plan',
  GRIDCOLUMNS_database_sqlserverSessionDetail_activeSessionHistory:
    'GRIDCOLUMNS_database_sqlserverSessionDetail_activeSessionHistory',
  GRIDCOLUMNS_database_sqlserverSessionDetail_plan:
    'GRIDCOLUMNS_database_sqlserverSessionDetail_plan',
  GRIDCOLUMNS_database_mysqlSqlDetail_plan: 'GRIDCOLUMNS_database_mysqlSqlDetail_plan',
  GRIDCOLUMNS_database_mysqlSqlDetail_sqlHistory: 'GRIDCOLUMNS_database_mysqlSqlDetail_sqlHistory',
  GRIDCOLUMNS_database_oracleSqlDetail_plan: 'GRIDCOLUMNS_database_oracleSqlDetail_plan',
  GRIDCOLUMNS_database_oracleSqlDetail_sqlHistory:
    'GRIDCOLUMNS_database_oracleSqlDetail_sqlHistory',
  GRIDCOLUMNS_database_postgresqlSqlDetail_plan: 'GRIDCOLUMNS_database_postgresqlSqlDetail_plan',
  GRIDCOLUMNS_database_postgresqlSqlDetail_sqlHistory:
    'GRIDCOLUMNS_database_postgresqlSqlDetail_sqlHistory',
  GRIDCOLUMNS_database_sqlserverSqlDetail_plan: 'GRIDCOLUMNS_database_sqlserverSqlDetail_plan',
  GRIDCOLUMNS_database_sqlserverSqlDetail_sqlHistory:
    'GRIDCOLUMNS_database_sqlserverSqlDetail_sqlHistory',
  GRIDCOLUMNS_database_slowQueryDetail_plan: 'GRIDCOLUMNS_database_slowQueryDetail_plan',
  GRIDCOLUMNS_database_oracleMultiview_sessionTab_sessions:
    'GRIDCOLUMNS_database_oracleMultiview_sessionTab_sessions',
  GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitChains:
    'GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitChains',
  GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitClass:
    'GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitClass',
  GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitEvent:
    'GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitEvent',
  GRIDCOLUMNS_database_oracleMultiview_sessionTab_sqlSummary:
    'GRIDCOLUMNS_database_oracleMultiview_sessionTab_sqlSummary',
  GRIDCOLUMNS_database_oracleMultiview_sessionTab_event:
    'GRIDCOLUMNS_database_oracleMultiview_sessionTab_event',
  GRIDCOLUMNS_database_oracleRtm_sessionDetail_plan:
    'GRIDCOLUMNS_database_oracleRtm_sessionDetail_plan',
  GRIDCOLUMNS_database_postgresqlRtm_topObjectWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_topObjectWindow',
  GRIDCOLUMNS_database_postgresqlRtm_topSqlWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_topSqlWindow',
  GRIDCOLUMNS_database_postgresqlRtm_fileUsageWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_fileUsageWindow',
  GRIDCOLUMNS_database_postgresqlRtm_sessionDetail_plan:
    'GRIDCOLUMNS_database_postgresqlRtm_sessionDetail_plan',
  GRIDCOLUMNS_database_postgresqlRtm_lockTree: 'GRIDCOLUMNS_database_postgresqlRtm_lockTree',
  GRIDCOLUMNS_database_postgresqlRtm_activeBackends:
    'GRIDCOLUMNS_database_postgresqlRtm_activeBackends',
  GRIDCOLUMNS_database_postgresqlRtm_vacuumingBackendsWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_vacuumingBackendsWindow',
  GRIDCOLUMNS_database_postgresqlRtm_parameterWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_parameterWindow',
  GRIDCOLUMNS_database_postgresqlRtm_topObjectTablespaceWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_topObjectTablespaceWindow',
  GRIDCOLUMNS_database_postgresqlRtm_oldStatisticsWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_oldStatisticsWindow',
  GRIDCOLUMNS_database_postgresqlRtm_slowQueryWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_slowQueryWindow',
  GRIDCOLUMNS_database_postgresqlRtm_sqlElapsedWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_sqlElapsedWindow',
  GRIDCOLUMNS_database_postgresqlRtm_rank: 'GRIDCOLUMNS_database_postgresqlRtm_rank',
  GRIDCOLUMNS_database_mysqlRtm_topObjectWindow: 'GRIDCOLUMNS_database_mysqlRtm_topObjectWindow',
  GRIDCOLUMNS_database_mysqlRtm_globalTempWindow: 'GRIDCOLUMNS_database_mysqlRtm_globalTempWindow',
  GRIDCOLUMNS_database_mysqlRtm_sessionTempWindow:
    'GRIDCOLUMNS_database_mysqlRtm_sessionTempWindow',
  GRIDCOLUMNS_database_mysqlRtm_topSqlWindow: 'GRIDCOLUMNS_database_mysqlRtm_topSqlWindow',
  GRIDCOLUMNS_database_mysqlRtm_topTransactionWindow:
    'GRIDCOLUMNS_database_mysqlRtm_topTransactionWindow',
  GRIDCOLUMNS_database_mysqlRtm_slowQueryWindow_plan:
    'GRIDCOLUMNS_database_mysqlRtm_slowQueryWindow_plan',
  GRIDCOLUMNS_database_mysqlRtm_thread: 'GRIDCOLUMNS_database_mysqlRtm_thread',
  GRIDCOLUMNS_database_mysqlRtm_lockTree: 'GRIDCOLUMNS_database_mysqlRtm_lockTree',
  GRIDCOLUMNS_database_mysqlRtm_rank: 'GRIDCOLUMNS_database_mysqlRtm_rank',
  GRIDCOLUMNS_database_mysql_sessionDetailWindow_plan:
    'GRIDCOLUMNS_database_mysql_sessionDetailWindow_plan',
  GRIDCOLUMNS_database_mysqlRtm_topEventWindow: 'GRIDCOLUMNS_database_mysqlRtm_topEventWindow',
  GRIDCOLUMNS_database_mysqlRtm_topSqlErrorWindow:
    'GRIDCOLUMNS_database_mysqlRtm_topSqlErrorWindow',
  GRIDCOLUMNS_database_mysqlRtm_topFullScanWindow:
    'GRIDCOLUMNS_database_mysqlRtm_topFullScanWindow',
  GRIDCOLUMNS_database_mysqlRtm_parameterWindow: 'GRIDCOLUMNS_database_mysqlRtm_parameterWindow',
  GRIDCOLUMNS_database_mysqlRtm_topTablespaceWindow:
    'GRIDCOLUMNS_database_mysqlRtm_topTablespaceWindow',
  GRIDCOLUMNS_database_rtm_alertLogs: 'GRIDCOLUMNS_database_rtm_alertLogs',
  GRIDCOLUMNS_database_mysqlRtm_slowQueryWindow: 'GRIDCOLUMNS_database_mysqlRtm_slowQueryWindow',
  GRIDCOLUMNS_database_mysqlRtm_sqlElapsedWindow: 'GRIDCOLUMNS_database_mysqlRtm_sqlElapsedWindow',
  GRIDCOLUMNS_database_sqlserverRtm_sessionListWindow:
    'GRIDCOLUMNS_database_sqlserverRtm_sessionListWindow',
  GRIDCOLUMNS_database_sqlserverRtm_sqlStatWindow:
    'GRIDCOLUMNS_database_sqlserverRtm_sqlStatWindow',
  GRIDCOLUMNS_database_sqlserverRtm_sessionTab_session:
    'GRIDCOLUMNS_database_sqlserverRtm_sessionTab_session',
  GRIDCOLUMNS_database_sqlserverRtm_sessionTab_blocked:
    'GRIDCOLUMNS_database_sqlserverRtm_sessionTab_blocked',
  GRIDCOLUMNS_database_sqlserverRtm_sessionManager_session:
    'GRIDCOLUMNS_database_sqlserverRtm_sessionManager_session',
  GRIDCOLUMNS_database_sqlserverRtm_sessionManager_blocked:
    'GRIDCOLUMNS_database_sqlserverRtm_sessionManager_blocked',
  GRIDCOLUMNS_database_sqlserverRtm_session: 'GRIDCOLUMNS_database_sqlserverRtm_session',
  GRIDCOLUMNS_database_sqlserverRtm_blocked: 'GRIDCOLUMNS_database_sqlserverRtm_blocked',
  GRIDCOLUMNS_database_mongos_clusterMap: 'GRIDCOLUMNS_database_mongos_clusterMap',
  GRIDCOLUMNS_database_cubrid_broker_activeCasList:
    'GRIDCOLUMNS_database_cubrid_broker_activeCasList',
  FILTER_database_instanceView: 'FILTER_database_instanceView',
  DATABASE_INSTANCE_DETAIL_DASHBOARD_SETTING_LIST:
    'DATABASE_INSTANCE_DETAIL_DASHBOARD_SETTING_LIST',
  DATABASE_BROKER_DETAIL_DASHBOARD_SETTING_LIST: 'DATABASE_BROKER_DETAIL_DASHBOARD_SETTING_LIST',
  DATABASE_INSTANCE_CLUSTER_DETAIL_DASHBOARD_SETTING_LIST:
    'DATABASE_INSTANCE_CLUSTER_DETAIL_DASHBOARD_SETTING_LIST',
  FILTERSEARCH_database_instanceView_instanceList:
    'FILTERSEARCH_database_instanceView_instanceList',
  FILTERSEARCH_database_instanceDetail_keyBrowser:
    'FILTERSEARCH_database_instanceDetail_keyBrowser',
  FILTERSEARCH_database_instanceDetail_keyBrowserScanMatch:
    'FILTERSEARCH_database_instanceDetail_keyBrowserScanMatch',
};
