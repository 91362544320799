import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { MetricGroupValue } from '@/openapi/meta/model';
import { MetricRequest, Nullable } from '@/common/utils/types';
import { MetricResponse, MetricResponseItem } from '@/openapi/data/model';
import { getByUserIdAndTenantIdMetricGroupV7ControllerAxios } from '@/openapi/common/api/metric-group-v7-controller-api';
import { getMetricData } from '@/common/api/data';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';

type MetricGroup = Nullable<MetricGroupValue>;

export interface State {
  metricGroup: MetricGroup[];
  dbMetricInfo: MetricResponseItem[][];
  previewDbMetricInfo: MetricResponseItem[][];
  errorStatusText: Record<string, string>;
}

const FRAME_NAME = FRAME_NAMES.DB_METRIC.METRIC;
export const metric: Module<State, RootState> = {
  namespaced: true,
  state: {
    metricGroup: [],
    dbMetricInfo: [],
    previewDbMetricInfo: [],
    errorStatusText: {},
  },
  mutations: {
    initPreviewDbMetricInfo: (state: State) => {
      state.previewDbMetricInfo = [];
    },
    initDbMetricInfo: (state: State) => {
      state.dbMetricInfo = [];
    },
    setMetricGroup(state: State, metricGroup: MetricGroup[]) {
      const DEFAULT_GROUP: MetricGroup[] = [
        {
          metricGroupName: 'Logical + Physical IO',
          type: 'mysql',
          createdTime: null,
          metricNames: [
            'db_mysql_innodb_buffer_pool_reads',
            'db_mysql_innodb_buffer_pool_read_requests',
          ],
        },
      ];
      state.metricGroup = [...DEFAULT_GROUP, ...metricGroup];
    },
    setDbMetricInfo: (state: State, data: MetricResponseItem[][]) => {
      state.dbMetricInfo = data;
    },
    setPreviewDbMetricInfo: (state: State, data: MetricResponseItem[][]) => {
      state.previewDbMetricInfo = data;
    },
    setErrorStatusText: (
      state: State,
      { metricName, statusText }: { metricName: string; statusText: string },
    ) => {
      state.errorStatusText[metricName] = statusText;
    },
  },
  actions: {
    fetchMetricGroup: async ({ commit }) => {
      const { data } = await getByUserIdAndTenantIdMetricGroupV7ControllerAxios({} as any);
      commit('setMetricGroup', data.data);
    },
    fetchDbMetricInfo: async (
      { commit },
      {
        metricInfo,
        instanceIds,
      }: { metricInfo: { metricName: string; interval: string }[]; instanceIds: string[] },
    ) => {
      try {
        const deduplicationMetricInfo = metricInfo.filter(
          ({ metricName }, idx, src) =>
            src.findIndex((val) => val.metricName === metricName) === idx,
        );

        const requests: MetricRequest[] = [];
        const convertedData: MetricResponse[] = [];

        // TODO: 수집주기(interval) 관련해서 확인 필요
        const oneMinuteInterval = deduplicationMetricInfo.filter(
          ({ interval }) => interval === '1m0s',
        );
        if (oneMinuteInterval.length) {
          oneMinuteInterval.forEach(({ metricName }) => {
            requests.push({
              aggregationType: 'byTarget',
              period: 'p1h',
              interval: 'I1m',
              category: 'mysql',
              dataId: metricName,
              interpolateType: 'Null',
              summaryType: 'current',
              targetIds: instanceIds,
            });
          });
        }

        const others = deduplicationMetricInfo.filter(({ interval }) => interval !== '1m0s');
        if (others.length) {
          others.forEach(({ metricName }) => {
            requests.push({
              aggregationType: 'byTarget',
              period: 'p3m',
              interval: 'I5s',
              category: 'mysql',
              interpolateType: 'Linear',
              dataId: metricName,
              summaryType: 'current',
              targetIds: instanceIds,
            });
          });
        }

        const promises = requests.map((request) =>
          getMetricData({
            metricV7Requests: [request],
            frameName: `${FRAME_NAME}/${request.dataId}`,
            isPolling: true,
          }),
        );

        const results = await Promise.allSettled(promises);
        results.forEach((result, idx) => {
          let statusText = '';
          const frameName = `${FRAME_NAME}/${requests[idx].dataId}`;

          if (result.status === 'fulfilled') {
            const { data } = result.value.data;
            const { status, reason } = data?.[0] || {};
            convertedData[idx] = data?.[0] || {};

            if (status === 'success') {
              commit('setErrorStatusText', {
                metricName: requests[idx].dataId,
                statusText: '',
              });
              commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
              commit('mysqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });

              return;
            }

            statusText = reason;
          }

          if (result.status === 'rejected') {
            statusText = getAPIErrorStatusText(result.reason);
          }

          commit('setErrorStatusText', {
            metricName: requests[idx].dataId,
            statusText,
          });
          commit(
            'mysqlSingleViewEnv/setFramesByFailedApi',
            { frameName, statusText },
            { root: true },
          );
          commit(
            'mysqlMultiViewEnv/setFramesByFailedApi',
            { frameName, statusText },
            { root: true },
          );
        });

        return convertedData;
      } catch (e: any) {
        return [];
      }
    },
  },
  getters: {
    getMetricGroup: (state: State) => state.metricGroup,
    getDbMetricInfo: (state: State): MetricResponseItem[][] => state.dbMetricInfo,
    getPreviewDbMetricInfo: (state: State): MetricResponseItem[][] => state.previewDbMetricInfo,
    getErrorStatusText: (state: State): Record<string, string> => state.errorStatusText,
    getMetricParams: (state, getters, rootState, rootGetters) => {
      const allMetricNames = rootGetters['dbMetric/getMetricNames'];
      const metricNames: string[] = rootGetters['mysqlMultiViewEnv/getDbMetricNames'];

      return metricNames.map((metricName) => ({
        metricName,
        interval:
          allMetricNames.find((target) => target.metricName === metricName)?.interval ?? '5s',
      }));
    },
  },
};
