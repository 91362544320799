import { KUBERNETES } from '@/common/define/userEnv/kubernetes.env.define';
import { ALERT } from '@/common/define/userEnv/alert.env.define';
import { DATABASE } from '@/common/define/userEnv/database.env.define';
import { INFRASTRUCTURE } from '@/common/define/userEnv/infrastructure.env.define';
import { APPLICATION } from '@/common/define/userEnv/application.env.define';
import { BUSINESS } from '@/common/define/userEnv/business.env.define';
import { LOG } from '@/common/define/userEnv/log.env.define';
import { REPORT } from '@/common/define/userEnv/report.env.define';
import { PA } from '@/common/define/userEnv/performanceAnalysis.env.define';
import { CONFIG } from '@/common/define/userEnv/config.env.define';
import { DASHBOARD } from './userEnv/dashboard.env.define';
import { HELP } from './userEnv/help.env.define';
import { CLOUD } from './userEnv/cloud.env.define';

export const USER_ENV_KEYS = {
  ...INFRASTRUCTURE,
  ...KUBERNETES,
  ...CLOUD,
  ...APPLICATION,
  ...DATABASE,
  ...BUSINESS,
  ...LOG,
  ...ALERT,
  ...PA,
  ...REPORT,
  ...CONFIG,
  ...DASHBOARD,
  ...HELP,
  ...CLOUD,
};
