import { computed, ref } from 'vue';
import { useInternational } from '@/common/locale';
import { useRoute } from 'vue-router';
import { capitalize } from 'lodash-es';
import { useSimpleTextTooltip } from '../simpleTextTooltip/simpleTextTooltip.uses';

interface StatusInfoType {
  status: string;
  type?: string;
}

export interface Props {
  statusInfo: StatusInfoType;
}

const STATUS = {
  GREEN: ['AVAILABLE'],
  BLUE: ['ACTIVE', 'RUNNING', 'OK'],
  RED: [
    'TERMINATED',
    'FAILED',
    'HARDWARE_FAILURE',
    'INCOMPATIBLE_HSM',
    'PAUSED',
    'STORAGE_FULL',
    'INACCESSIBLE_ENCRYPTION_CREDENTIALS_RECOVERABLE',
    'INACCESSIBLE_ENCRYPTION_CREDENTIALS',
    'INCOMPATIBLE_NETOWRK',
    'INCOMPATIBLE_OPTION_GROUP',
    'INCOMPATIBLE_PARAMETERS',
    'INCOMPATIBLE_RESTORE',
    'INSUFFICIENT_CAPACITY',
    'MAINTENANCE',
    'RESTORE_ERROR',
    'STOPPED',
    'DELETED',
    'RESTORE_FAILED_OR_SNAPSHOTTING',
    'ALARM',
  ],
  GRAY: ['INSUFFICIENT_DATA'],
};

export const setup = (props: Props) => {
  const { t } = useInternational();
  const route = useRoute();

  const statusTooltipMouseEvent = ref<MouseEvent | null>(null);
  const { onMouseEventInTarget } = useSimpleTextTooltip(statusTooltipMouseEvent);
  const statusTooltipInfo = ref<{ name: string; description: string }>({
    name: '',
    description: '',
  });

  const formattedStatus = computed(() => {
    const { status } = props.statusInfo;
    if (!status) return '';
    return status
      .replace(/[\s,_(]/g, '_')
      .replace(/\)/g, '')
      .replace(/-+/g, '_')
      .toUpperCase();
  });

  const statusToClass = computed(() => {
    if (STATUS.GREEN.includes(formattedStatus.value)) return 'green';
    if (STATUS.BLUE.includes(formattedStatus.value)) return 'blue';
    if (STATUS.RED.includes(formattedStatus.value)) return 'red';
    if (STATUS.GRAY.includes(formattedStatus.value)) return 'gray';
    return 'yellow';
  });

  const getTranslationKey = (status: StatusInfoType) => {
    const currentUrlSegments = route.path.split('/');
    let key = 'DESC.TAG.';

    if (
      formattedStatus.value === 'OK' ||
      formattedStatus.value === 'ALARM' ||
      formattedStatus.value === 'INSUFFICIENT_DATA'
    ) {
      return `${key}CLOUD_WATCH_ALARM.${formattedStatus.value}`;
    }

    if (currentUrlSegments[1] === 'cloud') {
      key += `${currentUrlSegments[3].replace(/-/g, '_')}${status.type ? `.${status.type}` : ''}.`;
    }

    return `${key.toUpperCase()}${formattedStatus.value}`;
  };

  const onMouseStatus = ({
    statusInfo = { status: '', type: '' },
    e,
  }: {
    statusInfo?: StatusInfoType;
    e: MouseEvent;
  }) => {
    if (statusInfo.status !== '') {
      const translationKey = getTranslationKey(statusInfo);
      const translation = t(translationKey);

      statusTooltipInfo.value.name = capitalize(statusInfo.status);
      statusTooltipInfo.value.description = translation === translationKey ? '' : translation;
    }

    onMouseEventInTarget(e);
  };

  return {
    statusToClass,
    statusTooltipMouseEvent,
    onMouseStatus,
    statusTooltipInfo,
  };
};
