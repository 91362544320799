import { storeToRefs } from 'pinia';
import { useTopBannerStore } from '@/common/stores/top-banner';

export const setup = () => {
  const topBannerStore = useTopBannerStore();
  const { bannerContent } = storeToRefs(topBannerStore);
  const { onClickCloseButton } = topBannerStore;

  return {
    bannerContent,

    onClickCloseButton,
  };
};
