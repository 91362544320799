import type { AxiosRequestConfig } from 'axios';
import { getHashedString } from '@/common/api/utils/utils';
import { cloneDeep, isPlainObject, omit } from 'lodash-es';
import { ERROR_TYPE, ErrorStateController } from '@/worker/commands/config/interceptors.types';

const apiCallSet = new Set<string>();

const getSortedObject = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};

const EXCEPTION_PARAMS = ['fromTime', 'toTime', 'lastTime'];
const getExceptionParams = (params: any) => {
  if (Array.isArray(params)) {
    return params.map((item) => {
      return getExceptionParams(item);
    });
  }
  if (isPlainObject(params)) {
    return getSortedObject(omit(cloneDeep(params), EXCEPTION_PARAMS));
  }
  return params;
};

const getParsedData = (data) => {
  try {
    const parsedData = JSON.parse(data);
    return typeof parsedData === 'object' ? parsedData : data;
  } catch (e) {
    return data;
  }
};

const getStringifyData = (data) => {
  if (!data) {
    return data;
  }
  if (Array.isArray(data)) {
    return JSON.stringify([...data]);
  }
  return isPlainObject(data) ? JSON.stringify({ ...data }) : data;
};

const getKey = (config: AxiosRequestConfig, apiKey: string): string | null => {
  const { data, params, url } = config ?? {};
  if (!url) {
    return null;
  }
  return getHashedString({
    url,
    data: getStringifyData(getExceptionParams(data)),
    params: getStringifyData(getExceptionParams(params)),
    apiKey,
  });
};

const getResponseConfig = (config: AxiosRequestConfig) => {
  const { data, params, url } = config ?? {};
  let parsedData = getParsedData(data);
  let parsedParams = getParsedData(params);

  if (typeof parsedData === 'object') {
    parsedData = getExceptionParams(parsedData);
  }

  if (typeof parsedParams === 'object') {
    parsedParams = getExceptionParams(parsedParams);
  }

  return {
    url,
    data: parsedData,
    params: parsedParams,
  } as AxiosRequestConfig;
};

export const apiController = {
  isAvailableRequest: (config: AxiosRequestConfig, frameName: string = ''): boolean => {
    const key = getKey(config, frameName);
    if (!key) {
      return false;
    }
    return !apiCallSet.has(key);
  },
  request: (config: AxiosRequestConfig, frameName: string = '') => {
    const key = getKey(config, frameName);
    if (key) {
      apiCallSet.add(key);
    }
  },
  response: (config: AxiosRequestConfig, frameName: string = '') => {
    const key = getKey(getResponseConfig(config), frameName);
    if (key) {
      apiCallSet.delete(key);
    }
  },
};

const errorState: ErrorStateController = {
  LOCKED_USER: {
    isLogout: false,
  },
};
export const getErrorState = <T extends ERROR_TYPE>(type: T): ErrorStateController[T] => {
  return errorState[type];
};
