import { ref } from 'vue';
import { defineStore } from 'pinia';
import { ViewType } from '@/common/utils/types';
import { USER_PROFILE_IMAGE, USER_PROFILE_IMAGE_DEFAULT } from '@/common/utils/define';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import {
  getAllByTenantUsersUserV7ControllerAxios,
  getAllGroupByUsersUserV7ControllerAxios,
  getGroupByUsersUserV7ControllerAxios,
} from '@/openapi/metaV6/api/user-v7-controller-api';
import { useInternational } from '@/common/locale';

export interface UserResponse {
  company?: string;
  department?: string;
  activeId?: string;
  email?: string;
  employeeId?: string;
  groupNames?: string;
  language?: string;
  locked?: boolean;
  name?: string;
  phone?: string;
  position?: string;
  profileImage?: string;
  slack?: string;
  timezone?: string;
  userId?: number;
  role?: number;
  rolePermissionName?: string;
  utcTimeOffset?: string;
  emailEnabled?: boolean;
  slackEnabled?: boolean;
  smsEnabled?: boolean;
  onSiteEnabled?: boolean;
}

export interface GroupListResponseItem {
  description?: string;
  groupId?: number;
  name?: string;
  users?: Array<UserResponse>;
  disableDelete?: {
    disable: boolean;
    tooltipMessage?: string;
  };
}

export const useUserGroupStore = defineStore('userGroup', () => {
  const { t } = useInternational();
  // LIST
  const searchWordOfGroupUsers = ref<string>('');
  const userGroups = ref<GroupListResponseItem[]>([]);
  const selectedUserGroupId = ref<number>(-1);
  const usersOfSelectedUserGroup = ref<UserResponse[]>([]);

  // WINDOW
  const isShowWindow = ref<boolean>(false);
  const initViewType = ref<ViewType>('view');
  const detailedUserGroupId = ref<number>(-1);
  const detailedUserGroupInfo = ref<GroupListResponseItem>({
    groupId: -1,
    name: '',
    description: '',
    users: [],
  });

  // Tenant 내 UserGroups 초기화
  const initUserGroups = () => {
    userGroups.value = [];
  };

  // userGroups 중에서 선택된 userGroup의 users 초기화
  const initUsersOfSelectedUserGroup = () => {
    usersOfSelectedUserGroup.value = [];
  };

  // 상세보기 선택된 UserGroup의 상세 정보 초기화
  const initDetailedUserGroupInfo = () => {
    detailedUserGroupInfo.value = {
      groupId: -1,
      name: '',
      description: '',
      users: [],
    };
  };

  // getGroupInfoAPI의 data 값을 userGroups에 세팅
  const setUserGroups = (newUserGroups: GroupListResponseItem[]) => {
    userGroups.value = newUserGroups.map((userGroupInfo) => {
      userGroupInfo.users = userGroupInfo.users!.map((userInfo) => {
        if (!USER_PROFILE_IMAGE.includes(userInfo.profileImage!)) {
          userInfo.profileImage = USER_PROFILE_IMAGE_DEFAULT;
        }
        return userInfo;
      });
      return {
        ...userGroupInfo,
        disableDelete: {
          disable: !!(userGroupInfo.users?.length > 0),
          tooltipMessage: t('MESSAGE.CANNOT_DELETE_USER_GROUP'),
        },
      };
    });
  };

  // 세팅된 userGroups의 배열에서 'ALL'인 groupId = -1 선택
  const setFirstUserGroupId = () => {
    selectedUserGroupId.value = -1;
  };

  // userGroups 중 userGroupId를 선택
  const setSelectedUserGroupId = (userGroupId: number) => {
    selectedUserGroupId.value = userGroupId;
  };

  // userGroups 중 상세보기 선택
  const setDetailedUserGroupId = (userGroupId: number) => {
    detailedUserGroupId.value = userGroupId;
  };

  const setInitViewType = (viewType: ViewType) => {
    initViewType.value = viewType;
  };

  // 선택된 userGroup 하위의 users값 세팅
  const setUsersOfSelectedUserGroup = (newUsersOfSelectedUserGroup: UserResponse[]) => {
    usersOfSelectedUserGroup.value = newUsersOfSelectedUserGroup.map((userInfo) => {
      if (!USER_PROFILE_IMAGE.includes(userInfo.profileImage!)) {
        userInfo.profileImage = USER_PROFILE_IMAGE_DEFAULT;
      }
      return userInfo;
    });
  };

  // userGroups에서 상세보기 팝업창 오픈 여부
  const setIsShowWindow = (isShow: boolean) => {
    isShowWindow.value = isShow;
  };

  // userGroups 중 상세보기로 선택된 userGroup 정보 세팅
  const setDetailedUserGroupInfo = (newDetailedUserGroupInfo: UserResponse) => {
    detailedUserGroupInfo.value = newDetailedUserGroupInfo;
  };

  // window의 viewType값 변경
  const setViewType = (viewType: ViewType) => {
    initViewType.value = viewType;
  };

  // 테넌트 내 모든 userGroups를 dispatch
  const dispatchUserGroups = async (frameName?: string) => {
    const { data } = await getAllByTenantUsersUserV7ControllerAxios({
      frameName: frameName ?? FRAME_NAMES.CONFIG_USER_GROUP.USER_GROUP_LIST,
    });
    const sortAllFirstData = data.data!.sort((a) => {
      if (a.groupId === -1) return -1;
      return 0;
    });
    setUserGroups(sortAllFirstData);
  };

  // 선택된 userGroup 하위의 users를 dispatch
  const dispatchUsersByUserGroup = async (userGroupId: number) => {
    const { data } = await getGroupByUsersUserV7ControllerAxios({
      groupId: userGroupId,
      frameName: FRAME_NAMES.CONFIG_USER_GROUP.USER_LIST,
    });
    setUsersOfSelectedUserGroup(data.data![0].users!);
  };

  // role 관계 없이 선택된 userGroup 하위의 모든 users를 dispatch
  const dispatchAllUsersByUserGroup = async (userGroupId: number, frameName?: string) => {
    const { data } = await getAllGroupByUsersUserV7ControllerAxios({
      groupId: userGroupId,
      frameName: frameName ?? FRAME_NAMES.CONFIG_USER_GROUP.USER_LIST,
    });
    setUsersOfSelectedUserGroup(data.data![0].users!);
  };

  // 선택된 userGroup의 상세 정보를 dispatch
  const dispatchUserGroupInfo = async (newDetailedUserGroupId: number) => {
    const { data } = await getGroupByUsersUserV7ControllerAxios({
      groupId: newDetailedUserGroupId,
      frameName: FRAME_NAMES.CONFIG_USER_GROUP_WINDOW.INFORMATION,
    });
    if (data.data?.length) {
      setDetailedUserGroupInfo(data.data[0]);
    } else {
      initDetailedUserGroupInfo();
    }
  };

  return {
    searchWordOfGroupUsers,
    userGroups,
    selectedUserGroupId,
    usersOfSelectedUserGroup,
    isShowWindow,
    initViewType,
    detailedUserGroupId,
    detailedUserGroupInfo,
    initUserGroups,
    initUsersOfSelectedUserGroup,
    initDetailedUserGroupInfo,
    setUserGroups,
    setFirstUserGroupId,
    setSelectedUserGroupId,
    setDetailedUserGroupId,
    setInitViewType,
    setUsersOfSelectedUserGroup,
    setIsShowWindow,
    setDetailedUserGroupInfo,
    setViewType,
    dispatchUserGroups,
    dispatchUsersByUserGroup,
    dispatchAllUsersByUserGroup,
    dispatchUserGroupInfo,
  };
});
