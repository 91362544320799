import dayjs from 'dayjs';

export const getYesterdayDate = (today: Date) => {
  return dayjs(today).subtract(1, 'day').toDate();
};

export const isSameDate = (date1: Date, date2: Date) => {
  const format = 'YYYY-MM-DD';
  return dayjs(date1).format(format) === dayjs(date2).format(format);
};
