/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { decodeGlobalVariableArgParam } from '@/dashboard/components/widgets/useCustomStat';
import { WIDGET_CHART_TYPE_KEY } from '@/dashboard/utils/define';
import { useRtmApi } from '@/common/utils/apiUtils';
import { getTargetAndColumnDataV7ControllerAxios } from '@/openapi/data/api/data-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import type { ArgParam } from '@/dashboard/components/widgets/useCustomStat';
import type { GridLayoutItem, WidgetChartType } from '@/dashboard/utils/types';
import type { TagsRequest, TagsRequestTagType } from '@/openapi/data/model';
import type { TargetKeyNameItem } from '@/openapi/dashboard/model/target-key-name-item';
import type { MetaStatV7Request } from '@/openapi/data/model/meta-stat-v7-request';
import type { ColumnItem } from '@/openapi/data/model/column-item';

type WidgetId = string;

export interface RequestTagWithWidgetInfo {
  widgetUUID: string;
  widgetName: string;
  category: string;
  targets: TagsRequest[];
  chartType: WidgetChartType;
  dataId?: string;
}

export interface AllDashboardTargetsColumns {
  widgetUUID: string;
  targets: TargetKeyNameItem[];
  tableColumns: ColumnItem[];
}

export const useDashboardTargetsStore = defineStore('dashboardTargets', () => {
  const allWidgetsByCurrentDashboard = ref<GridLayoutItem[]>([]);
  const allRequestTags = ref<RequestTagWithWidgetInfo[]>([]);
  const allDashboardTargetsColumns = ref<AllDashboardTargetsColumns[]>();
  const allDashboardCustomStats = ref<Map<WidgetId, ArgParam[] | undefined>>(new Map());

  const { callApi } = useRtmApi();

  const setAllWidgetsByCurrentDashboard = (widgets: GridLayoutItem[]) => {
    allWidgetsByCurrentDashboard.value = widgets;
  };

  const setAllRequestTags = (tags: RequestTagWithWidgetInfo[]) => {
    allRequestTags.value = tags;
  };

  const setAllDashboardTargetsColumns = (targets: AllDashboardTargetsColumns[]) => {
    allDashboardTargetsColumns.value = targets;
  };

  const extractWidgetCustomTargets = (
    widgetUUID: WidgetId,
    requestTags: RequestTagWithWidgetInfo[],
  ): TagsRequest[] => {
    const foundWidget = allDashboardCustomStats.value.get(widgetUUID);
    const extractedTags: TagsRequest[] = [];

    foundWidget?.forEach(({ targetIds }) => {
      const decodedTags = decodeGlobalVariableArgParam(targetIds?.value);

      decodedTags?.forEach(({ tagKey, tagType, tagValue }) => {
        const foundEmptyRequestTags = requestTags.find((tag) => {
          return tag.widgetUUID === widgetUUID && !tag.targets.length;
        });

        if (foundEmptyRequestTags) {
          const newTag: TagsRequest = {
            tagKey,
            tagType: tagType as TagsRequestTagType,
            tagValue: [tagValue],
          };
          foundEmptyRequestTags.targets.push(newTag);
          extractedTags.push(newTag);
        }
      });
    });

    return extractedTags;
  };

  const fetchDashboardTargetsColumns = async (requestTags = allRequestTags.value) => {
    const dashboardTargets: AllDashboardTargetsColumns[] = [];

    for (const { widgetUUID, targets, category, chartType, dataId } of requestTags) {
      const refinedTargets: TagsRequest[] = [];
      if (!targets.length) {
        const extractedTags = extractWidgetCustomTargets(widgetUUID, requestTags);
        refinedTargets.push(...extractedTags);
      } else {
        refinedTargets.push(...targets);
      }

      const addRequests: Partial<Record<WidgetChartType, MetaStatV7Request>> = {
        [WIDGET_CHART_TYPE_KEY.TREE_GRID]: {
          dataId,
        },
      };

      const requests: MetaStatV7Request[] = [
        {
          tags: refinedTargets,
          category,
          targetCategory: category,
          ...addRequests[chartType],
        },
      ];

      const { data } = await callApi({
        fn: getTargetAndColumnDataV7ControllerAxios,
        params: {
          requests,
        },
        frameName: FRAME_NAMES.DASHBOARD.TARGETS_COLUMN_DATA_LIST,
      });

      if (data?.length) {
        dashboardTargets.push({
          widgetUUID,
          targets: data[0].targets || [],
          tableColumns: data[0].tables?.[0]?.[0].columns || [],
        });
      }
    }

    return dashboardTargets;
  };

  return {
    allWidgetsByCurrentDashboard,
    allRequestTags,
    allDashboardTargetsColumns,
    allDashboardCustomStats,
    setAllWidgetsByCurrentDashboard,
    setAllRequestTags,
    setAllDashboardTargetsColumns,
    fetchDashboardTargetsColumns,
  };
});
