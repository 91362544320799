/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */

import { UUID } from '@/common/utils';

export class TreeNode {
  public parent: TreeNode | null;

  public children: TreeNode[] = [];

  constructor(parent: TreeNode | null) {
    this.parent = parent;
    if (this.parent) this.parent.children.push(this);
  }
}

export class Tree extends TreeNode {
  private id: UUID = ''; // unique

  public declare parent: Tree | null;

  public children: Tree[] = [];

  constructor(parent: Tree['parent'], id: UUID) {
    super(parent);
    this.id = id;
  }

  public addChild(id: UUID) {
    return new Tree(this, id);
  }

  public removeChild(child: Tree) {
    this.children = this.children.filter((c) => c !== child);
  }

  public findChild(id: UUID): Tree | null {
    const child = this.children.find((c) => c.id === id);
    if (child) return child;
    for (const c of this.children) {
      const found = c.findChild(id);
      if (found) return found;
    }
    return null;
  }

  public getId() {
    return this.id;
  }

  public setId(id: UUID) {
    this.id = id;
  }

  public static isDescendant(parent: Tree, child: Tree) {
    if (parent.id === child.id) return true;
    return parent.children.some((c) => Tree.isDescendant(c, child));
  }
}
