import { ref } from 'vue';
import {
  BaseTabPanelInfo,
  getSlideDetailStore,
  SlideTabPanelInfo,
  TOTAL_DETAIL,
} from '@/common/stores/slide-detail';
import { getTargetCheckMetaDatabaseInstanceV8ControllerAxios } from '@/openapi/metav8Over/api/meta-database-instance-v8-controller-api';
import { DATABASE_DETAIL } from '@/common/define/slideDetail.define';

export type DetailType = (typeof DATABASE_DETAIL)[keyof typeof DATABASE_DETAIL];

export const useInstanceDetail = () => {
  const slideDetailStore = getSlideDetailStore();
  const { addTabPanel } = slideDetailStore;

  const isShowDetail = ref(false);

  const fetchDetailInfo = async (instanceId: string) => {
    let isCluster = false;
    let dbType = '';
    let subType = '';
    try {
      const {
        data: { data },
      } = await getTargetCheckMetaDatabaseInstanceV8ControllerAxios({
        instanceId,
      });
      isCluster = data?.isCluster === 'true';
      dbType = data?.dbType ?? '';
      subType = data?.subType ?? '';
    } catch (e) {
      console.log(e);
    }

    return {
      isCluster,
      dbType,
      subType,
    };
  };

  const openInstanceDetail = async ({
    instanceId,
    instanceName,
    detailType,
    init = true,
    addition,
    slideCurrentState,
    state,
  }: {
    instanceId: string;
    instanceName: string;
    detailType?: DetailType;
    init?: boolean;
    addition?: BaseTabPanelInfo['addition'];
    slideCurrentState?: SlideTabPanelInfo['state'];
    state?: SlideTabPanelInfo['state'];
  }) => {
    if (!instanceId) {
      return;
    }
    let isCluster = detailType === TOTAL_DETAIL.CLUSTER;
    let isSharded = detailType === TOTAL_DETAIL.SHARDED_CLUSTER;

    if (!detailType) {
      const detailInfo = await fetchDetailInfo(instanceId);
      isCluster = detailInfo.isCluster;
      isSharded = detailInfo.dbType === 'MONGODB' && detailInfo.subType === 'mongos';
    }

    isShowDetail.value = true;

    if (isCluster) {
      addTabPanel(
        {
          type: TOTAL_DETAIL.CLUSTER,
          name: instanceName,
          cluster: {
            instanceId,
          },
          init,
          state,
        },
        slideCurrentState,
      );
    } else if (isSharded) {
      addTabPanel(
        {
          type: TOTAL_DETAIL.SHARDED_CLUSTER,
          name: instanceName,
          instance: {
            instanceId,
          },
          init,
          addition,
          state,
        },
        slideCurrentState,
      );
    } else {
      addTabPanel(
        {
          type: TOTAL_DETAIL.INSTANCE,
          name: instanceName,
          instance: {
            instanceId,
          },
          init,
          addition,
          state,
        },
        slideCurrentState,
      );
    }
  };

  return {
    isShowDetail,
    openInstanceDetail,
  };
};
