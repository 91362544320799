import { computed, getCurrentInstance, ref, useSlots, watch } from 'vue';
import {
  ClickData,
  CustomColumn,
  GridOption,
  GridSearchBarOption,
  RowData,
} from '@/common/utils/types';
import { exportToExcelFromClient } from '@/common/utils/exportExcelUtils';
import { useInternational } from '@/common/locale';
import { showErrorMsg, showMsg, showSuccessMsg } from '@/common/utils/commonUtils';
import { useEvGridFilterSearch } from '@/common/components/molecules/filterSearch/filterSearch.uses';
import { useUserEnvStore } from '@/common/stores/user-env';
import { isEqual } from 'lodash-es';
import { Emit, Props } from '@/common/components/molecules/grid/baseGrid.setup';
import { getColumnsToSave, getMergedColumns } from '@/common/utils/gridColumns.utils';

type CustomFieldInfo = Record<string, Record<string, any>>;

export const useCustomColumns = <
  ColumnData extends CustomColumn[] | Readonly<CustomColumn[]>,
  RowDataType = RowData<ColumnData>,
>(
  props: Props<ColumnData, RowDataType>,
  gridOption: GridOption,
  setColumnsForExcelDownload: (columns: ColumnData) => void,
) => {
  const userEnvStore = useUserEnvStore();

  const DEFAULT_COLUMN_INFO = {
    width: 120,
  };
  const CUSTOM_COLUMN_INFO = {
    ratio: {
      width: 126,
    },
    label: {
      width: 150,
    },
    'capitalize-label': {
      width: 150,
    },
    'upper-label': {
      width: 150,
    },
  };

  const prevColumns = ref<any[][] | undefined>();
  const computedColumns = ref<any[][]>([]);
  const customColumnFields = ref<CustomFieldInfo>({});

  const setCustomColumnInfo = (columns: ColumnData) => {
    const tempObj: CustomFieldInfo = {};

    columns.forEach((info: CustomColumn) => {
      const { field, rendererType, clickable, decimal } = info;
      if (rendererType) {
        if (!tempObj[field]) {
          tempObj[field] = {};
        }
        tempObj[field].rendererType = rendererType;
      }
      if (clickable) {
        if (!tempObj[field]) {
          tempObj[field] = {};
        }
        tempObj[field].clickable = clickable;
      }
      if (decimal) {
        if (!tempObj[field]) {
          tempObj[field] = {};
        }
        tempObj[field].decimal = decimal;
      }
    });

    customColumnFields.value = tempObj;
  };
  const setColumnInfo = (columns: ColumnData) => {
    const defaultColumnInfo = gridOption.adjust ? {} : DEFAULT_COLUMN_INFO;
    computedColumns.value = columns.map((val) => ({
      ...defaultColumnInfo,
      ...CUSTOM_COLUMN_INFO[val.rendererType],
      ...val,
    }));
  };

  const saveColumnInfo = async (columns) => {
    if (!props.columnEnvKey) {
      return;
    }
    setColumnsForExcelDownload(columns);
    prevColumns.value = columns;
    await userEnvStore.setEnvValue([{ key: props.columnEnvKey, value: getColumnsToSave(columns) }]);
  };

  watch(
    [() => props.columns, () => props.columnEnvKey],
    (curr, prev) => {
      const columns = props.hasDynamicColumns
        ? props.columns
        : getMergedColumns({
            baseColumns: props.columns,
            storedColumns: userEnvStore.getEnvValue(props.columnEnvKey),
          });
      setCustomColumnInfo(columns);
      setColumnInfo(columns);
      if (prevColumns.value && !isEqual(curr[0], prevColumns.value)) {
        saveColumnInfo(computedColumns.value);
      } else if (!isEqual(prev?.[0], curr[0])) {
        setColumnsForExcelDownload(computedColumns.value);
      }
    },
    { immediate: true },
  );

  return {
    computedColumns,
    customColumnFields,
    saveColumnInfo,
  };
};

export const useGridTooltip = (props, emit) => {
  const tooltipItems = ref<string[]>([]);
  const customTooltipRef = ref();
  // rowIndex, chips
  const clickCount = ({ evt, chips, totalChips, rowIndex }: ClickData) => {
    tooltipItems.value = totalChips;
    emit('click-cell-chip-more', { chips, totalChips, rowIndex });
    customTooltipRef.value.show(evt);
  };
  const clickChip = ({ evt, chips, rowIndex }) => {
    tooltipItems.value = chips;
    emit('click-cell-chip', { chips, rowIndex });
    customTooltipRef.value.show(evt);
  };
  const hideTooltip = () => {
    customTooltipRef.value.hide();
  };
  return {
    tooltipItems,
    customTooltipRef,
    clickCount,
    clickChip,
    hideTooltip,
  };
};

export const useSearchBar = (props, emit) => {
  const { t } = useInternational();
  const tempWord = ref<string>('');
  const searchWord = computed<string>({
    get: () => props?.searchWord ?? tempWord.value,
    set: (v) => {
      emit('update:search-word', v);
      tempWord.value = v;
    },
  });
  const searchBarOption = computed<GridSearchBarOption>(() => ({
    use: false,
    mode: 'full',
    placeholder: t('WORD.SEARCH'),
    ...props?.searchBarOption,
  }));

  const checkNumberStr = (str) => /^\d+(\.\d+)?$/.test(str);

  const filterGrid = (columns, rows, keyword) => {
    let rs: any = [];
    if (keyword) {
      rs = rows.filter((row) => {
        const isPassed = row.some((cell, i) => {
          const rendererType = columns[i]?.rendererType;
          const columnType = typeof cell;
          const isSearchable = !columns[i]?.hide && !(columns[i]?.searchable === false);
          let isMatched = false;

          if (!isSearchable) {
            isMatched = false;
          } else if (rendererType === 'chip-cell') {
            isMatched = cell.find((chipVal) => {
              const chipName = chipVal?.name ?? chipVal;
              return chipName.toLowerCase().includes(keyword);
            });
          } else if (columnType === 'string') {
            isMatched = cell.toLowerCase().includes(keyword);
          } else if (columnType === 'number' && checkNumberStr(keyword)) {
            isMatched = cell === +keyword;
          }

          return isMatched;
        });

        return isPassed;
      });
    } else {
      rs = rows;
    }

    return rs;
  };

  watch(
    () => props.searchBarOption,
    (newVal, oldVal) => {
      if (newVal.use !== oldVal.use) {
        searchWord.value = '';
      }
    },
  );

  const { filterSearchResultMV, filterGridByFilterSearch, filterTreeGridByFilterSearch } =
    useEvGridFilterSearch({
      columns: computed(() => props.columns),
      filterItems: props.searchBarOption?.filterSearch?.use
        ? props.searchBarOption.filterSearch?.filterItems
        : [],
    });

  return {
    searchWord,
    searchBarOption,
    filterGrid,

    filterSearchResultMV,
    filterGridByFilterSearch,
    filterTreeGridByFilterSearch,
  };
};

export const useCustomCellRender = () => {
  const slotNames = Object.keys(useSlots());
  const PREDEFINED_SLOTS = ['toolbar', 'tooltip-content'];
  const slotNamesOfCustomCell = slotNames.filter((name) => !PREDEFINED_SLOTS.includes(name));

  return {
    slotNamesOfCustomCell,
  };
};

export const useDeselect = (props, emit) => {
  const selectedRows = ref<any[]>(props.selectedRows);

  watch(
    () => props.selectedRows,
    (nextSelectedRows) => {
      selectedRows.value = nextSelectedRows;
    },
  );

  let skipSelectWatch = false;
  watch(selectedRows, (nextSelectedRows, prevSelectedRows) => {
    if (skipSelectWatch) {
      skipSelectWatch = false;
      return;
    }

    if (props.option?.useSelection?.disableRootNodeSelect && nextSelectedRows[0]?.level === 0) {
      skipSelectWatch = true;
      selectedRows.value = prevSelectedRows[0] ? [prevSelectedRows[0]] : [];
      return;
    }

    if (props.option?.useSelection?.disableDeselect) {
      if (!nextSelectedRows[0]) {
        skipSelectWatch = true;
        selectedRows.value = prevSelectedRows[0] ? [prevSelectedRows[0]] : [];
        return;
      }
    }

    emit('update:selected-rows', nextSelectedRows);
  });

  return {
    selectedRows,
  };
};

export const useGridSetting = <
  ColumnData extends CustomColumn[] | Readonly<CustomColumn[]>,
  RowDataType = RowData<ColumnData>,
>(
  props: Props<ColumnData, RowDataType>,
  emit: Emit<ColumnData, RowDataType>,
) => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();

  let gridColumns: ColumnData = props.columns;

  const menuItems = [
    {
      text: t('WORD.EXPORT_EXCEL'),
      click: async () => {
        if (gridColumns?.length && typeof gridColumns[0].index !== 'number') {
          gridColumns = gridColumns.map((col, index) => ({
            ...col,
            index,
          })) as ColumnData;
        }
        // 서버에서 excel 처리하는 경우, 에러 메시지 별도로 출력
        if (props.exportExcelOption?.exportByServer) {
          emit('export-to-excel-from-server', { columns: gridColumns });
        } else {
          const { hide: hideLoadingMsg } = showMsg(ctx, 'loading', {
            msgStr: t('NOTI.DOWNLOAD.LOADING'),
            durationMs: 1000000,
            showClose: false,
            icon: true,
          });
          try {
            const { rowDataFetchPromise } = props.exportExcelOption ?? {};
            let { rows } = props;
            if (rowDataFetchPromise) {
              rows = await rowDataFetchPromise();
            }
            await exportToExcelFromClient(gridColumns, rows, props.exportExcelOption);
            showSuccessMsg(ctx, t('NOTI.DOWNLOAD.SUCCESSFUL'));
          } catch (e) {
            console.log(e);
            showErrorMsg(ctx, t('NOTI.DOWNLOAD.FAILED'));
          } finally {
            hideLoadingMsg();
          }
        }
      },
    } as const,
  ];

  const gridSettingOption = computed<GridOption['useGridSetting']>(() =>
    props.option?.useGridSetting?.use
      ? {
          use: true,
          customContextMenu: [
            ...menuItems,
            ...(props.option.useGridSetting && props.option.useGridSetting?.customContextMenu
              ? props.option.useGridSetting.customContextMenu
              : []),
          ],
          columnMenuText: t('WORD.COLUMN_LIST'),
          searchText: t('WORD.SEARCH'),
          emptyText: t('MESSAGE.NO_DATA'),
          okBtnText: t('WORD.OK'),
        }
      : { use: false },
  );

  const setColumns = (columns: ColumnData) => {
    if (!columns?.length) {
      gridColumns = props.columns;
    }
    gridColumns = columns;
  };

  return {
    gridSettingOption,
    setColumns,
  };
};
