/* eslint-disable no-redeclare */
import { WidgetChartDataStatusInfo, WidgetTriggerPathType, WidgetUUID } from '@/common/utils/types';
import {
  CalendarTimeRange,
  DisplayStyleType,
  FilterWidgetChartType,
  WidgetChartData,
  WidgetChartType,
  WidgetColorOption,
  WidgetCreateType,
  WidgetExtraData,
  WidgetModeType,
  WidgetTimePeriod,
  WidgetTitleOption,
  WidgetType,
} from '@/dashboard/utils/types';
import { CurrentRawData } from '@/worker/commands/dashboard/current';
import { MetricRawData } from '@/worker/commands/dashboard/metrics';
import { WidgetScatterRawData } from '@/worker/commands/dashboard/scatter';
import { RowInfo, TableRawData } from '@/worker/commands/dashboard/table';
import { TopologyRawData } from '@/worker/commands/dashboard/topology';
import { FilterTableData } from '../widgetSettingsWindow/filterWidgetSettings/filterDataList.setup';
import { OneDayCompareChartOption } from '../widgetSettingsWindow/graphWidgetSettings/1dayCompareSettings/oneDayCompareSettingsArticle.setup';
import { EqualizerChartOption } from '../widgetSettingsWindow/graphWidgetSettings/equalizerSettings/equalizerSettingsArticle.setup';
import { GaugeChartOption } from '../widgetSettingsWindow/graphWidgetSettings/gauge/gaugeSettingArticle.setup';
import { TimeSeriesChartOption } from '../widgetSettingsWindow/graphWidgetSettings/graphWidgetSettings.types';
import { PieChartOption } from '../widgetSettingsWindow/graphWidgetSettings/pieSettings/pieSettingsChartOption/pieSettingsChartOption.setup';
import { ScatterChartOption } from '../widgetSettingsWindow/graphWidgetSettings/scatterSettings/scatterSettingsArticle.setup';
import { ScoreboardChartOption } from '../widgetSettingsWindow/graphWidgetSettings/scoreboardSettings/scoreboardSettingsArticle.setup';
import { StackedProportionBarChartOption } from '../widgetSettingsWindow/graphWidgetSettings/stackedProportionBarSettings/stackedProportionBarSettingsArticle.setup';

export type ChartOptions<T extends WidgetChartType> = T extends 'TIME_SERIES'
  ? TimeSeriesChartOption
  : T extends 'ONE_DAY_COMPARE'
    ? OneDayCompareChartOption
    : T extends 'GAUGE'
      ? GaugeChartOption
      : T extends 'PIE'
        ? PieChartOption
        : T extends FilterWidgetChartType
          ? undefined
          : T extends 'SCATTER'
            ? ScatterChartOption
            : T extends 'STACKED_PROPORTION_BAR'
              ? StackedProportionBarChartOption
              : T extends 'EQUALIZER'
                ? EqualizerChartOption
                : T extends 'SCOREBOARD'
                  ? ScoreboardChartOption
                  : any;

export type FilterOptions<T extends WidgetChartType> = T extends
  | 'HORIZONTAL_FILTER'
  | 'VERTICAL_FILTER'
  ? { filterList: FilterTableData[] }
  : T extends 'PIE'
    ? undefined
    : undefined;

export interface WidgetProps<T extends WidgetChartType = any> {
  widgetId: WidgetUUID;
  widgetType: WidgetType;
  createType: WidgetCreateType;
  chartType: T;
  titleOption: WidgetTitleOption;
  displayStyle: DisplayStyleType;
  noDataMsg: string;
  widgetColor?: WidgetColorOption;
  timePeriod?: WidgetTimePeriod;
  colorTheme?: string;
  chartData?: WidgetChartData[];
  mode?: WidgetModeType;
  chartOption: ChartOptions<T>;
  filterOption?: FilterOptions<T>;
  extraData?: WidgetExtraData[];
  calendarTimeRange?: CalendarTimeRange[];
  isInViewport?: boolean;
}

export interface WidgetEmit {
  (e: 'changeChartDataStatus', statusInfoList: WidgetChartDataStatusInfo[]): void;
  (e: 'update:chartOption', chartOption: any): void;
}

export interface ActionViewItem {
  begin: number;
  active: number;
  normal: number;
  warning: number;
  critical: number;
  end: number;
}

export interface SelectedTimeSeriesData {
  seriesId: string[];
}

export type ChartRawData =
  | MetricRawData
  | CurrentRawData
  | TableRawData
  | TopologyRawData
  | WidgetScatterRawData;

export const ChartDataType = ['metrics', 'current', 'table', 'scatter', 'topology'] as const;
export type ChartDataType = (typeof ChartDataType)[number];

export const MetricChartType = ['timeseries', 'oneDayCompare', 'exclusive'] as const;
export type MetricChartType = (typeof MetricChartType)[number];

export const CurrentChartType = [
  'scoreboard',
  'scoreboard-style2',
  'stackedProportionBar',
  'gauge',
] as const;
export type CurrentChartType = (typeof CurrentChartType)[number];

export const TableChartType = [
  'equalizer',
  'actionView',
  'table',
  'statusSummary',
  'pie',
  'serviceCard',
  'topList',
  'statusHexa',
  'treeGrid',
  'server',
] as const;
export type TableChartType = (typeof TableChartType)[number];

export const ScatterChartType = ['scatter'] as const;
export type ScatterChartType = (typeof ScatterChartType)[number];

export const TopologyChartType = ['topology'] as const;
export type TopologyChartType = (typeof TopologyChartType)[number];

export type ChartType =
  | MetricChartType
  | CurrentChartType
  | TableChartType
  | ScatterChartType
  | TopologyChartType;

export interface TargetTriggerInfo {
  targetId: string;
  targetName: string;
  triggerPath: WidgetTriggerPathType | null;
}

export const MetricDataField = ['fromTime', 'toTime'] as const;
export type MetricDataField = (typeof MetricDataField)[number];

export type MetricDataFieldInfo = Record<MetricDataField, string>;

export const CurrentDataField = ['targetId'] as const;
export type CurrentDataField = (typeof CurrentDataField)[number];

export type CurrentDataFieldInfo = Record<CurrentDataField, string>;

export const ScatterDataField = ['fromTime', 'toTime', 'minElapseTime', 'maxElapseTime'] as const;
export type ScatterDataField = (typeof ScatterDataField)[number];

export type ScatterDataFieldInfo = Record<ScatterDataField, string | number>;

export type TableDataFieldInfo = Record<string, RowInfo>;

export type WidgetChartDataWithIndex = WidgetChartData & { chartDataIndex: number };
