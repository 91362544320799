export const INFRASTRUCTURE = {
  INFRASTRUCTURE_HOST_DETAIL_DASHBOARD_SETTING_LIST:
    'INFRASTRUCTURE_HOST_DETAIL_DASHBOARD_SETTING_LIST',
  INFRASTRUCTURE_CONTAINER_DETAIL_DASHBOARD_SETTING_LIST:
    'INFRASTRUCTURE_CONTAINER_DETAIL_DASHBOARD_SETTING_LIST',
  INFRASTRUCTURE_NETWORK_DEVICE_DETAIL_DASHBOARD_SETTING_LIST:
    'INFRASTRUCTURE_NETWORK_DEVICE_DETAIL_DASHBOARD_SETTING_LIST',
  // TODO: 공통으로 들어가는 것이라 container_변경
  GRIDCOLUMNS_infrastructure_relationalInformation_targetList:
    'GRIDCOLUMNS_infrastructure_relationalInformation_targetList',

  GRIDCOLUMNS_infrastructure_host_hostList: 'GRIDCOLUMNS_infrastructure_host_hostListV5',
  GRIDCOLUMNS_infrastructure_hostDetail_agent_agentList:
    'GRIDCOLUMNS_infrastructure_hostDetail_agent_agentList',
  GRIDCOLUMNS_infrastructure_hostDetail_container_containerList:
    'GRIDCOLUMNS_infrastructure_hostDetail_container_containerList',
  GRIDCOLUMNS_infrastructure_hostDetail_information_memoryList:
    'GRIDCOLUMNS_infrastructure_hostDetail_information_memoryList',
  GRIDCOLUMNS_infrastructure_hostDetail_disk_diskList:
    'GRIDCOLUMNS_infrastructure_hostDetail_disk_diskList',
  GRIDCOLUMNS_infrastructure_hostDetail_installedPackage_packageList:
    'GRIDCOLUMNS_infrastructure_hostDetail_installedPackage_packageList',
  GRIDCOLUMNS_infrastructure_hostDetail_network_networkList:
    'GRIDCOLUMNS_infrastructure_hostDetail_network_networkList',
  GRIDCOLUMNS_infrastructure_hostDetail_process_processList:
    'GRIDCOLUMNS_infrastructure_hostDetail_process_processList',
  GRIDCOLUMNS_infrastructure_hostDetail_process_information_informationList:
    'GRIDCOLUMNS_infrastructure_hostDetail_process_information_informationList',

  GRIDCOLUMNS_infrastructure_container_containerList:
    'GRIDCOLUMNS_infrastructure_container_containerList',

  GRIDCOLUMNS_infrastructure_networkDevice_deviceList:
    'GRIDCOLUMNS_infrastructure_networkDevice_deviceList',
  GRIDCOLUMNS_infrastructure_networkdeviceDetail_interface_interfaceList:
    'GRIDCOLUMNS_infrastructure_networkdeviceDetail_interface_interfaceList',
  GRIDCOLUMNS_infrastructure_networkDevicedetail_sylog_logList:
    'GRIDCOLUMNS_infrastructure_networkDevicedetail_sylog_logList',
  GRIDCOLUMNS_infrastructure_networkDevicedetail_snmpTrap_snmpTrapList:
    'GRIDCOLUMNS_infrastructure_networkDevicedetail_snmpTrap_snmpTrapList',

  GRIDCOLUMNS_infrastructure_npmDetail_networkList:
    'GRIDCOLUMNS_infrastructure_npmDetail_networkList',

  GRIDCOLUMNS_infrastructure_scriptManager_scriptResultList:
    'GRIDCOLUMNS_infrastructure_scriptManager_scriptResultList',

  GRIDCOLUMNS_infrastructure_scriptManagerHistory_scriptHistoryList:
    'GRIDCOLUMNS_infrastructure_scriptManagerHistory_scriptHistoryList',

  FILTER_infrastructure_hostView: 'FILTER_infrastructure_hostView',
  FILTER_infrastructure_containerView: 'FILTER_infrastructure_containerView',
  FILTER_infrastructure_networkDeviceView: 'FILTER_infrastructure_networkDeviceView',
  FILTER_infrastructure_networkPerformanceMonitoringView:
    'FILTER_infrastructure_networkPerformanceMonitoringView',
  FILTERSEARCH_infrastructure_hostView_hostList: 'FILTERSEARCH_infrastructure_hostView_hostList',
  FILTERSEARCH_infrastructure_containerView_containerList:
    'FILTERSEARCH_infrastructure_containerView_containerList',
  FILTERSEARCH_infrastructure_networkDevice_deviceList:
    'FILTERSEARCH_infrastructure_networkDevice_deviceList',
  FILTERSEARCH_infrastructure_npm_networkList: 'FILTERSEARCH_infrastructure_npm_networkList',

  STAT_infrastructure_networkPerformanceMonitoringView:
    'STAT_infrastructure_networkPerformanceMonitoringView',
  STAT_infrastructure_networkPerformanceMonitoringView_edgeStat:
    'STAT_infrastructure_networkPerformanceMonitoringView_edgeStat',
  STAT_infrastructure_networkPerformanceMonitoringView_edge:
    'STAT_infrastructure_networkPerformanceMonitoringView_edge',
  STAT_infrastructure_networkPerformanceMonitoringView_node:
    'STAT_infrastructure_networkPerformanceMonitoringView_node',
  STAT_infrastructure_networkPerformanceMonitoringView_edgeStat_global:
    'STAT_infrastructure_networkPerformanceMonitoringView_edgeStat_global',
  STAT_infrastructure_networkPerformanceMonitoringView_edge_global:
    'STAT_infrastructure_networkPerformanceMonitoringView_edge_global',
  STAT_infrastructure_networkPerformanceMonitoringView_node_global:
    'STAT_infrastructure_networkPerformanceMonitoringView_node_global',

  POSITIONS_infrastructure_networkPerformanceMonitoringView_node_global:
    'POSITIONS_infrastructure_networkPerformanceMonitoringView_node_global',
  POSITIONS_infrastructure_networkPerformanceMonitoringView_node:
    'POSITIONS_infrastructure_networkPerformanceMonitoringView_node',
  FILTERSEARCH_infrastructure_scriptManagerHistory_scriptHistoryList:
    'FILTERSEARCH_infrastructure_scriptManagerHistory_scriptHistoryList',
};
