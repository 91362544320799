import { DashboardList } from '@/common/stores/dashboard-popup';
import { DetailInfoData } from '@/common/utils';
import { store } from '@/common/store';
import dayjs from 'dayjs';
import { DashboardKey } from '@/common/uses/useDashboardJump';
import { computed } from 'vue';

export interface Props {
  dashboardList: DashboardList[];
  moveDashboard: (id: number, keyInfo?: DashboardKey) => void;
  keyInfo?: DashboardKey;
  infoDataList?: DetailInfoData[];
}

interface DashboardItem extends DashboardList {
  icon: string;
}

const getIconType = (isDashboard: boolean, isNewWindow: boolean) => {
  const icon = {
    dashboard: 'icon-dashboard-regular',
    link: 'icon-link-chain',
    linkNew: 'icon-send-copy',
  };

  if (isDashboard) {
    return icon.dashboard;
  }
  return isNewWindow ? icon.linkNew : icon.link;
};

const setup = (props: Props) => {
  const selectedList = computed<DashboardItem[]>(() => {
    return (
      props.dashboardList?.map((item) => {
        return {
          ...item,
          icon: getIconType(!!item.dashboardId, item.isNewWindow ?? false),
        };
      }) ?? []
    );
  });

  const onClickDashboardList = (list: DashboardItem) => {
    if (list.dashboardId) {
      props.moveDashboard(list.dashboardId, props.keyInfo);
    } else {
      if (!list.url) {
        return;
      }

      const accountInfo = store.getters['myInfo/getAccountInfo'];
      const containerKey = props.infoDataList?.filter((data) => data.field === 'containerKey')[0]
        ?.values[0];

      const url = list.url
        .replace('$intermaxFromTime', `${+dayjs().tz().subtract(10, 'minutes')}`)
        .replace('$intermaxToTime', `${+dayjs().tz()}`)
        .replace('$timeZone', accountInfo.timezone ?? '')
        .replace('$containerId', containerKey ?? '');

      if (list.isNewWindow) {
        window.open(url, '_blank');
      } else {
        location.replace(url);
      }
    }
  };

  return {
    selectedList,
    onClickDashboardList,
  };
};

export { setup };
