import { computed, inject } from 'vue';
import { DetailItemInfo } from '@/common/components/organisms/alertListDetail/alertListDetail.types';
import { AlertType, RuleInfoProps } from '@/alert/components/alertDetail/alertDetail.types';
import { useInternational } from '@/common/locale';
import { useCriteriaTooltip } from '@/alert/components/alertGrid/alertGrid.setup';

export interface Props {
  selectedId: string;
  selectedGroupId: string;
  list: DetailItemInfo[];
  title?: string;
}
export interface Emit {
  (e: 'update:selected-id', value: string): void;
  (e: 'click-item', value: DetailItemInfo | null): void;
  (
    e: 'clear-items',
    value: {
      items: DetailItemInfo[];
      type: AlertType;
    },
  ): void;
  (e: 'openDetail'): void;
  (e: 'reFetch'): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();

  const selectedId = computed<string>({
    get: () => props.selectedId,
    set: (val: string) => emit('update:selected-id', val),
  });
  const selectedRuleProp = computed<RuleInfoProps | undefined>(() => {
    const select = props.list.find((item) => item.cardId === selectedId.value);
    return select
      ? {
          name: select?.name,
          ruleId: select?.id,
          type: select?.type,
        }
      : undefined;
  });
  const onClickItem = (item: DetailItemInfo) => {
    if (selectedId.value === item.cardId || !item.name || !item.type) {
      selectedId.value = '';
      emit('click-item', null);
    } else {
      selectedId.value = item.cardId;
      emit('click-item', item);
    }
  };
  const onClickClear = (item: DetailItemInfo) => {
    const items = item.id && item.name ? [item] : props.list;
    const type = props.selectedGroupId === '0' ? 'system' : 'user';
    emit('clear-items', { items, type });
  };

  const onClickCloseDetail = () => {
    selectedId.value = '';
    emit('click-item', null);
  };

  const emitOpenDetail = inject('emitOpenDetail');
  const onDetail = () => {
    if (typeof emitOpenDetail === 'function') {
      emitOpenDetail();
    }
  };

  const { criteriaTooltipMouseEvent, criteriaTooltipText, onCriteriaMouse } = useCriteriaTooltip();

  return {
    selectedId,
    selectedRuleProp,
    onClickItem,
    onClickClear,
    onClickCloseDetail,
    onDetail,
    t,

    criteriaTooltipMouseEvent,
    criteriaTooltipText,
    onCriteriaMouse,
  };
};
