export const LicenseTypeName = [
  'application',
  'cloud',
  'container',
  'database',
  'infrastructure',
] as const;

export const LicenseLevel = ['PERM', 'TERM', 'TRIAL'] as const;

export const LicenseTargetDisplayName = {
  ndm: 'NDM',
  kafka: 'Kafka',
  apm: 'APM',
  redis: 'Redis',
  postgresql: 'PostgreSQL',
  mysql: 'MySQL',
  oracle: 'Oracle',
  sqlServer: 'SQL Server',
  aws: 'AWS',
  azure: 'Azure',
  gcp: 'GCP',
  ncp: 'NCP',
} as const;

export const LicenseTargetLanguageKeys = {
  host: 'HOST',
  log: 'LOG',
  container: 'CONTAINER',
  appContainer: 'APPLICATION_CONTAINER',
  allDimension: 'TOTAL_DIMENSION',
};

export const LicenseTargetUnitLanguageKeys = {
  host: 'HOST',
  log: 'LOGS',
  vcore: 'VCORE',
  instance: 'INSTANCE',
  device: 'NETWORK_DEVICE',
};
