<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { Emit, Props, setup } from './rangeCalendar.setup';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  t,
  vueDatePickerRef,
  userLanguage,
  checkDisabledDate,
  monthYearButtonDirection,
  selectedValues,
  makers,
  isToday,
  isSunday,
  getFormattedDay,
  onChangeStart,
  onChangeEnd,
  setCalendarMonthYear,
} = setup(props, emit);

defineExpose({
  setCalendarMonthYear,
});
</script>

<template>
  <div class="range-calendar">
    <vue-date-picker
      ref="vueDatePickerRef"
      :model-value="selectedValues"
      :locale="userLanguage"
      :enable-time-picker="false"
      :min-date="props.minDate"
      :max-date="props.maxDate"
      :markers="makers"
      :disabled="props.disabled"
      :disabled-dates="checkDisabledDate"
      inline
      range
      multi-calendars
      week-start="0"
      month-name-format="long"
      @range-start="onChangeStart"
      @range-end="onChangeEnd"
    >
      <template #marker>
        <span class="today-marker" />
      </template>

      <template #day="{ day, date }">
        <span
          :class="[
            'range-calendar__date',
            {
              today: isToday(date),
              sunday: isSunday(date),
            },
          ]"
        >
          {{ day }}
        </span>
      </template>

      <template #year="{ text }">
        <span>{{ `${text}${userLanguage === 'ko-KR' ? '년' : ''}` }}</span>
      </template>

      <template #calendar-header="{ index }">
        <span>{{ getFormattedDay(index) }}</span>
      </template>

      <template #month-overlay-value="{ text }">
        {{ text.slice(0, 3) }}
      </template>

      <template #calendar-icon>
        <span class="range-calendar__overlay-close"> {{ t('WORD.CLOSE') }} </span>
      </template>
    </vue-date-picker>
  </div>
</template>

<style scoped lang="scss">
.range-calendar {
  :deep(.dp__theme_light) {
    --dp-background-color: var(--color-gray-07-00);
    --dp-primary-color: var(--color-blue-05-05);
    --dp-range-between-dates-background-color: var(--color-blue-09-01);
    --dp-range-between-dates-text-color: var(--color-gray-00-12);
    --dp-range-between-border-color: var(--color-blue-09-01);
    --dp-secondary-color: var(--color-gray-04-08);
    --dp-text-color: var(--color-gray-02-10);
    --dp-hover-color: var(--color-gray-05-03);
    --dp-hover-text-color: var(--color-gray-02-10);
    --dp-icon-color: var(--color-gray-02-10);
    --dp-icon-hover-color: var(--color-gray-02-10);
    --dp-cell-size: 32px;
    --dp-font-family: 'Roboto';
  }
  :deep(.dp__menu) {
    border: none;
  }
  :deep(.dp__action_row) {
    display: none;
  }
  :deep(.dp__instance_calendar > .dp__calendar) {
    margin-top: 16px;
  }
  .today-marker {
    position: absolute;
    top: var(--dp-cell-padding);
    right: var(--dp-cell-padding);
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: var(--color-green-05-05);
  }
  :deep(.dp__today) {
    border: none;
  }
  :deep(.dp__month_year_wrap .dp__month_year_wrap) {
    flex-direction: v-bind('monthYearButtonDirection');
    justify-content: center;
  }
  :deep(.dp__month_year_select) {
    width: fit-content;
    padding: 3px;
    font-weight: 500;
    color: var(--color-gray-00-12);
  }
  .range-calendar__date {
    font-size: 12px;
    font-weight: 400;
    &.today {
      font-weight: 700;
      color: var(--color-gray-00-12);
    }
    &.sunday {
      color: var(--color-red-05-05);
    }
  }
  :deep(.dp__range_end),
  :deep(.dp__range_start) {
    > span {
      color: var(--color-gray-00-00) !important;
    }
  }
  :deep(.dp__cell_disabled:hover) {
    background-color: transparent;
  }
  :deep(.dp__cell_disabled),
  :deep(.dp__cell_offset) {
    > span {
      color: var(--dp-secondary-color) !important;
    }
  }
  :deep(.dp__calendar_header) {
    border: none;
    font-size: 11px;
    font-weight: 400;
    color: var(--color-gray-02-10);
  }
  :deep(.dp__calendar_header_item) {
    height: 14px;
    padding: 0;
  }
  :deep(.dp__calendar_header_separator) {
    display: none;
  }
  :deep(.dp__menu_inner) {
    gap: 20px;
  }
  :deep(.dp__overlay) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  :deep(.dp__overlay_container) {
    width: 100%;
  }
  :deep(.dp__overlay_action) {
    width: 224px;
    height: 28px;
    border-radius: 2px;
    background-color: var(--color-gray-05-03);
    color: var(--color-gray-00-11);

    &:hover {
      background-color: var(--color-gray-04-04);
    }

    &:active {
      background-color: var(--color-gray-03-06);
    }

    .range-calendar__overlay-close {
      width: 100%;
      font-size: 13px;
      font-weight: 500;
    }
  }
  :deep(.dp__overlay_col) {
    padding: 4px;
  }
  :deep(.dp__overlay_cell_pad) {
    padding: 6px;
    font-size: 14px;
  }
}
</style>
