export const OrderByByEnum = {
  Time: 'TIME',
  Value: 'VALUE',
} as const;

export type OrderByBy = (typeof OrderByByEnum)[keyof typeof OrderByByEnum];
export const OrderByOrderEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const;

export type OrderByOrder = (typeof OrderByOrderEnum)[keyof typeof OrderByOrderEnum];

export interface OrderBy {
  by?: OrderByBy;
  order?: OrderByOrder;
}
