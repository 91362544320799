<template>
  <base-header>
    <template #title>
      <bread-crumbs
        mode="instance"
        db-instance-type="multiView"
        :db-type="selectedDbType"
      />
    </template>
    <overview-alert />
  </base-header>
</template>

<script lang="ts" setup>
import BaseHeader from '@/common/components/templates/BaseHeader.vue';
import OverviewAlert from '@/database/components/overviewAlert/OverviewAlert.vue';
import BreadCrumbs from '@/common/components/molecules/breadCrumbs/BreadCrumbs.vue';
import { useModel } from './uses';

const { selectedDbType } = useModel();
</script>

<style lang="scss" scoped>
:deep(.app-additional-info) {
  justify-content: flex-end;
  padding: 9px 12px;
}
</style>
