import { UnwrapNestedRefs, reactive, Ref, ref } from 'vue';
import { Module } from 'vuex';
import { RootState } from '@/common/store';
import dayjs, { Dayjs } from 'dayjs';
import { ADDITIONAL_STATS_FOR_PA_OBJ } from '@/oracle/views/multiView/dbMetric/uses';
import { TimeModelByIntervalItem } from '@/openapi/oracle/model';
import {
  checkFrameFetchCycle,
  getAPIErrorStatusText,
  getTimezoneForApiParam,
  roundToDigitNumber,
  standardTimeToUtcZeroTime,
} from '@/common/utils/commonUtils';
import { DECIMAL, ORACLE_DEFAULT_FETCH_CYCLE } from '@/common/utils/define';
import { ChartData, FetchInfo, MetricRequest } from '@/common/utils/types';
import { Command } from '@/worker/commands';
import { CommandOptions, useCommand } from '@/worker/composables/useCommand';
import { transformResData, TransformResDataOpt } from '@/worker/utils';
import {
  timeModelByIntervalOracleV7ControllerAxios,
  waitClassPostOracleV7ControllerAxios,
} from '@/openapi/oracleV7/api/oracle-v7-controller-api';
import { WaitClassItem, WaitClassMetricItemV7 } from '@/openapi/oracleV7/model';
import { getMetricData } from '@/common/api/data';
import { transformMetricChartData } from '@/database/utils/metricUtils';
import { MetricFailError, checkValidMetric, preprocessMetric } from '@/database/utils/utils';

interface GetChartDataParams {
  data: any;
  config: any;
  fields?: string[];
  metricInfo: {
    type: 'metrics' | 'waitClasses';
    list: string[];
  };
  dataFormat?: (val: number | null) => void;
}

interface ResChartData {
  chartData: null | (number | null)[];
  chartDateTime: Dayjs[];
}

interface State {
  previewPaChartData: ResChartData;
  chatDataList: UnwrapNestedRefs<{ [frameId in string]: ChartData }>;
  previewChartData: Ref<ChartData>;
  fetchCycle: Record<number, FetchInfo>;
  errorStatus: Record<string, string>;
}

const frameName = 'DB Metric';
const defaultFetchCycle: FetchInfo = ORACLE_DEFAULT_FETCH_CYCLE.dbMetric;
export const dbMetric: Module<State, RootState> = {
  namespaced: true,
  state: {
    previewPaChartData: {
      chartData: null,
      chartDateTime: [],
    },
    chatDataList: reactive({}),
    previewChartData: ref({} as ChartData),
    fetchCycle: {},
    errorStatus: {},
  },
  mutations: {
    initPreviewPaChartData: (state: State) => {
      state.previewPaChartData.chartData = null;
      state.previewPaChartData.chartDateTime.length = 0;
    },
    setPreviewPaChartData: (state: State, data: ResChartData) => {
      state.previewPaChartData.chartData = data.chartData;
      state.previewPaChartData.chartDateTime = data.chartDateTime;
    },
    setChartData: (state: State, { data, frameId }: { data: ChartData; frameId: number }) => {
      state.chatDataList[frameId] = data;
    },
    setPreviewChartData: (state: State, data: Ref<ChartData>) => {
      state.previewChartData = data;
    },
    setFetchCycle: (
      state: State,
      { frameId, fetchCycle }: { frameId: number; fetchCycle: FetchInfo },
    ) => {
      state.fetchCycle[frameId] = fetchCycle;
    },
    setErrorStatus: (
      state: State,
      { metricName, statusText }: { metricName: string; statusText: string },
    ) => {
      state.errorStatus[metricName] = statusText;
    },
  },
  actions: {
    fetchPreviewPaChartData: async (
      { commit },
      { metricName, instanceIds }: { metricName: string; instanceIds: string[] },
    ) => {
      // pa stat change
      try {
        let convertedData: ResChartData = {
          chartData: null,
          chartDateTime: [],
        };
        const getChartData = ({
          data,
          config,
          fields,
          metricInfo,
          dataFormat = (val) => val,
        }: GetChartDataParams): ResChartData => {
          const { data: resData, query } = data;

          const transformDataOpt: TransformResDataOpt = {
            type: 'chart',
            fromTime: query?.fromTime ?? '',
            toTime: query?.toTime ?? '',
            interval: query?.interval ?? '',
            fields: fields ?? ['time', 'avg'],
            valProperty: {
              name: metricInfo.type,
              metricProperty: {
                name: metricInfo.type === 'waitClasses' ? 'waitClassName' : 'metricName',
                list: metricInfo.list,
              },
            },
            needDateTime: true, // 다시 조회하지 않고 이전 chart date 그대로 사용
            dataFormat,
            apiConfig: config,
          };

          const { data: chartData, dateTime: chartDateTime } = transformResData(
            resData.response ? preprocessMetric(resData ?? []) : resData ?? [],
            transformDataOpt,
          ) ?? {
            data: null,
            dateTime: [],
          };

          return {
            chartData,
            chartDateTime,
          };
        };

        // pa > wait class
        const hasWaitClass = metricName === ADDITIONAL_STATS_FOR_PA_OBJ.WAIT_CLASS.NAME;
        // pa > wait class time model
        const hasWaitClassTimeModel =
          metricName === ADDITIONAL_STATS_FOR_PA_OBJ.WAIT_CLASS_TIME_MODEL.NAME;

        if (hasWaitClass) {
          const toTime = standardTimeToUtcZeroTime(+dayjs());
          const fromTime = dayjs(toTime).subtract(59, 'm').format('YYYY-MM-DD HH:mm:ss');
          const timezone = getTimezoneForApiParam();
          const { data, config } = await waitClassPostOracleV7ControllerAxios({
            interval: 'I1m',
            timezone,
            request: {
              fromTime,
              toTime,
              instanceIds: instanceIds as unknown as Set<string>,
            },
          });

          if (data?.data && data?.query) {
            const { data: resData } = data;

            const waitClassNameList: string[] = (resData?.[0] as WaitClassItem).waitClasses!.map(
              (item: WaitClassMetricItemV7) => item?.waitClassName ?? '',
            );

            if (waitClassNameList.length) {
              convertedData = getChartData({
                data,
                config,
                fields: ['collectTime', 'diffWaitTime'],
                metricInfo: {
                  type: 'waitClasses',
                  list: waitClassNameList,
                },
                dataFormat: (val) => val && roundToDigitNumber(val / 1000, DECIMAL.ORACLE),
              });
            }
          }
        } else if (hasWaitClassTimeModel) {
          const toTime = standardTimeToUtcZeroTime(+dayjs());
          const fromTime = dayjs(toTime).subtract(59, 'm').format('YYYY-MM-DD HH:mm:ss');
          const { data, config } = await timeModelByIntervalOracleV7ControllerAxios({
            instanceIds,
            interval: 'I1m',
            fromTime,
            toTime,
            size: 5,
          });

          if (data?.data && data?.query) {
            const { data: resData } = data;

            const timeModelDisplayNameList: string[] = (
              resData?.[0] as TimeModelByIntervalItem
            ).metrics!.map((item) => item?.metricName ?? '');

            if (timeModelDisplayNameList.length) {
              convertedData = getChartData({
                data,
                config,
                metricInfo: {
                  type: 'metrics',
                  list: timeModelDisplayNameList,
                },
                dataFormat: (value) => value && roundToDigitNumber(value / 1000, DECIMAL.ORACLE),
              });
            }
          }
        } else if (metricName) {
          const metricRequests: MetricRequest[] = [
            {
              aggregationType: 'byTarget',
              category: 'oracle',
              dataId: metricName,
              interval: 'I1m',
              summaryType: 'current',
              targetIds: instanceIds,
              period: 'p1h',
              interpolateType: 'Null',
            },
          ];

          const { data } = await getMetricData({
            metricV7Requests: metricRequests,
            frameName,
          });

          checkValidMetric(data?.data ?? []);

          const rawData = transformMetricChartData(data?.data ?? [], {
            transformType: 'array',
          }) as ChartData[];

          if (rawData[0] && rawData[0].data) {
            const keys = Object.keys(rawData[0].data ?? {});

            convertedData = {
              chartData: rawData[0].data[keys[0]] ?? [],
              chartDateTime: rawData[0].labels,
            };
          }
        }

        commit('setPreviewPaChartData', convertedData);
        commit('setErrorStatus', { metricName, statusText: '' });
        commit('oracleMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText =
          e instanceof MetricFailError ? e.getErrorStatusText() : getAPIErrorStatusText(e);
        commit('setErrorStatus', { metricName, statusText });

        commit(
          'oracleMultiViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
    fetchChartData: async ({ state, commit, rootGetters }, { metricName, frameId }) => {
      // multi view db metric
      const instanceIds = rootGetters['oracleMultiViewEnv/getInstanceIds'];
      const command: Command<'oracle'> = {
        namespace: 'oracle',
        method: 'dbMetricChart',
        params: {
          metricNames: [metricName],
          instanceIds,
          interval: state.fetchCycle[frameId]?.[1] ?? defaultFetchCycle[1],
        },
      };
      const options: CommandOptions = {
        initialData: state.chatDataList[frameId],
        immediate: false,
      };

      const { data, apiTraceInfo, error, execute, intervalInfo } = useCommand<ChartData>(
        command,
        options,
      );
      await execute(command.params);

      const fetchCycle = checkFrameFetchCycle({
        defaultArr: defaultFetchCycle,
        prevArr: state.fetchCycle[frameId] ?? defaultFetchCycle,
        collectInfo: intervalInfo.value?.collectInfo?.[0] ?? {},
      });

      commit('setChartData', { data, frameId });
      commit('setFetchCycle', { fetchCycle, frameId });
      commit('setErrorStatus', {
        metricName,
        statusText:
          error instanceof MetricFailError
            ? error.getErrorStatusText()
            : getAPIErrorStatusText(error.value),
      });
      commit(
        'setAPITrace',
        { frameName: `${frameName} > ${metricName}`, apiTraceInfo },
        { root: true },
      );
      commit(
        'oracleMultiViewEnv/setFramesByFailedApi',
        {
          frameName: `${frameName} - ${metricName}`,
          statusText:
            error instanceof MetricFailError
              ? error.getErrorStatusText()
              : getAPIErrorStatusText(error.value),
        },
        { root: true },
      );
    },
    fetchPreviewChartData: ({ commit, rootGetters }, metricName) => {
      // multi view stat change
      const instanceIds = rootGetters['oracleMultiViewEnv/getInstanceIds'];
      const command: Command<'oracle'> = {
        namespace: 'oracle',
        method: 'dbMetricChart',
        params: {
          metricNames: [metricName],
          instanceIds,
          interval: 'I1m',
        },
      };
      const { data, error } = useCommand<ChartData>(command);

      commit('setPreviewChartData', data);
      commit('setErrorStatus', {
        metricName,
        statusText:
          error instanceof MetricFailError
            ? error.getErrorStatusText()
            : getAPIErrorStatusText(error.value),
      });
    },
  },
  getters: {
    getPreviewDbMetricInfo: (state: State): ResChartData => state.previewPaChartData,
    getChartData: (state: State) => (frameId) => state.chatDataList[frameId],
    getPreviewChartData: (state: State) => state.previewChartData,
    getFetchCycle: (state: State) => state.fetchCycle,
    getErrorStatusText: (state: State) => (metricName) => state.errorStatus[metricName],
  },
};
