import { getCurrentInstance, ref } from 'vue';
import { getSlideDetailStore } from '@/common/stores/slide-detail';
import { useAbortApi, useRtmApi } from '@/common/utils/apiUtils';
import { showErrorMsg, showSuccessMsg } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import { PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { AxiosError } from 'axios';
import { getDetailAlertRuleControllerAxios } from '@/openapi/alert/api/alert-rule-controller-api';
import { AlertRuleDetail } from '@/openapi/alert/model';
import { RuleCriteria } from '@/alert/components/alertDetail/alertDetail.types';
import { lowerCase } from 'lodash-es';
import { useAlertDetailStore } from '@/alert/stores/alert-detail';

export const useSlideDetailApi = () => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { abortApi, getSignal } = useAbortApi();
  const { t } = useInternational();
  const slideDetailStore = getSlideDetailStore();

  const callApi = async <Fn extends (p: any) => PromiseAxiosResponse<any>>({
    fn,
    params,
    successMsg,
    errorMsg,
    frameName,
    isTimeout = false,
    hasIndicator = true,
  }: {
    fn: Fn;
    params?: Parameters<Fn>[0];
    isTimeout?: boolean;
    successMsg?: string;
    frameName?: string;
    errorMsg?: string;
    hasIndicator?: boolean;
  }) => {
    let state: Awaited<ReturnType<Fn>>['data']['data'];
    let error: AxiosError | null = null;
    let queryInfo: any;
    let configInfo: any;

    try {
      if (!hasIndicator) {
        slideDetailStore.setIsDetailLoading(true);
      }
      const { data, config } = await fn({
        isTimeout,
        frameName,
        signal: getSignal(),
        ...params,
      });
      state = data.data;
      queryInfo = data.query;
      configInfo = config;

      if (successMsg) {
        showSuccessMsg(ctx, t(successMsg));
      }
    } catch (e: any) {
      error = e;
      state = [];
      const { status } = e?.response ?? {};
      if (status && status !== 406) {
        const msg = t(errorMsg ?? 'NOTI.PRODUCT.TEMP_SERVER_ERROR');
        showErrorMsg(ctx, msg);
      }
    }

    return {
      data: state,
      query: queryInfo,
      config: configInfo,
      error,
    };
  };
  return {
    callApi,
    abortApi,
  };
};

type Rule = Omit<AlertRuleDetail, 'ruleCriteria'> & { ruleCriteria?: RuleCriteria };

export const useAlertRule = () => {
  const rule = ref<Rule>({});

  const { callApi } = useRtmApi();
  const { setUserAlertInformationData } = useAlertDetailStore();

  const fetchUserAlertRule = async ({ ruleId }: { ruleId: string }) => {
    if (!ruleId) {
      rule.value = {};
      return;
    }
    const { data, error } = await callApi({
      fn: getDetailAlertRuleControllerAxios,
      params: {
        alertRuleId: ruleId,
      },
      isTimeout: false,
      isShowErrorMsg: false,
    });

    if (error) {
      rule.value = {};
      setUserAlertInformationData(null);
      return;
    }

    rule.value = (data?.[0] || {}) as Rule;
    setUserAlertInformationData(data?.[0] || null);
  };

  const getUserAlertRule = async ({
    ruleId,
    fields,
  }: {
    ruleId?: string;
    fields?: ('ruleCriteria' | 'type')[];
  }) => {
    if (!ruleId) {
      return {};
    }

    if (rule.value.ruleId !== ruleId) {
      await fetchUserAlertRule({ ruleId });
    }

    if (fields) {
      return fields.reduce(
        (acc, cur) => {
          if (cur === 'type') {
            acc[cur] = lowerCase(rule.value[cur]);
          } else {
            acc[cur] = rule.value[cur];
          }
          return acc;
        },
        {} as Pick<Rule, 'ruleCriteria' | 'type'>,
      );
    }

    return rule.value;
  };

  return {
    getUserAlertRule,
  };
};
