<script lang="ts" setup>
import TimePeriodIndicator from '@/common/components/molecules/timePeriodIndicator/TimePeriodIndicator.vue';
import UnitContainer from '@/common/components/molecules/unitContainer/UnitContainer.vue';
import { CHART_COLOR, setup } from '@/alert/components/alertDetail/alertHistory.setup';
import { AlertDetailTabProps } from '@/alert/components/alertDetail/alertDetail.types';
import { GLOBAL_TIME_CURRENT_AND_PERIOD } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import TextViewerWindow from '@/common/components/organisms/textViewerWindow/TextViewerWindow.vue';
import ListHeader from '@/common/components/organisms/listHeader/ListHeader.vue';
import HelpTooltip from '@/common/components/molecules/HelpTooltip/HelpTooltip.vue';
import { getAlertGridComp } from '@/alert/components/alertGrid/alertGrid.utils';
import { USER_ENV_KEYS } from '@/common/define/userEnv.define';
import { CHECK_BY_TARGET } from '@/alert/utils/define';

const props = defineProps<AlertDetailTabProps>();

const {
  periodInfo,
  onUpdatedIndicator,

  ruleInfo,

  chartOption,
  chartData,

  tabPanels,
  selectedTab,
  gridColumns,
  gridData,
  isShowMessageWindow,
  eventAlertInfo,
  onClickCell,
  exportToExcelFromServer,
  t,
} = setup(props);

const { CRITICAL, WARNING } = CHART_COLOR;
</script>

<template>
  <section class="alert-detail-history">
    <div class="alert-history-toolbar">
      <time-period-indicator
        :period-info="periodInfo"
        :options="{
          ...GLOBAL_TIME_CURRENT_AND_PERIOD,
          interval: 'I5s',
        }"
        @update-period-info="onUpdatedIndicator"
      />
    </div>
    <div class="alert-history-chart">
      <p class="alert-history-chart__title">{{ t('WORD.HISTORY') }}</p>
      <unit-container class="alert-history-chart__content">
        <ev-chart
          :data="chartData"
          :options="chartOption"
        />
        <ul class="chart-legend">
          <li class="chart-legend-item critical">Critical</li>
          <li class="chart-legend-item warning">Warning</li>
        </ul>
      </unit-container>
    </div>
    <ev-tabs
      v-model="selectedTab"
      :panels="tabPanels"
      stretch
      class="alert-history-tab"
    >
      <ev-tab-panel
        v-for="item in tabPanels"
        :key="item.value"
        :text="item.text"
        :value="item.value"
      >
        <template v-if="selectedTab === 'sortByTime'">
          <list-header :title="t('WORD.HISTORY_LIST')">
            <template #titleInformation>
              <help-tooltip
                display-event-type="hover"
                pos="bottom"
              >
                <div class="rule-helps">
                  <div class="rule-help">
                    <h5 class="rule-help__header">{{ t('MESSAGE.ALERT_HISTORY_TITLE') }}</h5>
                    <p class="rule-help__content">
                      {{ t('MESSAGE.ALERT_HISTORY_MAX_ROWS') }}
                    </p>
                  </div>
                  <div class="rule-help">
                    <h5 class="rule-help__header">{{ t('WORD.ALERT') }}</h5>
                    <p class="rule-help__content">
                      <span>
                        {{ t('MESSAGE.CHECK_BY_TARGET_LAST_ALERT_HELP') }}
                      </span>
                      <span>
                        {{ t('MESSAGE.CHECK_BY_COMBINATION_LAST_ALERT_HELP') }}
                      </span>
                    </p>
                  </div>
                </div>
              </help-tooltip>
            </template>
          </list-header>
          <component
            :is="getAlertGridComp({ ruleCriteria: ruleInfo.ruleCriteria || CHECK_BY_TARGET })"
            :grid-columns="gridColumns"
            :grid-data="gridData"
            :options="{ useExcelFromServer: true, useDashboardJump: true }"
            :env-key="USER_ENV_KEYS.GRIDCOLUMNS_alert_alertDetail_historyList"
            :filter-env-key="USER_ENV_KEYS.FILTERSEARCH_alert_alertDetail_historyList"
            :export-excel-title="t('WORD.HISTORY_LIST')"
            type="detail"
            class="alert-history"
            @on-click-cell="onClickCell"
            @export-to-excel-from-server="exportToExcelFromServer"
          />
        </template>
      </ev-tab-panel>
    </ev-tabs>
  </section>
  <text-viewer-window
    v-model:is-show="isShowMessageWindow"
    :text="eventAlertInfo.messageText"
    is-network-alert
    :line-number="false"
    :network-info="eventAlertInfo.networkInfo"
    title="Message"
  />
</template>

<style lang="scss">
.alert-detail-history {
  display: flex;
  flex-direction: column;
  height: 100%;

  .alert-history-toolbar {
    display: flex;
    justify-content: flex-end;
    margin: 0 10px;
  }

  .time-period-refresh {
    margin: 0 10px;
  }

  .alert-history-chart {
    height: 166px;
    margin: 12px 10px 24px;
    border: 1px solid var(--alert-history-chart-border);

    &__title {
      height: 32px;
      padding: 0 12px;
      font-size: 14px;
      font-weight: 700;
      line-height: 32px;
    }

    &__content {
      height: calc(100% - 32px);
      padding: 12px;
    }

    .ev-chart {
      height: calc(100% - 17px) !important;
    }

    .chart-legend {
      display: flex;
      gap: 8px;
      padding: 4px 0 0 48px;
      font-size: 10px;
      color: var(--alert-history-chart-legend-color);
    }

    .chart-legend-item {
      position: relative;
      padding-left: 10px;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        transform: translateY(-50%);
        content: '';
      }

      &.critical::before {
        background-color: v-bind('CRITICAL');
      }

      &.warning::before {
        background-color: v-bind('WARNING');
      }
    }
  }

  .alert-history-tab {
    height: calc(100% - 226px);
  }
  .list-header {
    height: 20px;
    padding-bottom: 8px;

    &__wrapper-left {
      gap: 8px;
    }
  }

  .alert-history {
    height: calc(100% - 20px);
  }
}
/* stylelint-disable */
.rule-helps {
  padding: 6px 4px;
  .rule-help {
    padding: 12px 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &:nth-child(n + 2) {
      border-top: 1px solid var(--alert-history-tooltip-splitter-color);
    }
    &__header {
      margin-bottom: 8px;
      font-weight: 700;
    }
    &__content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      white-space: pre;
    }
  }
}
/* stylelint-enable */
</style>
