import { WidgetUUID } from '@/common/utils';
import { useDashboardViewStore } from '@/dashboard/stores/dashboard-view';
import { DASHBOARD } from '@/common/define/userEnv/dashboard.env.define';
import { storeToRefs } from 'pinia';
import { useUserEnvStore } from '@/common/stores/user-env';

export type DashboardUserEnvKeys = (typeof DASHBOARD)[keyof typeof DASHBOARD];

export const useDashboardUserEnv = () => {
  const { monitoringDashboard } = storeToRefs(useDashboardViewStore());
  const userEnvStore = useUserEnvStore();

  const getWidgetUserEnvKey = (
    envKey: DashboardUserEnvKeys,
    widgetUUID: WidgetUUID,
    dashboardId?: number,
  ) => {
    const currentDashboardId = monitoringDashboard.value.dashboardId ?? -1;
    const targetId = dashboardId || currentDashboardId;
    return `${envKey}_${targetId}_${widgetUUID}`;
  };

  const copyDashboardUserEnv = async (targetDashboardId: number, newDashboardId: number) => {
    const currentDashboardKeyStr = `_${targetDashboardId}_`;
    const currentEnvData = userEnvStore.getEnvValueByPattern(currentDashboardKeyStr);
    const { globalEnv, customEnv } = Object.keys(currentEnvData).reduce<{
      globalEnv: any[];
      customEnv: any[];
    }>(
      (acc, key) => {
        const newKey = key.replace(currentDashboardKeyStr, `_${newDashboardId}_`);
        const entry = { key: newKey, value: currentEnvData[key] };
        if (key.includes('global')) {
          acc.globalEnv.push(entry);
        } else {
          acc.customEnv.push(entry);
        }
        return acc;
      },
      { globalEnv: [], customEnv: [] },
    );
    await userEnvStore.setEnvValue(globalEnv, true);
    await userEnvStore.setEnvValue(customEnv, false);
  };

  return { getWidgetUserEnvKey, copyDashboardUserEnv };
};
