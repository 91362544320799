/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  RsaVO,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getRsaKeyRsaControllerAxios = ({
  creationTime,
  id,
  lastAccessedTime,
  maxInactiveInterval,
  _new,
  rsaId,
  valueNames,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  creationTime?: number;
  id?: string;
  lastAccessedTime?: number;
  maxInactiveInterval?: number;
  _new?: boolean;
  rsaId?: string;
  valueNames?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<RsaVO> => {
  const localVarPath = '/api/v1/rsa-key';
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      creationTime,
      id,
      lastAccessedTime,
      maxInactiveInterval,
      _new,
      rsaId,
      valueNames,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export { getRsaKeyRsaControllerAxios };
