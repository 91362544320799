<script setup lang="ts">
import SimpleTextTooltip from '@/common/components/molecules/simpleTextTooltip/SimpleTextTooltip.vue';
import { Props, setup } from './featuredIcon.setup';

const props = withDefaults(defineProps<Props>(), {
  iconCode: 'icon-multi',
  size: 'small',
  iconColor: 'default',
});
const { featuredIcon, tooltipMouseEvent, tooltipText, onMouseEventInTarget, needsBackground } =
  setup(props);
</script>

<template>
  <template v-if="props.iconCode">
    <ev-icon
      :icon="featuredIcon"
      :size="props.size"
      :class="[
        'featured-icon',
        { 'featured-icon--with-background': needsBackground && props.iconColor === 'white' },
      ]"
      @mouseenter="onMouseEventInTarget"
      @mouseleave="onMouseEventInTarget"
    />
    <simple-text-tooltip
      :mouse-event="tooltipMouseEvent"
      :text="tooltipText"
      base-pos="bottom"
    />
  </template>
</template>

<style lang="scss" scoped>
.featured-icon {
  &--with-background {
    background-color: #ffffff;
  }
}
</style>
