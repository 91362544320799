/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  ApiResponseDatabaseEnabledCountResponse,
  ApiResponseDatabaseResponseWithColumn,
  ApiResponseInstanceInformationItemV8,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getV8InstancesDatabaseInstanceV8ControllerAxios = ({
  databaseTypes,
  favoriteOnly,
  fromTime,
  instanceGroupIds,
  period,
  tenantId,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  databaseTypes?: Array<
    | 'altibase'
    | 'cassandra'
    | 'cubrid'
    | 'db2'
    | 'informix'
    | 'mariadb'
    | 'mongodb'
    | 'mysql'
    | 'oracle'
    | 'postgresql'
    | 'ppas'
    | 'redis'
    | 'sqlserver'
    | 'sybase'
    | 'tibero'
    | 'unknown'
  >;
  favoriteOnly?: boolean;
  fromTime?: string;
  instanceGroupIds?: Array<string>;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  tenantId?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseDatabaseResponseWithColumn> => {
  const localVarPath = '/api/v8/database/instances';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      databaseTypes,
      favoriteOnly,
      fromTime,
      instanceGroupIds,
      period,
      tenantId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getEnableInstancesDatabaseInstanceV8ControllerAxios = ({
  databaseType,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  databaseType?: 'cubrid' | 'mongodb' | 'mysql' | 'oracle' | 'postgresql' | 'redis' | 'sqlserver';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseDatabaseEnabledCountResponse> => {
  const localVarPath = '/api/v8/database/instances/enabled';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      databaseType,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getInstanceInformationDatabaseInstanceV8ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseInstanceInformationItemV8> => {
  const localVarPath = '/api/v8/metav6/database-instance/{instanceId}/information'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getV8InstancesDatabaseInstanceV8ControllerAxios,
  getEnableInstancesDatabaseInstanceV8ControllerAxios,
  getInstanceInformationDatabaseInstanceV8ControllerAxios,
};
