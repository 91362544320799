/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  ApiResponseSpanDao,
  ApiResponseSpanExceptionDao,
  ApiResponseSpanLogDao,
  ApiResponseSpanRelationItem,
  ApiResponseSpanTagDao,
  ApiResponseTraceListResponse,
  ApiResponseTxnCurrentStat,
  ApiResponseTxnTopGroup,
  FieldResponse,
  FilterResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getTransactionCurrentApplicationTransactionControllerAxios = ({
  fromTime,
  toTime,
  wasGroupId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  toTime: string;
  wasGroupId: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseTxnCurrentStat> => {
  const localVarPath = '/api/v7/application/transactions/current-stat';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      toTime,
      wasGroupId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getInfoApplicationTransactionControllerAxios = ({
  fromTime,
  spanId,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  spanId: string;
  toTime: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseSpanDao> => {
  const localVarPath = '/api/v7/application/transactions/{spanId}/info'.replace(
    `{${'spanId'}}`,
    encodeURIComponent(String(spanId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getLogApplicationTransactionControllerAxios = ({
  fromTime,
  spanId,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  spanId: string;
  toTime: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseSpanLogDao> => {
  const localVarPath = '/api/v7/application/transactions/{spanId}/log'.replace(
    `{${'spanId'}}`,
    encodeURIComponent(String(spanId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTraceMapApplicationTransactionControllerAxios = ({
  fromTime,
  toTime,
  traceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  toTime: string;
  traceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseSpanRelationItem> => {
  const localVarPath = '/api/v7/application/transactions/{traceId}/map'.replace(
    `{${'traceId'}}`,
    encodeURIComponent(String(traceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getErrorApplicationTransactionControllerAxios = ({
  fromTime,
  spanId,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  spanId: string;
  toTime: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseSpanExceptionDao> => {
  const localVarPath = '/api/v7/application/transactions/{spanId}/span-error'.replace(
    `{${'spanId'}}`,
    encodeURIComponent(String(spanId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSpanTreeApplicationTransactionControllerAxios = ({
  fromTime,
  toTime,
  traceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  toTime: string;
  traceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseSpanRelationItem> => {
  const localVarPath = '/api/v7/application/transactions/{traceId}/span-tree'.replace(
    `{${'traceId'}}`,
    encodeURIComponent(String(traceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTagApplicationTransactionControllerAxios = ({
  fromTime,
  spanId,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  spanId: string;
  toTime: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseSpanTagDao> => {
  const localVarPath = '/api/v7/application/transactions/{spanId}/tag'.replace(
    `{${'spanId'}}`,
    encodeURIComponent(String(spanId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTransactionTopGroupApplicationTransactionControllerAxios = ({
  fromTime,
  toTime,
  wasGroupId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  toTime: string;
  wasGroupId: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseTxnTopGroup> => {
  const localVarPath = '/api/v7/application/transactions/top-group';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      toTime,
      wasGroupId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTraceApplicationTransactionControllerAxios = ({
  dataTypes,
  fromTime,
  requestTypes,
  toTime,
  traceStatus,
  column,
  isReport,
  pageNum,
  pageSize,
  report,
  reportColumnList,
  search,
  sortType,
  timezone,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dataTypes: Array<'EtoE' | 'OpenTelemetry'>;
  fromTime: string;
  requestTypes: Array<'HTTP' | 'Others'>;
  toTime: string;
  traceStatus: 'ALL' | 'ERROR' | 'NORMAL';
  column?: string;
  isReport?: boolean;
  pageNum?: number;
  pageSize?: number;
  report?: boolean;
  reportColumnList?: Array<string>;
  search?: string;
  sortType?: 'ASC' | 'DESC';
  timezone?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseTraceListResponse> => {
  const localVarPath = '/api/v7/application/transactions/trace';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      column,
      dataTypes,
      fromTime,
      isReport,
      pageNum,
      pageSize,
      report,
      reportColumnList,
      requestTypes,
      search,
      sortType,
      timezone,
      toTime,
      traceStatus,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTraceListFieldsApplicationTransactionControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<FieldResponse> => {
  const localVarPath = '/api/v7/application/transactions/trace/fields';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTraceFilterApplicationTransactionControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<FilterResponse> => {
  const localVarPath = '/api/v7/application/transactions/trace/filter';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getTransactionCurrentApplicationTransactionControllerAxios,
  getInfoApplicationTransactionControllerAxios,
  getLogApplicationTransactionControllerAxios,
  getTraceMapApplicationTransactionControllerAxios,
  getErrorApplicationTransactionControllerAxios,
  getSpanTreeApplicationTransactionControllerAxios,
  getTagApplicationTransactionControllerAxios,
  getTransactionTopGroupApplicationTransactionControllerAxios,
  getTraceApplicationTransactionControllerAxios,
  getTraceListFieldsApplicationTransactionControllerAxios,
  getTraceFilterApplicationTransactionControllerAxios,
};
