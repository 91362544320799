import { getCurrentInstance, hasInjectionContext, inject, ref } from 'vue';
import { useInternational } from '@/common/locale';
import { showErrorMsg } from '@/common/utils/commonUtils';
import { defineStore, storeToRefs } from 'pinia';
import { useSlideDetail } from '@/common/stores/slide-detail.logic';

export * from './slide-detail.logic';

const useUtil = () => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();

  const withErrorMsg =
    <T extends (...args: any[]) => any>(fn: T) =>
    (...args: Parameters<T>) => {
      try {
        fn(...args);
      } catch (e: unknown) {
        if (e instanceof Error) {
          showErrorMsg(ctx, t(e.message));
        }
      }
    };

  return {
    withErrorMsg,
  };
};

export const useSlideDetailStore = defineStore('slideDetail', () => {
  const { addTabPanel, addSplitTabPanel, ...rest } = useSlideDetail();
  const { withErrorMsg } = useUtil();

  const isExpand = ref(false);

  const setIsExpand = (value: boolean) => {
    isExpand.value = value;
  };

  return {
    addTabPanel: withErrorMsg(addTabPanel),
    addSplitTabPanel: withErrorMsg(addSplitTabPanel),
    isExpand,
    setIsExpand,
    ...rest,
  };
});

export const useSplitSlideDetailStore = defineStore('splitSlideDetail', () => {
  const slideDetailStore = useSlideDetailStore();
  const {
    addTabPanel,
    setSplitOpened,
    addSplitTabPanel,
    initSplitTabPanel,
    setIsSplitDetailLoading,
    selectedSplitTabComputed,
    setIsExpand,
  } = slideDetailStore;

  const {
    isSplitOpened,
    splitDetailComp,
    slideSplitTabPanel,
    splitSelectedSlideTabPanel,
    isSplitDetailLoading,
  } = storeToRefs(slideDetailStore);

  return {
    addTabPanel,
    isOpened: isSplitOpened,
    selectedTabComputed: selectedSplitTabComputed,
    deleteLastSlideDetailHistory: initSplitTabPanel,
    setOpened: setSplitOpened,
    addSplitTabPanel,
    detailComp: splitDetailComp,
    slideTabPanel: slideSplitTabPanel,
    selectedSlideTabPanel: splitSelectedSlideTabPanel,
    isDetailLoading: isSplitDetailLoading,
    initSplitTabPanel,
    setIsDetailLoading: setIsSplitDetailLoading,
    setIsExpand,
  };
});

export const getSlideDetailStore = () => {
  if (!hasInjectionContext()) {
    return useSlideDetailStore();
  }
  const store = inject('slideDetailStore', useSlideDetailStore) || useSlideDetailStore;
  return store();
};
