import { TokenStatus } from '@/common/components/molecules/filterSearch/filterSearch.type';

export const LOGICAL_OPERATORS = [
  {
    name: 'OR',
    value: 'OR',
  },
  {
    name: 'AND',
    value: 'AND',
  },
] as const;

export const TOKEN_STATUS: Record<TokenStatus, TokenStatus> = {
  EMPTY: 'EMPTY',
  KEY_DONE: 'KEY_DONE',
  OPERATOR_DONE: 'OPERATOR_DONE',
  VALUE_ING: 'VALUE_ING',
  VALUE_DONE: 'VALUE_DONE',
} as const;

export const MAXIMUM_HISTORY_SIZE = 5;

export const FILTER_SEARCH_OPERATORS = [
  'like',
  'not like',
  '==',
  '!=',
  '>',
  '>=',
  '<',
  '<=',
] as const;

export const AGE_UNITS = {
  s: 1,
  m: 60,
  h: 3600,
  d: 86400,
  M: 2592000,
  y: 31536000,
} as const;

export type AgeUnit = keyof typeof AGE_UNITS;
