<template>
  <component
    :is="props.tag"
    class="flex-col"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed, CSSProperties } from 'vue';

const props = withDefaults(
  defineProps<{
    tag?:
      | 'div'
      | 'section'
      | 'article'
      | 'aside'
      | 'main'
      | 'header'
      | 'footer'
      | 'nav'
      | 'ul'
      | 'ol';
    spacing?: number;
    align?: CSSProperties['alignItems'];
    justify?: CSSProperties['justifyContent'];
    wrap?: CSSProperties['flexWrap'];
  }>(),
  {
    tag: 'div',
    spacing: 0,
    align: 'normal',
    justify: 'normal',
    wrap: 'nowrap',
  },
);

const gap = computed(() => `${props.spacing}px`);
</script>

<style scoped>
.flex-col {
  display: flex;
  flex-flow: column v-bind('props.wrap');
  gap: v-bind('gap');
  align-items: v-bind('props.align');
  justify-content: v-bind('props.justify');
}
</style>
