import { TargetTagType, WidgetUUID } from '@/common/utils';
import { generateUUID } from '@/common/utils/commonUtils';
import {
  WidgetGroupValueResponseV8,
  WidgetValueTargetDetailV7,
  WidgetValueTargetV7,
} from '@/openapi/metaV6/model';
import { cloneDeep, isNil, omit } from 'lodash-es';
import { useWidgetUUIDStore } from '../stores/widget-uuid';
import { applyDefaultChartOptions } from '../utils/chartOption.utils';
import { getWidgetCompName, isJsonType } from '../utils/dashboardUtils';
import {
  DEFAULT_WIDGET_COLOR_OPTION,
  DEFAULT_WIDGET_SIZE,
  DEFAULT_WIDGET_TITLE_STYLE,
  DISPLAY_STYLE_KEY,
  WIDGET_CHART_TYPE_KEY,
} from '../utils/define';
import { GridLayoutItem, WidgetChartData } from '../utils/types';
import {
  chartTypeMap,
  createTypeDtoMap,
  createTypeMap,
  displayStyleMap,
  targetTagTypeMap,
  timePeriodMap,
  widgetDataTypeMap,
  widgetSeriesTypeMap,
  widgetTypeMap,
} from './widgets.datas';

const convertTitleOption = (
  titleOptionDto: WidgetGroupValueResponseV8['titleOption'],
): GridLayoutItem['titleOption'] => {
  return titleOptionDto && isJsonType(titleOptionDto)
    ? JSON.parse(titleOptionDto)
    : {
        showTitle: true,
        titleText: '',
        titleStyle: cloneDeep(DEFAULT_WIDGET_TITLE_STYLE),
      };
};

const convertToWidgetColor = (
  widgetColorDto: WidgetGroupValueResponseV8['widgetColor'],
): GridLayoutItem['widgetColor'] => {
  return widgetColorDto && isJsonType(widgetColorDto)
    ? JSON.parse(widgetColorDto)
    : cloneDeep(DEFAULT_WIDGET_COLOR_OPTION);
};

const convertToChartOption = (
  chartOptionDto: WidgetGroupValueResponseV8['chartOption'],
): GridLayoutItem['chartOption'] => {
  return chartOptionDto && isJsonType(chartOptionDto) ? JSON.parse(chartOptionDto) : {};
};

const convertToCreateType = (
  createTypeDto: WidgetGroupValueResponseV8['createType'],
): GridLayoutItem['createType'] => {
  if (isNil(createTypeDto)) {
    return 'base';
  }
  const createType = createTypeMap[createTypeDto];
  if (!createType) {
    throw new Error(`${createTypeDto} createType이 추가되었습니다.`);
  }

  return createType;
};

const convertToFilterOption = (
  filterOptionDto: WidgetGroupValueResponseV8['filterOption'],
): GridLayoutItem['filterOption'] => {
  return filterOptionDto && isJsonType(filterOptionDto) ? JSON.parse(filterOptionDto) : undefined;
};

const convertToCalendarTimeRange = (
  calendarTimeRangeDto: WidgetGroupValueResponseV8['value'],
): GridLayoutItem['calendarTimeRange'] => {
  return calendarTimeRangeDto && isJsonType(calendarTimeRangeDto)
    ? JSON.parse(calendarTimeRangeDto)
    : undefined;
};

const convertToWidgetType = (
  widgetTypeDto: WidgetGroupValueResponseV8['widgetType'],
): GridLayoutItem['widgetType'] => {
  if (!widgetTypeDto) {
    return 'GRAPHS';
  }
  const widgetType = widgetTypeMap[widgetTypeDto];
  if (!widgetType) {
    throw new Error(`${widgetTypeDto} widgetType이 추가되었습니다.`);
  }

  return widgetType;
};

const convertToChartType = (
  chartTypeDto: WidgetGroupValueResponseV8['chartType'],
): GridLayoutItem['chartType'] => {
  if (!chartTypeDto) {
    return 'TIME_SERIES';
  }
  const chartType = chartTypeMap[chartTypeDto];
  if (!chartType) {
    throw new Error(`${chartTypeDto} chartType이 추가되었습니다.`);
  }

  return chartType;
};

const convertToDisplayStyle = (
  displayStyleDto: WidgetGroupValueResponseV8['displayStyle'],
): GridLayoutItem['displayStyle'] => {
  if (isNil(displayStyleDto) || displayStyleDto === '') {
    return '';
  }
  const displayStyle = displayStyleMap[displayStyleDto];
  if (!displayStyle) {
    throw new Error(`${displayStyleDto} displayStyle이 추가되었습니다.`);
  }

  return displayStyle;
};

const convertToTimePeriod = (
  timePeriodDto: WidgetGroupValueResponseV8['period'],
): GridLayoutItem['timePeriod'] => {
  if (isNil(timePeriodDto)) {
    return undefined;
  }
  const timePeriod = timePeriodMap[timePeriodDto];
  if (!timePeriod) {
    throw new Error(`${timePeriodDto} timePeriod이 추가되었습니다.`);
  }

  return timePeriod;
};

const convertToWidgetSeriesType = (
  seriesTypeDto: WidgetValueTargetV7['seriesType'],
): WidgetChartData['seriesType'] => {
  if (isNil(seriesTypeDto)) {
    return undefined;
  }
  const seriesType = widgetSeriesTypeMap[seriesTypeDto];
  if (!seriesType) {
    throw new Error(`${seriesTypeDto} seriesType이 추가되었습니다.`);
  }

  return seriesType;
};

const convertToWidgetDataType = (
  dataTypeDto: WidgetValueTargetV7['dataType'],
): WidgetChartData['dataType'] => {
  if (isNil(dataTypeDto)) {
    return undefined;
  }
  const dataType = widgetDataTypeMap[dataTypeDto];
  if (!dataType) {
    throw new Error(`${dataTypeDto} dataType이 추가되었습니다.`);
  }

  return dataType;
};

const convertToTargetTagType = (
  tagTypeDto: WidgetValueTargetDetailV7['tagType'],
): TargetTagType => {
  const tagType = targetTagTypeMap[tagTypeDto];
  if (!tagType) {
    throw new Error(`${tagTypeDto} tagType이 추가되었습니다.`);
  }

  return tagType;
};

const covertToChartData = (chartDataDto: WidgetValueTargetV7): WidgetChartData => {
  return {
    ...chartDataDto,
    id: generateUUID(),
    category: chartDataDto.category ?? '',
    seriesType: convertToWidgetSeriesType(chartDataDto.seriesType),
    dataType: convertToWidgetDataType(chartDataDto.dataType),
    targets: chartDataDto.targets.map((target) => ({
      ...target,
      tagType: convertToTargetTagType(target.tagType),
      tagValue: target.tagValueId ?? '',
    })),
    value:
      chartDataDto.value && isJsonType(chartDataDto.value) ? JSON.parse(chartDataDto.value) : '',
  };
};

const convertToExtraData = (
  extraDataDto: WidgetGroupValueResponseV8['extraData'],
): GridLayoutItem['extraData'] => {
  return extraDataDto?.map((extraData) => ({
    extraId: extraData.extraId ?? '',
    targets:
      extraData.targets?.map((target) =>
        target.map((item) => ({
          id: item.id ?? '',
          value: item.value ?? '',
        })),
      ) ?? [],
  }));
};

const convertToGridLayoutItem = (widgetChildValue: WidgetGroupValueResponseV8): GridLayoutItem => {
  const chartType = convertToChartType(widgetChildValue.chartType);
  const displayStyle = convertToDisplayStyle(widgetChildValue.displayStyle);

  const titleOption = convertTitleOption(widgetChildValue.titleOption);

  const widgetSize = DEFAULT_WIDGET_SIZE[chartType][displayStyle];

  const { generateWidgetUUID } = useWidgetUUIDStore();

  return {
    i: widgetChildValue.widgetUUID ?? generateWidgetUUID(),
    x: widgetChildValue.x ?? 0,
    y: widgetChildValue.y ?? 0,
    w: widgetChildValue.width ?? 1,
    h: widgetChildValue.height ?? 1,
    widgetMappingId: widgetChildValue.widgetMappingId,
    compName: getWidgetCompName(WIDGET_CHART_TYPE_KEY[chartType], DISPLAY_STYLE_KEY[displayStyle]),
    widgetType: convertToWidgetType(widgetChildValue.widgetType),
    chartType,
    titleOption: convertTitleOption(widgetChildValue.titleOption),
    widgetColor: convertToWidgetColor(widgetChildValue.widgetColor),
    createType: convertToCreateType(widgetChildValue.createType),
    displayStyle,
    headerType: titleOption.headerType ?? 'basic',
    timePeriod: convertToTimePeriod(widgetChildValue.period),
    chartData: widgetChildValue.chartData?.map(covertToChartData),
    colorTheme: widgetChildValue.colorTheme,
    minW: widgetSize?.minWidth,
    minH: widgetSize?.minHeight,
    chartOption: applyDefaultChartOptions(
      chartType,
      convertToChartOption(widgetChildValue.chartOption),
    ),
    filterOption: convertToFilterOption(widgetChildValue.filterOption),
    calendarTimeRange: convertToCalendarTimeRange(widgetChildValue.value),
    customId: widgetChildValue.customId,
    isInViewport: false,
    originHeight: undefined,
    extraData: convertToExtraData(widgetChildValue.extraData),
  };
};

export const convertListToGridLayoutItem = (
  widgetGroupValue: WidgetGroupValueResponseV8[],
): GridLayoutItem => {
  const setGroup = (parent: GridLayoutItem) => {
    const childrenGridLayout = widgetGroupValue
      .filter((widget) => widget.parentUUID === parent?.i && !!widget.widgetUUID)
      .map((child) => setGroup(convertToGridLayoutItem(child)));

    return parent ? { ...parent, children: childrenGridLayout } : null;
  };

  const parentWidget = widgetGroupValue.find((widget) => isNil(widget.parentUUID));
  return parentWidget ? setGroup(convertToGridLayoutItem(parentWidget)) : null;
};

export const convertToGridLayoutItemList = (
  widgetGroupValue: WidgetGroupValueResponseV8[][],
): GridLayoutItem[] => {
  return widgetGroupValue.map(convertListToGridLayoutItem);
};

const convertToCreateTypeDto = (
  createType: GridLayoutItem['createType'],
): WidgetGroupValueResponseV8['createType'] => {
  const createTypeDto = createTypeDtoMap[createType];
  if (!createTypeDto) {
    throw new Error(`${createType} createType을 추가해주세요.`);
  }

  return createTypeDto;
};

const convertToExtraDataDto = (
  extraData: GridLayoutItem['extraData'],
): WidgetGroupValueResponseV8['extraData'] => {
  return extraData?.map((data) => ({
    extraId: data.extraId,
    targets: data.targets.map((target) =>
      target.map((item) => ({
        id: item.id,
        value: item.value,
      })),
    ),
  }));
};

const convertToWidgetGroupValueResponseV8 = (
  gridLayoutItem: GridLayoutItem,
  parentUUID: WidgetUUID | null,
): WidgetGroupValueResponseV8 => {
  return {
    widgetUUID: gridLayoutItem.i,
    parentUUID: parentUUID ?? undefined,
    x: gridLayoutItem.x,
    y: gridLayoutItem.y,
    width: gridLayoutItem.w,
    height: gridLayoutItem.h,
    widgetType: gridLayoutItem.widgetType,
    chartType: gridLayoutItem.chartType,
    displayStyle: gridLayoutItem.displayStyle,
    titleOption: JSON.stringify({
      ...gridLayoutItem.titleOption,
      headerType: gridLayoutItem.headerType,
    }),
    widgetColor: JSON.stringify(gridLayoutItem.widgetColor),
    chartOption: JSON.stringify(gridLayoutItem.chartOption),
    widgetMappingId: gridLayoutItem.widgetMappingId,
    filterOption:
      gridLayoutItem.filterOption != null ? JSON.stringify(gridLayoutItem.filterOption) : undefined,
    period: gridLayoutItem.timePeriod,
    value: gridLayoutItem.calendarTimeRange ? JSON.stringify(gridLayoutItem.calendarTimeRange) : '',
    colorTheme: gridLayoutItem.colorTheme,
    customId: gridLayoutItem.customId,
    chartData:
      gridLayoutItem.chartData?.map((chartData) => ({
        ...chartData,
        targets: chartData.targets.map((target) => ({
          ...target,
          tagValueId: target.tagValue,
        })),
        value: chartData.value ? JSON.stringify(chartData.value) : '',
      })) ?? [],
    createType: convertToCreateTypeDto(gridLayoutItem.createType),
    extraData: convertToExtraDataDto(gridLayoutItem.extraData),
  };
};

export const convertToWidgetGroupValueResponseV8Group = (
  gridLayoutItem: GridLayoutItem,
  parentUUID: WidgetUUID | null,
): WidgetGroupValueResponseV8[] => {
  const { children } = gridLayoutItem;
  if (children && children.length > 0) {
    const exceptChild = omit(gridLayoutItem, 'children');
    return [
      convertToWidgetGroupValueResponseV8(exceptChild, parentUUID),
      ...children.flatMap((child) =>
        convertToWidgetGroupValueResponseV8Group(child, gridLayoutItem.i),
      ),
    ];
  }
  return [convertToWidgetGroupValueResponseV8(gridLayoutItem, parentUUID)];
};

export const convertToWidgetGroupValueResponseV8List = (
  gridLayoutItemList: GridLayoutItem[],
): WidgetGroupValueResponseV8[][] => {
  return gridLayoutItemList.map((item) => convertToWidgetGroupValueResponseV8Group(item, null));
};
