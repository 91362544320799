import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useInternational } from '@/common/locale';
import { useViewModeStore } from '@/common/stores/view-mode';
import { getVersion } from '@/common/utils/commonUtils';

export const setup = () => {
  const { t } = useInternational();
  const { viewMode } = storeToRefs(useViewModeStore());

  const moduleInfo = computed<string[]>(() => {
    const version = getVersion();
    const currentViewMode = t(`WORD.${viewMode.value.toUpperCase()}`);
    return [
      t('WORD.GNB.HELP.SYSTEM_MODULE_VERSION', { version, viewMode: currentViewMode }),
      t('WORD.GNB.HELP.K8S_MODULE_VERSION', { version }),
      t('WORD.GNB.HELP.APPLICATION_MODULE_VERSION', { version }),
      t('WORD.GNB.HELP.DATABASE_MODULE_VERSION', { version, viewMode: currentViewMode }),
    ];
  });

  return {
    t,

    moduleInfo,
  };
};
