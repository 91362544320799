/* eslint-disable no-redeclare */
export const CountUnit = ['', 'K', 'M', 'G', 'T'] as const;
export type CountUnit = (typeof CountUnit)[number];

export const BinaryBytesUnit = ['byte', 'KiB', 'MiB', 'GiB', 'TiB'] as const;
export type BinaryBytesUnit = (typeof BinaryBytesUnit)[number];

export const TimeUnit = ['ps', 'µs', 'ms', 's', 'm', 'h'] as const;
export type TimeUnit = (typeof TimeUnit)[number];

export const CoreUnit = [
  'nanocore',
  'microcore',
  'millicore',
  'core',
  'kilocore',
  'megacore',
  'gigacore',
  'teracore',
] as const;
export type CoreUnit = (typeof CoreUnit)[number];

export const BytesUnit = ['byte', 'KB', 'MB', 'GB', 'TB'] as const;
export type BytesUnit = (typeof BytesUnit)[number];
