import { ref } from 'vue';
import { defineStore } from 'pinia';
import { MenuInfo } from '@/common/components/organisms/baseMenu/types';
import { VIEW_MODE } from '@/common/define/common.define';
import { useViewModeStore } from './view-mode';
import { BASE_MENU_VALUE } from '../components/organisms/baseMenu/baseMenu.define';

export interface MenuHistory {
  [key: string]: string;
}

export interface MainPanelInfo {
  isMainPanelExpanded: boolean; // 기본 GNB 좌우 확장 여부
  selectedCategory?: string;
  selectedSubMenu?: string;
}

export interface SubPanelInfo {
  openedSubPanelMenuInfo: MenuInfo | null;
  selectedSubPanelMenu?: string;
}

export const useBaseMenuStore = defineStore('baseMenuStore', () => {
  const { viewMode } = useViewModeStore();
  const menuHistory = ref<MenuHistory>({});
  const menuVisitRoute = ref<MenuInfo[]>([]);

  const mainPanelInfo = ref<MainPanelInfo>({ isMainPanelExpanded: false });
  const subPanelInfo = ref<SubPanelInfo>({ openedSubPanelMenuInfo: null });
  const isBaseMenuHidden = ref<boolean>(false);

  const getLastMenu = (category: string) => menuHistory.value?.[category] ?? '';
  const updateLastMenu = (category: string, lastMenu: string) => {
    menuHistory.value[category] = lastMenu;
  };

  const destroyLastMenu = () => {
    menuHistory.value = {};
  };

  const toggleBaseMenu = () => {
    isBaseMenuHidden.value = !isBaseMenuHidden.value;
  };

  const selectSubPanelMenu = (selectedMenu: string) => {
    subPanelInfo.value.selectedSubPanelMenu = selectedMenu;
  };

  const selectCategory = ({
    menuValue,
    subMenuValue,
  }: {
    menuValue?: string;
    subMenuValue?: string;
  }) => {
    if (menuValue) {
      mainPanelInfo.value.selectedCategory = menuValue;
    }
    if (subMenuValue) {
      mainPanelInfo.value.selectedSubMenu = subMenuValue;
    }
  };

  const openSubPanel = (menuList: MenuInfo) => {
    // 우측 패널 열림
    mainPanelInfo.value.selectedSubMenu = menuList.value;
    if (subPanelInfo.value.openedSubPanelMenuInfo?.value === menuList.value) {
      return;
    }
    subPanelInfo.value.openedSubPanelMenuInfo = menuList;
  };

  const closeSubPanel = () => {
    // 우측 패널 닫힘
    subPanelInfo.value.openedSubPanelMenuInfo = null;
    delete subPanelInfo.value.selectedSubPanelMenu;
  };

  const shrinkMainPanel = () => {
    // 확장된 메뉴 축소
    mainPanelInfo.value.isMainPanelExpanded = false;
    delete mainPanelInfo.value.selectedSubMenu;
  };

  const convertSubMenuForPADatabase = (subMenuValue?: string) => {
    if (!subMenuValue || viewMode !== VIEW_MODE.EXEMONE) return subMenuValue;
    if (subMenuValue.startsWith(BASE_MENU_VALUE.ORACLE)) {
      return BASE_MENU_VALUE.ORACLE;
    }
    if (subMenuValue.startsWith(BASE_MENU_VALUE.PG)) {
      return BASE_MENU_VALUE.PG;
    }
    if (subMenuValue.startsWith(BASE_MENU_VALUE.MYSQL)) {
      return BASE_MENU_VALUE.MYSQL;
    }
    if (subMenuValue.startsWith(BASE_MENU_VALUE.SQLSERVER)) {
      return BASE_MENU_VALUE.SQLSERVER;
    }
    return subMenuValue;
  };

  const toggleCategory = (menuValue?: string, subMenuValue?: string) => {
    // 특정 메뉴 클릭하면 열림
    if (!mainPanelInfo.value.isMainPanelExpanded) {
      // 메뉴 바 좁혀져 있을 때 확장
      mainPanelInfo.value.isMainPanelExpanded = true;

      if (
        !mainPanelInfo.value.selectedCategory ||
        mainPanelInfo.value.selectedCategory !== menuValue
      ) {
        // 열린 메뉴가 없거나 다른 메뉴 클릭할 때
        mainPanelInfo.value.selectedCategory = menuValue;
      }
    } else if (mainPanelInfo.value.selectedCategory === menuValue) {
      // 메뉴 바 확장되어 있고 같은 메뉴 한번 더 클릭할 때
      if (
        !(mainPanelInfo.value.selectedCategory === BASE_MENU_VALUE.HELP) &&
        !(mainPanelInfo.value.selectedCategory === BASE_MENU_VALUE.SETTINGS)
      ) {
        // 고객지원 메뉴, 프로필 메뉴는 여닫는 형태가 아니므로 delete 불필요
        delete mainPanelInfo.value.selectedCategory;
      }
      delete mainPanelInfo.value.selectedSubMenu;
    } else {
      // 메뉴 바 확장되어 있고 다른 메뉴 클릭할 때
      mainPanelInfo.value.selectedCategory = menuValue;
    }
    if (subMenuValue) {
      // 열린 메뉴 내부의 하위 메뉴 선택
      mainPanelInfo.value.selectedSubMenu = convertSubMenuForPADatabase(subMenuValue);
    }

    if (
      subPanelInfo.value.openedSubPanelMenuInfo &&
      menuValue !== BASE_MENU_VALUE.SETTINGS &&
      menuValue !== BASE_MENU_VALUE.HELP
    ) {
      closeSubPanel();
    }
  };

  const resetMenuInfo = () => {
    closeSubPanel();
    shrinkMainPanel();
    destroyLastMenu();
  };

  return {
    menuHistory,
    menuVisitRoute,

    getLastMenu,
    updateLastMenu,

    mainPanelInfo,
    subPanelInfo,

    selectSubPanelMenu,
    selectCategory,
    openSubPanel,
    closeSubPanel,

    toggleCategory,
    shrinkMainPanel,

    resetMenuInfo,

    isBaseMenuHidden,
    toggleBaseMenu,
  };
});
