import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { VIEW_MODE } from '@/common/define/common.define';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import { RouteRecordRaw } from 'vue-router';

const PREFIX_APPLICATION = 'application_';

const applicationMenu: RouteRecordRaw[] = [
  {
    path: '/application/',
    name: `${PREFIX_APPLICATION}Application`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION,
      invisibleViewMode: [VIEW_MODE.MAXGAUGE],
    },
    children: [
      {
        path: 'was',
        name: `${PREFIX_APPLICATION}WAS`,
        component: () =>
          import(/* webpackChunkName: "application-was" */ '../views/wasView/WasView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_WAS,
        },
      },
      // NOTE: tmp
      // {
      //   path: 'traces',
      //   name: `${PREFIX_APPLICATION}Trace`,
      //   component: () =>
      //     import(/* webpackChunkName: "application-traces" */ '../views/tracesView/TracesView.vue'),
      // },
      {
        path: 'transaction',
        name: `${PREFIX_APPLICATION}Transaction`,
        component: () =>
          import(
            /* webpackChunkName: "application-transaction" */ '@/application/views/TransactionView/TransactionView.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_TRANSACTION,
        },
      },
      // URL 모니터링 숨김 처리 Agent 완료 전 까지
      /*   {
        path: 'urlMonitoring',
        name: `${PREFIX_APPLICATION}URL_Monitoring`,
        component: () =>
          import(
            /!* webpackChunkName: "application-urlMonitoring" *!/ '../views/urlMonitoring/UrlMonitoring.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_TRANSACTION,
        },
      }, */
    ],
  },
];

const rumMenu: RouteRecordRaw[] = [
  {
    path: '/application/',
    name: `${PREFIX_APPLICATION}RUM`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      invisibleViewMode: [VIEW_MODE.MAXGAUGE],
    },
    children: [
      {
        path: 'pagePerformanceMonitoring',
        name: `${PREFIX_APPLICATION}Page Performance Monitoring`,
        component: () =>
          import(
            /* webpackChunkName: "application-was" */ '../views/pagePerformanceMonitoringView/PagePerformanceMonitoringView.vue'
          ),
        meta: {
          rolePermission:
            ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_PAGE_PERFORMANCE_MONITORING,
        },
      },
      // NOTE: 11월 범위에서 보류
      // {
      //   path: 'userMonitoring',
      //   name: `${PREFIX_APPLICATION}User Monitoring`,
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "application-user-monitoring" */ '../views/browserErrorTracking/BrowserErrorTracking.vue'
      //     ),
      //   meta: {
      //     // NOTE: role permission add
      //     // rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_RUM,
      //   },
      // },
      {
        path: 'browserErrorTracking',
        name: `${PREFIX_APPLICATION}Browser Error Tracking`,
        component: () =>
          import(
            /* webpackChunkName: "application-browser-error-tracking" */ '../views/browserErrorTracking/BrowserErrorTracking.vue'
          ),
        meta: {
          rolePermission:
            ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_BROWSER_ERROR_TRACKING,
        },
      },
    ],
  },
];

const getApplicationNaviMenu = () => {
  const filteredApplicationMenu = extractAccessibleRoutes(applicationMenu);

  return [...filteredApplicationMenu];
};

const getRumNaviMenu = () => {
  const filteredRumMenu = extractAccessibleRoutes(rumMenu);

  return [...filteredRumMenu];
};

export { applicationMenu, rumMenu, getApplicationNaviMenu, getRumNaviMenu };
