import { axiosInstance, PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { ChangePasswordRequest } from '../model';

export interface Token {
  accessToken: string;
  refreshToken: string;
}

const postV1RefreshTokenAxios = (tokenInfo: Token): Promise<AxiosResponse<Token>> =>
  axiosInstance.post('/api/v1/refresh-token', tokenInfo);

// RSA 암호화된 유저 패스워드 수정 API
// 개별 header 설정이 필요해서 axios config 추가함
const postV3ChangePasswordAxios = (
  request: ChangePasswordRequest,
  config: AxiosRequestConfig = {},
): PromiseAxiosResponse<string> => axiosInstance.post('/api/v3/change-password', request, config);

export { postV1RefreshTokenAxios, postV3ChangePasswordAxios };
