/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  BusinessAlertDetailResponse,
  BusinessAlertResponse,
  DataMetricResponse,
  ServiceDetailResponse,
  ServiceGroupListFilterResponse,
  ServiceListRequest,
  ServiceListResponse,
  ServiceMetricRequest,
  ServiceTargetKindResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getBusinessCategoryXmServiceControllerAxios = ({
  serviceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  serviceId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ServiceTargetKindResponse> => {
  const localVarPath = '/api/v7/service';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      serviceId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getServiceDetailXmServiceControllerAxios = ({
  serviceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  serviceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ServiceDetailResponse> => {
  const localVarPath = '/api/v7/service/{serviceId}'.replace(
    `{${'serviceId'}}`,
    encodeURIComponent(String(serviceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getServiceGroupListFilterXmServiceControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ServiceGroupListFilterResponse> => {
  const localVarPath = '/api/v7/service/filter';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getServiceListXmServiceControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: Array<ServiceListRequest>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ServiceListResponse> => {
  const localVarPath = '/api/v7/service';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getBusinessAlertDetailXmServiceControllerAxios = ({
  targetKind,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  targetKind: string;
  request: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<BusinessAlertDetailResponse> => {
  const localVarPath = '/api/v7/service/alert-detail';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      targetKind,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getBusinessAlertsXmServiceControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<BusinessAlertResponse> => {
  const localVarPath = '/api/v7/service/alerts';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getMetricsXmServiceControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: Array<ServiceMetricRequest>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<DataMetricResponse> => {
  const localVarPath = '/api/v7/service/metrics';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getBusinessCategoryXmServiceControllerAxios,
  getServiceDetailXmServiceControllerAxios,
  getServiceGroupListFilterXmServiceControllerAxios,
  getServiceListXmServiceControllerAxios,
  getBusinessAlertDetailXmServiceControllerAxios,
  getBusinessAlertsXmServiceControllerAxios,
  getMetricsXmServiceControllerAxios,
};
