/* eslint-disable max-classes-per-file */
import { FilterUUID, WidgetUUID } from '@/common/utils';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { FilterData } from '@/dashboard/types/filter';
import { useUserEnvStore } from '@/common/stores/user-env';
import { useDashboardUserEnv } from '@/common/uses/userEnv/useDashboardUserEnv';
import { DASHBOARD } from '@/common/define/userEnv/dashboard.env.define';
import { standardTimeToUtcZeroTime } from '@/common/utils/commonUtils';
import { cloneDeep } from 'lodash-es';
import { encodeArgParam } from '../utils/dashboardUtils';
import { setInitFilterValue } from '../components/widgetSettingsWindow/filterWidgetSettings/filterData/filterData.utils';
import { WidgetModeType } from '../utils/types';

class WidgetFilterSearch {
  private searchInfo: Map<FilterUUID, FilterData>;

  constructor(searchInfo: Map<FilterUUID, FilterData> = new Map()) {
    this.searchInfo = searchInfo;
  }

  public getSearchInfo() {
    return this.searchInfo;
  }

  public getFilterData(filterUUID: FilterUUID) {
    return this.searchInfo.get(filterUUID);
  }

  public deleteFilterData(filterUUID: FilterUUID) {
    return this.searchInfo.delete(filterUUID);
  }

  public setFilterData<Data extends FilterData>(filterUUID: FilterUUID, filterData: Data) {
    this.searchInfo.set(filterUUID, filterData);
  }

  public initSearchInfo() {
    this.searchInfo.forEach((filter, key) => {
      this.searchInfo.set(key, setInitFilterValue(filter));
    });
  }

  public cloneDeep() {
    return new WidgetFilterSearch(cloneDeep(this.getSearchInfo()));
  }
}

class DashboardFilterSearch {
  private searchInfo: Map<WidgetUUID, WidgetFilterSearch>;

  constructor(searchInfo: Map<WidgetUUID, WidgetFilterSearch> = new Map()) {
    this.searchInfo = searchInfo;
  }

  public getSearchInfo() {
    return this.searchInfo;
  }

  public getWidgetFilterSearch(widgetUUID: WidgetUUID) {
    return this.searchInfo.get(widgetUUID);
  }

  public getFilterDataForWidget(widgetUUID: WidgetUUID, filterUUID: FilterUUID) {
    return this.searchInfo.get(widgetUUID)?.getFilterData(filterUUID);
  }

  public setWidgetFilterData(widgetUUID: WidgetUUID, widgetFilterSearch: WidgetFilterSearch) {
    this.searchInfo.set(widgetUUID, widgetFilterSearch);
  }

  private initializeWidgetFilterSearchIfNotExists(widgetUUID: WidgetUUID) {
    if (!this.searchInfo.get(widgetUUID)) {
      this.searchInfo.set(widgetUUID, new WidgetFilterSearch());
    }
  }

  public setFilterData<Data extends FilterData>(
    widgetUUID: WidgetUUID,
    filterUUID: FilterUUID,
    filterData: Data,
  ) {
    this.initializeWidgetFilterSearchIfNotExists(widgetUUID);
    this.searchInfo.get(widgetUUID)?.setFilterData(filterUUID, filterData);
  }

  public initWidgetSearchInfo(widgetUUID: WidgetUUID) {
    this.searchInfo.get(widgetUUID)?.initSearchInfo();
  }
}

type UserEnvFilterValue = Record<FilterUUID, Pick<FilterData, 'filterId' | 'filterType' | 'value'>>;

const convertUserEnvFilterValue = (value: WidgetFilterSearch): UserEnvFilterValue => {
  const result: UserEnvFilterValue = {};
  value.getSearchInfo().forEach((filterData, key) => {
    result[key] = {
      filterId: filterData.filterId,
      filterType: filterData.filterType,
      value: filterData.value,
    };
  });
  return result;
};

const convertWidgetFilterSearch = (
  userEnvFilterValue: Record<FilterUUID, FilterData>,
): WidgetFilterSearch => {
  const result = new WidgetFilterSearch();
  Object.entries(userEnvFilterValue).forEach(([key, value]) => {
    result.setFilterData(key, cloneDeep(value));
  });
  return result;
};

// string의 경우 ''로 감싼다.
export const getFilterValue = (filterData: FilterData): string | undefined => {
  switch (filterData?.filterType) {
    case 'toggle':
    case 'checkbox':
    case 'text':
    case 'number':
      return encodeArgParam(filterData.value);
    case 'calendar':
      return encodeArgParam(standardTimeToUtcZeroTime(filterData.value));
    case 'selectbox':
    case 'selectbox2':
    case 'target':
      switch (filterData.selectType) {
        case 'single':
          return encodeArgParam(filterData.value.optionItem);
        case 'multiple':
          return encodeArgParam(filterData.value.optionItems);
        default:
          return undefined;
      }
    default:
      return undefined;
  }
};

export const useFilterSearchStore = defineStore('filterSearchStore', () => {
  const dashboardSearchInfo = ref<DashboardFilterSearch>(new DashboardFilterSearch());

  const filterToSearch = ref<DashboardFilterSearch>(new DashboardFilterSearch());
  const searchWidgetUUID = ref<WidgetUUID>('');

  const editFilterSearchInfo = ref<WidgetFilterSearch>(new WidgetFilterSearch());

  const setSearchWidgetUUID = (widgetUUID: WidgetUUID) => {
    searchWidgetUUID.value = widgetUUID;
  };

  const userEnvStore = useUserEnvStore();

  const { getWidgetUserEnvKey } = useDashboardUserEnv();

  // user env에서 filter 정보를 가져와 초기화
  const initFilterInfoUserEnv = (widgetUUID: WidgetUUID) => {
    const userEnvKey = getWidgetUserEnvKey(
      DASHBOARD.FILTERVALUES_dashboard_filterWidget_user,
      widgetUUID,
    );
    const userEnvFilterValue = userEnvStore.getEnvValue(userEnvKey);
    if (userEnvFilterValue) {
      dashboardSearchInfo.value.setWidgetFilterData(
        widgetUUID,
        convertWidgetFilterSearch(userEnvFilterValue),
      );
    }
  };

  // user env에 filter 정보를 저장
  const saveFilterInfoUserEnv = (
    widgetUUID: WidgetUUID,
    newValue: WidgetFilterSearch | undefined,
  ) => {
    const userEnvKey = getWidgetUserEnvKey(
      DASHBOARD.FILTERVALUES_dashboard_filterWidget_user,
      widgetUUID,
    );
    if (newValue) {
      userEnvStore.setEnvValue([{ key: userEnvKey, value: convertUserEnvFilterValue(newValue) }]);
    } else {
      userEnvStore.clearUserEnvValue(userEnvKey);
    }
  };

  const saveDashboardInfoUserEnv = () => {
    dashboardSearchInfo.value.getSearchInfo().forEach((widgetFilterSearch, key) => {
      saveFilterInfoUserEnv(key, widgetFilterSearch);
    });
  };

  const isFilterSetted = (filterData: FilterData) => {
    if (filterData && filterData.required) {
      switch (filterData.filterType) {
        case 'text':
          return !(filterData.value == null || filterData.value === '');
        case 'number':
          return !(filterData.value == null);
        case 'calendar':
          return !(filterData.value == null || filterData.value === '');
        case 'selectbox':
        case 'selectbox2':
        case 'target':
          switch (filterData.selectType) {
            case 'single':
              return !(filterData.value.optionItem == null || filterData.value.optionItem === '');
            case 'multiple':
              return !(filterData.value.optionItems.length === 0);
            default:
              return true;
          }
        default:
          return true;
      }
    }
    return true;
  };

  const isWidgetRequiredNotSetted = (mode: WidgetModeType, widgetUUID: WidgetUUID) => {
    if (mode === 'preview') {
      const filters = editFilterSearchInfo.value.getSearchInfo();
      return filters ? Array.from(filters)?.some(([, item]) => !isFilterSetted(item)) : true;
    }
    const filters = dashboardSearchInfo.value.getWidgetFilterSearch(widgetUUID)?.getSearchInfo();
    return filters ? Array.from(filters)?.some(([, item]) => !isFilterSetted(item)) : true;
  };

  const route = useRoute();
  watch(
    () => route.params.id,
    async () => {
      dashboardSearchInfo.value = new DashboardFilterSearch();
      filterToSearch.value = new DashboardFilterSearch();
    },
  );

  return {
    dashboardSearchInfo,
    filterToSearch,
    searchWidgetUUID,
    editFilterSearchInfo,
    initFilterInfoUserEnv,
    saveFilterInfoUserEnv,
    saveDashboardInfoUserEnv,
    setSearchWidgetUUID,
    isWidgetRequiredNotSetted,
  };
});
