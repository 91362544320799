/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  ApplicationRumInfoResponse,
  ApplicationRumListResponse,
  ApplicationRumPageMetricResponse,
  ApplicationRumPageResponse,
  ApplicationRumScatterResponse,
  ApplicationRumTopResponse,
  ApplicationRumUserInfoResponse,
  CommonRumSearch,
  FilterResponse,
  RumPageFilterRequest,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getApplicationRumInfoApplicationRumPageControllerAxios = ({
  applicationId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  applicationId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApplicationRumInfoResponse> => {
  const localVarPath = '/api/v8/application/rum';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getApplicationRumUserFilterApplicationRumPageControllerAxios = ({
  fromTime,
  toTime,
  applicationId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  toTime: string;
  applicationId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApplicationRumUserInfoResponse> => {
  const localVarPath = '/api/v8/application/rum/meta/user-info';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationId,
      fromTime,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getApplicationRumPageMetricApplicationRumPageControllerAxios = ({
  applicationId,
  fromTime,
  toTime,
  interval,
  pageUrl,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  applicationId: string;
  fromTime: string;
  toTime: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  pageUrl?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApplicationRumPageMetricResponse> => {
  const localVarPath = '/api/v8/application/rum/metricChart';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationId,
      fromTime,
      interval,
      pageUrl,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getRumPageFilterApplicationRumPageControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<FilterResponse> => {
  const localVarPath = '/api/v8/application/rum/page/filter';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getApplicationRumTopListApplicationRumPageControllerAxios = ({
  applicationGroupIds,
  fromTime,
  interval,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  applicationGroupIds?: Array<string>;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApplicationRumTopResponse> => {
  const localVarPath = '/api/v8/application/rum/top-load';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationGroupIds,
      fromTime,
      interval,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getApplicationRumListApplicationRumPageControllerAxios = ({
  search,
  applicationGroupIds,
  fromTime,
  interval,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  search: CommonRumSearch;
  applicationGroupIds?: Array<string>;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApplicationRumListResponse> => {
  const localVarPath = '/api/v8/application/rum/list';
  const httpMethod = 'post';

  const data: any = search;
  const config: any = {
    params: {
      applicationGroupIds,
      fromTime,
      interval,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getApplicationRumPageListApplicationRumPageControllerAxios = ({
  fromTime,
  toTime,
  filter,
  applicationId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  toTime: string;
  filter: RumPageFilterRequest;
  applicationId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApplicationRumPageResponse> => {
  const localVarPath = '/api/v8/application/rum/page/list';
  const httpMethod = 'post';

  const data: any = filter;
  const config: any = {
    params: {
      applicationId,
      fromTime,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getApplicationRumScatterListApplicationRumPageControllerAxios = ({
  fromTime,
  toTime,
  filter,
  applicationId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  toTime: string;
  filter: RumPageFilterRequest;
  applicationId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApplicationRumScatterResponse> => {
  const localVarPath = '/api/v8/application/rum/scatter';
  const httpMethod = 'post';

  const data: any = filter;
  const config: any = {
    params: {
      applicationId,
      fromTime,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getApplicationRumInfoApplicationRumPageControllerAxios,
  getApplicationRumUserFilterApplicationRumPageControllerAxios,
  getApplicationRumPageMetricApplicationRumPageControllerAxios,
  getRumPageFilterApplicationRumPageControllerAxios,
  getApplicationRumTopListApplicationRumPageControllerAxios,
  getApplicationRumListApplicationRumPageControllerAxios,
  getApplicationRumPageListApplicationRumPageControllerAxios,
  getApplicationRumScatterListApplicationRumPageControllerAxios,
};
