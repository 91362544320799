import { useApiTraceStore } from '@/common/stores/api-trace';
import { useAuthStore } from '@/common/stores/auth';
import { EvMessage } from 'evui';
import { onBeforeUnmount } from 'vue';
import router from '@/common/router';
import { isEmpty } from 'lodash-es';

export const onMessageHandler = {
  setApiTraceInfo: (data) => {
    if (data.action) {
      delete data.action;
    }

    useApiTraceStore().setTraceInfo({
      frameName: data.frameName,
      apiResponse: JSON.parse(data?.apiTraceInfo ?? '{}'),
      isError: data.isError,
    });
  },
  logout: () => {
    if (router.currentRoute.value.fullPath === '/manager') {
      router.push('/manager');
    } else {
      router.push('/logout');
    }
  },
  updateToken: () => {
    useAuthStore().updateToken();
  },
  systemError: () => {
    if (import.meta.env.PROD) {
      window.location.href = '/CUSTOM/serverError.html';
    } else {
      console.error('windowMessage.utils.ts: systemError');
    }
  },
  showMessage: (messageOption) => {
    if (!isEmpty(messageOption)) {
      EvMessage(messageOption);
    }
  },
};

export const useMessageEventInMainThread = () => {
  const messageFn = (event) => {
    if (window.origin !== event.origin) {
      return;
    }

    const { action, ...rest } = event?.data ?? {};
    if (action && onMessageHandler[action]) {
      onMessageHandler[action](rest);
    }
  };

  window.addEventListener('message', messageFn);

  onBeforeUnmount(() => {
    window.removeEventListener('message', messageFn);
  });
};
