import { HexagonChartOption } from '@/common/components/molecules/hexagonChart/hexagonChart.types';
import { parsePeriod } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';
import { getCurrentTheme, roundToDigit, roundToDigitNumber } from '@/common/utils/commonUtils';
import { ChartOption, SeriesData } from '@/common/utils/types';
import {
  COLOR_LIST_BY_THEME,
  EXTRA_COLOR_LIST,
  SAFE_DEFAULT_CHART_THEME,
} from '@/dashboard/utils/color.define';
import { CUSTOM_KEY } from '@/dashboard/utils/define';
import type { ChartThemeType, WidgetIntervalType } from '@/dashboard/utils/types';
import { ECharts } from 'echarts';

type rectRadius = {
  tl: number;
  tr: number;
  br: number;
  bl: number;
};

type BarSize = {
  // 타겟 갯수
  dataLength?: number;
  // 차트 wrapper element 사이즈
  elementSize?: number;
  // bar 최소 길이/높이
  minBarSize: number;
};
/**
 * 모서리 둥근 사각형 그려주는 함수(ft. Stackoverflow)
 * https://stackoverflow.com/questions/1255512/how-to-draw-a-rounded-rectangle-on-html-canvas
 * @param context
 * @param x
 * @param y
 * @param w width
 * @param h height
 * @param rd radius
 * @param fill 채울지 여부
 * @param stroke 테두리 여부
 */
export const roundedRect = (
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  rd: number | rectRadius,
  fill: boolean,
  stroke: boolean,
): void => {
  let radius: rectRadius;
  if (typeof rd === 'number') {
    radius = {
      tl: rd,
      tr: rd,
      br: rd,
      bl: rd,
    };
  } else {
    radius = rd;
  }
  context.beginPath();
  context.moveTo(x + radius.tl, y);
  context.lineTo(x + w - radius.tr, y);
  context.quadraticCurveTo(x + w, y, x + w, y + radius.tr);
  context.lineTo(x + w, y + h - radius.br);
  context.quadraticCurveTo(x + w, y + h, x + w - radius.br, y + h);
  context.lineTo(x + radius.bl, y + h);
  context.quadraticCurveTo(x, y + h, x, y + h - radius.bl);
  context.lineTo(x, y + radius.tl);
  context.quadraticCurveTo(x, y, x + radius.tl, y);
  context.closePath();
  if (fill) {
    context.fill();
  }
  if (stroke) {
    context.stroke();
  }
};
export const animate = ({ duration, draw, timing }) => {
  const start: number = performance.now();

  requestAnimationFrame(function run(time) {
    let timeFraction: number = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    const progress = timing(timeFraction);

    draw(progress);

    if (timeFraction < 1) {
      requestAnimationFrame(run);
    }
  });
};

/**
 * 좌측 Grouping Summary 내 top5 바차트
 * ev-chart - bar chart 데이터 구조에 맞게 변경
 *
 * @param dataArr: 오름차순으로 정렬된 배열 데이터
 * @param pointColor: 가장 상단 바(top1) 색상 지정
 * */
export const setEvBarChartData = (dataArr) => {
  if (!dataArr.length) {
    return [];
  }
  const arr = dataArr.reverse();
  const last = arr[arr.length - 1];
  arr[arr.length - 1] = {
    value: last,
    color: '#6AA9F2',
    textColor: '#FFFFFF',
  };
  return arr;
};

/**
 * eChart legend 사용시 'legendselectchanged' 이벤트 콜백으로 사용
 * 모든 series 가 hide 되면 마지막으로 클릭한 series 는 보이도록 함
 * */
export const onClickEChartLegend = function (this: ECharts, { name, selected = {} }) {
  const isDeselectedAll = Object.values(selected).every((isSelected) => !isSelected);
  if (isDeselectedAll && this.setOption) {
    selected[name] = true;
    this.setOption({
      legend: { selected },
    });
  }
};

export const getHexagonChartOption = (userOptions: HexagonChartOption) => {
  const options: HexagonChartOption = {
    gap: 0,
    padding: 10,
    top: 10,
    minSize: 20,
    maxSize: 40,
  };

  const keys = Object.keys(options);
  keys.forEach((key) => {
    if (userOptions && userOptions[key]) {
      options[key] = userOptions[key];
    }
  });

  return options;
};

// baseHexColor 기반으로 값을 계산하여 darkColor or lightColor 를 (font) 반환
export const getContrastColor = ({
  baseHexColor,
  darkColor,
  lightColor,
}: {
  baseHexColor: string;
  darkColor: string;
  lightColor: string;
}) => {
  // ## Comment hex to rgb
  const hex = baseHexColor.replace(/^#/, '');
  const bigint = parseInt(hex, 16);
  /* eslint-disable */
  // no-bitwise
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  /* eslint-enable */

  // ## Comment (dark) 0 ... 1000 (light)
  const brightness = (r * 299 + g * 587 + b * 114) / 256;

  return brightness > 600 ? darkColor : lightColor;
};

/**
 * 현재 theme에 따른 전체 color list 반환
 * @returns {Record<ChartThemeType, string[]>}
 */
export const getColorListByTheme = (): Record<ChartThemeType, string[]> => {
  const currentTheme = getCurrentTheme();
  return COLOR_LIST_BY_THEME[currentTheme];
};

export const getExtraColorListByTheme = (): string[] => {
  const currentTheme = getCurrentTheme();
  return EXTRA_COLOR_LIST[currentTheme];
};

/**
 * 이전에 설정해둔 theme(BLOSSOM, ...)가 없어지면서
 * 이전에 설정된 theme가 COLOR_LIST_BY_THEME에 없을 경우 COLOR_LIST_BY_THEME[SAFE_DEFAULT_CHART_THEME] 반환 (LG 요구 사항 및 앞으로 변경될 수 있는 theme에 대한 대비)
 * 그렇지 않으면 현재 theme에 해당되는 color list 반환
 * @returns {string[]}
 */
export const getSafeColorListByTheme = (theme: string): string[] => {
  const themes = Object.keys(getColorListByTheme());

  const colorListByTheme: string[] = themes.includes(theme)
    ? getColorListByTheme()[theme]
    : getColorListByTheme()[SAFE_DEFAULT_CHART_THEME];

  const extraList = getExtraColorListByTheme().filter(
    (color: string) => !colorListByTheme.includes(color),
  );

  return colorListByTheme.concat(extraList);
};

/**
 * 이전에 설정해둔 theme(BLOSSOM, ...)가 없어지면서
 * 이전에 설정된 theme가 COLOR_LIST_BY_THEME key에 없을 경우 SAFE_DEFAULT_CHART_THEME 반환 (LG 요구 사항 및 앞으로 변경될 수 있는 theme에 대한 대비)
 * 그렇지 않으면 선택된 color theme명 반환
 * @returns {string}
 */
export const getSafeSelectedTheme = (selected = ''): string => {
  return Object.keys(getColorListByTheme()).includes(selected) || selected === CUSTOM_KEY
    ? selected
    : SAFE_DEFAULT_CHART_THEME;
};

export const useEvChartStyle = () => {
  const theme = getCurrentTheme();

  const stylesByTheme = {
    dark: {
      axisStyle: {
        axisLineColor: '#515151',
        gridLineColor: '#515151',
        labelStyle: {
          color: '#EEEEEE',
          fontSize: 11,
          fontFamily: 'Roboto',
          fitWidth: true,
        },
      },
      legendStyle: {
        color: '#FFFFFF',
        inactive: '#AAAAAA',
      },
      tooltipStyle: {
        colorShape: 'circle',
        fontColor: {
          title: '#FFFFFF',
          label: '#C8C8C8',
          value: '#EEEEEE',
        },
        fontSize: {
          title: 12,
          contents: 11,
        },
        rowPadding: {
          left: 8,
          right: 10,
        },
        useScrollbar: true,
        maxHeight: 410,
      },
      indicatorStyle: {
        color: '#FFFFFF',
        segments: [6, 2],
      },
      tipStyle: {
        tipBackground: '#515151',
        tipStyle: {
          textColor: '#FFFFFF',
          fontWeight: 500,
          fontSize: 12,
        },
        indicatorColor: '#FFFFFF',
      },
      dragSelectionStyle: {
        fillColor: '#41A2FF',
        opacity: 0.2,
      },
      brushStyle: {
        selection: {
          fillColor: '#C8C8C8',
          opacity: 0.3,
        },
      },
      scrollbarStyle: {
        width: 6,
        background: 'transparent',
        thumbStyle: {
          background: '#2D2D2D',
          radius: 1,
        },
      },
      heatmapStrokeStyle: {
        color: '#242424',
        lineWidth: 8,
        radius: 1,
      },
      noDataSeriesColor: '#3A3A3A',
      maxTipStyle: {
        background: '#50BC5E',
        textColor: '#FFFFFF',
        fontSize: 12,
        fontFamily: 'Roboto',
        fontWeight: 500,
      },
    },
    light: {
      axisStyle: {
        axisLineColor: '#D8DBDE',
        gridLineColor: '#D8DBDE',
        labelStyle: {
          color: '#434A54',
          fontSize: 11,
          fontFamily: 'Roboto',
          fitWidth: true,
        },
      },
      legendStyle: {
        color: '#434A54',
        inactive: '#b1b7c0',
      },
      tooltipStyle: {
        colorShape: 'circle',
        fontColor: {
          title: '#282C32',
          label: '#626872',
          value: '#434A54',
        },
        fontSize: {
          title: 12,
          contents: 11,
        },
        rowPadding: {
          left: 8,
          right: 10,
        },
        useScrollbar: true,
        maxHeight: 410,
      },
      indicatorStyle: {
        color: '#626872',
        segments: [6, 2],
      },
      tipStyle: {
        tipBackground: '#515151',
        tipStyle: {
          textColor: '#FFFFFF',
          fontWeight: 500,
          fontSize: 12,
        },
        indicatorColor: '#80868F',
      },
      dragSelectionStyle: {
        fillColor: '#41A2FF',
        opacity: 0.2,
      },
      brushStyle: {
        selection: {
          fillColor: '#9FA5AE',
          opacity: 0.3,
        },
      },
      scrollbarStyle: {
        width: 6,
        background: '#EDF0F2',
        thumbStyle: {
          background: '#D8DBDE',
          radius: 1,
        },
      },
      heatmapStrokeStyle: {
        color: '#FFFFFF',
        lineWidth: 8,
        radius: 1,
      },
      noDataSeriesColor: '#E3E7EA',
      maxTipStyle: {
        background: '#50BC5E',
        textColor: '#FFFFFF',
        fontSize: 12,
        fontFamily: 'Roboto',
        fontWeight: 500,
      },
    },
  };

  return {
    ...stylesByTheme[theme],
  };
};

export const useCommonChartOptions = () => {
  const { axisStyle, legendStyle, tooltipStyle, indicatorStyle, tipStyle } = useEvChartStyle();

  const multiViewLineChartOption: ChartOption = {
    type: 'line',
    padding: {
      top: 4,
      right: 0,
      left: -10,
      bottom: 4,
    },
    legend: {
      ...legendStyle,
      show: false,
      width: 120,
    },
    tooltip: {
      ...tooltipStyle,
      use: true,
      formatter: ({ y }) => `${roundToDigitNumber(y, 3)}`,
    },
    axesX: [
      {
        ...axisStyle,
        type: 'time',
        showGrid: false,
        timeFormat: 'HH:mm:ss',
        interval: 'second',
      },
    ],
    axesY: [
      {
        ...axisStyle,
        type: 'linear',
        showGrid: true,
        showAxis: false,
        startToZero: true,
        autoScaleRatio: 0.1,
      },
    ],
    indicator: {
      ...indicatorStyle,
    },
  };

  const top5ChartOption: ChartOption = {
    type: 'bar',
    padding: {
      top: 0,
      right: 2,
      left: 2,
      bottom: 4,
    },
    thickness: 1,
    legend: {
      show: false,
    },
    horizontal: true,
    borderRadius: 5,
    axesY: [
      {
        ...axisStyle,
        type: 'step',
        showGrid: false,
      },
    ],
    axesX: [
      {
        ...axisStyle,
        type: 'linear',
        startToZero: true,
        autoScaleRatio: 0.2,
        showGrid: true,
      },
    ],
    indicator: {
      use: false,
    },
    selectItem: {
      ...tipStyle,
      use: true,
    },
    tooltip: {
      ...tooltipStyle,
    },
  };

  const theme = getCurrentTheme();
  const top5ChartValueColorOnBar = theme === 'dark' ? '#FFFFFF' : '#626872';
  const top5ChartSeriesOptions: SeriesData = {
    showValue: {
      use: true,
      fontSize: 10,
      textColor: top5ChartValueColorOnBar,
      align: 'start',
    },
    color: theme === 'dark' ? '#777777' : '#d8dbde',
  };
  const top5ChartPercentSeriesOptions: SeriesData = {
    ...top5ChartSeriesOptions,
    showValue: {
      ...top5ChartSeriesOptions.showValue,
      formatter: (value) => `${roundToDigit(value, 1)}%`,
    },
  };

  return {
    multiViewLineChartOption,
    top5ChartOption,
    top5ChartPercentSeriesOptions,
    top5ChartSeriesOptions,
    top5ChartValueColorOnBar,
  };
};

export const useEChartStyle = () => {
  const theme = getCurrentTheme();

  const stylesByTheme: {
    [theme: string]: {
      timeRangeBarStyle: {
        alertLabels: string[];
        tooltipTextColor: string;
        tooltipBackgroundColor: string;
        emphasisShadowColor: string;
        axisLabelColor: string;
      };
      pieChartStyle: {
        markerColor: string;
        emphasis: {
          borderColor: string;
          borderWidth: number;
          shadowColor: string;
          shadowBlur: number;
        };
      };
      treemapStyle: {
        valueColors: {
          BG1: string;
          BG2: string;
          BG3: string;
          BG4: string;
          BG5: string;
        };
        borderColor: string;
        labelColor: string;
        dimColor: string;
      };
    };
  } = {
    dark: {
      timeRangeBarStyle: {
        alertLabels: ['#3A3A3A', '#087DED', '#F9CB3B', '#EB1331'],
        tooltipTextColor: '#EEEEEE',
        tooltipBackgroundColor: '#3A3A3A',
        emphasisShadowColor: '#FFFFFF',
        axisLabelColor: '#777777',
      },
      pieChartStyle: {
        markerColor: '#EEEEEE',
        emphasis: {
          borderColor: 'rgb(255, 255, 255, 0.5)',
          borderWidth: 2,
          shadowColor: '#00000080',
          shadowBlur: 10,
        },
      },
      treemapStyle: {
        valueColors: {
          BG1: '#93C3DB',
          BG2: '#84A9D0',
          BG3: '#7596C8',
          BG4: '#6684BF',
          BG5: '#5772B6',
        },
        borderColor: 'transparent',
        labelColor: 'rgba(255, 255, 255, 0.6)',
        dimColor: '#272727',
      },
    },
    light: {
      timeRangeBarStyle: {
        alertLabels: ['#E3E7EA', '#087DED', '#F9CB3B', '#FF1F3F'],
        tooltipTextColor: '#434a54',
        tooltipBackgroundColor: '#FFFFFF',
        emphasisShadowColor: '#000000',
        axisLabelColor: '#777777',
      },
      pieChartStyle: {
        markerColor: '#434A54',
        emphasis: {
          borderColor: 'rgb(255, 255, 255, 0.6)',
          borderWidth: 2,
          shadowColor: '#0000004D',
          shadowBlur: 10,
        },
      },
      treemapStyle: {
        valueColors: {
          BG1: '#93C3DB',
          BG2: '#84A9D0',
          BG3: '#7596C8',
          BG4: '#6684BF',
          BG5: '#5772B6',
        },
        borderColor: 'transparent',
        labelColor: '#FFFFFF',
        dimColor: '#D8DBDE',
      },
    },
  };

  return {
    ...stylesByTheme[theme],
  };
};

export const getCustomPeriodInterval = (
  timePeriod?: string,
  periodInfo?: { fromTime: number; toTime: number },
): WidgetIntervalType => {
  const { offset } = timePeriod
    ? parsePeriod(timePeriod)
    : { offset: Math.floor((periodInfo!.toTime - periodInfo!.fromTime) / 1000) };

  const MINUTE = 60;
  const ONE_HOUR = 60 * MINUTE;
  const ONE_DAY = 24 * ONE_HOUR;

  let interval: WidgetIntervalType = 'I24h';
  if (offset <= 10 * MINUTE) {
    interval = 'I5s';
  } else if (offset <= ONE_HOUR) {
    interval = 'I1m';
  } else if (offset <= ONE_DAY) {
    interval = 'I10m';
  } else if (offset <= 7 * ONE_DAY) {
    interval = 'I1h';
  }

  return interval;
};

// ## 시간단위 s,m,h,d 만 가능
// ## 초과 ~ 이하
export const getTimeFormatByPeriod = (timePeriod: string): string => {
  const { offset } = parsePeriod(timePeriod);
  let format = 'HH:mm:ss';

  const MINUTE_15 = 15 * 60;
  const HOUR_1 = 60 * 60;
  const DAY_1 = 24 * 60 * 60;

  if (offset > MINUTE_15 && offset <= HOUR_1) {
    format = 'HH:mm';
  } else if (offset >= DAY_1) {
    format = 'DD HH:MM';
  }

  return format;
};

const CHART_INTERVAL_BY_FORMAT = {
  'HH:mm:ss': 'second',
  'HH:mm': 'minute',
  'DD HH:MM': 'hour',
};
export const getTimeFormatAndChartIntervalByPeriod = (period: string) => {
  const timeFormat = getTimeFormatByPeriod(period);
  const chartInterval = CHART_INTERVAL_BY_FORMAT?.[timeFormat] ?? 'second';

  return {
    timeFormat,
    chartInterval,
  };
};
// 바 차트 스크롤 할당시 높이/넓이 체크
export const getBarChartArea = ({ dataLength = 0, elementSize = 0, minBarSize }: BarSize) => {
  if (elementSize && elementSize < minBarSize * dataLength) {
    return `${minBarSize * dataLength}px`;
  }
  return '100%';
};

export default {
  roundedRect,
  animate,
  getHexagonChartOption,
};
