import { defineStore } from 'pinia';
import { ref } from 'vue';
import { RuleInfoProps } from '@/alert/components/alertDetail/alertDetail.types';
import { AlertRuleDetail } from '@/openapi/alert/model';

export const useAlertDetailStore = defineStore('alertDetail', () => {
  const selectedRuleInfo = ref<RuleInfoProps>({
    name: '',
    type: 'user',
  });

  const userAlertInformationData = ref<AlertRuleDetail | null>(null);

  const setUserAlertInformationData = (data: AlertRuleDetail | null) => {
    userAlertInformationData.value = data;
  };

  return {
    selectedRuleInfo,
    userAlertInformationData,
    setUserAlertInformationData,
  };
});
