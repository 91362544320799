/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  AlertEventRuleRequest,
  AlertKubernetesEventResponse,
  AlertMetricRuleRequest,
  AlertRuleDetailResponse,
  DefaultContentResponse,
  MetaAlertRuleListResponse,
  ResultResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const removeAlertRulesMetaAlertRuleControllerAxios = ({
  alertRuleIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertRuleIds: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/metav6/alert-rules';
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      alertRuleIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const removeK8sReasonMetaAlertRuleControllerAxios = ({
  eventId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  eventId: Array<number>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/metav6/kubernetes/alert-rules/reason';
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      eventId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getMetaAlertRuleControllerAxios = ({
  alertGroupIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertGroupIds: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MetaAlertRuleListResponse> => {
  const localVarPath = '/api/v6/metav6/alert-groups/alert-rules';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      alertGroupIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getDetailMetaAlertRuleControllerAxios = ({
  alertRuleId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertRuleId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertRuleDetailResponse> => {
  const localVarPath = '/api/v6/metav6/alert-rules/{alertRuleId}'.replace(
    `{${'alertRuleId'}}`,
    encodeURIComponent(String(alertRuleId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getK8sReasonByKindMetaAlertRuleControllerAxios = ({
  kind,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  kind:
    | 'cronjob'
    | 'daemonset'
    | 'deployment'
    | 'job'
    | 'node'
    | 'pod'
    | 'replicaset'
    | 'service'
    | 'statefulset';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertKubernetesEventResponse> => {
  const localVarPath = '/api/v6/metav6/kubernetes/alert-rules/{kind}/reason'.replace(
    `{${'kind'}}`,
    encodeURIComponent(String(kind)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getDefaultContentMetaAlertRuleControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<DefaultContentResponse> => {
  const localVarPath = '/api/v6/metav6/notification/default-content';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const eventUpdateMetaAlertRuleControllerAxios = ({
  alertRuleId,
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertRuleId: string;
  request: AlertEventRuleRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/metav6/alert-rules/event/{alertRuleId}'.replace(
    `{${'alertRuleId'}}`,
    encodeURIComponent(String(alertRuleId)),
  );
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const metricUpdateMetaAlertRuleControllerAxios = ({
  alertRuleId,
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertRuleId: string;
  request: AlertMetricRuleRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/metav6/alert-rules/metric/{alertRuleId}'.replace(
    `{${'alertRuleId'}}`,
    encodeURIComponent(String(alertRuleId)),
  );
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateK8sReasonMetaAlertRuleControllerAxios = ({
  eventId,
  kind,
  reason,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  eventId: number;
  kind:
    | 'cronjob'
    | 'daemonset'
    | 'deployment'
    | 'job'
    | 'node'
    | 'pod'
    | 'replicaset'
    | 'service'
    | 'statefulset';
  reason: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/metav6/kubernetes/alert-rules/{eventId}/reason'.replace(
    `{${'eventId'}}`,
    encodeURIComponent(String(eventId)),
  );
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      kind,
      reason,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const eventAddMetaAlertRuleControllerAxios = ({
  alertGroupId,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertGroupId: string;
  request: AlertEventRuleRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/metav6/alert-groups/{alertGroupId}/event/alert-rules'.replace(
    `{${'alertGroupId'}}`,
    encodeURIComponent(String(alertGroupId)),
  );
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const metricAddMetaAlertRuleControllerAxios = ({
  alertGroupId,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertGroupId: string;
  request: AlertMetricRuleRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/metav6/alert-groups/{alertGroupId}/metric/alert-rules'.replace(
    `{${'alertGroupId'}}`,
    encodeURIComponent(String(alertGroupId)),
  );
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addK8sReasonMetaAlertRuleControllerAxios = ({
  kind,
  reasons,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  kind:
    | 'cronjob'
    | 'daemonset'
    | 'deployment'
    | 'job'
    | 'node'
    | 'pod'
    | 'replicaset'
    | 'service'
    | 'statefulset';
  reasons: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertKubernetesEventResponse> => {
  const localVarPath = '/api/v6/metav6/kubernetes/alert-rules/{kind}/reason'.replace(
    `{${'kind'}}`,
    encodeURIComponent(String(kind)),
  );
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      reasons,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  removeAlertRulesMetaAlertRuleControllerAxios,
  removeK8sReasonMetaAlertRuleControllerAxios,
  getMetaAlertRuleControllerAxios,
  getDetailMetaAlertRuleControllerAxios,
  getK8sReasonByKindMetaAlertRuleControllerAxios,
  getDefaultContentMetaAlertRuleControllerAxios,
  eventUpdateMetaAlertRuleControllerAxios,
  metricUpdateMetaAlertRuleControllerAxios,
  updateK8sReasonMetaAlertRuleControllerAxios,
  eventAddMetaAlertRuleControllerAxios,
  metricAddMetaAlertRuleControllerAxios,
  addK8sReasonMetaAlertRuleControllerAxios,
};
